import "./MailDet/MailDet.scss"

import { useEffect, useState} from "react";
import { useParams} from "react-router-dom";

import useAxios from "../../hook/useAxios";
import {useLogin} from "../../hook/useLogin";

import { MesForm } from "./MailDet/MesForm";
import {MesItem } from "./MailDet/MesItem";
import { Top } from "./MailDet/Top";

const MailDet = () => {
    var {id}=useParams();

    const {user}=useLogin();
    const axios=useAxios(user);

    const [data,setData] = useState(null);

    // get mail
    useEffect(()=>{
        axios.get('/mail/mes/',{params:{id:id}})
        .then(res=>res.data)
        .then(data=>{
            setData(data.res);
            // data.res
            // ?setData(data.res)
            // :navigate('../mail')
        });
    },[]);

    const append = (item) => {
        const newMes=data.mes.concat(item);
        setData({...data,mes:newMes});
    }

    return (
        data?<div className="mail">
            
            <Top data={data}/>
        
            <MesItem data={data.mes} setData={setData}/>

            <MesForm id={id} setData={setData} append={item=>append(item)}/>
    
        </div>:<></>
    )
}

export {MailDet}