import {Link} from 'react-router-dom';
import { useLogin } from '../../hook/useLogin';

const Item = ({children,link,block}) => {

    const {user,setModal,setPay}=useLogin();

    // block - need pay
    // user - loged
    // children - content

    return (
        user
        ?
            block
            ?<div className="item" onClick={()=>setPay(true)}>
                {children}
            </div>
            :<Link to={link} state={0} className="item">{children}</Link>
        :<div className="item" onClick={()=>setModal({open:true,st:'mn'})}>
            {children}
        </div>
            
                
    )
}

export {Item}