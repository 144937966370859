import { useState } from 'react';
//import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import { Link} from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import { MySelect } from "../../../components/MySelect";
import { Errors } from "../../../components/Errors";

import { MesOk } from "./MesOk";
import { MesArh } from "./MesArh";
import { NewEditor } from '../../../components/NewEditor/NewEditor';
import { MyCreatable } from '../../../components/MyCreatable';

const Form = ({axios,data,type,pay}) =>{

    const [mesOk,setMesOk] = useState(false);
    const [mesArh,setMesArh] = useState(false);

    // parse
    const err = {
        req: 'bitte ausfüllen'
    }

    //const navigate=useNavigate();

    const {
        register, 
        handleSubmit,
        control, 
        formState: {errors},
    } = useForm({
        defaultValues:data
    });

    const [status,setAction] = useState(0);

    // send
    const onSubmit = data =>{

        // prepare to send
        data={...data,status:status};

        axios.post('/firm/save',{...data || false})
        .then(res=>res.data)
        .then(data=>{
            // if more > 10 proj
            if(data.pay){
                pay(true);
            }else{
                // save or publicate
                status
                ?setMesArh(true)
                :setMesOk(true)
            }
        });
    };

    const gender = [
        {"label":'Frau',"value":0},
        {"label":'Herr',"value":1}
    ]

    return (
        <>
            {mesOk?<MesOk setShow={setMesOk}/>:''}
            {mesArh?<MesArh setShow={setMesArh}/>:''}

            <div className="fr-pf">
                <div className="fr-pf-content">
                
                    <form onSubmit={handleSubmit(onSubmit)}>
                        
                        <div className="fr-pf-title">
                            <div className="font-h3b-24">Projekt Information</div>
                        </div>

                        <div className="form _tree">
                            <div className="_span">
                                <div className="inputs-block _label _span">
                                    <label>Projekt Titel</label>
                                    <input 
                                        {...register("projName",{required:err.req})}
                                        className="inputs off" 
                                        autoComplete="off"/>
                                </div>
                                <Errors error={errors?.projName?.message}/>
                            </div>

                            <div className="_span">
                                <div className="inputs-block _label _span">
                                    <label>Kurze Beschreibung</label>
                                    <input 
                                        {...register("shortText",{required:err.req})}
                                        className="inputs off" 
                                        autoComplete="off"/>
                                </div>
                                <Errors error={errors?.shortText?.message}/>
                            </div>

                            <div className='_span'>
                                <div className="inputs-block _label _span _select">
                                    <label>Kategorieauswahl</label>
                                    <Controller 
                                        name="cats" 
                                        control={control}
                                        rules={{required:err.req}}
                                        render={({field:{onChange,value}})=>
                                            <MySelect 
                                                isMulti={true}
                                                isSearchable={false}
                                                value={type.cat.filter(item=>value.includes(item.value))}
                                                onChange={value=>onChange(value.map(item=>item.value))}
                                                placeholder="All categories"
                                                options={type.cat}
                                            />
                                        }
                                    />
                                </div>
                                <Errors error={errors?.cats?.message}/>
                            </div>

                            <div className='_span'>
                                <div className="inputs-block _label _span _select">
                                    <label>Skils</label>
                                    <Controller 
                                        name="skills" 
                                        control={control}
                                        rules={{required:err.req}}
                                        render={({field:{onChange,value}})=>
                                            <MyCreatable
                                                value={value}
                                                onChange={e=>onChange(e)}
                                                placeholder="Schlagwörter für bessere Suchoptionen eingeben über Koma"
                                                options={type.skills}
                                            />
                                        }
                                    />
                                </div>
                                <Errors error={errors?.skills?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label">
                                    <label>Beschäftigung</label>
                                    <input 
                                        {...register("job",{required:false})}
                                        className="inputs off" 
                                        autoComplete="off"/>
                                </div>
                                <Errors error={errors?.job?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label _select">
                                    <label>Projekt Art</label>
                                    <Controller 
                                        name="idType" 
                                        control={control}
                                        rules={{required:err.req}}
                                        render={({field:{onChange,value}})=>
                                            <MySelect 
                                                isSearchable={false}
                                                value={type.proj.filter(item=>item.value===value)}
                                                onChange={e=>onChange(e.value)}
                                                options={type.proj}/>
                                        }
                                    />
                                </div>
                                <Errors error={errors?.idType?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label _select">
                                    <label>Auslastung</label>
                                    <Controller 
                                        name="idWork" 
                                        control={control}
                                        rules={{required:err.req}}
                                        render={({field:{onChange,value}})=>
                                            <MySelect 
                                                isSearchable={false}
                                                value={type.work.filter(item=>item.value===value)}
                                                onChange={e=>onChange(e.value)} 
                                                options={type.work}/>
                                        }
                                    />
                                </div>
                                <Errors error={errors?.idWork?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label">
                                    <label>Projekt Nr.</label>
                                    <input 
                                        {...register("projNumber",{ required: false })}
                                        className="inputs off" 
                                        autoComplete="off"/>
                                </div>
                                <Errors error={errors?.projNumber?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label _select">
                                    <label>Land</label>
                                    <Controller 
                                        name="idLand" 
                                        control={control}
                                        rules={{required:err.req}}
                                        render={({field:{onChange,value}})=>
                                            <MySelect 
                                                isSearchable={false}
                                                value={type.lands.filter(item=>item.value===value)}
                                                onChange={e=>onChange(e.value)} 
                                                options={type.lands}/>
                                        }
                                    />
                                </div>
                                <Errors error={errors?.idLand?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label">
                                    <label>Stadt</label>
                                    <input 
                                        {...register("locationJson.city",{required:err.req})}
                                        className="inputs off" 
                                        autoComplete="off"/>
                                </div>
                                <Errors error={errors?.locationJson?.city?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label">
                                    <label>PLZ</label>
                                    <input 
                                        {...register("locationJson.code",{required:false})}
                                        className="inputs off" 
                                        autoComplete="off"/>
                                </div>
                                <Errors error={errors?.locationJson?.code?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label">
                                    <label>Start</label>
                                    <Controller 
                                        name="startData" 
                                        control={control}
                                        rules={{required:err.req}}
                                        render={({field:{onChange,value}})=>
                                            <DatePicker 
                                                selected={value}
                                                closeOnScroll={true}
                                                alwaysShowMask={false}
                                                onChange={onChange}
                                                className="myDatepicker"
                                                dateFormat="MM/yyyy"
                                                placeholder="Datum auswählen"
                                                showMonthYearPicker
                                                // customInput={
                                                //     <InputMask mask="99.99.9999" maskChar={null}/>
                                                // }
                                            />
                                        }
                                    />
                                    <div className="icons _cal _small inputs-ico"></div>
                                </div>
                                <Errors error={errors?.startData?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label">
                                    <label>Ende</label>
                                    <Controller 
                                        name="endData" 
                                        control={control}
                                        rules={{required:false}}
                                        render={({field:{onChange,value}})=>
                                            <DatePicker 
                                                selected={value}
                                                closeOnScroll={true}
                                                alwaysShowMask={false}
                                                onChange={onChange}
                                                className="myDatepicker"
                                                dateFormat="MM/yyyy"
                                                showMonthYearPicker
                                                placeholder="Datum auswählen"
                                                // customInput={
                                                //     <InputMask mask="99.99.9999" maskChar={null}/>
                                                // }
                                            />
                                        }
                                    />
                                    <div className="icons _cal _small inputs-ico"></div>
                                </div>
                                <Errors error={errors?.endData?.message}/>
                            </div>
                        </div>

                        <div className="fr-pf-title">
                            <div className="font-h3b-24">Ansprechpartner</div>
                        </div>

                        <div className="form">
                            <div>
                                <div className="inputs-block _label _select">
                                    <label className="font-text5-14">Anrede</label>
                                    <Controller 
                                        name="gender" 
                                        control={control}
                                        rules={{required:err.req}}
                                        render={({field:{onChange,value}})=>
                                            <MySelect 
                                                isSearchable={false}
                                                value={gender.filter(item=>item.value===value)}
                                                onChange={e=>onChange(e.value)} 
                                                options={gender}
                                            />
                                        }
                                    />
                                </div>
                                <Errors error={errors?.gender?.message}/>
                            </div>
                            <div>
                                <div className="inputs-block _label">
                                    <label>Kontakt Person</label>
                                    <input 
                                        {...register("contactName",{required:err.req})}
                                        className="inputs off" 
                                        autoComplete="off"/>
                                </div>
                                <Errors error={errors?.contactName?.message}/>
                            </div>
                            <div>
                                <div className="inputs-block _label">
                                    <label>E-Mail Adresse</label>
                                    <input 
                                        {...register("contactEmail",{required:err.req})}
                                        className="inputs off" 
                                        autoComplete="off"/>
                                </div>
                                <Errors error={errors?.contactEmail?.message}/>
                            </div>
                            <div>
                                <div className="inputs-block _label">
                                    <label>Telefonnummer</label>
                                    <input
                                        {...register("contactPhone",{required:err.req})}
                                        onKeyPress={(e) => !/[0-9\+\-\(\)\s]/.test(e.key) && e.preventDefault()}/>
                                </div>
                                <Errors error={errors?.contactPhone?.message}/>
                            </div>
                        </div>

                        <div className="fr-pf-title">
                            <div className="font-h3b-24">Projekt beschreibung</div>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <Controller 
                                    name="descrText" 
                                    control={control}
                                    rules={{required:false}}
                                    render={({field:{onChange,value}})=>
                                        <NewEditor 
                                            html={value}
                                            placeholder="Bitte beschreiben Sie Ihr Unternehmen"
                                            onChange={html=>onChange(html)}
                                        />
                                    }
                                />
                            </div>
                            <Errors error={errors?.descrText?.message}/>
                        </div>

                        <div className="fr-pf-title">
                            <div className="font-h3b-24">Aufgaben</div>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <Controller 
                                    name="taskText" 
                                    control={control}
                                    rules={{required:false}}
                                    render={({field:{onChange,value}})=>
                                        <NewEditor 
                                            html={value}
                                            placeholder="Bitte beschreiben Sie Ihr Unternehmen"
                                            onChange={html=>onChange(html)}
                                        />
                                    }
                                />
                            </div>
                            <Errors error={errors?.taskText?.message}/>
                        </div>

                        <div className='form _free'>
                            <div className="_span">
                                <div className="inputs-block _label _span">
                                    <label>Link zum Projekt</label>
                                    <input 
                                        {...register("taskLink",{required:false})}
                                        className="inputs off" 
                                        autoComplete="off"/>
                                </div>
                            </div>
                        </div>

                        <div className="fr-pf-title">
                            <div className="font-h3b-24">Anforderungen</div>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <Controller 
                                    name="requireText" 
                                    control={control}
                                    rules={{required:false}}
                                    render={({field:{onChange,value}})=>
                                        <NewEditor 
                                            html={value}
                                            placeholder="Bitte beschreiben Sie Ihr Unternehmen"
                                            onChange={html=>onChange(html)}
                                        />
                                    }
                                />
                            </div>
                            <Errors error={errors?.requireText?.message}/>
                        </div>

                        <div className="fr-pf-title">
                            <div className="font-h3b-24">Was wir bieten</div>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <Controller 
                                    name="proposeText" 
                                    control={control}
                                    rules={{required:false}}
                                    render={({field:{onChange,value}})=>
                                        <NewEditor 
                                            html={value}
                                            placeholder="Bitte beschreiben Sie Ihr Unternehmen"
                                            onChange={html=>onChange(html)}
                                        />
                                    }
                                />
                            </div>
                            <Errors error={errors?.proposeText?.message}/>
                        </div>
                        

                        {/* <div className="fr-pf-title">
                            <div className="font-h3b-24">Benefits</div>
                        </div>
                        <div>

                            <div className="inputs-block _label">
                                <Controller 
                                    name="benefitText" 
                                    control={control}
                                    render={({field:{onChange,value}})=>
                                        <NewEditor 
                                            html={value}
                                            placeholder="Bitte beschreiben Sie Ihr Unternehmen"
                                            onChange={html=>onChange(html)}
                                        />
                                    }
                                />
                            </div>
                            <Errors error={errors?.benefitText?.message}/>
                        </div> */}

                        <div className="form _button">
                            {data.id?
                            <div>
                                <Link 
                                    to={`/proj/${data.id}`}
                                    rel="nofollow" 
                                    className="button button-secondary">
                                    ANZEIGEN
                                </Link>
                            </div>:<></>}
                            <div>
                                <button 
                                type="submit"
                                onClick={()=>setAction(1)}
                                className="button button-secondary">
                                speichern</button>
                            </div><div>
                                <button
                                type="submit"
                                onClick={()=>setAction(0)}
                                className="button button-primary">
                                veröffentlichen</button>
                            </div>
                        </div>

                        <div>Information nach Artikel 13 DSGVO</div>
                    </form>
                </div>
            </div>
        </>
    )
}

export {Form}