import ReactTooltip from "react-tooltip";

import { useState } from "react";
import {useLogin} from "../hook/useLogin";
import useAxios from "../hook/useAxios";

const Like = ({id,type,like}) =>{

    const [active,setActive]=useState(like);
    
    const {user,setModal}=useLogin();
    const axios = useAxios(user);

    // like
    const setLike = (e)=>{
        
        e.stopPropagation();
        e.preventDefault();
        
        // save like or open modal 
        user?sendLike():setModal({open:true,st:'mn'});
    }

    const sendLike = ()=>{
        // change status
        setActive(!active);
        // send server
        axios.post('/user/like',{id:id,type:type})
    }

    return (
        <>
            <ReactTooltip />
            <div 
                className={`icons _heart ${active ? 'active':''}`} 
                data-help="like" data-tip="folgen" data-place="top" data-effect="solid" data-class="c-tooltips"
                onClick={e=>setLike(e)}>
            </div>
        </>
    )
    
}

export {Like}