import {Navi} from '../../../components/Navi';

const Info = ({config}) =>{
    const navi=[
        {name:'Home',url:'/'},
        {name:'Kontakt',url:'/contacts'}
    ]

    return (
        <div className="contacts-part">
            <Navi list={navi}/>
            <div className="contacts-logo">
                <img src='/img/logo/logo.png' alt=""/>    
            </div>
            <div className="contacts-info">
                <div className='contacts-info-item'>
                    <div className="icons _map"></div>
                    <div>{config.adres}</div>
                </div>
                <div className='contacts-info-item'>
                    <div className="icons _web "></div>
                    <div>{config.web}</div>
                </div>
                <div className="contacts-info-item">
                    <div className="icons _email "></div>
                    <div>{config.email}</div>
                </div>
                <div className="contacts-info-item">
                    <div className="icons _phone "></div>
                    <div>{config.phone}</div>
                </div>
            </div>
        </div>
    )
}
export {Info}