import "./NewsDet.scss";
import { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";

import useAxios from '../../hook/useAxios';
import {useLogin} from '../../hook/useLogin';

import { Navi } from "../../components/Navi";
import { Loading } from "../../components/Loading";

const NewsDet = () =>{

    // edit proj
    const {id}=useParams();

    // html
    function createMarkup(html) {
        return {__html: html};
    }

    // data
    const [data,setData] = useState();
    const [list,setList] = useState();

    // get news
    useEffect(()=>{
        axios.get('/site/news',{params:{id:id}})
        .then(res=>res.data)
        .then(data=>{
            // news
            setData(data.res[0]);
            // navi
            setList([
                {url:'/news',name:'Nachrichten'},
                {url:'',name:data.res[0].name},
            ])
        });
    },[]);

    // axios user
    const {user}=useLogin();
    const axios = useAxios(user);

    return (
        <div className="news-det">
            <div className='page-block _small'>
                {data?
                <><Navi list={list}/>
                <div>
                    <div className="news-det-title font-h1b-50">
                        <div>{data.name}</div>
                        <div className="news-det-data">{data.data}</div>
                    </div>
                    {data.img!=''?<div className="news-det-img"><img src={`/katalog/news/big/${data.img}`}/></div>:''}
                    <div 
                        className="news-det-text"
                        dangerouslySetInnerHTML={createMarkup(data.text)} 
                    />
                    {data.source!=''?<div className="news-det-href">Nachrichtenquelle: <a href={data.source} target="_blank">{data.source}</a></div>:''}
                </div></>:<Loading/>}
            </div>
        </div>
    )
}

export {NewsDet}