import "./Mes.scss";
import {Modal} from '../../../components/Modal';

const MesError = ({setShow}) =>{
    return (
        <Modal setShow={setShow}>
            <div className="prof-mes">
            <div className="prof-mes-icon">
                    <div className='icons _alert _alert-error'></div>
                </div>
                <div className="font-h5b-18">Daten nicht gespeichert</div>
                <div>Bitte versuchen Sie es erneut zu speichern oder versuchen Sie es später noch einmal</div>
            </div>
        </Modal>
    )
}

export {MesError}