import {Controller, useForm} from 'react-hook-form';
import { CheckboxItem } from "../../components/CheckboxItem";
import { Errors } from "../Errors";

const Rules = ({errors,control,rules}) => {

    const err={
        req: "bitte akzeptieren"
    }

    return (
        <div className="login-form-stat">
            {rules.map((item,i)=>
            <div key={i}>
                <Controller 
                    name={item.name}
                    control={control}
                    rules={item.rules?{required:err.req}:null}
                    render={({field:{onChange,value}})=>
                        <CheckboxItem 
                            active={value}
                            value={item.name}
                            toggle={()=>onChange(!value)}>
                            {item.label}
                        </CheckboxItem>
                    }
                />
                <Errors error={errors?.[item.name]?.message}/>
            </div>
            )}
        </div>
    )
}

export {Rules}