
import { FreeItem } from "./Free/FreeItem";

import {useEffect, useMemo, useState } from "react";

import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";
import Pagination from "../../components/Pagination";

const pageSize=5;

const Free = () => {

    // data state
    const [data, setData] = useState([]);

    const {user}=useLogin();
    const axios=useAxios(user);

    const [page,setPage] = useState(1);

    useEffect(()=>{
        axios.get('/firm/free')
        .then(res=>res.data)
        .then(data=>setData(data.res || []));
    },[]);

    // slide
    const curData = useMemo(() => {
        const firstPageIndex = (page - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return data.slice(firstPageIndex, lastPageIndex);
    }, [page,data]);

    const freeDel = free =>{
        setData(data.filter(item=>item.id!==free.id));
        axios.delete('/firm/free',{params:{id:free.id}});
    }

    return (
    <div className="fr-ps">
        <table className="table">
            <thead>
                <tr className="table-title">
                    <td>Experten</td>
                    <td>Status</td>
                    <td>Ationen</td>
                </tr>
            </thead>
            <tbody>
                {curData
                ?curData.map((item,i)=><FreeItem 
                    key={i}
                    item={item} 
                    del={()=>freeDel(item)}/>)
                :<tr></tr>}
            </tbody>
        </table>
        <Pagination
            className="pagination-bar"
            currentPage={page}
            totalCount={data.length}
            pageSize={pageSize}
            onPageChange={page => setPage(page)}
        />
    </div>
    )
}

export {Free}