import "./Prof.scss";

import {useEffect,useState} from 'react';

import useAxios from '../../hook/useAxios';
import {useLogin} from '../../hook/useLogin';

import { Loading } from "../../components/Loading";

import { Form } from "./Prof/Form";

const Prof = () => {

    // get user info
    const {user,setUser} = useLogin();
    const axios = useAxios(user);

    const [type,setType]= useState({
        gender:[],
        free:[],
        lands:[]
    });

    // data fn
    const setDate = (date) =>(
        date
        ?new Date(date)
        :new Date()
    )

    // get types
    useEffect(()=>{
        axios.get('/site/type/',{params:{type:['gender','free','lands']}})
        .then(res=>res.data)
        .then(data =>{
            setType(data);
        }).catch((err) => console.log(err))
    },[]);

    // fotm data
    const [data,setData] = useState();

    useEffect(()=>{
        axios.get('/free/prof')
        .then(res=>res.data)
        .then(data=>{
            // start data from now if dont set
            data.res.statusFrom=setDate(data.res.statusFrom);
            setData(data.res);
        })
    },[])

    return (
        data
        ?<Form 
        data={data} 
        type={type}
        axios={axios}
        setUser={setUser} 
        user={user}/>
        :<Loading/>
    )
}

export {Prof}