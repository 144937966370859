import './Cookie.scss';
import { useEffect, useState } from "react"
import { CheckboxItem } from './CheckboxItem';

// import { useLogin } from "../hook/useLogin";
// import useAxios from "../hook/useAxios";

const Cookie = () =>{
    
    // const {user,setUser}=useLogin();
    // const axios=useAxios(user);
    //axios.post('/site/cookie/');

    const [list,setList] = useState([
        {name:'Erforderlich: Sie sind erforderlich, damit die Website ordnungsgemäß funktioniert.',active:false},
        {name:'Präferenzen: Helfen Sie dabei, sich beim Surfen an Informationen zu Ihren Präferenzen zu erinnern und helfen Sie uns, bei Ihrer Rückkehr ein besseres Erlebnis zu bieten',active:false},
        {name:'Statistiken: Helfen Sie mit, die Leistung unserer Website zu verbessern, indem Sie verstehen, wie Sie mit unserer Website interagieren, z. B. die Seiten, die Sie am nützlichsten finden',active:false},
        {name:'Marketing: Helfen Sie uns, Ihnen Anzeigen zu zeigen, von denen wir glauben, dass Sie basierend auf Ihren vorherigen Besuchen und den Dingen, an denen Sie interessiert waren, für Sie interessant und relevant sein könnten.',active:false}
    ]);

    const [active,setActive] = useState(true);
    const [cookie,setCookie] = useState(localStorage.getItem('cookie') || false);
    //const [cookie,setCookie] = useState(false);

    const cookieSet = ()=>{
        setActive(false);
        setCookie(true);
        localStorage.setItem('cookie',true);
    }

    const cookieDel = ()=>{
        setActive(false);
        setCookie(true);
        localStorage.setItem('cookie',false);
    }

    useEffect(()=>{
        setActive(true);
    },[]);

    const toggle = (id)=>{
        list[id].active=!list[id].active;
        setList([...list]);
    }

    // close modal fn
    const closeCookie = () =>{
        setActive(false);
    }

    return(
        !cookie?
        <div className={`cookie ${active?'active':''}`} onClick={closeCookie}>
            <div className="cookie-block" onClick={(e)=>e.stopPropagation()}>
                <div>
                    <div className="font-h4b-20">Wir verwenden die Cookies</div>
                    <div className="cookie-text">
                        {list.map((item,i)=>
                            <CheckboxItem 
                                key={i}
                                active={item.active}
                                value={i}
                                toggle={(id)=>toggle(id)}>
                                {item.name}
                            </CheckboxItem>
                            )
                        }
                    </div>
                </div>
                <div className="cookie-right">
                    <div className="cookie-button">
                        <div className="button button-primary" onClick={()=>cookieSet()}>Alle Cookies akzeptieren</div>
                        <div className="button button-secondary" onClick={()=>cookieSet()}>Änderungen speichern</div>
                        <div className="button button-secondary" onClick={()=>cookieDel()}>Alle ablehnen</div>
                    </div>
                </div>
            </div>
        </div>:''
    )
}

export {Cookie}