import { Link } from 'react-router-dom';
import { useLogin } from '../../hook/useLogin';
import './Main.scss';

const Main = ({json,status}) => {
    
    const {user}=useLogin();

    // tarif
    const acc=user.tarif==2?'Premium':'Basis';
    const pr=user.tarif==2?'_pr':'';

    const mainmenu=[...json];

    delete mainmenu[0];

    let hideMenu=[
        '/main/',
        '/stat/'
    ];

    return (
        <>
            <div className="fr-mn-title">
                <div><div className="fr-mn-img">
                    {user.img!==''
                        ?<img src={user.img}/>
                        :<div className="icons _user _black _opacity"></div>
                    }
                </div></div>
                <div>
                    <div>Hallo {user.name} {user.lastName}!</div>
                    <div className={`fr-mn-status ${pr}`}>
                        {acc} Account {user.payd?<span>- gültig bis {user.payTill}</span>:''}
                    </div>
                </div>
            </div>

            <div className="fr-mn-cards">
            {
                    mainmenu.map((item,i)=>
                        {
                            if(!hideMenu.includes(item.url)){
                                return <Link 
                                    to={item.view?`${item.url}${user.id}`:`${item.url}`} 
                                    className="card" 
                                    key={i}>
                                    <div className="card-ico">
                                        <div className={`icons _menu ${item.st}`}></div>
                                    </div>
                                    <div className="card-title">{item.name}</div>
                                    <div className="card-text">{item.text}</div>
                                </Link>
                            }
                        }
                    )
                }
            </div>
        </>
    )
}

export {Main}