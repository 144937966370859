import { Link } from "react-router-dom"

const NewsItem = ({item}) =>{
    return (
        <div>
            <div className="news-title font-h2b-32">
                <Link to={`/news/${item.id}`}>{item.name}</Link>
                <div className="news-data">{item.data}</div>
            </div>
            <div className="news-text">{item.shortText}</div>
        </div>
    )
}

export {NewsItem}