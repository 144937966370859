// left cart modal
//const action = {type:''}

//localStorage.removeItem("like");

// local list
const list = localStorage.getItem("like") !== null 
  ? JSON.parse(localStorage.getItem("like"))
  : [];

const defState={
    list:list
  }
  
  const SET_LIKE = "SET_LIKE";
  const HIDE_CART = "HIDE_CART";
  
  export const likeList = (state = defState,action) => {
    switch(action.type){
      case SET_LIKE:
        var newList=state.list;

        if(newList.indexOf(action.id) === -1){
          newList = [...newList,action.id];
        }else{
          newList = newList.filter(item => item !== action.id);
        }

        localStorage.setItem("like",JSON.stringify(newList));

        return {...state,list:newList}
      default:
        return state
    }
  
  }
  
  export const setLikeAction = (id) => ({type:SET_LIKE,id:id});