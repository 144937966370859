import { useState } from "react";
import {useForm} from 'react-hook-form';

import {useLogin} from "../../hook/useLogin";
import useAxios from '../../hook/useAxios';

import { Errors } from '../Errors';

const Recovery = () => {

    // hooks
    const {user,setModal} = useLogin();
    const axios=useAxios(user);

    // err message
    const err={
        req: "bitte die E-Mail Adresse eingeben",
        email: "E-Mail nicht erlaubt",
    }

    // result
    const [res,setRes] = useState(false);

    const { 
        register,
        handleSubmit,
        formState: {errors},
        reset,
        setErrors,
    } = useForm();

    // enter user
    const onSubmit = data =>{
        axios.post('/site/recovery',data)
        .then(res => res.data)
        .then(data => {
            if(data.errors){
                setErrors(data.errors);
            }else{
                reset();
                setRes(true);
            }
        });
    }

    return (
        <div className="login-content">
            <div className="login-left">
                <div>
                    <div className="login-logo">
                        <img src="/img/logo/logoS.png" alt="Logo"/>
                    </div>
                    <div className="login-text">Willkommen zurück!</div>
                </div>
                <div className="login-left-buttom">
                    <div className="login-small-text font-text4">Neu bei Freelancerweb?</div>
                    <div 
                        className="href font-button2-13" 
                        onClick={()=>setModal({open:true,st:'mn'})}
                    >Ins Konto einloggen</div>
                </div>
            </div>
            <div className="login-main">
                {res
                ?<div>
                    Auf Ihre E-Mail Adresse wurde ein Link zur Wiederherstellung Ihres Passworts gesendet.
                </div>
                :<form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login-form">
                        <div className="login-form-input">
                            <div className="inputs-block _label">
                                <label forhtml="name" className="font-text5-14">E-Mail</label>
                                <input 
                                    {...register("login",{
                                        required: err.req,
                                        pattern: {
                                          value: /^[^\s]+@[^\s@]+(\.[^\s@]+){1,}$/,
                                          message: err.email
                                        }
                                    })}
                                    placeholder="email@gmail.com"
                                    className="inputs off" 
                                    autoComplete="off"
                                />
                            </div>
                            <Errors error={errors?.login?.message}/>
                        </div>
                        <div className="login-form-input">
                            <div className="login-flex">
                                <div 
                                    className="font-button2-13 href" 
                                    onClick={()=>setModal({open:true,st:'mn'})}>
                                    Haben Sie Ihr Passwort vergessen?
                                </div>
                            </div>
                        </div>
                        <div className="login-form-input">
                            <button 
                                type="submit" 
                                className="button button-primary">
                                Passwort anfordern
                            </button>
                        </div>
                    </div>
                </form>}

            </div>
        </div>
    )
}

export {Recovery}