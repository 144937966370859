
import { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link, useNavigate } from 'react-router-dom';
import { MesDel } from '../../../components/MesDel/MesDel';
const ArhiveItem = ({item,del}) =>{

    const navigate = useNavigate();
    const [show,setShow] = useState(false);

    return (
        <tr className="table-row _bottom" key={item.id}>
            <td>
                <div>
                    <div className="proj-item-name">{item.projName}</div>
                    <div className="proj-item-status">
                        {
                            item.active
                            ?<div className="label _free _line">Wartet auf Veröffentlichung</div>
                            :<div className="label _no _line">Projekt deaktiviert</div>
                        }
                    </div>
                </div>
            </td>
            <td><span style={{opacity:0.3}}>Laufzeit:</span> {item.data}</td>
            <td>
                <ReactTooltip />
                <MesDel setShow={setShow} show={show} del={()=>{del();setShow(false)}}>
                    <p>Das Projekt wird aus der Liste entfernt, aber Ihre Bewerbung bleibt für das Projekt aktiv.
                    <br/>Ein Projekt aus der Liste entfernen?</p>
                </MesDel>
                <div className="table-icons">
                    <Link 
                        to={`/proj/${item.id}`} 
                        rel="nofollow"
                        className="icons _eye"
                        data-help="View" data-tip="anzeigen" data-place="top" data-effect="solid" data-class="c-tooltips"
                    ></Link>
                    <div 
                        className="icons _edit" 
                        data-help="Edit" data-tip="bearbeiten" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>navigate(`../add/${item.id}`)}
                    ></div>
                    <div 
                        className="icons _del" 
                        data-help="Delete" data-tip="löschen" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>setShow(true)}></div>
                </div>
            </td>
        </tr>
    )
}

export {ArhiveItem}