import './Content.scss';

// page for firm
import {Proj} from './Proj';
import {Prof} from './Prof';
import {Add} from './Add';
import {Free} from './Free';
import {Arhive} from './Arhive';

// user for all
import {Main} from '../user/Main.jsx';
import {Mail} from '../user/Mail';
import {Stat} from '../user/Stat';
import {MailDet} from '../user/MailDet';
import {Config} from '../user/Config';

// menu
import json from './menu.json';
import {Menu} from '../user/Menu.jsx';

// user
import { useLogin } from '../../hook/useLogin';
import useAxios from '../../hook/useAxios';
import {Routes, Route,Navigate} from 'react-router-dom';
import { useEffect } from 'react';
import { Premium } from '../user/Premium';

const FirmContent = () => {

    const {user}=useLogin();
    const axios=useAxios();

    useEffect(()=>{
        axios.get('/firm/main')
        .then(res=>res.data)
        .then(data=>
            json.map(item=>{
                return Object.keys(data).includes(item.st)
                ?item.pre=data[item.st]:item
            })
        )
    },[])

    return (
        <>
            <Premium/>

            <div className="page-block _small">
                <div className="freelance">
                    <Menu json={json}/>
                
                    <div className="freelance-content">
                        <Routes>
                            <Route path="/main" element={<Main json={json}/>}/>
                            <Route path="/add" element={<Add/>}/>
                            <Route path="/add/:id" element={<Add/>}/>
                            <Route path="/proj" element={<Proj/>}/>
                            <Route path="/prof" element={<Prof/>}/>
                            <Route path="/arhive" element={<Arhive/>}/>
                            <Route path="/free" element={<Free/>}/>
                            <Route path="/stat" element={<Stat/>}/>
                            <Route path="/mail" element={<Mail/>}/>
                            <Route path="/mail/:id" element={<MailDet/>}/>
                            <Route path="/config/" element={<Config/>}/>
                            <Route path="*" element={<Navigate to={'./main'}/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    )
}

export {FirmContent}