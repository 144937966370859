import { CheckboxItem } from "./CheckboxItem";
const Checkbox = ({list,toggle}) => {
    return (
        list.map((item,i)=>
        <CheckboxItem 
            key={i}
            {...item}
            toggle={toggle}>
            {item.label}
        </CheckboxItem>)
    )
}
export {Checkbox}