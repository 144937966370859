import { useEffect, useState } from "react";

import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";

const Site = () => {

    const {user,config,setConfig}=useLogin();
    const axios=useAxios(user);

    const submit = () =>{
        axios.post('/admin/config',config);
    }

    return (
        config?
    <div className="fr-cf">
        <div className="fr-cf-content">
    
            <div className="form">
    
                <div className="inputs-block">
                    <div className="font-h6b-16">Site-Konfigurationen</div>
                </div>

                <div className="inputs-block _label _span">
                    <label>Email</label>
                    <input 
                        value={config.email}
                        onChange={(e)=>setConfig({...config,email:e.target.value})}
                        type="text"
                        className="inputs off" 
                        autoComplete="off"/>
                </div>

                <div className="inputs-block _label _span">
                    <label>Web</label>
                    <input 
                        value={config.web}
                        onChange={(e)=>setConfig({...config,web:e.target.value})}
                        type="text"
                        className="inputs off" 
                        autoComplete="off"/>
                </div>

                <div className="inputs-block _label _span">
                    <label>Adres</label>
                    <input 
                        value={config.adres}
                        onChange={(e)=>setConfig({...config,adres:e.target.value})}
                        type="text"
                        className="inputs off" 
                        autoComplete="off"/>
                </div>
    
                <div className="inputs-block _label _span">
                    <label>Telefon</label>
                    <input 
                        value={config.phone}
                        onChange={(e)=>setConfig({...config,phone:e.target.value})}
                        type="text"
                        className="inputs off" 
                        autoComplete="off"/>
                </div>

                <div className="inputs-block _label">
                    <label>Facebook</label>
                    <input 
                        value={config.socJson.fb} 
                        type="text" 
                        className="inputs _icon off" 
                        autoComplete="off"
                        onChange={(e)=>{setConfig({...config,socJson:{...config.socJson,fb:e.target.value}})}}/>
                    <div className="icons _fb _small inputs-ico"></div>
                </div>

                <div className="inputs-block _label">
                    <label>Linkedin</label>
                    <input 
                        value={config.socJson.li} 
                        type="text" 
                        className="inputs _icon off" 
                        autoComplete="off"
                        onChange={(e)=>{setConfig({...config,socJson:{...config.socJson,li:e.target.value}})}}/>
                    <div className="icons _li _small inputs-ico"></div>
                </div>

                <div className="inputs-block _label">
                    <label>Xing</label>
                    <input 
                        value={config.socJson.xi} 
                        type="text" 
                        className="inputs _icon off" 
                        autoComplete="off"
                        onChange={(e)=>{setConfig({...config,socJson:{...config.socJson,xi:e.target.value}})}}/>
                    <div className="icons _xi _small inputs-ico"></div>
                </div>

                <div className="inputs-block _label">
                    <label>YouTube</label>
                    <input 
                        value={config.socJson.yt} 
                        type="text" 
                        className="inputs _icon off" 
                        autoComplete="off"
                        onChange={(e)=>{setConfig({...config,socJson:{...config.socJson,yt:e.target.value}})}}/>
                    <div className="icons _yt _small inputs-ico"></div>
                </div>
    
            </div>
        </div>
    
        <div className="fr-cf-save">
            <div className="button button-primary" onClick={()=>submit()}>Speichern</div>
        </div>
    </div>:''
    )
}

export {Site}