import { Form } from "./Form"

import {useEffect,useState} from 'react';
import {useParams} from "react-router-dom";

import useAxios from '../../../hook/useAxios';
import {useLogin} from '../../../hook/useLogin';

const Add = () =>{

    // axios user
    const {user}=useLogin();
    const axios = useAxios(user);

    // data fn
    const setDate = (date) =>(
        date
        ?new Date(date)
        :new Date()
    )

    // data
    const [data,setData] = useState();

    // edit proj
    const {id}=useParams();

    // load data
    useEffect(()=>{
        // add new
        if(id==='add'){
            setData({
                name:'',
                text:'',
                shortText:'',
                data:setDate()
            })
        // update
        }else{
            axios.get('/admin/news/',{params:{id:id}})
            .then(res=>res.data)
            .then(data => {
                data.res=data.res[0];
                data.res.data=setDate(data.res.data);
                setData(data.res);
            })
        }
    },[]);

    return (
        data?<Form data={data} axios={axios}/>:''
    )
}
export {Add}