import "./Info.scss";
import {Link} from 'react-router-dom';
import {Rubrics} from "./InfoFirm/Rubrics";

import {useLogin} from "../../hook/useLogin";

const InfoFirm = () => {

    const {setModal} = useLogin();

    return(
        <>
        <div className="page-content _white">
            <div className="page-block">
                <div className="font-h1b-50 services-info-title">Info fur Firmen</div>
                <div className="font-text1-32">Sie sind nur 4 Schritte von ihren neuen Projekt entfernt</div>
                <div className="services-info-main font-text2-24">Sehen Sie, wie einfach Sie Informationen über Ihr Unternehmen und Ihre Projekte veröffentlichen können</div>
                <div className="services-info-etap">
                    <div className="services-info-line"></div>
                    <div className="services-info-number">
                        <div className="services-info-img _ico1"><span></span></div>
                    </div>
                    <div className="services-info-item">
                        <div className="font-h3b-24 services-info-title1">Sie registrieren sich auf dem Portal</div>
                        <div className="font-text2-24">Geben Sie Ihre E-Mail Adresse und einen Kennwort ein. E-Mail Adresse bestätigen</div>
                    </div>
                    <div className="services-info-number">
                        <div className="services-info-img _ico2_1"></div>
                    </div>
                    <div className="services-info-item">
                        <div className="font-h3b-24 services-info-title1">Sich auf der Plattform einloggen und über Dashboard die Daten eingeben</div>
                        <div className="font-text2-24">Geben Sie ihre Firmendaten ein</div>
                    </div>
                    <div className="services-info-number">
                        <div className="services-info-img _ico3_1"></div>
                    </div>
                    <div className="services-info-item">
                        <div className="font-h3b-24 services-info-title1">Über Dashboard Sie können neue Projekte erfassen</div>
                        <div className="font-text2-24">Bitte fügen Sie die Projektbeschreibung, Projektrubrik, Qualifikation von Experten u.v.m. ein</div>
                    </div>
                    <div className="services-info-number">
                        <div className="services-info-img _ico4"><span className="_bottom"></span></div>
                    </div>
                    <div className="services-info-item">
                        <div className="font-h3b-24 services-info-title1">Nach dem Sie können Ihr Projekt veröffentlichen</div>
                        <div className="font-text2-24">Ab jetzt können die Experten mühelos Ihre Projekte finden, und Sie der passender Freelancer</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="page-content _gray">
            <div className="services-block">
                <div className="page-block">
                    <div className="font-h2b-32">Außerdem Sie haben bei uns folgende Optionen</div>
                    <div className="services-info-option">
                        <div><div className="services-info-ico _ico1"></div></div>
                        <div className="services-info-text font-text2-24">Nachdem Sie Ihr Projekt auf dem Portal veröffentlicht haben, können Sie im Bereich Statistik verfolgen, wer und wie oft Ihr Profil besucht hat</div>
                        <div><div className="services-info-ico _ico2"></div></div>
                        <div className="services-info-text font-text2-24">Alle auf freelancenunter.de registrierten Experten erhalten Zugriff auf Ihr Firmenprofil und können Sie sowohl intern auf in-Mail als auch extern auf E-Mail kontaktieren</div>
                        <div><div className="services-info-ico _ico3"></div></div>
                        <div className="services-info-text font-text2-24">Die gesamte Kommunikation mit der Freelancern erfolgt direkt und ohne zusätzliche Kosten und Vermittlungsgebühren</div>
                        <div><div className="services-info-ico _ico4"></div></div>
                        <div className="services-info-text font-text2-24">Mithilfe der Newsletter-Funktion erhalten Sie Informationen zu neu veröffentlichten Profile</div>

                        <div><div className="services-info-ico _ico5"></div></div>
                        <div className="services-info-text font-text2-24">Platzierung Ihr Firmenlogo auf Hauptseite und auf anderen Seiten des Portals</div>
                        <div><div className="services-info-ico _ico6"></div></div>
                        <div className="services-info-text font-text2-24">Und viele andere Leistungen gem. <Link to="/services/firm/">Registration Pakete</Link></div>
                    </div>
                    <div 
                        className="button button-primary"
                        onClick={()=>setModal({open:true,st:'fi'})}>
                        Unternehmen registriereN
                    </div>
                </div>
            </div>
        </div>
        <div className="page-content _white">
            <div className="page-block _big">
                <div className="font-h1b-50">Rubriken</div>
                <Rubrics/>
            </div>
        </div>
        </>
    )
}

export {InfoFirm}