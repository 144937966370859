import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Errors } from '../components/Errors';

const Spam = () =>{

    // parse
    const err = {
        req: 'bitte ausfüllen'
    }

    const {
        register, 
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm();

    const onSubmit = data => {
        //console.log(data);
        reset();
        axios.post('/api/v1/site/spam/',data);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="page-feedback-form">
                <div>
                    <input 
                    {...register('email',{
                        required:err.req
                    })}
                    placeholder="E-Mail Adresse"
                    className="inputs _black" />
                </div>
                <div><button type="submit" className="page-feedback-submit"/></div>
            </div>
            <Errors error={errors?.email?.message}/>
        </form>
    )
}

export {Spam}