import "./Top.scss";

import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Menu} from '../components/Menu/Menu';
import {Account} from '../components/Account';

import useAxios from '../hook/useAxios';
import { useLogin } from '../hook/useLogin';

const Top = () =>{

    const [menu,setMenu]=useState(false);
    const {config,signIn} = useLogin();
    const axios=useAxios();

    const prevent = (e) => {
        e.stopPropagation();
    }

    const showMenu = (e) => {
        prevent(e);
        setMenu(!menu);
    }

    // document click
    const hendler = () =>{
        setMenu(false);
    }

    useEffect(() => {
        // scroll on documents close menu
        //window.addEventListener('scroll',hendler);
        // click on documents close menu
        window.addEventListener('click',hendler);

        return () => {
            //window.removeEventListener('scroll',hendler);
            window.removeEventListener('click',hendler);
        }
    },[])

    const AdminEnter = () => {
        // enter to admin
        axios.get('/site/enter',{params:{token: localStorage.getItem('admin')}})
        .then(res=>res.data)
        .then(data => {
            // no user, user problem
            if(data.error){
                console.log(data.error);
            }else{
                if(data.res){
                    signIn(data.res,'/account/users/');
                }
            }
        })
    }

    return (
        <div className="page-menu-block">
            {localStorage.getItem('admin')?<div className="page-menu-admin" onClick={()=>AdminEnter()}>Admin-Panel</div>:''}
            <div className="page-menu">
                <div className="page-top-left">
                    <Link to="/" className="page-top-logo">
                        <img src='/img/logo/logo.png' alt="Freelance hunter" />
                    </Link>
                    <div className='page-menu-ico'>
                        <div className='icons _topmenu' onClick={showMenu}></div>
                    </div>
                </div>
                <Menu show={menu} onClick={prevent}/>
                <Account/>
            </div>
        </div>
    )
}
export {Top}