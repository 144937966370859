import { Link } from "react-router-dom"

import {useLogin} from "../../../hook/useLogin";

import {Swiper,SwiperSlide } from "swiper/react"

const TopProj = ({list}) =>{

    const {user,setModal}=useLogin();

    return (
        list?

            <div className="page-block">
            
                <div className="page-content-text">
                    <div className="font-h1b-50 _center">Top Projekte</div>
                    {/* <div className="font-text1-32">
                        Täglich überzeugen sich viele Projektanbieter in den guten Eigenschaften von Freelance Hunter.
                    </div> */}
                </div>

                <div className="swiper-block">
                    <Swiper 
                        breakpoints={{
                            400: {
                                slidesPerView: 1,
                                slidesPerGroup:1
                            },
                            800: {
                                slidesPerView: 4,
                                slidesPerGroup:4
                            },
                            1000: {
                                slidesPerView: 5,
                                slidesPerGroup:5
                            }
                        }}
                        speed = {2000}
                        autoplay = {{
                            delay: 5000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true
                        }}
                        pagination={{ clickable: true }}
                        centeredSlides={false}
                        centerInsufficientSlides={true}
                        centeredSlidesBounds={false}>
                        {list.map((item,i)=>
                            <SwiperSlide key={i}>
                                {user
                                    ?<Link className="home-proj-item" to={`/proj/${item.id}`}>
                                        <div className="home-proj-title">{item.projName}</div>
                                        <div className="home-proj-icons">
                                            <div><div className="icons _home"></div><div>{item.typeName}</div></div>
                                            <div><div className="icons _clock"></div><div>Anfang: {item.startData}</div></div>
                                            <div><div className="icons _bag"></div><div>{item.workType}</div></div>
                                            {item.endData?<div><div className="icons _clock"></div><div>Ende: {item.endData}</div></div>:''}
                                        </div>
                                    </Link>
                                    :<div className="home-proj-item" onClick={()=>setModal({open:true,st:'mn'})}>
                                        <div className="home-proj-title">{item.projName}</div>
                                        <div className="home-proj-icons">
                                            <div><div className="icons _home"></div><div>{item.typeName}</div></div>
                                            <div><div className="icons _clock"></div><div>Anfang: {item.startData}</div></div>
                                            <div><div className="icons _bag"></div><div>{item.workType}</div></div>
                                            {item.endData?<div><div className="icons _clock"></div><div>Ende: {item.endData}</div></div>:''}
                                        </div>
                                    </div>
                                }
                            </SwiperSlide>)}
                    </Swiper>
                </div>

                <div className="_center"><Link to="/projs" className="button button-secondary">Projekte</Link></div>
            </div>
        :''
    )
}

export {TopProj}