import { useState } from "react";
import {useNavigate,useLocation} from "react-router-dom";

import { Navi } from "../../../components/Navi";
import { Like } from "../../../components/Like";
import { Share } from "../../../components/Share";

import { Mes } from "./Mes";
import { Alert } from "./Alert";

const Top = ({data,user,axios,setModal}) => {

    // navigation
    const navi=[
        {name:'Home',url:'/'},
        {name:'Projekte',url:'/projs'},
        {name:data.name,url:'/'}
    ];

    const gender = [
        'Frau',
        'Herr'
    ];

    const navigate = useNavigate();

    // back
    // var navigate=useNavigate();
    // const goBack = () => navigate(-1);
    var location=useLocation();
    const from=location.state?.from || 0;
    const back=[
        'Zurück',
        'Zurück',
        'Zurück zur Bearbeitung'
    ];

    // show modal
    const [mes,setMes]=useState(false);
    const [mesAlert,setAlert]=useState(false);
    const [projId,setProj]=useState(null);

    const alert = proj =>{
        setProj(proj);
        setAlert(true);
    }

    // download PDF
    const downloadPDF = () => {
        user
        ?axios.get('/doc/pdf/',{params:{id:data.id,type:3},responseType: 'blob'})
        .then(res=>{
            var url = window.URL.createObjectURL(res.data),
            link = document.createElement("a");
            link.href = url;
            link.setAttribute('download', `${data.name}.pdf`);
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        })
        :setModal({open:true,st:'mn'})
    }

    // email show
    const [emailShow,setEmail] = useState(false);

    return (
        <>
            {mesAlert
            ?<Alert
                proj={projId}
                setAlert={setAlert}/>
            :''}

            {mes?<Mes
                data={{
                    id:data.id,
                    idUser:data.idUser,
                    name:data.name
                }}
                setShow={setMes}
                alertMes={proj=>alert(proj)}/>:""}

            <div className="pr">

                <div className="page-block _small">
                    
                    <div className="pr-top">
                        <div>
                            <div className="navi _gray">
                                <Navi list={navi}/>
                            </div>
                        </div>
                        <div
                            onClick={()=>navigate(-1)}
                            className="back font-button1-16">
                            {back[from]}
                        </div>
                    </div>

                    <div className="pr-info">
                        <div>
                            <div className="pr-title font-h2b-32">{data.name}</div>
                            <div className="pr-sub">
                                {data.projNumber !== ''?<div className="pr-number font-text3-18">Projekt Nr: {data.projNumber}</div>:''}
                                <div className="pr-data">
                                    <div className="icons _data _small"></div>
                                    <div className="font-text3-18">Veröffentlicht <span>{data.createData}</span></div>
                                </div>
                            </div>
                            <div className="pr-info-icons">
                                <div className="pr-icons _ver">
                                    <div className="pr-item">
                                        <div className="icons _home _small"></div>
                                        <div>Projektart: <div className="pr-item-text">{data.typeName}</div></div>
                                    </div>
                                    
                                    {data.job !== ''?<div className="pr-item">
                                        <div className="icons _bag _small"></div>
                                        <div>Beschäftigung: <div className="pr-item-text">{data.job}</div></div>
                                    </div>:''}
                                    
                                    <div className="pr-item">
                                        <div className="icons _map _small"></div>
                                        <div>Ort: <div className="pr-item-text">{data.map}</div></div>
                                    </div>
                                    
                                    <div className="pr-item">
                                        <div className="icons _clock _small"></div>
                                        <div>Laufzeit: <div className="pr-item-text">{data.startData} {data.endData?` - ${data.endData}`:''}</div></div>
                                    </div>

                                </div><div className="pr-icons _ver">
                                    
                                    <div className="pr-item">
                                        <div className="icons _user _small _black"></div>
                                        <div>Ansprechpartner: <div className="pr-item-text">{gender[data.gender]} {data.contactName}</div></div>
                                    </div>
                                    
                                    <div className="pr-item">
                                        <div className="icons _phone _small"></div>
                                        <div>Telefonnummer: <div className="pr-item-text">{data.contactPhone}</div></div>
                                    </div>

                                    {data.contactEmail
                                    ?<div className="pr-item">
                                        <div className="icons _email _small"></div>
                                        {
                                            emailShow
                                            ?<div className="pr-item-text"><a href={`mailto:${data.contactEmail}`} target="_blank" rel="noreferrer" className="href">{data.contactEmail}</a></div>
                                            :<div className="font-text4-16 href" onClick={()=>setEmail(true)}>E-Mail Adresse anzeigen</div>
                                        }
                                    </div>
                                    :''}

                                </div>
                            </div>
                        </div>
                        <div className="pr-right">
                            <div className="pr-right-icons">
                                <Share link={`/proj/${data.id}`}/>
                                <Like id={data.id} type="2" like={data.l}/>
                            </div>
                            <div className="pr-right-buttons">

                                {/* freelancer */
                                    user && user.type==0
                                    ?<div className="button button-primary" onClick={()=>setMes(true)}>BEWERBEN</div>
                                    :''
                                }
                                
                                <div
                                    className="button button-small _download"
                                    onClick={()=>downloadPDF()}>
                                    Herunterladen als PDF
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {Top}