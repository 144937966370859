import "./Info.scss";

import {useLogin} from "../../hook/useLogin";
import useAxios from "../../hook/useAxios";

import { TopFirm } from "./InfoFree/TopFirm";

import { useEffect, useState } from "react";

const InfoFree = () => {

    const axios=useAxios()

    const [list,setList] = useState({});

    useEffect(()=>{
        axios.get('/site/home')
        .then(res=>res.data)
        .then(data=>
            data?setList(data.res):''
        )
    },[])

    let circle = <div className="slide-circle">
        <div className="slide-circle-item active"></div>
        <div className="slide-circle-item"></div>
        <div className="slide-circle-item"></div>
    </div>;

    const {setModal} = useLogin();

    return(
        <>
        <div className="page-content _white">
            <div className="page-block">
                <div className="font-h1b-50 services-info-title">Info fur Freelancer</div>
                <div className="font-text1-32">Sie sind nur 3 Schritte von ihren neuen Projekt entfernt:</div>
                <div className="services-info-etap">
                    <div className="services-info-line"></div>
                    <div className="services-info-number">
                        <div className="services-info-img _ico1"><span/></div>
                    </div>
                    <div className="services-info-item">
                        <div className="font-h3b-24 services-info-title1">Sie registrieren sich auf dem Portal</div>
                        <div className="font-text2-24">Geben Sie Ihre E-Mail Adresse und einen Kennwort ein. E-Mail Adresse bestätigen</div>
                    </div>
                    <div className="services-info-number">
                        <div className="services-info-img _ico2"></div>
                    </div>
                    <div className="services-info-item">
                        <div className="font-h3b-24 services-info-title1">Sich auf der Plattform einloggen und über Dashboard die Daten eingeben</div>
                        <div className="font-text2-24">Geben Sie ihre Persönliche-, Berufliche Daten, Qualifikation und Skills ein</div>
                    </div>
                    <div className="services-info-number">
                        <div className="services-info-img _ico3"><span className="_bottom"></span></div>
                    </div>
                    <div className="services-info-item">
                        <div className="font-h3b-24 services-info-title1">Und als letztes, veröffentlichen Sie Ihr Profil</div>
                        <div className="font-text2-24">Ab jetzt können die Headhunter mühelos sie finden und mit Projektangeboten auf sie zukommen</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="page-content  _gray">
            <div className="services-block">
                <div className="page-block">
                    <div className="font-h2b-32">  Sie haben bei uns folgende Optionen</div>
                    <div className="services-info-option">
                        <div><div className="services-info-ico _ico1"></div></div>
                        <div className="services-info-text font-text2-24">Nachdem Sie Ihr Profil auf dem Portal veröffentlicht haben, können Sie im Bereich Statistik verfolgen, wer und wie oft Ihr Profil besucht hat</div>
                        <div><div className="services-info-ico _ico2"></div></div>
                        <div className="services-info-text font-text2-24">Alle auf freelancenunter.de registrierten Headhunter und Personaldienstleister erhalten Zugriff auf Ihr Profil und können Sie sowohl intern auf in-Mail als auch extern auf E-Mail kontaktieren</div>
                        <div><div className="services-info-ico _ico3"></div></div>
                        <div className="services-info-text font-text2-24">Die gesamte Kommunikation mit dem Unternehmen erfolgt direkt und ohne zusätzliche Kosten und Vermittlungsgebühren</div>
                        <div><div className="services-info-ico _ico4"></div></div>
                        <div className="services-info-text font-text2-24">Mithilfe der Newsletter-Funktion erhalten Sie Informationen zu neu veröffentlichten Projekten. Entsprechend Ihrer Qualifikation und Ihres Profils und Skills</div>
                    </div>
                    <div 
                        className="button button-primary"
                        onClick={()=>setModal({open:true,st:'fr'})}>
                        als Freelancer registrieren
                    </div>
                </div>
            </div>
        </div>
        
        <TopFirm  list={list.top}/>
        
        </>
    )
}

export {InfoFree}