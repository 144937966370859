import { useState } from "react"
import { Link} from "react-router-dom";

const MenuItem = ({cat,item}) =>{
    
    const [show,setShow]=useState(false);
    
    return (
        <div 
            className='page-menu-item'
            onMouseOver={()=>setShow(true)} 
            onMouseOut={() =>setShow(false)}
            onClick={()=>{setShow(!show)}}
            onTouchStart={()=>setShow(!show)}
            onTouchEnd={e =>e.preventDefault()}
            >
            <div className="page-menu-link _has-menu">
                {item.name}
            </div>
            <div className={`page-menu-dropdown ${show?'active':''}`}>
                <div className='page-menu-block overflow overflow-y'>
                    {cat.map((catItem,i)=>
                        <Link
                            key={i} 
                            onTouchStart={e=>e.stopPropagation()}
                            onTouchEnd={e=>e.stopPropagation()}
                            replace={true}
                            className='page-menu-cat'
                            to={item.url}
                            state={{cat:catItem.value}}>
                            {catItem.label}
                        </Link>
                    )}
                </div>
            </div>
        </div>
    )
}

export {MenuItem}