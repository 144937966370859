import './Other.scss';

import {useEffect,useMemo,useState} from 'react';
import {useLocation, useSearchParams} from "react-router-dom";

import useAxios from '../../hook/useAxios';
import { useLogin } from '../../hook/useLogin';

import { Tags } from "../../components/Tags";
import Pagination from "../../components/Pagination";
import {Loading} from "../../components/Loading";
import {MySelect} from '../../components/MySelect';

import {PayMes} from '../../components/PayMes/PayMes';

import { Top } from './Free/Top';
import { List } from "./List";
import { useParams,useNavigate} from 'react-router-dom';

const PageSize=10;

const Free = () => {

    const {page}=useParams();
    const history = useNavigate();

    const {user,pay,setPay} = useLogin();
    const axios = useAxios(user);

    // cat from menu click
    const location=useLocation();
    const cat = useMemo (
        ()=>location?.state?.cat || null,
        [location?.state?.cat]
    );
    
    useEffect(()=>{
        setSearch({...search,cat:cat});
        setRefresh(true);
    },[cat]);

    // menuFilter
    const [menuFilter,setMenuFilter] = useState();
    window.addEventListener('click',()=>setMenuFilter(false));
    const prevent = (e) => {e.stopPropagation();e.preventDefault();}

    // search
    const [query,setQuery] = useSearchParams();
    const searchVal = query.get('search') || '';

    // post array
    const [search,setSearch] = useState({
        page:parseInt(page) || 1,
        cat:location?.state?.cat || null,
        land:null,
        search:searchVal || '',
        tags:[],
        sorting:null
    });

    // refresh
    const [refresh,setRefresh] = useState(true);
    const [block,setBlock] = useState(false);

    // filters state
    const [cats,setCats] = useState([]);
    const [lands,setLands]= useState([]);

    // filters state
    const [tags,setTags] = useState([]);

    const setTagsFilter = value => {
        const newVal = tags.map(item=>{
            if(item.value===value){item.active=!item.active}
            return item;
        })
        setTags(newVal);
        setSearch({...search,tags:tags.filter(tag=>tag.active).map(tag=>(tag.value))});   
    }

    // sorting
    const sortArray=[
        {label:'Standart',value:''}
    ];

    // result state
    const [data, setData] = useState([]);

    // start load
    useEffect(()=>{
        const req={params:{type:[
            'cat',
            'lands',
            'tags'
        ]}};
        axios.get('/site/type/',req)
            .then(res=>res.data)
            .then(data => {
                data.cat.unshift({label:'alle',value:null})
                setCats(data.cat);
                setTags(data.tags);
                setLands(data.lands);
            });
    },[]);

    // search
    useEffect(()=>{
        axios.get('/site/free/',{params:{...search}})
            .then(res=>res.data)
            .then(data => {
                setData(data.res || []);
                setBlock(data.pay);
                setRefresh(false);
                setMenuFilter(false);
                search.search.length>0 && setQuery({...query,search:search.search});
            });
    },[refresh]);

    return (
    <>
        <Top 
            setSearch={search=>setSearch(search)} 
            setRefresh={refresh=>setRefresh(refresh)}
            search={search}
        />
        {user?<PayMes show={pay} setShow={setPay}/>:''}
        <div className="page-block">
            <div className="other-block">
                <div className={`other-menu ${menuFilter && 'active'}`} onClick={(e)=>prevent(e)}>
                    <div>
                        <div className="other-menu-title font-h3-18">Kategorie auswählen</div>
                        <div>
                            <MySelect 
                                value={cats.filter(item=>item.value===search.cat)}
                                onChange={res=>setSearch({...search,cat:res.value})} 
                                options={cats} 
                                placeholder="All categories"
                                isSearchable={false}/>
                        </div>
                    </div>
                    <div>
                        <div className="other-menu-title font-h3-18">Land</div>
                        <div>
                            <MySelect 
                                onChange={res=>setSearch({...search,land:res.value})} 
                                options={lands} 
                                placeholder="Country"
                                isSearchable={false}/>
                        </div>
                    </div>
                    <div>
                        {/* <div className="other-menu-title font-h3-18">Tags</div> */}
                        {/* <div className="tag">
                            <Tags toggle={setTagsFilter} tags={tags}/>
                        </div> */}
                    </div>
                    <div className="button button-primary _width" onClick={()=>setRefresh(true)}>Suche eingrenzen</div>
                </div>
                <div className="other-content">
                    
                    {/* {block?<div className="other-content-block" onClick={()=>setPayMes(true)}></div>:''} */}

                    <div className="other-content-top">
                        <div className="other-filter" onClick={(e)=>{prevent(e);setMenuFilter(true)}}>
                            <div className="icons _topmenu"></div>
                        </div>
                        <div className="font-h3-18">{data.length>0 && `${data.length} Expert`}</div>
                        <div className="other-content-select">
                            <div className="font-text4-16">Sortieren nach:</div>
                            <div className="other-content-choise">
                                <MySelect 
                                    select={(res)=>setSearch({...search,sorting:res.value})} 
                                    options={sortArray} 
                                    placeholder="Standart"
                                    isSearchable={false}/>
                            </div>
                        </div>
                    </div>
        
                    <div className="other-flex">
                        {refresh
                            ? <Loading/>
                            :(<div className="other-items">                                
                                {data.length
                                ?<List data={data} page={search.page} pageSize={PageSize} path="/freelances" block={block}/> 
                                :<div className="other-noresult">Keine Informationen gefunden</div>}
                            </div>)
                        }
                    </div>
                    <div className="other-pag">
                        <Pagination
                            className="pagination-bar"
                            currentPage={search.page}
                            totalCount={data.length}
                            pageSize={PageSize}
                            onPageChange={page => {
                                setSearch({...search,page:page})
                                history(`/freelances/${page}`);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
export {Free};