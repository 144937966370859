import { useState } from 'react';
import InputMask from 'react-input-mask';
import { Link } from "react-router-dom";
import {Controller, useForm} from 'react-hook-form';

import { MySelect } from "../../../components/MySelect";
import { NewEditor } from "../../../components/NewEditor/NewEditor"
import { Errors } from "../../../components/Errors";
import DatePicker from '../../../hook/useDatepicker';

import { Img } from "../../user/Img";

import { DocAdd } from "./DocAdd";
import { MesOk } from "./MesOk";
import { MesError } from "./MesError";

const Form = ({data,setUser,user,type,axios}) =>{

    const def=false;
    const [mesOk,setMesOk] = useState(def);
    const [mesError,setMesError] = useState(def);

    const err={
        req : 'bitte ausfüllen',
    }

    const {
        register, 
        handleSubmit,
        control, 
        formState: {errors}
    } = useForm({
        defaultValues:data
    });

    const onSubmit = data => {
        //console.log(data);
        axios.post('/firm/edit',data)
        .catch(e=>setMesError(true))
        .then(setMesOk(true))

    }

    return (
        <>
            {mesOk?<MesOk setShow={setMesOk}/>:''}
            {mesError?<MesError setShow={setMesError}/>:''}

            <div className="fr-pf">
                <div className="fr-pf-content">

                    <Img/>
                
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form">

                            <div>
                                <div className="inputs-block _label">
                                    <label>Company name:</label>
                                    <input 
                                        {...register('companyName',{required:err.req})}
                                        className="inputs off"/>
                                </div>
                                <Errors error={errors?.companyName?.message}/>
                            </div>
                            <div>
                                <div className="inputs-block _label">
                                    <label>Profil Titel:</label>
                                    <input 
                                        {...register('titleText',{required:err.req})}
                                        placeholder='z.B.: Endkunde, Dienstleister oder Head Hunter'
                                        className="inputs off"/>
                                </div>
                                <Errors error={errors?.shortText?.message}/>
                            </div>
                        </div>

                        <div className="fr-pf-title">
                            <div className="font-h6b-16">Allgemeine Information</div>
                        </div>

                        <div className="form _tree">

                            <div>
                                <div className="inputs-block _label _select">
                                    <label>Land</label>
                                    <Controller 
                                        name="idLand" 
                                        control={control}
                                        rules={{required:err.req}}
                                        render={({field:{onChange,value}})=>
                                            <MySelect 
                                            isSearchable={false}
                                            value={type.lands.filter(item=>item.value===value)}
                                            onChange={e=>onChange(e.value)}
                                            options={type.lands}/>
                                        }
                                    />
                                </div>
                                <Errors error={errors?.idLand?.message}/>
                            </div>
                            
                            <div>
                                <div className="inputs-block _label">
                                    <label>Stadt</label>
                                    <input 
                                        {...register('locationJson.city',{required:err.req})}
                                        className="inputs off"/>
                                </div>
                                <Errors error={errors?.locationJson?.city?.message}/>
                            </div>
                            <div>
                                <div className="inputs-block _label">
                                    <label>PLZ</label>
                                    <input 
                                        {...register('locationJson.code',{required:err.req})}
                                        className="inputs off"/>
                                </div>
                                <Errors error={errors?.locationJson?.code?.message}/>
                            </div>
                            <div>
                                <div className="inputs-block _label">
                                    <label>Straße</label>
                                    <input 
                                        {...register('locationJson.street',{required:err.req})}
                                        className="inputs off"/>
                                </div>
                                <Errors error={errors?.locationJson?.street?.message}/>
                            </div>
                            <div>
                                <div className="inputs-block _label">
                                    <label>Haus №</label>
                                    <input 
                                        {...register('locationJson.build',{required:err.req})}
                                        className="inputs off"/>
                                </div>
                                <Errors error={errors?.locationJson?.build?.message}/>
                            </div>
                        
                        </div>
                        <div className="form _tree">
                            {/* monthsShown={2} showYearPicker */}
                            <div>
                                <div className="inputs-block _label">
                                    <label>Grundungsjahr</label>
                                    <Controller 
                                        name="dataStart" 
                                        control={control}
                                        render={({field:{onChange,value}})=>
                                            <DatePicker 
                                                selected={value}
                                                closeOnScroll={true}
                                                alwaysShowMask={false}
                                                onChange={onChange}
                                                className="myDatepicker"
                                                dateFormat="dd.MM.yyyy"
                                                placeholder="Datum auswählen"
                                                customInput={
                                                    <InputMask mask="99.99.9999" maskChar={null}/>
                                                }
                                            />
                                        }
                                        rules={{ required: err.req }}/>
                                    <div className="icons _cal _small inputs-ico"></div>
                                </div>
                                <Errors error={errors?.dataStart?.message}/>
                            </div>
                            
                            <div>
                                <div className="inputs-block _label">
                                    <label>Mitarbeiter Zahl</label>
                                    <input 
                                        {...register('workers',{required: err.req})}
                                        placeholder="500"
                                        className="inputs off"/>
                                </div>
                                <Errors error={errors?.workers?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label">
                                    <label>Niederlassung Zahl</label>
                                    <input 
                                        {...register('depart',{required: err.req})}
                                        placeholder="1"
                                        className="inputs off"/>
                                </div>
                                <Errors error={errors?.depart?.message}/>
                            </div>
                        
                        </div>

                        <div className="fr-pf-title">
                            <div className="font-h6b-16">Kontakt Person</div>
                        </div>

                        <div className="form _tree">
                            
                            <div>
                                <div className="inputs-block _label _select">
                                    <label>Anrede</label>
                                    <Controller 
                                        name="gender"
                                        control={control}
                                        rules={{require:err.req}}
                                        render={({field:{onChange,value}})=>
                                            <MySelect 
                                                value={type.gender.filter(item=>item.value===value)}
                                                onChange={e=>onChange(e.value)}
                                                isSearchable={false}
                                                placeholder="auswählen ..."
                                                options={type.gender}/>
                                            }
                                    />
                                </div>
                                <Errors error={errors?.gender?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label">
                                    <label>Vorname</label>
                                    <input
                                        {...register('name',{required: err.req})}
                                        placeholder='Alex'
                                        onChange={e=>{
                                            setUser({...user,name:e.target.value})
                                        }}
                                        className="inputs off"/>
                                </div>
                                <Errors error={errors?.name?.message}/>
                            </div>
                            
                            <div>
                                <div className="inputs-block _label">
                                    <label>Nachname</label>
                                    <input 
                                        {...register('lastName',{required: err.req})}
                                        placeholder='Fuller'
                                        onChange={(e)=>{
                                            setUser({...user,lastName:e.target.value});
                                        }}
                                        className="inputs off"/>
                                </div>
                                <Errors error={errors?.lastName?.message}/>
                            </div>
                            
                            <div>
                                <div className="inputs-block _label">
                                    <label>Position</label>
                                    <input 
                                        {...register('position',{required: err.req})}
                                        placeholder='Fuller'
                                        className="inputs off" />
                                </div>
                                <Errors error={errors?.position?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label _label">
                                    <label>Telefonnummer</label>
                                    <input 
                                        {...register('phone',{required: err.req})}
                                        placeholder="+49 456 78 78"
                                        className="inputs off"/>
                                </div>
                                <Errors error={errors?.phone?.message}/>
                            </div>
                            
                            <div>
                                <div className="inputs-block _label _label">
                                    <label>Web</label>
                                    <input 
                                        {...register('web',{required: err.req})}
                                        placeholder="www."
                                        className="inputs off"/>
                                </div>
                                <Errors error={errors?.web?.message}/>
                            </div>

                        </div>

                        <div className="fr-pf-title">
                            <div className="font-h6b-16">Kurze Beschreibung</div>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <textarea 
                                    {...register('shortText',{
                                        required: err.req,
                                        maxLength:{
                                            value:200,
                                            message:'Nicht mehr als 200 Zeichen'
                                        }
                                    })}
                                    className="inputs off"/>
                            </div>
                            <Errors error={errors?.shortText?.message}/>
                        </div>

                        <div className="fr-pf-title">
                            <div className="font-h6b-16">Über Uns</div>
                        </div>
                        
                        <div>
                            <div className="inputs-block _label">
                                <Controller 
                                    name="descrText" 
                                    control={control}
                                    rules={{required: err.req}}
                                    render={({field:{onChange,value}})=>
                                        <NewEditor 
                                            html={value}
                                            placeholder="Bitte beschreiben Sie Ihr Unternehmen"
                                            onChange={html=>onChange(html)}
                                        />
                                    }
                                />
                            </div>
                            <Errors error={errors?.descrText?.message}/>
                        </div>

                        <div className="fr-pf-title">
                            <div className="font-h6b-16">Benefits</div>
                        </div>
                        
                        <div>
                            <div className="inputs-block _label">
                                <Controller 
                                    name="benefitText" 
                                    control={control}
                                    rules={{required: err.req}}
                                    render={({field:{onChange,value}})=>
                                        <NewEditor 
                                            html={value}
                                            placeholder="Bitte beschreiben Sie Ihr Unternehmen"
                                            onChange={html=>onChange(html)}
                                        />
                                    }
                                />
                            </div>
                            <Errors error={errors?.benefitText?.message}/>
                        </div>

                        <div className="fr-pf-title">
                            <div className="font-h6b-16">Anlagen</div>
                        </div>
                            
                            <div className="inputs-block">
                                <div>Hier können Sie Ihre Datenschutzerklärung hochladen.</div>
                            </div>

                            <DocAdd 
                                register={register} 
                                control={control} 
                                errors={errors}
                            />

                        <div className="fr-pf-title">
                            <div className="font-h6b-16">Soziale Netzwerke</div>
                        </div>

                        <div className="form">

                            <div>
                                <div className="inputs-block _label">
                                    <label>Facebook</label>
                                    <input 
                                        {...register("socJson.fb")}
                                        className="inputs _icon off"/>
                                    <div className="icons _fb _small inputs-ico"></div>
                                </div>
                                <Errors error={errors?.socJson?.fb?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label">
                                    <label>Google</label>
                                    <input 
                                        {...register("socJson.gl")}
                                        className="inputs _icon off" />
                                    <div className="icons _gl _small inputs-ico"></div>
                                </div>
                                <Errors error={errors?.socJson?.gl?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label">
                                    <label>Linkedin</label>
                                    <input 
                                        {...register("socJson.li")}
                                        className="inputs _icon off" />
                                    <div className="icons _li _small inputs-ico"></div>
                                </div>
                                <Errors error={errors?.socJson?.li?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label">
                                    <label>Xing</label>
                                    <input 
                                        {...register("socJson.xi")}
                                        className="inputs _icon off"/>
                                    <div className="icons _xi _small inputs-ico"></div>
                                </div>
                                <Errors error={errors?.socJson?.xi?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label">
                                    <label>YouTube</label>
                                    <input 
                                        {...register("socJson.yt")}
                                        className="inputs _icon off"/>
                                    <div className="icons _yt _small inputs-ico"></div>
                                </div>
                                <Errors error={errors?.socJson?.yt?.message}/>
                            </div>

                            <div>
                                <div className="inputs-block _label">
                                    <label>Twitter</label>
                                    <input 
                                        {...register("socJson.tw")}
                                        className="inputs _icon off"/>
                                    <div className="icons _tw _small inputs-ico"></div>
                                </div>
                                <Errors error={errors?.socJson?.tw?.message}/>
                            </div>

                        </div>
                        <div className="form _button">
                            <div><button type="submit" className="button button-primary">Speichern</button></div>
                        </div>
                    </form>

                </div>
            </div>
        </>
    )

    // <div><Link to={`/firm/${user.id}`} target="_blank" rel="noopener noreferrer" className="button button-secondary">ANZEIGEN</Link></div>
}

export {Form}