import {Modal} from '../../../components/Modal';

import { useLogin } from "../../../hook/useLogin";
import useAxios from "../../../hook/useAxios";

const Mes = ({setShow}) =>{

    const {user,signOut}=useLogin();
    const axios=useAxios(user);

    // send premium account
    const submit = ()=>{
        axios.delete('/user/');
        setShow(false);
        signOut();
    }
    
    return (
        <Modal setShow={setShow}>
            <div className="mes-pay">
                <div>
                    <div className="font-h2b-32 _center">
                        Account löschen
                    </div>
                </div>
                <div>
                    Alle Ihre Daten werden gelöscht
                </div>
                <div>
                    <div className="mes-del-button">
                        <div className="button button-primary" onClick={()=>setShow(false)}>Nein</div>
                        <div className="button button-secondary" onClick={()=>submit()}>Ja</div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export {Mes}