import {Controller, useForm} from 'react-hook-form';
import { useEffect,useState } from "react";

import {useLogin} from "../../hook/useLogin";
import useAxios from "../../hook/useAxios";

import { MySelect } from "../../components/MySelect";

import { Rules } from "./Rules";
import { Errors } from "../Errors";


const Firm = ({fromPage}) => {

    // fetch
    const {user,setModal,signIn} = useLogin();
    const axios=useAxios(user);

    // state
    const [showPas,setPas]=useState(true);
    const [res,setRes] = useState(false);

    // err message
    const err={
        req: "Feld muss ausgefüllt werden",
        pas: "Falsches Passwort",
        email: "E-Mail nicht erlaubt",
    }

    const rules=[
        {
            name:'rules',
            label:<>Ich akzeptiere die <a href="/page/agb/" target="_blank">AGB</a> (Allgemeinen Geschäftsbedingungen) der Freelancerweb</>,
            rules:true
        },{
            name:'spam',
            label:<>Ich möchte den Freelancerweb Newsletter abonnieren.</>,
        },{
            name:'politic',
            label:<>Ich akzeptiere die <a href="/page/date/" target="_blank">Datenschutz</a> (Datenschutzbestimmungen) der Freelancerweb.</>,
            rules:true
        },{
            name:'info',
            label:<>Ich bestätige die Richtigkeit die Information.</>,
            rules:true
        }

    ]

    // useForm
    const {
        register, 
        handleSubmit,
        formState: {errors},  
        setError,
        control
    } = useForm({
        defaultValues:{
            type:1,
            companyName:'',
            idCompany:null,
            gender:null,
            name:'',
            lastName:'',
            login:'',
            pas:'',
            
            rules:false,
            spam:false,
            politic:false,
            info:false
        }
    });

    const showError = (error) => {
        for (const [key,val] of Object.entries(error)){
            setError(key,{type:"required",message:val})
        }
    }

    // type state
    var [type,setType] = useState({
        gender:[], // gender
        company:[] // company
    });

    useEffect(()=>{
        console.log('test');
        axios.get('/site/type',{params:{'type':['company','gender']}})
        .then(res=>res.data)
        .then(data=>{
            setType(data);
        })
    },[]);

    // regedit user
    const onSubmit = data =>{
        axios.post('/site/regedit',data)
        .then(res=>res.data)
        .then(data => {
            // error
            if(data.error){
                showError(data.error);
            // no error
            }else{
                // regedit -> proof email -> proof back to site -> login

                //signIn(data.res,fromPage); // sing in
                //setModal(false); // close modal
                setRes(true);
            }
        });
    }

    const re = {
            login: /^[^\s]+@[^\s@]+(\.[^\s@]+){1,}$/,
            pas : /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,]).{8,}$/
    }

    return (
        type.company?
        <div className="login-content">
            <div className="login-left">
                <div>
                    <div className="login-logo">
                        <img src="/img/logo/logo_big.png" alt="Logo"/>
                    </div>
                    <div className="login-text">Unternehmen registrieren</div>
                </div>
                <div className="login-left-buttom">
                    <div className="login-small-text font-text4">Haben Sie schon einen Account</div>
                    <div href="/" 
                        className="href font-button2-13" 
                        onClick={()=>setModal({open:true,st:'mn'})}>
                        Ins Konto einloggen
                    </div>
                </div>
            </div>
            <div>
                {res?
                <div>
                    Bitte bestätigen Ihre E-Mail Adresse. Einen Bestätigungslink wurde auf hinterlegte E-Mail gesendet.
                </div>
                :<form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login-form">
                        <div className="login-form-input">
                            <div className="login-title">Oder registrieren Sie sich per E-Mail</div>
                        </div>
                        <div className="login-form-input">                
                            <div className="login-form-flex">
                                <div>
                                    <div className="inputs-block _label">
                                        <label forhtml="name" className="font-text5-14">Unternehmen</label>
                                        <input 
                                            {...register("companyName",{
                                                required:err.req
                                            })}
                                            placeholder="IKEA"
                                            className="inputs off" 
                                            autoComplete="off"/>
                                    </div>  
                                    <Errors error={errors?.companyName?.message}/>
                                </div>
                                <div>
                                    <div className="inputs-block _label _select">
                                        <label forhtml="name" className="font-text5-14">Art des Unternehmens</label>
                                        <Controller 
                                            name="idCompany" 
                                            control={control}
                                            rules={{required:err.req}}
                                            render={({field:{onChange,value}})=>
                                                <MySelect 
                                                    isSearchable={false}
                                                    value={type.company.filter(item=>item.value===value)}
                                                    onChange={e=>onChange(e.value)} 
                                                    options={type.company}
                                                />
                                            }
                                        />
                                    </div>
                                    <Errors error={errors?.idCompany?.message}/>
                                </div>
                            </div>
                        </div>
                        
                        <div className="login-title _center">Kontakt Person</div>
                        
                        <div className="login-form-input">
                            <div className="login-form-flex">
                                <div>
                                    <div className="inputs-block _label _select">
                                        <label className="font-text5-14">Anrede</label>
                                        <Controller 
                                            name="idGender" 
                                            control={control}
                                            rules={{required:err.req}}
                                            render={({field:{onChange,value}})=>
                                                <MySelect 
                                                    isSearchable={false}
                                                    value={type.gender.filter(item=>item.value===value)}
                                                    onChange={e=>onChange(e.value)} 
                                                    options={type.gender}
                                                />
                                            }
                                        />
                                    </div>  
                                    <Errors error={errors?.idGender?.message}/>
                                </div>
                                <div>
                                    <div className="inputs-block _label">
                                        <label className="font-text5-14">Vorname</label>
                                        <input  
                                            {...register("name",{
                                                required:err.req
                                            })}
                                            placeholder="Muster" 
                                            className="inputs off"
                                            autoComplete="off"/>
                                    </div>
                                    <Errors error={errors?.name?.message}/>
                                </div>
                                <div>
                                    <div className="inputs-block _label">
                                        <label className="font-text5-14">Nachname</label>
                                        <input 
                                            {...register("lastName",{
                                                required:err.req
                                            })}
                                            placeholder="Muster Mustermann" 
                                            className="inputs off" 
                                            autoComplete="off"/>
                                    </div>
                                    <Errors error={errors?.lastName?.message}/>
                                </div>
                            </div>
                        </div>
                        <div className="login-form-input">
                            <div className="login-form-flex">
                                <div>
                                    <div className="inputs-block _label">
                                        <label className="font-text5-14">E-mail</label>
                                        <input 
                                            {...register("login",{
                                                required:err.req,
                                                pattern:{
                                                    value: re.login,
                                                    message:err.email
                                                }
                                            })}
                                            className="inputs off" 
                                            autoComplete="off"/>
                                    </div>
                                    <Errors error={errors?.login?.message}/>
                                </div>
                                <div>
                                    <div className="inputs-block _label">
                                        <label className="font-text5-14">Passwort</label>
                                        <input 
                                            {...register("pas",{
                                                required:err.req,
                                                pattern: {
                                                    value: re.pas,
                                                    message: err.pas,
                                                }
                                            })}
                                            type={showPas?'password':'text'}
                                            className="inputs off"
                                            autoComplete="off"/>
                                        <div 
                                            className="icons _eye _small inputs-ico inputs-eye" 
                                            onClick={()=>setPas(!showPas)}></div>
                                    </div>
                                    <div className="login-pas-text">Das Passwort muss aus mehr als 8 Zeichen bestehen. Muss Buchstaben und Sonderzeichen beinhaltet.</div>
                                    <Errors error={errors?.pas?.message}/>
                                </div>
                            </div>
                        </div>
                        <div className="login-form-input">
                            <Rules control={control} errors={errors} rules={rules}/>
                        </div>
                        <div className="login-form-input ">
                            <div className="login-buttons">
                                <div className="login-capcha"></div>
                                <div>
                                    <button type="submit" className="button button-primary">
                                        Registrieren
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>}
            </div>
        </div>:''
    )
}

export {Firm}