import React from 'react';
import {GoogleLogin} from 'react-google-login';


const responseGoogle = (response) => {
    //console.log(response);
}

const Google = ({setLogin}) =>{ 
    return (
    <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText="Continue With Google"
        render={renderProps => (
            <button className="button button-soc _gl" onClick={renderProps.onClick} disabled={renderProps.disabled}>Continue With Google</button>
        )}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
    />
    )
}

export {Google}