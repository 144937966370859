import {useLogin} from "../hook/useLogin";
import useAxios from "../hook/useAxios";
import { useState } from "react";
import { ShareMes } from "./ShareMes";
import ReactTooltip from "react-tooltip";

const Share = ({link}) =>{
    
    const {user,setModal}=useLogin();
    const axios = useAxios(user);

    // show modal
    const [show,setShow]=useState(false);

    // share click or open registration
    const shareClick = e => {
        e.stopPropagation();
        e.preventDefault();

        user
        ?setShow(true)
        :setModal({open:true,st:'mn'});
    }

    return (
        <>
            {show?<ShareMes link={link} setShow={setShow}/>:<></>}
            <ReactTooltip />
            <div
                className="icons _share" 
                data-help="share" data-tip="teilen" data-place="top" data-effect="solid" data-class="c-tooltips"
                onClick={shareClick}>
            </div>
        </>
    );
}

export {Share}