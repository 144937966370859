import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../hook/usePagination';
import '../css/pagination.scss';
const Pagination = props => {

  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div
      className={classnames('pag', { [className]: className })}>
      <div
        className={classnames('pag-do font-h7b-14', {
          disabled: currentPage === 1
        })}
        onClick={onPrevious}>
        Zurück
      </div>
      {paginationRange.map((pageNumber,i) => {
        if (pageNumber === DOTS) {
          return <div className="pag-dot font-h7b-14" key={i}>&#8230;</div>;
        }

        return (
          <div
            className={classnames('pag-item font-h7b-14', {
              active: pageNumber === currentPage
            })}
            key={i}
            onClick={() => onPageChange(pageNumber)}>
            {pageNumber}
          </div>
        );
      })}
      <div
        className={classnames('pag-do font-h7b-14', {
          disabled: currentPage === lastPage
        })}
        onClick={onNext}>
        Weiter
      </div>
    </div>
  );
};

export default Pagination;
