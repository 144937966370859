import './ProjItem.scss';

import { useNavigate } from 'react-router-dom';

import ReactTooltip from 'react-tooltip';
import { MesDel } from '../../../components/MesDel/MesDel';
import { useState } from 'react';

const ProjItem = ({item,del,pause}) =>{

    const navigate = useNavigate();
    const [show,setShow] = useState(false);

    return (
        <tr className="table-row _bottom" key={item.id}>
            <td>
                <div>
                    <div className="proj-item-name">{item.projName}</div>
                    <div className="proj-item-status">
                        {
                            item.pause
                            ?<div className="label _wait _line">ANGEHALTEN</div>
                            :
                                item.proof
                                    ?<div className="label _free _line">Wartet auf Veröffentlichung</div>
                                    :
                                        !item.active
                                        ?<div className="label _no _line">Projekt deaktiviert</div>
                                        :''
                            }
                    </div>
                </div>
            </td>
            <td className='proj-item-data'><span style={{opacity:0.3}}>Projektende:</span> {item.data}</td>
            <td>
                <ReactTooltip />
                <MesDel setShow={setShow} show={show} del={()=>{del();setShow(false)}}>
                    <p>Das Projekt wird aus der Liste entfernt, aber Ihre Bewerbung bleibt für das Projekt aktiv.
                    <br/>Ein Projekt aus der Liste entfernen?</p>
                </MesDel>
                <div className="table-icons">
                    <div 
                        data-help="Pause / Start" data-tip={item.pause?'aktivieren':'Pause'} data-place="top" data-effect="solid" data-class="c-tooltips"
                        className={`icons ${item.pause?'_play':'_stop'}`} 
                        onClick={()=>pause()}></div>
                    <div 
                        className="icons _edit" 
                        data-help="Edit" data-tip="bearbeiten" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>navigate(`../add/${item.id}`)}>
                    </div>
                    <div 
                        className="icons _del" 
                        data-help="Delete" data-tip="löschen" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>setShow(true)}>    
                    </div>
                </div>
            </td>
        </tr>
    )
}

export {ProjItem}