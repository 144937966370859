import { useEffect, useState } from "react";
import useAxios from "../../../hook/useAxios";
import {useLogin} from "../../../hook/useLogin";

const Alert = ({proj,setAlert}) =>{

    const {user}=useLogin();
    const axios=useAxios(user);
    const [anim,setAnim]=useState(false);

    const hide = () =>{
        setAnim(false);
        const animTime=setTimeout(()=>setAlert(false),300);
    }

    useEffect(()=>{
        setAnim(true);
        const alertTime=setTimeout(()=>hide(),5000);
    },[])

    // send form
    const submit = ()=>{
        axios.delete('/free/proj',{params:{id:proj}})
        .then(()=>{
            hide();
        });
    }

    return (
        <div className={`alert ${anim?'anim':''}`}>
            <div>
                <span>Die Bewerbung für das Projekt </span>
                <span className="font-h6b-16">Computer-und Informationstechnologie </span> 
                <span>wurde erfolgreich eingereich </span>
            </div>
            <div><div className="button button-small _no" onClick={()=>submit()}>Abbrechen</div></div>
        </div>
    )
}
export {Alert}