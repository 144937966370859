import { useEffect, useState } from "react";
import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";

import { SpamItem } from "./Spam/SpamItem";

const Spam = () =>{

    const {user} = useLogin();
    const axios = useAxios(user);

    const spamDel = id =>{
        setData(data.filter(item=>item.id!==id));
        axios.delete('/free/spam',{params:{id:id}});
    }

    // spam
    const [data,setData] = useState(null);
    useEffect(()=>{
        axios.get('/free/spam')
        .then(res=>res.data)
        .then(data=>setData(data.res));
    },[])

    return (
        <div>
            <table className="table">
                <thead>
                    <tr className="table-title">
                        <td>Kategorie</td>
                        <td>Daten</td>
                        <td>Aktionen</td>
                    </tr>
                </thead>
                <tbody>
                    {data?data.map((item,i)=>
                    <SpamItem 
                        key={i}
                        item={item} 
                        del={()=>spamDel(item.id)} 
                        />):<tr></tr>}
                </tbody>
            </table>
        </div>
    )
}

export {Spam}