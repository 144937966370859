import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';

import {Facebook} from './Facebook';
import {Google} from './Google';

import {useLogin} from "../../hook/useLogin";
import useAxios from '../../hook/useAxios';

import { CheckboxItem } from "../../components/CheckboxItem";

import { Errors } from '../Errors';

const Main = ({from}) => {

    // hooks
    const [showPas,setPas]=useState(true);
    const {user,signIn,setModal} = useLogin();
    const axios=useAxios(user);

    // err message
    const err={
        req: "bitte ausfüllen",
        pas: "Das Passwort muss aus mehr als 8 Buchstaben und Zahlen bestehen",
        email: "E-Mail nicht erlaubt",
    }

    // useForm
    const {
        register, 
        handleSubmit,
        formState: {errors},
        setError,
        control    
    } = useForm({
        defaultValues:{
            login:'',
            pas:'',
            save:false
        }
    });

    const showError = (error) => {
        for (const [key,val] of Object.entries(error)){
            setError(key,{type:"required",message:val})
        }
    }

    // enter user
    const onSubmit = data => {

        axios.get('/site/enter',{params:data})
        .then(res=>res.data)
        .then(data => {
            // no user, user problem
            if(data.error){
                showError(data.error);
            }else{
                if(data.res){
                    signIn(data.res,from);
                    setModal({open:false,st:''});
                }
            }
        })
    }

    const re = {
        login: /^[^\s]+@[^\s@]+(\.[^\s@]+){1,}$/,
        pas : /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,]).{8,}$/
    }

    return (
        <div className="login-content">
            <div className="login-left">
                <div>
                    <div className="login-logo">
                        <img src="/img/logo/logo_big.png" alt="Logo"/>
                    </div>
                    <div className="login-text">Willkommen zurück!</div>
                </div>
                <div className="login-left-buttom">
                    <div className="login-small-text font-text4">Neu bei Freelancerweb?</div>
                    <div 
                        className="href font-button2-13"
                        onClick={()=>setModal({open:true,st:'ch'})}
                    >Jetzt kostenfrei registrieren</div>
                </div>
            </div>
            <div className="login-main">
                <div className="login-soc">
                    <Facebook/>
                </div>
                <div className="login-soc">
                    <Google/>
                </div>
                
                <div className="login-form-input login-center">
                    <span>oder melden Sie sich mit E-Mail</span>
                </div>
                    
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login-form">
                        <div className="login-form-input">
                            <div className='inputs-block _label'>
                                <label forhtml="name" className="font-text5-14">E-Mail</label>
                                <input 
                                    {...register("login",{
                                        required:err.req,
                                        validate:value=>{
                                            return (
                                                re.login.test(value) || value==='admin'
                                            ) || err.email
                                        }
                                    })}
                                    placeholder="email@gmail.com"
                                    className="inputs off"
                                />
                            </div>
                            <Errors error={errors?.login?.message}/>
                        </div>
                        <div className="login-form-input">
                            <div className='inputs-block _label'>
                                <label forhtml="pas" className="font-text5-14">Passwort</label>
                                <input 
                                    {...register("pas",{
                                        required:err.req
                                    })}
                                    type={showPas?'password':'text'}
                                    className="inputs off"
                                />
                                <div className="icons _eye _small inputs-ico inputs-eye" onClick={()=>setPas(!showPas)}></div>
                            </div>
                            <Errors error={errors?.pas?.message}/>
                        </div>
                        <div className="login-form-input">
                            <div className="login-flex">
                                <div>
                                    <Controller 
                                        name="save"
                                        control={control}
                                        render={({field:{onChange,value}})=>
                                            <CheckboxItem 
                                                active={value}
                                                value="save"
                                                toggle={()=>onChange(!value)}>
                                                Angemeldet bleiben
                                            </CheckboxItem>
                                        }
                                    />
                                </div>
                                <div 
                                    className="font-button2-13 href"
                                    onClick={()=>setModal({open:true,st:'re'})}>
                                    Passwort vergessen?
                                </div>
                            </div>
                        </div>
                        <div className="login-form-input">
                            <button type="submit" className="button button-primary">
                                Anmelden bei Freelancerweb
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export {Main}