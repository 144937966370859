import {Outlet,useLocation} from 'react-router-dom';
import {Top} from './Top';
import {Footer} from './Footer';
import './Main.scss';

import {ScrollToTop} from "../components/ScrollToTop";

const Main = () =>{

    let location = useLocation(); 
    const url=location.pathname.split('/');

    return (
        <>
            <ScrollToTop/>
            <Top/>
            <div className={`page-main ${url[1]==='page'?'_simple':''}`}>
                <Outlet/>
            </div>
            <Footer/>
        </>
    )
}

export {Main}