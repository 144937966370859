import './Other.scss';
import {useEffect,useMemo,useState} from 'react';
import {useSearchParams,useLocation} from "react-router-dom";

import { List } from "./List";
import { Tags } from "../../components/Tags";
import { Checkbox } from '../../components/Checkbox';
import Pagination from "../../components/Pagination";
import {Loading} from "../../components/Loading";
import {MySelect} from '../../components/MySelect';

import useAxios from '../../hook/useAxios';
import { useLogin } from '../../hook/useLogin';

import { Top } from './Proj/Top';
import { useParams,useNavigate} from 'react-router-dom';
import { Spam } from './Spam';

const PageSize=10;

const Proj = () => {

    const {page}=useParams();
    const history = useNavigate();

    const {user} = useLogin();
    const axios = useAxios(user);

    // cat from menu click
    const location=useLocation();
    const cat = useMemo (
        ()=>location?.state?.cat || null,
        [location?.state?.cat]
    );

    useEffect(()=>{
        setSearch({...search,cat:cat});
        setRefresh(true);
    },[cat]);

    // menuFilter
    const [menuFilter,setMenuFilter] = useState();
    window.addEventListener('click',()=>setMenuFilter(false));
    const prevent = (e) => {e.stopPropagation();e.preventDefault();}

    // search from url
    const [query,setQuery] = useSearchParams();
    const searchVal = query.get('search') || '';

    // sorting array
    const sortArray=[
        {label:'Standart',value:''}
    ];

    // post array
    const [search,setSearch] = useState({
        page:parseInt(page) || 1,
        cat:location?.state?.cat || null,
        land:null,
        search:searchVal || '',
        tags:[],
        sorting:null
    });

    // refresh
    const [refresh,setRefresh] = useState(true);

    // filter cats lends
    const [cats,setCats] = useState([]);
    const [lands,setLands]= useState([]);

    const addActive = (data) =>(
            data.map((item)=>({...item,active:false})
        )
    );

    // type filter
    const [type,setType]=useState([]);
    const setTypeCheck = (val) => {
        const newVal = type.map((item)=>{
            if(item.value===val){item.active=!item.active}
            return item;
        })
        setType(newVal);
    }

    // work filter
    const [work,setWork]=useState([]);
    const setWorkCheck = (val) => {
        const newVal = work.map((item)=>{
            if(item.value===val){item.active=!item.active}
            return item;
        });
        setWork(newVal);
    }

    // filters state
    const [tags,setTags] = useState([]);
    const setTagsFilter = value => {
        const newVal = tags.map(item=>{
            if(item.value===value){item.active=!item.active}
            return item;
        })
        setTags(newVal)
        setSearch({...search,tags:tags.filter(tag=>tag.active).map(tag=>tag.value)});   
    }

    // data state
    const [data, setData] = useState([]);

    //start load
    useEffect(()=>{
        const req={params:{type:[
            'cat',
            'proj',
            'work',
            'lands',
            'tags'
        ]}};
        axios.get('/site/type/',req)
        .then(res=>res.data)
        .then(data=>{
            data.cat.unshift({label:'alle',value:null})
            setCats(data.cat);
            
            setTags(data.tags);
            setLands(data.lands);
            setType(addActive(data.proj));
            setWork(addActive(data.work));
        }).catch((error) => {
            console.log(error);
        })
    },[]);

    //get data
    useEffect(()=>{
        const req={params:{...search}};
        axios.get('/site/proj/',req)
        .then(res=>res.data)
        .then(data=>{
            setData(data.res || []); // set new data
            setRefresh(false); // set refresh false
            setMenuFilter(false); // hide menu
            search.search.length>0 && setQuery({search:search.search}); // set url search
        }).catch((error) => {
            console.log(error);
        })
    },[refresh]);

    return (
        <>
            <Top 
                setSearch={search=>setSearch(search)} 
                setRefresh={refresh=>setRefresh(refresh)}
                search={search}
            />

            <div className="page-block">
                <div className="other-block">
                    <div className={`other-menu ${menuFilter && 'active'}`} onClick={(e)=>prevent(e)}>
                        <div>
                            <div className="other-menu-title font-h3-18">Kategorie auswählen</div>
                            <div>
                                <MySelect 
                                    value={cats.filter(item=>item.value===search.cat)}
                                    onChange={(res)=>setSearch({...search,cat:res.value})} 
                                    options={cats} 
                                    placeholder="All categories"
                                    isSearchable={false}/>
                            </div>
                        </div>
                        <div>
                            <div className="other-menu-title font-h3-18">Land</div>
                            <div>
                                <MySelect 
                                    onChange={res=>setSearch({...search,land:res.value})} 
                                    options={lands} 
                                    placeholder="All lands"
                                    isSearchable={false}/>
                            </div>
                        </div>
                        {/* <div>
                            <div className="other-menu-title font-h2-24">Tags</div>
                            <Tags toggle={setTagsFilter} tags={tags}/>
                        </div> */}
                        <div>
                            <div className="other-menu-title">Projekt Art</div>
                            <div className="other-checkbox">
                                {type.length ? <Checkbox list={type} toggle={setTypeCheck}/>:<></>}
                            </div>
                        </div><div>
                            <div className="other-menu-title">Auslastung</div>
                            <div className="other-checkbox">
                                {work.length ? <Checkbox list={work} toggle={setWorkCheck}/>:<></>}
                            </div>
                        </div>

                        <div className="button button-primary _width" onClick={()=>setRefresh(true)}>Suche eingrenzen</div>

                        {user?.type===0?<Spam cats={cats}/>:''}
                    </div>
                    <div className="other-content">
                        
                        <div className="other-content-top">
                            <div className="other-filter" onClick={(e)=>{prevent(e);setMenuFilter(true)}}>
                                <div className="icons _topmenu"></div>
                            </div>
                            <div className="font-h3-18">{data.length>0 && `${data.length} Projekte`}</div>
                            {/* <div className="other-content-select">
                                <div className="font-text4-16">Sortieren nach:</div>
                                <div className="other-content-choise">
                                    <MySelect 
                                        select={(res)=>setSearch({...search,sorting:res.value})} 
                                        options={sortArray} 
                                        placeholder="Standart"
                                        isSearchable={false}/>
                                </div>
                            </div> */}
                        </div>

                        <div className="other-flex">
                            {refresh
                                ? <Loading/>
                                :(<div className="other-items">                                
                                    {data.length
                                    ?<List data={data} page={search.page} pageSize={PageSize} path="/projs"/>
                                    :<div className="other-noresult">Keine Informationen gefunden</div>}
                                </div>)
                            }
                        </div>
                        <div className="other-pag">    
                            <Pagination
                                className="pagination-bar"
                                currentPage={search.page}
                                totalCount={data.length}
                                pageSize={PageSize}
                                onPageChange={page => {
                                    setSearch({...search,page:page})
                                    history(`/projs/${page}`);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export {Proj};