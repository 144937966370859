import {Controller} from 'react-hook-form';
import InputMask from 'react-input-mask';

import { MySelect } from "../../../components/MySelect";
import { Errors } from "../../../components/Errors";
import DatePicker from "../../../hook/useDatepicker";

const title=[];
title[31]={first:'Ausbildungsstätte',second:'Bezeichnung'};
title[32]={first:'Weiterbildungseinrichtung',second:'Kursbezeichnunh'}
title[41]={first:'FH',second:'Fach'}
title[42]={first:'Universität',second:'Fakultät / Fach'}

// Ausbildung - Ausbildungsstätte - Bezeichnung
// Weiterbildung - Weiterbildungseinrichtung - Kursbezeichnunh
// Fachhochschule - FH - Fach
// Studium - Universität - Fakultät / Fach

const Study = ({setData,data,type,form}) =>{

    const err={
        req : 'bitte ausfüllen'
    }

    // data fn
    const setDate = (date) =>(
        date
        ?new Date(date)
        :null
    )

    const delStudy = index =>{
        // delete by index
        let newStudy=data.study.filter((item,key)=>key!==index);
        setData({...data,study:newStudy});
    }

    // idStudy
    // 19 - study
    // 20 - kurs

    // dateFormat="dd LLLL yyyy"

    // const today = new Date();
    // const tomorrow = new Date();
    // tomorrow.setDate(tomorrow.getDate()+1);

    return (
        data.study.map((item,key)=>{
            const inputName=`study.${key}`;
            const error=form.errors?.study?.[key];
            
            // default
            var idStatus=form.watch(`${inputName}.idStudy`) || 31;

            return <div className="section-block" key={key}>
                <input type="hidden" {...form.register(`${inputName}.id`)} />
                <div className="fr-fr-s">
                    <div>
                        <div className="inputs-block _label _select">
                            <label>Art der Ausbildung</label>
                            <Controller 
                                name={`${inputName}.idStudy`}
                                control={form.control}
                                rules={{required:err.req}}
                                render={({field:{onChange,value}})=>{
                                    //value=value || 31;
                                    return <MySelect 
                                        isSearchable={false}
                                        value={type.filter(item=>item.value===value)}
                                        onChange={e=>onChange(e.value)}
                                        options={type}/>
                                }}
                            />
                        </div>
                        <Errors error={error?.idStudy?.message}/>
                    </div>
                    <div className='fr-fr-data'>
                        <div>
                            <div className="inputs-block _label">
                                <label>Start</label>
                                <Controller 
                                    name={`${inputName}.startData`}
                                    control={form.control}
                                    rules={{required:err.req}}
                                    render={({field:{onChange,value}})=>{
                                        return <DatePicker 
                                            selected={setDate(value)}
                                            closeOnScroll={true}
                                            alwaysShowMask={false}
                                            onChange={onChange}
                                            maxDate={Date.now()}
                                            className="myDatepicker"
                                            dateFormat="dd.MM.yyyy"
                                            placeholder="Datum auswählen"
                                            customInput={
                                                <InputMask mask="99.99.9999" maskChar={null}/>
                                            }
                                        />
                                    }}
                                />
                                <div className="icons _cal _small inputs-ico"></div>
                            </div>
                            <Errors error={error?.startData?.message}/>
                        </div>
                        <div>
                            <div className="inputs-block _label">
                                <label>Ende</label>
                                <Controller 
                                    name={`${inputName}.endData`}
                                    control={form.control}
                                    render={({field:{onChange,value}})=>
                                        <DatePicker
                                            selected={setDate(value)}
                                            closeOnScroll={true}
                                            alwaysShowMask={true}
                                            onChange={onChange}
                                            maxDate={Date.now()}
                                            className="myDatepicker"
                                            dateFormat="dd.MM.yyyy"
                                            placeholder="Datum auswählen"
                                            customInput={
                                                <InputMask mask="99.99.9999" maskChar={null}/>
                                            }
                                        />
                                    }
                                />
                                <div className="icons _cal _small inputs-ico"></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="inputs-block _label">   
                            <label>{title[idStatus].first}</label>
                            <input 
                                {...form.register(`${inputName}.studyName`,{required:err.req})}
                                className="inputs off"
                                autoComplete="off"/>
                        </div>
                        <Errors error={error?.studyName?.message}/>
                    </div>
                    <div>
                        <div className="inputs-block _label">
                            <label>{title[idStatus].second}</label>
                            <input 
                                {...form.register(`${inputName}.studyPlace`,{required:err.req})}
                                className="inputs off"
                                autoComplete="off"/>
                        </div>
                        <Errors error={error?.studyPlace?.message}/>
                    </div>
                </div>
                <div className="button button-small _minus" onClick={()=>{delStudy(key)}}>Position löschen</div>
            </div>
       })
    )
}

export {Study};