import "./Det.scss";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";
import { Loading } from "../../components/Loading";

import { Proj } from "./FirmDet/Proj";
import { Docs } from "./FirmDet/Docs";
import { Top } from "./FirmDet/Top";
import { Soc } from "./Soc";

const FirmDet = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  const { user, setModal } = useLogin();
  const axios = useAxios(user);

  function createMarkup(html) {
    return { __html: html };
  }

  // pay mes
  const [payMes, setPayMes] = useState(false);

  // var navigate=useNavigate();
  // const goBack = () => navigate(-1);

  // navigation data
  const navi = [
    { name: "Home", url: "/" },
    { name: "Firmen", url: "/firm" },
  ];

  // title of proj
  const title = [
    "ähnliche projekte aus dieser Kategorie",
    "Projekte des Unternehmens",
  ];

  useEffect(() => {
    // get detail
    // stat inside get fn
    axios
      .get("/site/firmDet/", { params: { id: id, type: 2 } })
      .then((res) => res.data)
      .then((data) => {
        data.res ? setData(data.res) : (window.location.href = "/firm/");
      });
  }, []);

  return data ? (
    <div className="other-det">
      <Top data={data} user={user} axios={axios} setModal={setModal} />

      <div className="other-det-content page-block _small">
        <div className="title">Über Uns</div>
        <div className="other-det-text">
          <div dangerouslySetInnerHTML={createMarkup(data.descrText)} />
        </div>
        <div className="title">Benefits</div>
        <div>
          <div dangerouslySetInnerHTML={createMarkup(data.benefitText)} />
        </div>

        {data.proj.length ? (
          <Proj
            company={data.id}
            list={data.proj}
            title={data.tarif < 2 ? title[0] : title[1]}
          />
        ) : (
          ""
        )}

        {data.docs ? <Docs list={data.docs} /> : <></>}

        {data?.soc?.length ? <Soc list={data.soc} /> : <></>}

        {/* <div className="other-det-bottom" ref={contacts}>
                    <div className="title">Kontakt {`&`} Standort</div>
                    <div className="other-det-contacts">
                        <div className="other-det-form">
                            <Contacts/>
                        </div>
                        <div className="other-det-map">
                            {<img src="/img/temp/map.jpg" alt="map"/>}
                        </div>
                    </div>
                </div> */}
      </div>
    </div>
  ) : (
    <Loading />
  );
};
export { FirmDet };
