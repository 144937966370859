import './Stat.scss';

import { useMemo, useState } from "react";
import Pagination from "../../components/Pagination";
import { Graf } from './Stat/Graf';
import { useEffect } from 'react';

import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";
import { UserList } from './Stat/UserList';
import { Loading } from '../../components/Loading';
import { useNavigate } from 'react-router-dom';

const pageSize=5;

const Stat = () => {

    const navigate=useNavigate();

    // data state
    const [data,setData] = useState();
    const [page,setPage] = useState(1);

    const {user}=useLogin();
    const axios=useAxios(user);

    //const graf=[12,23,15,20,30,21];

    useEffect(()=>{
        axios.get('/stat/')
        .then(res=>res.data)
        .then(data=>{
            setData(data.res)
        });
        
    },[]);

    const statClear = () =>{
        axios.delete('/stat/');
        data.user=[];
        data.all=0;
        Object.keys(data.graf).forEach(key => data.graf[key]=0);
        setData({...data});
    }

    return (
        data?<>
        {(user.payd && user.tarif===2)?
            <>
                <Graf graf={data.graf} all={data.all} statClear={statClear}/>

                <UserList data={data.user} page={page} pageSize={pageSize}/>

                <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={data.user.length}
                    pageSize={pageSize}
                    onPageChange={page => setPage(page)}
                />
            </>
        :navigate(-1)}</>:<Loading/>
    )
}

export {Stat}