import "./Mes.scss";
import {Modal} from '../../../components/Modal';

const Mes = ({setShow}) =>{
    return (
        <Modal setShow={setShow}>
            <div className="prof-mes">
                <div className="prof-mes-icon">
                    <div className='icons _alert _alert-ok'></div>
                </div>
                <div className="font-h5b-18">Profildaten erfolgreich gespeichert</div>
                <div>Die Daten werden vom Site-Administrator geprüft und in Kürze auf der Site veröffentlicht</div>
            </div>
        </Modal>
    )
}

export {Mes}