import {useEffect,useState} from 'react';
import {useParams} from "react-router-dom";

import useAxios from '../../hook/useAxios';
import {useLogin} from '../../hook/useLogin';

import { PayMes } from "../../components/PayMes/PayMes";
import { Loading } from "../../components/Loading";

import { Form } from './Add/Form';

const Add = () => {

    const def={
        "id":"",
            "projName":"",
            "projNumber":"",
            "job":"",
            "idType":"",
            "idWork":"",
            "idLand":"",
            "locationJson":{
                "city":"",
                "code":""
            },
            "startData":"",
            "endData":"",
            "taskLink":"",
            "descrText":"",
            "taskText":"",
            "proposeText":"",
            "shortText":"",
            "requireText":"",
            "benefitText":"",
            "cats":[],
            "skills":[]
    }

    // data fn
    const setDate = (date) =>(
        date
        ?new Date(date)
        :new Date()
    )

    // axios user
    const {user}=useLogin();
    const axios = useAxios(user);

    // data def
    const [data,setData] = useState();

    // type default
    var [type,setType] = useState({
        cat:[],
        skills:[],
        work:[],
        proj:[],
        lands:[]
    });

    // edit proj
    const {id}=useParams();

    // pay mes
    const [payMes,setPayMes] = useState(false);

    // start load
    useEffect(()=>{

        //get type
        const req={
            params:{'type':[
                'cat',
                'skills',
                'proj',
                'work',
                'lands'
            ]}
        }

        axios.get('/site/type/',req)
        .then(res=>res.data)
        .then(data => {
            setType(data);
        }).catch((error) => {
            console.log(error);
        })
            
        // get data
        axios.get('/firm/proj',{params:{id:id}})
        .then(res=>res.data)
        .then(data =>{

            if(data.pay){
                setPayMes(true);
            }

            if(data.res){
                data.res.startData=setDate(data.res.startData);
                data.res.endData=setDate(data.res.endData);
                setData(data.res);
            }else{
                setData(def);
            }   
        }).catch((error) => {
            console.log(error);
        })

    },[]) 

    //! append new value to select
    // const appendHendler = (e) =>{
    //     switch (e.key) {
    //         case 'Enter':
    //         case 'Tab':
    //         e.preventDefault();
    //         setType(type.skills.push({value:0,label:e.target.value}));
    //         console.log(type.skills);
    //     }
    // }

    return (
        data
        ?
        <>
        <PayMes setShow={setPayMes} show={payMes}/>
        <Form
            pay={setPayMes}
            type={type}
            user={user}
            axios={axios}
            data={data}/>
        </>:<Loading/>
    )
}

export {Add}