import "./Form.scss";

import { useRef,useState} from "react";
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';

import { Errors } from "../../../components/Errors";
import { NewEditor } from '../../../components/NewEditor/NewEditor';
import { Loading } from "../../../components/Loading";
import { MesOk } from "./MesOk";

const Form = ({data,axios}) => {

    // image change
    const choiseFile = useRef(null);

    // nok message
    const def=false;
    const [mesOk,setMesOk] = useState(def);

    // parse
    const err = {
        req: 'bitte ausfüllen'
    }

    // form
    const {
        register, 
        handleSubmit,
        control, 
        formState: {errors},
        watch
    } = useForm({
        defaultValues:data
    });

    // send
    const onSubmit = data =>{

        const formData = new FormData();
        formData.append('img',data.img);
        const json=JSON.stringify(data);
        formData.append('data',json);

        // prepare to send
        axios.post('/admin/news',formData,{
            headers:{
                'content-type': 'multipart/form-data'
            }
        })
        .then(res=>res.data)
        .then(()=>{
            //console.log(data.res);
            setMesOk(true);
        });
    };

    watch();

    return (
        <>
            {mesOk?<MesOk setShow={setMesOk}/>:''}
            {data
            ?<div>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="form _tree">

                        <div className="_span">
                            <div className="inputs-block _label">
                                <label>Überschrift der Nachrichten</label>
                                <input 
                                    {...register("name",{required:err.req})}
                                    className="inputs off" 
                                    autoComplete="off"/>
                            </div>
                            <Errors error={errors?.name?.message}/>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <label>Datum</label>
                                <Controller 
                                    name="data" 
                                    control={control}
                                    rules={{required:err.req}}
                                    render={({field:{onChange,value}})=>
                                        <DatePicker 
                                            selected={value}
                                            closeOnScroll={true}
                                            alwaysShowMask={false}
                                            onChange={onChange}
                                            className="myDatepicker"
                                            dateFormat="dd.MM.yyyy"
                                            placeholder="Datum auswählen"
                                            customInput={
                                                <InputMask mask="99.99.9999" maskChar={null}/>
                                            }
                                        />
                                    }
                                />
                                <div className="icons _cal _small inputs-ico"></div>
                            </div>
                            <Errors error={errors?.data?.message}/>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <label>Nachrichtenquelle</label>
                                <input 
                                    {...register("source")}
                                    className="inputs off" 
                                    autoComplete="off"/>
                            </div>
                        </div>

                        <div>
                            <div 
                                className={`button button-small _download ${choiseFile.current?choiseFile.current.value!=''?'_active':'':''}`}
                                onClick={(e) => choiseFile.current.click()}>
                                Datei hochladen
                            </div>
                            <Controller
                                name="img"
                                control={control}
                                render={({field:{onChange}})=>
                                    <input
                                        type="file"
                                        onChange={e=>onChange(e.target.files[0])} 
                                        ref={choiseFile}
                                    />
                                }
                            />
                        </div>

                    </div>

                    <div className="fr-pf-title">
                        <div className="font-h3b-24">Kurze Beschreibung</div>
                    </div>

                    <div>
                        <div className="inputs-block _label">
                            <Controller 
                                name="shortText" 
                                control={control}
                                rules={{required:err.req}}
                                render={({field:{onChange,value}})=>
                                    <textarea
                                        defaultValue={value}
                                        onChange={html=>onChange(html)}
                                    />
                                }
                            />
                        </div>
                        <Errors error={errors?.shortText?.message}/>
                    </div>

                    <div className="fr-pf-title">
                        <div className="font-h3b-24">Gesamte Beschreibung</div>
                    </div>

                    <div>
                        <div className="inputs-block _label">
                            <Controller 
                                name="text" 
                                control={control}
                                rules={{required:err.req}}
                                render={({field:{onChange,value}})=>
                                    <NewEditor 
                                        html={value?value:''}
                                        onChange={html=>onChange(html)}
                                    />
                                }
                            />
                        </div>
                        <Errors error={errors?.text?.message}/>
                    </div>

                    <div className="form">
                        <div>
                            <button type="submit" className="button button-primary">Speichern</button>
                        </div>
                    </div>

                </form>
            </div>:<Loading/>}
        </>
    )
}

export {Form}