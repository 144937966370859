import "./Account.scss";
import {Link} from "react-router-dom";

// login
import {useLogin} from "../hook/useLogin";
import { MailChecker } from "./Account/MailChecker";

const Account = () => {

    // login modal
    const {user,setModal,signOut} = useLogin();

    return (
        <div className="page-top-right">
            {user?
            <>
                <div className="menu-account">
                    
                    <MailChecker/>
                    
                    {/* <Link to={'/account/'} className="button button-line">Main account</Link> */}
                    
                    <Link to={'/account/'} className="menu-account-foto">
                        {user.img
                        ?<img src={user.img} alt="Foto"/>
                        :<div className="icons _user _black"></div>}
                    </Link>
                    <div className="menu-account-out" onClick={() => signOut('/')}>
                        <div className="icons _out"></div>
                    </div>
                </div>
                <Link to={'/account/'} className="menu-account-ico">
                    <div className="icons _user _black"></div>
                </Link>
            </>
            :<>
                <div className="menu-account">
                    <div className="menu-account-login button button-line font-button1-16" 
                        onClick={()=>setModal({open:true,st:'mn'})}>
                            Login
                    </div>
                    <div 
                        className="button button-primary font-button1-16" 
                        onClick={()=>setModal({open:true,st:'ch'})}>
                            Registrieren
                    </div>
                </div>
                <div 
                    className="menu-account-ico" 
                    onClick={()=>setModal({open:true,st:'mn'})}>
                        <div className="icons _user _black"></div>
                </div>
            </>}
        </div>
    )
}
export {Account}