// import './index.scss';

import App from './App';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from './auth/AuthContext';

import { Provider } from 'react-redux';

import { store } from "./store";

const root = createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Provider>
  </BrowserRouter>
);
