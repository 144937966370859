import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {Chart as ChartJS,registerables} from "chart.js";
ChartJS.register(...registerables);

const Graf = ({graf,all,statClear}) => {

    const options = {
        responsive: true,
        scales: {
            xAxis: {
                ticks: {
                    display: false,
                },
                grid:{
                    display:false
                }
            },
            yAxis:{
                beginAtZero: true,
                ticks:{
                    beginAtZero: true,
                    stepSize: 1
                    // callback: function(value, index, ticks) {
                    //     return '$' + value;
                    // }
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: false,
                text: 'Title of Chart',
            },
            tooltip:{
                enabled:false
            }
        },
    };

    const labels = Array();

    const data = {
        labels,
        datasets: [
            {
                label: 'Label', //label name
                data: graf, // data set
                borderColor: '#0084FF', // line color
                backgroundColor: '#ffffff', // pointer inside color
                borderWidth:2, // line border width
                pointBorderWidth:2, // border of points
                pointHoverBackgroundColor: '#fffffff',
                pointRadius:4, // point radius
                hoverBorderWidth:2
            },
        ],
    };

    return (
        <>
            <div className="font-h6b-16 fr-st-title">Besucherstatistik</div>
            <div className="fr-st-top">
                <div className="fr-st-graf">
                    <Line options={options} data={data} />
                </div>
                <div className="fr-st-right">
                    <div className="font-h3b-24 fr-st-ico"><div className="icons _user"></div><div>x {all}</div></div>
                    <div className="font-text4-16 fr-st-text">{all} Personen haben Ihr Profil in den letzten 15 Tagen angesehen</div>
                    <div><div className="button button-secondary" onClick={()=>statClear()}>Statistik löschen</div></div>
                </div>
            </div>
        </>
    )
}

export {Graf}