const RatItem = ({ points }) => {
  return (
    <div className="rat">
      <div className="rat-points">{points ? points.toFixed(1) : ""}</div>
      <div className="rat-stars">
        {[...Array(5)].map((arr, i) => (
          <div
            key={i}
            className={`icons _star _small ${
              points >= i + 1 ? "active" : points <= i ? "" : "_half"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export { RatItem };
