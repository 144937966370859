import CreatableSelect from 'react-select/creatable';

const customStyles = {
  option: (provided, state) => ({
    ...provided, // save old props
    background: 
    state.isSelected ? 'var(--color-black-10)' :
    state.isFocused ? 'var(--color-blue-10)' : '#fff',
    color:'#000',
    padding:'10px'
  }),
}

const MyCreatable = (prop) => {

  return <CreatableSelect
        isMulti
        isClearable
        placeholder="Auswählen ... "
        noOptionsMessage={()=>"keine Optionen ..."}
        formatCreateLabel={inputText=>`Hinzufügen ... ${inputText}`}
        styles={customStyles}
        className="react-select"
        classNamePrefix="select"
        {...prop}
      />
}

export {MyCreatable}