const TagsList = ({tags}) => {

    return (
        <div className="taglist">
            {tags.map((item,i)=>
                <div className="taglist-item" key={i}>{item.name}</div>)
            }
        </div>
    )

}

export {TagsList}