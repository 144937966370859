import './Menu.scss';

import {Link, useLocation} from 'react-router-dom';
import { useLogin } from '../../hook/useLogin';
import { useState,useEffect } from 'react';
import { PayMes } from '../../components/PayMes/PayMes';

const Menu = ({json,acc}) =>{

    const {user,signOut} = useLogin();

    // get firms two path for active style
    let {pathname}=useLocation();
    pathname=`/${pathname.split('/').slice(1,3).join('/')}/`;

    // menu mobile
    let [mActive,setMenu] = useState(false);

    // map all menu list
    let menu=json;
    // let menu=json.map(
    //     item=>({...item,active:`/account${item.url}`===pathname})
    // );

    let hideMenu=[
        //'/stat/',
    ];

    const prevent = (e) => {
        e.stopPropagation();
    }

    // toggle menu
    const toggleMenu = () =>{
        setMenu(!mActive);
    }

    // event hendler
    const hendler= () =>{
        setMenu(false);
    }

    useEffect(() => {
        // scroll on documents close menu
        //window.addEventListener('scroll',hendler);
        // click on documents close menu
        window.addEventListener('click',hendler);

        return () => {
            //window.removeEventListener('scroll',hendler);
            window.removeEventListener('click',hendler);
        }
    },[])

    const viewLink=user.type==0?'/freelance/':'/firm/';

    // stat access
    const [pay,setPay] = useState(false);
    const payAccess = (e,url) => {
        //! premium
        if(url==='/stat/'){
            if(!user.payd || user.tarif!==2){
                e.preventDefault();
                setPay(true);
            }
        }
    }

    return (
        <div 
            className={`side-menu ${mActive?'active':''}`} 
            onClick={e=>{prevent(e);toggleMenu()}}>
            <PayMes setShow={setPay} show={pay}/>
            {
                menu.map((item,i)=>{
                    if(!hideMenu.includes(item.url)){
                        // active class
                        const active=`${item.url}`===pathname;
                        return <Link  
                            key={i}
                            to={`${item.url}`}
                            onClick={e=>payAccess(e,item.url)}
                            className={`side-menu-item ${active?'active':''}`}>
                            <div className="side-menu-icon">
                                <div className={`icons _menu _side-menu ${item.st}`}/>
                            </div>
                            <div className="side-menu-text">{item.name}</div>
                        </Link>
                    }
                })
            }

            {user.type!=2?<Link
                to={`${viewLink}${user.id}`} 
                className={`side-menu-item`}
                state={{from:2,back:`/account`}}>
                <div className="side-menu-icon">
                    <div className={`icons _menu _side-menu _m-eye`}/>
                </div>
                <div className="side-menu-text">Profilvorschau</div>
            </Link>:''}

            <div 
                className={`side-menu-item`}
                style={{marginTop:'20px'}}
                onClick={() => signOut('/')}>
                <div className="side-menu-icon">
                    <div className="icons _m-out _menu _side-menu"></div>
                </div>
                <div className="side-menu-text" >Logout</div>
            </div>

        </div>
    )
}

export {Menu}