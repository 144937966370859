import { useState } from "react";
import { Link,useNavigate} from "react-router-dom"
import { useLogin } from "../../../hook/useLogin";

const Searching = () =>{

    const {user} = useLogin();

    const [search,setSearch] = useState({
        proj:'',
        free:''
    });

    const navigate = useNavigate();

    const onSubmit = (e,path) =>{
        e.preventDefault();
        var search=e.target.search.value;

        if(search.length){
            navigate(`/${path}/?search=${search}`);
            //Navigate(`/${path}/?search=${search}`);
        }
    }

    return (
        <div className="home-banner wow fadeIn">
            {!user || user.type!==1
            ?<div className="home-banner-item home-banner-left">
                <div className="home-banner-content">
                    <div className="home-banner-title">Projekt findet</div>
                    {/* <div className="home-banner-text">Nach einem Projekt suchen oder Berufsbezeichnungen/Skills eingeben</div> */}
                    <form onSubmit={e=>onSubmit(e,'projs')}>
                        <div className="search-form">
                            <div className="search-form-input">
                                <input 
                                    type="text" 
                                    className="inputs" 
                                    placeholder="SAP Entwickler, Einkauf Software"
                                    value={search.proj}
                                    name="search"
                                    onChange={(e)=>setSearch({...search,proj:e.target.value})}
                                    />
                            </div>
                            <button type="submit" className="button button-search submit">
                                SUCHEN
                            </button>
                        </div>
                    </form>
                </div>
            </div>:''}
            {!user || user.type!==0
            ?<div className="home-banner-item home-banner-right">
                <div className="home-banner-content">
                    <div className="home-banner-title">Freelancer finden</div>
                    {/* <div className="home-banner-text">Nach passendem Freelancer suchen</div> */}
                    <form onSubmit={e=>onSubmit(e,'freelances')}>
                        <div className="search-form">
                            <div className="search-form-input">
                                <input 
                                    type="text" 
                                    className="inputs" 
                                    placeholder="Einkäufer, SAP Berater, Reinigungskraft"
                                    value={search.free}
                                    name="search"
                                    onChange={(e)=>setSearch({...search,free:e.target.value})}
                                    />
                            </div>
                            <button type="submit" className="button button-search submit">
                                SUCHEN
                            </button>
                        </div>
                    </form>
                </div>
            </div>:''}
        </div>
    )
}

export {Searching}