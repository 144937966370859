import { Errors } from '../../../components/Errors';
import useAxios from '../../../hook/useAxios';
import {useLogin} from '../../../hook/useLogin';

const Doc = ({setData,data,form}) => {

    const err={
        req : 'bitte ausfüllen'
    }

    const {user} = useLogin();
    const axios = useAxios(user);

    // del doc
    const delDoc = (index) => {
        // delete by index
        let id=data.doc[index].id;
        axios.delete('/doc/',{params:{id:id}});
        let newItem=data.doc.filter((item,key)=>key!==index);
        setData({...data,doc:newItem});
    };


    return (
        data.doc.length
        ?<div className="fr-fr-d">
        {data.doc.map((item,key)=>{
            
            const inputName=`doc.${key}`;
            const error=form.errors?.doc?.[key];

            return <div key={key}>
                <div>
                    <input 
                        type="hidden" 
                        {...form.register(`${inputName}.id`)} 
                        defaultValue={item.id} />
                    <div className="inputs-block _label">
                        <label>Datei Name</label>
                        <input 
                            {...form.register(`${inputName}.name`,{required:err.req})}
                            placeholder="Weitermachen" 
                            className="inputs off"
                            autoComplete="off"/>
                    </div>
                    <Errors error={error?.name?.message}/>
                </div>
                <div className='fr-fr-name'>{item.file}</div>
                <div><div className="icons _del" onClick={()=>{delDoc(key)}}></div></div>
            </div>})}
        </div>:<></>
    )
}

export {Doc}