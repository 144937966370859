import { useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { MesDel } from "../../../components/MesDel/MesDel";
import { MesMail } from "../../../components/MesMail/MesMail";

const SpamItem = ({item,del}) =>{

    const [remove,setRemove] = useState();
    
    return (
        <tr className="table-row" key={item.id}>
            <td>
                {item.name}
            </td>
            <td>
                {item.data}
            </td>
            <td>
                <ReactTooltip/>
                <MesDel setShow={setRemove} show={remove} title="Löschen einer Mailingliste" del={()=>{setRemove(false);del()}}>
                    <p>Wollen Sie die Mailing-Anfrage wirklich löschen?</p>
                </MesDel>
                <div className="table-icons">
                    <div className="icons _del"
                        data-help="Delete" data-tip="Löschen" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>setRemove(true)}>
                    </div>
                </div>
            </td>
        </tr>
    )
}

export {SpamItem}