import { useRef, useState } from 'react';
import useAxios from '../../hook/useAxios';
import {useLogin} from '../../hook/useLogin';

import { MesDel } from '../../components/MesDel/MesDel';
import ReactTooltip from 'react-tooltip';

const Img = () =>{

    // get user info
    const {user,setUser} = useLogin();
    const axios = useAxios(user);

    const choiseImage = useRef();
    
    // save image change
    const sendImage = (image)=>{
        if(image){  
            if(image.size<=2000000){
                const formData = new FormData();
                formData.append("img", image);

                // else data
                //formData.append("recipe", JSON.stringify(data));

                axios
                .post('/user/image', formData,{headers:{'content-type': 'multipart/form-data'}})
                .then(res=>res.data)
                .then(data =>{
                    // renew globel image
                    setUser({...user,img:data.img});
                    localStorage.setItem('user',JSON.stringify({...user,img:data.img}));
                })
                .catch(() => console.log("File Upload Error"));
            }
            choiseImage.current.value='';
        }
    };

    // request for image delete
    const imageDel = () => {
        setShow(false);
        if(user.img){
            setUser({...user,img:null})
            localStorage.setItem('user',JSON.stringify({...user,img:null}))
            axios.delete('/user/image');
        }
    };

    const [show,setShow] = useState(false);

    return <div className="fr-pf-top">
            <ReactTooltip />
            <MesDel setShow={setShow} show={show} del={imageDel}>
                <p>Bestätigen Sie die Löschung wirklich</p>
            </MesDel>
            <div className="fr-pf-foto">
                {user.img
                ?<img src={user.img} alt="Foto"/>
                :<div className="icons _user _black _big"></div>}
            </div>
            <div>
                <div 
                    className="icons _del"
                    data-help="Delete" data-tip="Löschen" data-place="top" data-effect="solid" data-class="c-tooltips" 
                    onClick={()=>setShow(true)}></div>
            </div>
            <div>
                <div 
                    className="icons _edit" 
                    data-help="Edit" data-tip="Foto bearbeiten" data-place="top" data-effect="solid" data-class="c-tooltips" 
                    onClick={e => {choiseImage.current.click();}}>
                </div>
            </div>
            <div>größe 200 x 200 px</div>
            <input 
                type="file" 
                onChange={e => sendImage(e.target.files[0])} 
                ref={choiseImage}/>
        </div>
}

export {Img}