import "./News.scss";

import { useEffect, useState } from 'react';

import useAxios from '../../hook/useAxios';
import {useLogin} from '../../hook/useLogin';
import { NewsItem } from "./News/NewsItem";
import { Loading } from "../../components/Loading";

const News = () =>{

    const [data,setData]=useState(null);

    useEffect(()=>{
        axios.get('/site/news')
        .then(res=>res.data)
        .then(data=>setData(data.res));
    },[])

    // axios user
    const {user}=useLogin();
    const axios = useAxios(user);

    return (
        <div className='news'>
            <div className='page-block _small'>
                <div className="font-h1b-50">News</div>
                <div className="news-list">
                    {data?
                    data.map((item,i)=>
                        <NewsItem item={item} key={i}/>
                    ):<Loading/>}
                </div>
            </div>
        </div>
    )
}

export {News}