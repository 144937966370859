
import { useState } from "react";
import { Like } from "../../components/Like";
import { Rat } from "../../components/Rat";
import { RatItem } from "../../components/RatItem";
import { Share } from "../../components/Share";
import { useLogin } from "../../hook/useLogin";
import { Item } from "./Item";

const FirmItem = ({item}) =>{

    const {user}=useLogin();

    const [points,setRat]=useState(item.rat);

    return (
        <Item link={`/firm/${item.id}`}>
            <div className="item-logo item-foto">
                {item.logo
                ?<img src={item.logo} alt="foto"/>
                :<div className="icons _user _black _big _opacity"></div>}
            </div>
            <div className="item-line"></div>
            <div className="item-info">
                <div className="item-info-top">
                    <div>
                        <div className="item-title font-h4b-20">{item.name}</div>
                        {points?<RatItem points={points}/>:''}
                        {item.map && <div className="item-location font-text5-14">
                            <div className="icons _map _small"></div>
                            <div>{item.map}</div>
                        </div>}
                    </div>
                    <div>
                        <div className="item-icons">
                            <Rat id={item.id} type={1} setRat={setRat}/>
                            <Share link={`/firm/${item.id}`}/>
                            <Like id={item.id} type="1" like={item.l}/>
                        </div>
                    </div>
                </div>
                <div className="item-descr font-text5-14">{item.text}</div>
            </div>
        </Item>
    )
}

export {FirmItem}