import './NewEditor.scss';

import React, {useState, useRef} from 'react';
import { Editor, EditorState, RichUtils} from 'draft-js';
import { convertToHTML, convertFromHTML} from 'draft-convert';

//import 'draft-js/dist/Draft.css';

const NewEditor = ({html,onChange,placeholder}) =>{

    const editor = useRef()
    const focus = () => editor.focus();

    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(convertFromHTML(html))
        //() => EditorState.createEmpty(),
    );

    const onEditorChange = newState => {
        setEditorState(newState);
        //editorState.hasText();
        onChange(convertToHTML(editorState.getCurrentContent()));
        //(convertToHTML(EditorState.getCurrentContent()));
    }

    // handler keybord command
  //   const handleKeyCommand = command => {
  //     const newState = RichUtils.handleKeyCommand(
  //       editorState,
  //       command
  //     );
  //     if (newState) {
  //       onEditorChange(newState);
  //       return "handled";
  //     }
  //     return "not-handled";
	// };

    // editor style
    const styleMap = {
        CODE: {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            padding: 2,
        },
    };

    // button UL toggle
    const toggleBlockType = blockType => {
        onEditorChange(
              RichUtils.toggleBlockType(
              editorState,
              blockType
          )
        );
    }
    
    // button style toggle
    const toggleInlineStyle = inlineStyle => {
        onEditorChange(
            RichUtils.toggleInlineStyle(
            editorState,
            inlineStyle
            )
        );
    }

    const StyleButton = (props) => {
  
        const onToggle = (e) => {
            e.preventDefault();
            props.onToggle(props.style);
        };
      
        let className = 'RichEditor-styleButton';
        if (props.active) {
            className += ' RichEditor-activeButton';
        }
    
        return (
            <span className={`${className} ${props.ico} icons _small`} onMouseDown={onToggle}>
                {props.label}
            </span>
        );
      }

    const BLOCK_TYPES = [
        // {label: 'H1', style: 'header-one'},
        // {label: 'H2', style: 'header-two'},
        // {label: 'H3', style: 'header-three'},
        // {label: 'H4', style: 'header-four'},
        // {label: 'H5', style: 'header-five'},
        // {label: 'H6', style: 'header-six'},
        // {label: 'Blockquote', style: 'blockquote'},
        {label: '', style: 'unordered-list-item',ico:'_list'},
        // {label: 'OL', style: 'ordered-list-item'},
        // {label: 'Code Block', style: 'code-block'},
      ];
      
      const BlockStyleControls = (props) => {
        const {editorState} = props;
        const selection = editorState.getSelection();
        const blockType = editorState
          .getCurrentContent()
          .getBlockForKey(selection.getStartKey())
          .getType();
      
        return (
          <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
              <StyleButton
                key={type.label}
                active={type.style === blockType}
                label={type.label}
                onToggle={props.onToggle}
                style={type.style}
                ico={type.ico}
              />
            )}
          </div>
        );
      };
      
      var INLINE_STYLES = [
        {label: '', style: 'BOLD' ,ico:'_bold'},
        // {label: 'Italic', style: 'ITALIC'},
        // {label: 'Underline', style: 'UNDERLINE'},
        // {label: 'Monospace', style: 'CODE'},
      ];
      
      const InlineStyleControls = (props) => {
        const currentStyle = props.editorState.getCurrentInlineStyle();
        
        return (
          <div className="RichEditor-controls">
            {INLINE_STYLES.map((type) =>
              <StyleButton
                key={type.label}
                active={currentStyle.has(type.style)}
                label={type.label}
                onToggle={props.onToggle}
                style={type.style}
                ico={type.ico}
              />
            )}
          </div>
        );
      };

      return (
        <div className="RichEditor-root">
          <div className="RichEditor-toolbar">
            <BlockStyleControls
              editorState={editorState}
              onToggle={toggleBlockType}
            />
            <InlineStyleControls
              editorState={editorState}
              onToggle={toggleInlineStyle}
            />
          </div>
          <div>
            <Editor
              stripPastedStyles={true}
              customStyleMap={styleMap}
              editorState={editorState}
              onChange={onEditorChange}
              spellCheck={true}
              placeholder={placeholder}
              ref={editor}
            />

            {/*handleKeyCommand={handleKeyCommand}*/}
          </div>
        </div>
      );
}

export {NewEditor}