export const Imp = {
    title:'Impressum',
    html:<div>
        <div>Unser Impressum gilt für die Seite <a href="https://freelancerweb.de">www.freelancerweb.de</a></div>
        <div>sowie für die Freelancerweb-Seiten auf <a href="https://facebook.com">Facebook</a>, <a href="http://youtube.com">YouTube</a>, <a href="https://linkedin.com">Linkedin</a> und Xing.</div>
        
        <br/>
        
        <div className="font-h1b-40">freelancerweb.de</div>
        <div>Maximin Straße 24</div>
        <div>D-54340 Longuich</div>
        <div>Deutschland</div>
        <div><b>Tel.:</b> +49 6502 404 0834</div>
        <div><b>E-Mail:</b> <a href="mailto:info@freelancerweb.de" target="_blank">info@freelancerweb.de</a></div>
        <div><b>Web:</b> <a href="https://freelancerweb.de">www.freelancerweb.de</a></div>
        <div><b>Verantwortlicher:</b> Intemaconsulting (Igor Zukrow) (im Folgenden: Freelancerweb),</div>
        <div>Maximin Straße 24, 54340 Longuich, Deutschland</div>

        <br/>
        
        <div><b>Vertreten durch die persönlich haftende Gesellschafter:</b></div>
        <div>Igor Zukrow</div>

        <br/>
        
        <div><b>Firmensitz:</b> Longuich</div>
        <div><b>USt.ID:</b> DE344038306</div>

        <br/>
        
        <div><b>Haftungsausschluss und Copyright:</b></div>
        <div>Für den Inhalt der Website ist allein der Nutzer verantwortlich. Unabhängig davon, ob eine Person oder ein Unternehmen Informationen über sich selbst, über ein Projektangebot oder sonstige Informationen veröffentlich hat, übernimmt freelancerweb.de keine Haftung für den Inhalt.</div>
        <br/>
        <div>Für die Vollständigkeit und Richtigkeit der auf diesen Internetseiten enthaltenen Informationen wird keine Gewähr übernommen.</div>
        <br/>
        <div>Dieses Plattform und alle darin enthaltenen Beiträge und Abbildungen sind urheberrechtlich geschützt. </div>
        <br/>
        <div>Jede Verwertung ohne Zustimmung der freelancerweb.de ist untersagt und unerlaubt.</div>
        <br/>
        <div>Freelancerweb © ist ein eingetragenes Warenzeichen der intemaconsulting.</div>

        <br/>
        
        <div>Alle Rechte vorbehalten.</div>
    
    </div>
}