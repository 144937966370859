import React from 'react';

import FacebookAuth from "react-facebook-auth";
 
const MyFacebookButton = ({ onClick }) => (
  <button 
    onClick={onClick} 
    className="button button-soc _fb">
    Continue With Facebook
  </button>
);
 
const authenticate = (response) => {
  const {
      accessToken: token,
      name,
      id,
      picture:{
        data:{
            url
        }
      }
    } = response;

    //console.log({name,token,id,url});
};


const Facebook = () => {

    const authenticate = (data) =>{
      //console.log(data);
    }

    return (
        <FacebookAuth
            appId={process.env.REACT_APP_FACEBOOK_ID}
            callback={authenticate}
            component={MyFacebookButton}
        />
    )
}

export {Facebook}