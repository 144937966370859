import "./Mes.scss";
import { useState } from "react";
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import {Modal} from '../../../components/Modal';

import { useLogin } from "../../../hook/useLogin";
import useAxios from "../../../hook/useAxios";

const Mes = ({setShow,id,tar}) =>{

    const date=new Date();
    date.setMonth(date.getMonth() + 12)

    const {user}=useLogin();
    const axios=useAxios(user);

    const [tarif,setTarif] = useState(null);

    const [dataTo,setDataTo] = useState(date);

    // send premium account
    const submit = ()=>{
        if(tarif){
            const req={tarif:tarif,dataTo:dataTo,id:id};
            axios.post('/admin/account',req);
            //setDataTo(new Date());
            setShow(false);

            // callback
            tar(tarif,dataTo);
        }
    }
    
    return (
        <Modal setShow={setShow}>
            <div className="mes-pay">
                <div>
                    <div className="font-h2b-32 _center">
                        Paketlaufzeit
                    </div>
                </div>
                <div className="mes-pay-account">
                    <div className={`label _wait ${tarif!==1?'_opacity':''}`} onClick={()=>setTarif(1)}>Basic</div>
                    <div className={`label _free ${tarif!==2?'_opacity':''}`} onClick={()=>setTarif(2)}>Premium</div>
                </div>
                <div className="inputs-block _label">
                    <label>Verlängerungsdatum</label>
                    <DatePicker 
                        selected={dataTo}
                        closeOnScroll={true}
                        alwaysShowMask={false}
                        onChange={value=>setDataTo(value)}
                        className="myDatepicker"
                        dateFormat="dd.MM.yyyy"
                        placeholder="Datum auswählen"
                        customInput={
                            <InputMask mask="99.99.9999" maskChar={null}/>
                        }
                    />
                </div>
                <div>
                    <div className="mes-del-button">
                        <div className="button button-primary" onClick={()=>setShow(false)}>Nein</div>
                        <div className="button button-secondary" onClick={()=>submit()}>Ja</div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export {Mes}