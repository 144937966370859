import { Link } from "react-router-dom"

import {useLogin} from "../../../hook/useLogin";

import {Swiper,SwiperSlide } from "swiper/react"

const FirmList = ({list}) =>{

    const {user,setModal}=useLogin();

    return (
        list?

            <div className="page-block">
            
                <div className="page-content-text">
                    <div className="font-h1b-50">Neue Firmen</div>
                    <div className="font-text1-32">
                        Täglich überzeugen wir viele Projektanbieter mit unseren tollen Eigenschaften
                    </div>
                </div>

                <div className="swiper-block">
                    <Swiper 
                        breakpoints={{
                            400: {
                                slidesPerView: 3,
                                slidesPerGroup:3
                            },
                            800: {
                                slidesPerView: 5,
                                slidesPerGroup:5
                            },
                            1000: {
                                slidesPerView: 6,
                                slidesPerGroup:6
                            }
                        }}
                        speed = {2000}
                        autoplay = {{
                            delay: 5000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true
                        }}
                        pagination={{ clickable: true }}
                        centeredSlides={false}
                        centerInsufficientSlides={true}
                        centeredSlidesBounds={false}>
                        {list.map(item=>
                            <SwiperSlide key={item.id}>
                                {user
                                    ?<Link 
                                        to={`/firm/${item.id}`} 
                                        className="home-company-item _shadow">
                                        <img src={item.img} alt="logo"/>
                                    </Link>
                                    :<div
                                        onClick={()=>setModal({open:true,st:'mn'})}
                                        className="home-company-item">
                                            <img src={item.img} alt="logo"/>
                                        </div>
                                }
                            </SwiperSlide>)}
                    </Swiper>
                </div>

                {user && 1?'':<div onClick={()=>setModal({open:true,st:'fi'})} className="button button-secondary">Registrieren</div>}
            </div>
        :''
    )
}

export {FirmList}