import {useParams} from 'react-router-dom'
import {ScrollToTop} from "../../components/ScrollToTop";
import {Imp} from './Simple/Imp';
import { Agb } from './Simple/Agb';
import { Data } from './Simple/Data';
import { Rules } from './Simple/Rules';

import './Simple.scss';

const Simple = ({title,text}) => {
    const {page} = useParams();

    const pageContent={
        'imp':Imp,
        'agb':Agb,
        'date':Data,
        'rules':Rules,
    }

    return (
        page in pageContent?
        <>
            <ScrollToTop/>
            <div className="simple-page">
                <div className="page-block _small">
                    <div className="simple-title">{pageContent[page].title}</div>
                    <div className="simple-text">
                        {pageContent[page].html}
                    </div>
                </div>
            </div>
        </>:<></>
    )
}

export {Simple}