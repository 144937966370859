import { useState,useRef} from "react";
import { Doc } from "./Doc";

import useAxios from '../../../hook/useAxios';
import {useLogin} from '../../../hook/useLogin';
import { PayMes } from "../../../components/PayMes/PayMes";

const DocAdd = ({setData,data,form}) => {

    const {user} = useLogin();
    const axios = useAxios(user);

    // image change
    const choiseFile = useRef(null);

    const [pay,setPay] = useState(false);

    const addDoc = (file)=>{
        const formData = new FormData();
        formData.append("file", file);
        if(file){  
            if(file.size<=2000000){
                const formData = new FormData();
                formData.append("file", file);
                axios
                .post('/doc/', formData,{
                    headers:{'content-type': 'multipart/data-data'}
                })
                .then(res=>res.data)
                .then(file =>{
                    //! premium // pay error
                    if(file.pay){
                        setPay(true);
                    // if all allright
                    }else if(file.res){
                        // add new file
                        let newItem=data.doc.concat([file.res]);
                        setData({...data,doc:newItem})
                    }
                })
                .catch(e => console.log("File Upload Error",e));
            }
            choiseFile.current.value='';
        }
    };

    return (
    <div className="section-block active">
        <PayMes setShow={setPay} show={pay}/>
        <Doc 
            setData={setData}
            data={data}
            form={form}/>
        <div 
            className="button button-small _plus" 
            onClick={e => choiseFile.current.click()}>
            Datei hochladen
        </div>
        <input 
            type="file" 
            onChange={e => addDoc(e.target.files[0])} 
            ref={choiseFile}/>
    </div>)
}

export {DocAdd}