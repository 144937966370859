import { useEffect, useState } from "react";
import { MySelect } from "../../components/MySelect"
import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin"
import { Mes } from "./Spam/Mes";

const Spam = ({cats}) =>{

    const {user} = useLogin();
    const axios = useAxios(user);

    const [cat,setCat] = useState(null);
    const [mes,setMes] = useState(false);

    const sendForm = () =>{
        axios.post('/free/spam/',{cat:cat})
        setMes(true);
    }

    return (<>
        {mes?<Mes setShow={setMes}/>:''}
        <div className='other-spam'>
            <div>
            Wenn Sie über die Neuigkeiten aus der Rubrik sich informieren möchten, abonnieren Sie den Verteiler.
            </div>
            <div>
                <MySelect 
                    value={cats.filter(item=>item.value===cat)}
                    onChange={res=>setCat(res.value)} 
                    options={cats} 
                    placeholder="All categories"
                    isSearchable={false}/>
            </div>
            <div>
            <div 
                className="button button-primary _width" 
                onClick={()=>sendForm()}>
                Anmelden
            </div>
            </div>
        </div>
    </>
    )
}

export {Spam}