import { Navi } from '../../../components/Navi';

const Top = ({search,setSearch,setRefresh}) =>{

    const navi=[
        {'name':'Home','url':'/'},
        {'name':'Freelancer','url':'/freelance'}
    ];

    const onSubmit = e =>{
        e.stopPropagation();
        e.preventDefault();
        setRefresh(true);
    }

    return (
        <div className="other-banner">
            <div className="other-banner-block">
        
                <div className="page-block">
                    <div className="navi _white">
                        <Navi list={navi}/>
                    </div>
                </div>
        
                <div className="page-block">
                    <div className="other-banner-title">Suchen sie nach einem Expert, der am besten ihrer Anforderungen entspricht?</div>
                    <div className="other-form">
                        <form onSubmit={onSubmit}>
                            <div className="search-form">
                                <div className="search-form-input">
                                    <input 
                                        type="text" 
                                        className="inputs" 
                                        value={search.search} 
                                        onChange={(e)=>setSearch({...search,search:e.target.value})} 
                                        placeholder="Suche nach Unternehmen"/>
                                </div>
                                <button type="submit" className="button button-search submit">SUCHEN</button>
                            </div>
                        </form>
                    </div>
        
                </div>
            </div>
        </div>
    )
}

export {Top}