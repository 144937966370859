import useAxios from '../../hook/useAxios';
import { useLogin } from '../../hook/useLogin';

import {useEffect,useMemo,useState} from 'react';
import {useLocation, useSearchParams} from "react-router-dom";

import { List } from "./List";
import { Chars } from "../../components/Chars";
import { Loading } from "../../components/Loading";
import { Tags } from "../../components/Tags";
import Pagination from "../../components/Pagination";
import { MySelect } from "../../components/MySelect";

import './Other.scss';
import { Top } from './Firm/Top';
import { useParams,useNavigate} from 'react-router-dom';

const pageSize=10;

const Firm = () => {

    const {page}=useParams();
    const history = useNavigate();

    const {user} = useLogin();
    const axios = useAxios(user);

    // cat from menu click
    const location=useLocation();
    const cat = useMemo(
        ()=>location?.state?.cat || null,
        [location?.state?.cat]
    );
    useEffect(()=>{
        setSearch({...search,cat:cat});
        setRefresh(true);
    },[cat]);

    // menuFilter
    const [menuFilter,setMenuFilter] = useState();
    window.addEventListener('click',()=>setMenuFilter(false));
    const prevent = (e) => {e.stopPropagation();e.preventDefault();}

    // search
    const [query,setQuery] = useSearchParams();
    const searchVal = query.get('search') || '';

    // post array
    const [search,setSearch] = useState({
        page:parseInt(page) || 1,
        cat:location?.state?.cat || null,
        land:null,
        search:searchVal || '',
        tags:[],
        sorting:null,
        letter:''
    });

    // data state
    const [data, setData] = useState([]);

    // refresh
    const [refresh,setRefresh] = useState(true);

    // filters state
    const [cats,setCats] = useState([]);
    const [lands,setLands]= useState([]);
    const [letters,setLetters] = useState([]);

    // filters state
    // const [tags,setTags] = useState([]);
    // const setTagsFilter = value => {
    //     const newVal = tags.map(item=>{
    //         if(item.value===value){item.active=!item.active}
    //         return item;
    //     })
    //     setTags(newVal)
    //     setSearch({...search,tags:tags.filter(tag=>tag.active).map(tag=>(tag.value))});   
    // }

    //start load
    useEffect(()=>{

        // site types
        const req={params:{type:[
            'tags',
            'cat',
            'lands',
            'letter'
        ]}}
        axios.get('/site/type/',req)
        .then(res=>res.data)
        .then(data => {
            data.cat.unshift({label:'alle',value:null})
            setCats(data.cat);
            
            //setTags(data.tags);
            setLands(data.lands);
            setLetters(data.letter); 
        }).catch((error) => {
            console.log(error);
        })
    },[]);

    useEffect(()=>{
        const req={params:{...search}};
        axios.get('/site/firm',req)
            .then(res=>res.data)
            .then(data => {
                setData(data.res || []);
                setRefresh(false);
                setMenuFilter(false);
                search.search.length>0 && setQuery({search:search.search});
            }).catch((error) => {
                console.log(error);
            })
    },[search.letter,refresh]);
    
    return (
        <>
            <Top 
                setSearch={search=>setSearch(search)} 
                setRefresh={refresh=>setRefresh(refresh)}
                search={search}
            />

            <div className="page-block">
                <div className="other-block">
                    <div className={`other-menu ${menuFilter && 'active'}`} onClick={(e)=>prevent(e)}>
                        <div>
                            <div className="other-menu-title font-h3-18">Kategorie auswählen</div>
                            <div>
                                <MySelect 
                                    value={cats.filter(item=>item.value===search.cat)}
                                    onChange={res=>setSearch({...search,cat:res.value})}
                                    options={cats} 
                                    placeholder="All categories"
                                    isSearchable={false}/>
                            </div>
                        </div>
                        <div>
                            <div className="other-menu-title font-h3-18">Land</div>
                            <div>
                                <MySelect 
                                    onChange={res=>setSearch({...search,land:res.value})} 
                                    options={lands} 
                                    placeholder="Country"
                                    isSearchable={false}/>
                            </div>
                        </div>
                        {/* <div>
                            <div className="other-menu-title font-h3-18">Tags</div>
                            <div className="tag">
                                <Tags toggle={setTagsFilter} tags={tags}/>
                            </div>
                        </div> */}
                        <div className="button button-primary _width" onClick={()=>setRefresh(true)}>Suche eingrenzen</div>
                    </div>

                    <div className="other-content">

                            <div className="other-char">
                                <div className="other-filter" onClick={(e)=>{prevent(e);setMenuFilter(true)}}>
                                    <div className="icons _topmenu"></div>
                                </div>
                                <div className="char">
                                    {letters.length 
                                    ?<Chars 
                                        setLetter={letter=>setSearch({...search,letter:letter!=='*'?letter:''})} 
                                        letter={search.letter} 
                                        letters={letters}/>
                                    :''
                                    }
                                </div>
                            </div>
                            <div className="other-flex">
                                {refresh
                                    ?<Loading/>
                                    :<div className="other-items">                                
                                        {data.length
                                        ? <List data={data} page={search.page} pageSize={pageSize} path="/firms"/> 
                                        : <div className="other-noresult">Keine Informationen gefunden</div>}
                                    </div>
                                }
                            </div>
                            <div className="other-pag">
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={search.page}
                                    totalCount={data.length}
                                    pageSize={pageSize}
                                    onPageChange={page => {
                                        setSearch({...search,page:page});
                                        history(`/firms/${page}`);
                                    }}
                                />
                            </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export {Firm};