import { Link } from "react-router-dom"

const UserItem = ({item}) =>{
    return (
        <Link to={`/freelance/${item.id}`} target="_blank" className="card-stat">
            <div className="card-stat-foto">
                {item.ico
                    ?<img src={item.ico} alt="Foto"/>
                    :<div className="icons _user _black _opacity _big"></div>
                }
            </div>
            <div className="card-stat-title">{item.name}</div>
            <div>{item.cat}</div>
            <div>{item.d}</div>
        </Link>
    )
}

export {UserItem}