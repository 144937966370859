import "./ShareMes.scss";

import {useForm } from 'react-hook-form';

import { Errors } from "../components/Errors";
import {useLogin} from "../hook/useLogin";
import useAxios from "../hook/useAxios";
import {Modal} from './Modal';

const ShareMes = ({setShow,link}) =>{

    // parse
    const err = {
        req: 'bitte ausfüllen',
        eml: 'E-Mail nicht erlaubt'
    }

    const emailRegex=/^[^\s]+@[^\s@]+(\.[^\s@]+){1,}$/;

    const {user}=useLogin();
    const axios=useAxios(user);

    const {
        register, 
        handleSubmit,
        formState: {errors},    
        reset   
    } = useForm();

    // send form
    const onSubmit = data =>{
        const req={email:data.email,link:link};
        axios.post('/user/share',req);
        setShow(false);
        reset();
    }

    return (
        <Modal setShow={setShow}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="share-mes">
                    <div>
                        <div className="font-h2b-32 _center">Teilen</div>
                    </div>
                    <div>
                        <div className="inputs-block _label">
                            <label>E-mail:</label>
                            <input 
                                {...register("email",{
                                    required:err.req,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: err.eml
                                    }
                                })}
                                placeholder="" 
                                className="inputs off"/>    
                        </div>
                        <Errors error={errors?.email?.message}/>
                    </div>
                    <div>
                        <div className="share-mes-text font-text5-14 _center">Geben Sie die zu sendende E-Mail-Adresse ein</div>
                        <button type="submit" className="button button-primary _width">senden</button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

export {ShareMes}