import { TagsList } from "../../../components/TagsList";

const Info = ({data}) =>{

    function createMarkup(html) {
        return {__html: html};
    }

    // http patern 
    var pattern = /^((http|https|ftp):\/\/)/;

   return (
        <>
            {data.descrText!==''?<><div className="title">Projekt beschreibung</div>
            <div className="other-det-text" dangerouslySetInnerHTML={createMarkup(data.descrText)}/></>:''}

            {data.skills.length
            ?<>
                <div className="title">Skils</div>
                <TagsList title="Skils" tags={data.skills}/>
            </>
            :<></>}

            {data.taskText!==''?<><div className="title">Aufgaben</div>
            <div dangerouslySetInnerHTML={createMarkup(data.taskText)} /></>:''}

            {data.requireText!==''?<><div className="title">Anforderungen</div>
            <div dangerouslySetInnerHTML={createMarkup(data.requireText)} /></>:''}

            {data.taskLink?<div><a href={pattern.test(data.taskLink)?data.taskLink:`https://${data.taskLink}`} target="_blank" rel="noreferrer" className="pr-item-text">Link zur Aufgabe</a></div>:''}

            {data.proposeText!==''?<><div className="title">Was wir bieten</div>
            <div dangerouslySetInnerHTML={createMarkup(data.proposeText)} /></>:''}

            {/* <div className="title">Benefits</div>
            <div dangerouslySetInnerHTML={createMarkup(data.benefitText)} /> */}
        </>
   )
}

export {Info}