
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom"

import useAxios from "../../../hook/useAxios";
import { useLogin } from "../../../hook/useLogin";
import { setLikeAction } from "../../../store/likeList";

const NewsItem = ({item}) =>{

    const axios = useAxios();

    const dispatch = useDispatch();
    const setLike = (e,id) => {
        e.preventDefault();
        dispatch(setLikeAction(id));

        const action = likeList.indexOf(id)===-1?1:0;

        //send 
        const req={id:id,action:action}; // 1 -- add 0 -- sub
        axios.post('/site/like',req);
    }

    const likeList = useSelector(state => state.like.list);

    return (
        <div className="home-news-item">
            <Link to={`/news/${item.id}`} className="home-news-img">
                <img src={`/katalog/news/big/${item.img}`}/> 
            </Link>
            <div className="home-news-descr">
                <div className="home-news-data">{item.data}</div>
                <Link to={`/news/${item.id}`} className="home-news-title">{item.name}</Link>
                <div>{item.shortText}</div>
            </div>
            <div className="home-news-icons">
                <div className={`icons _like _vsmall _gray ${likeList.indexOf(item.id)!==-1?'active':''}`} onClick={e=>setLike(e,item.id)}></div>
                <Link to={`/news/${item.id}`} className="icons _sg _vsmall _gray"></Link>
            </div>
        </div>
    )
}

export {NewsItem}