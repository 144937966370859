import "./Company.scss";
import { CompanyItem } from "./Company/CompanyItem";

import { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";

import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";

const pageSize=5;

const Company = () => {

    // data state
    const [data, setData] = useState([]);
    const [page,setPage] = useState(1);

    const {user}=useLogin();
    const axios=useAxios(user);

    useEffect(()=>{
        axios.get('/free/firm')
        .then(res=>res.data)
        .then(data=>setData(data.res || []));
    },[]);

    const comDel = com =>{
        setData(data.filter(item=>item.id!==com.id));
        axios.delete('/free/firm',{params:{id:com.id}});
    }

    return <div className="fr-ps">
        <table className="table">
            <thead>
                <tr className="table-title">
                    <td>Firmen</td>
                    <td>Ort</td>
                    <td>Ationen</td>
                </tr>
            </thead>
            <tbody>
                {data?data.map((item,i)=>
                <CompanyItem key={i} del={()=>comDel(item)} item={item}/>)
                :<tr></tr>}
            </tbody>
        </table>
        <Pagination
            className="pagination-bar"
            currentPage={page}
            totalCount={data.length}
            pageSize={pageSize}
            onPageChange={page => setPage(page)}
        />
    </div>
}
export {Company}
    