import { useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { MesDel } from "../../../components/MesDel/MesDel";
import { MesMail } from "../../../components/MesMail/MesMail";

const ProjItem = ({item,set,del}) =>{

    const [remove,setRemove] = useState();
    const [active,setActive] = useState();
    const [mail,setMail] = useState(false);
    
    return (
        <tr className="table-row" key={item.id}>
            <td>
                <div className="table-image">
                    <Link className="table-image-ico" to={`/proj/${item.id}`} target="_blank">
                        {item.ava
                            ?<img src={item.ava} alt="Foto"/>
                            :<div className="table-image-user"></div>
                        }
                    </Link>
                    <div>
                        <div className="table-bold">{item.name}</div>
                        <div>{item.addInfo}</div>
                    </div>
                </div>
            </td>
            <td>
                <div className="table-bold">{item.projName}</div>
                <div className="table-data"><span>Datum der Änderung: </span>{item.editData}</div>
            </td>
            <td>
                <ReactTooltip/>
                {mail?<MesMail setShow={setMail} id={item.idUser}  topic="Überprüfe das Ergebnis"/>:<></>}
                <MesDel setShow={setRemove} show={remove} del={()=>{setRemove(false);del()}}>
                    <p>Anfrage zur Projektaktivierung löschen?</p>
                </MesDel>
                <MesDel setShow={setActive} show={active} del={()=>{setActive(false);set()}} title="Bestätigen">
                    <p>Aktivieren Sie das Projekt und veröffentlichen Sie es auf der Website?</p>
                </MesDel>
                <div className="table-icons">
                    <div className="button button-line" 
                        data-help="Active" data-tip="Bestätigen" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>setActive(true)}>
                        Bestätigen</div>
                    <div 
                        className="icons _send"
                        data-help="Send" data-tip="Senden" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>setMail(true)}>
                    </div>
                    <div className="icons _del"
                        data-help="Delete" data-tip="Löschen" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>setRemove(true)}>
                    </div>
                </div>
            </td>
        </tr>
    )
}

export {ProjItem}