import './Content.scss';
import json from './menu.json';

import {Routes, Route,Navigate} from 'react-router-dom';

import {Proj} from './Proj.jsx';
import {Site} from './Site';

// all user 
import {Menu} from '../user/Menu.jsx';
import {Config} from '../user/Config';
import {Mail} from '../user/Mail';
import {MailDet} from '../user/MailDet';

import { Pay } from './Pay';
import { Users } from './Users';
import { Tabs } from './Tags';

import { News } from './News';
import { Add } from './News/Add';

const AdminContent = () => {

    return (
        <div className="page-block _small">
            <div className="fr">
                <Menu json={json}/>
            
                <div className="fr-content">
                    <Routes>
                        <Route path="/pay" element={<Pay/>}/>
                        <Route path="/pay/add/:id" element={<Pay/>}/>
                        
                        <Route path="/mail/" element={<Mail/>}/>
                        <Route path="/mail/:id" element={<MailDet/>}/>
                        
                        <Route path="/site/" element={<Site/>}/>
                        
                        <Route path="/news/" element={<News/>}/>
                        <Route path="/news/:id" element={<Add/>}/>

                        <Route path="/tags/" element={<Tabs/>}/>
                        <Route path="/users/" element={<Users/>}/>
                        <Route path="/proj/" element={<Proj/>}/>
                        <Route path="/config/" element={<Config/>}/>
                        <Route path="*" element={<Navigate to={'./users'}/>}/>
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export {AdminContent}