import "./Notfound.scss";

import {ScrollToTop} from "../../components/ScrollToTop";

const Notfound = () => {
    return(
        <>
        <ScrollToTop/>
            <div className="notfound">
                404
                <span>Error</span>
            </div>
        </>
    )
}

export {Notfound}