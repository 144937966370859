import Select from 'react-select'

const customStyles = {
  option: (provided, state) => ({
    ...provided, // save old props
    background: 
    state.isSelected ? 'var(--color-black-10)' :
    state.isFocused ? 'var(--color-blue-100)' : '#fff)',
    color:'#000',
    padding:'10px'
  }),
}

const MySelect = (prop) => {

  return <Select
        placeholder="Auswählen ... "
        noOptionsMessage={()=>"keine Optionen ..."}
        styles={customStyles}
        className="react-select"
        classNamePrefix="select"
        {...prop}
      />
}

export {MySelect}