import "./Mes.scss";
import { useState } from "react";
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import {Modal} from '../../../components/Modal';

import { useLogin } from "../../../hook/useLogin";
import useAxios from "../../../hook/useAxios";

const Mes = ({setShow,pay,id}) =>{

    const date=new Date();
    date.setMonth(date.getMonth() + 12)

    const {user}=useLogin();
    const axios=useAxios(user);

    const [dataTo,setDataTo] = useState(date);

    // send premium account
    const submit = ()=>{
        const req={dataTo:dataTo,id:id};
        axios.post('/admin/premium',req);
        //setDataTo(new Date());
        setShow(false);
        pay();
    }
    
    return (
        <Modal setShow={setShow}>
            <div className="mes-pay">
                <div>
                    <div className="font-h2b-32 _center">
                        Paketlaufzeit
                    </div>
                </div>
                <div className="inputs-block _label">
                    <label>Verlängerungsdatum</label>
                    <DatePicker 
                        selected={dataTo}
                        closeOnScroll={true}
                        alwaysShowMask={false}
                        onChange={value=>setDataTo(value)}
                        className="myDatepicker"
                        dateFormat="dd.MM.yyyy"
                        placeholder="Datum auswählen"
                        customInput={
                            <InputMask mask="99.99.9999" maskChar={null}/>
                        }
                    />
                </div>
                <div>
                    <div className="mes-del-button">
                        <div className="button button-primary" onClick={()=>setShow(false)}>Nein</div>
                        <div className="button button-secondary" onClick={()=>submit()}>Ja</div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export {Mes}