import { useEffect, useState } from "react"
import useAxios from "../../../hook/useAxios";

const Rubrics = () =>{

    const [rubrics,setRubrics] = useState(null);
    const axios=useAxios()

    useEffect(()=>{
        axios.get('/site/type',{params:{type:["cat"]}})
        .then(res=>res.data)
        .then(data=>{
            setRubrics(data.cat);
        })
    },[])

    return (
        rubrics
        ?<div className="rubric">
            {
                rubrics.map((item,i)=> 
                    <div className="rubric-item" key={i}>{item.label}</div>
                )
            }
        </div>
        :''
    )
}

export {Rubrics}