import { useEffect, useState } from "react";
import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";
import { ProjItem } from "./Proj/ProjItem"

const Proj = () =>{

    // data state
    const [data, setData] = useState([]);

    const {user}=useLogin();
    const axios=useAxios(user);

    useEffect(()=>{
        axios.get('/admin/proj')
        .then(res=>res.data)
        .then(data=>setData(data.res));
    },[]);

    const projDel = id =>{
        setData(data.filter(item=>item.id!==id));
        axios.delete('/admin/proj',{params:{id:id}});
    }

    const projSet = id =>{
        setData(data.filter(item=>item.id!==id));
        axios.post('/admin/proj',{id:id});
    }

    return (
        <>
            <table className="table">
                <thead>
                    <tr className="table-title">
                        <td>Absender</td>
                        <td>Projekt</td>
                        <td>Aktionen</td>
                    </tr>
                </thead>
                <tbody>
                    {data?data.map((item,i)=>
                    <ProjItem 
                        key={i}
                        item={item} 
                        del={()=>projDel(item.id)} 
                        set={()=>projSet(item.id)}/>):<tr></tr>}
                </tbody>
            </table>
        </>
    )
}

export {Proj}