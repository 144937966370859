import { useEffect, useState } from 'react';

import useAxios from '../../../hook/useAxios';
import {useLogin} from '../../../hook/useLogin';
import { Loading } from "../../../components/Loading";

import { NewsItem } from "./NewsItem";

import {Swiper,SwiperSlide } from "swiper/react"

const News = () =>{

    const [data,setData]=useState(null);

    useEffect(()=>{
        axios.get('/site/news')
        .then(res=>res.data)
        .then(data=>setData(data.res));
    },[])

    // axios user
    const {user}=useLogin();
    const axios = useAxios(user);

    return (
        data?<div className='home-news'>
            <div className='page-block _small'>
                <div className="font-h1b-50 _center">News</div>
                <div className="home-news-slider">
                    <Swiper 
                        breakpoints={{
                            400: {
                                slidesPerView: 1,
                                slidesPerGroup:1
                            },
                            800: {
                                slidesPerView: 3,
                                slidesPerGroup:3
                            }
                        }}
                        pagination={{ clickable: true }}
                        centeredSlides={false}
                        centerInsufficientSlides={true}
                        centeredSlidesBounds={false}>
                        {data.map((item,i)=>
                        <SwiperSlide key={i}>
                            <NewsItem item={item}/>
                        </SwiperSlide>)}
                    </Swiper>
                </div>
            </div>
        </div>:<Loading/>
    )
}

export {News}