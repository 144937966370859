import { Like } from "../../components/Like";
import { Share } from "../../components/Share";
import { TagsList } from "../../components/TagsList";
import { Item } from "./Item";

const ProjItem = ({item}) => {

    return (
        <Item link={`/proj/${item.id}`}>
            <div className="item-info">
                <div className="item-title font-h4b-20">{item.name}</div>
                {item.map && <div className="item-proj-info font-text4-16">
                    <div className="icons _map"></div>
                    <div>{item.map}</div>
                </div>}

                {item.skills.length
                ?<TagsList title="Skils" tags={item.skills}/>
                :<></>}

                <div className="item-descr text5-14">
                    {item.shortText}
                </div>
            </div>
            <div className="item-proj-right">
                <div className="item-logo _proj">
                    {item.logo
                        ?<img src={item.logo} alt="Foto"/>
                        :<div className="icons _user _black _big _opacity"></div>
                    }
                </div>
                <div className="item-icons">
                    <Share link={`/proj/${item.id}`}/>
                    <Like id={item.id} type="2" like={item.l}/>
                </div>
            </div>
        </Item>
    )
}

export {ProjItem}