import { Link } from "react-router-dom";
import { useLogin } from "../../hook/useLogin";

const Premium = () => {

    const {user}=useLogin();

    const link=user.type===0?'/services/free/':'/services/firm/';

    return (
        !user.payd
        ?<div className="fr-top">
            <div className="page-block">
                <div className="fr-top-flex">
                    <div>Nutzen Sie alle Optionen des Portals</div>
                    <Link
                        to={link}
                        replace={true} 
                        className="button button-primary _green">
                        Account Upgraden
                    </Link>
                </div>
            </div>
        </div>:<></>
    )
}
export {Premium}