import "./Mes.scss";
import {Modal} from '../../../components/Modal';

const MesArh = ({setShow}) =>{
    return (
        <Modal setShow={setShow} navi='/account/arhive/'>
            <div className="mes-simple">
                <div className="message-icon">
                    <div className='icons _alert _alert-arh'></div>
                </div>
                <div className="font-h5b-18">Ihr Projekt wurde erfolgreich gespeichert.</div>
                <div>Ab sofort Sie können die gespeicherte Projekte unter Darshboard -  Gespeicherte Projekte ansehen und bearbeiten.</div>
                <div>Wir wünschen Ihnen viel Erfog. </div>
            </div>
        </Modal>
    )
}

export {MesArh}