import {ProjItem} from './ProjItem';
import { FirmItem } from './FirmItem';
import { FreeItem } from './FreeItem';

import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const List = ({data,like,page,pageSize,path,block}) => {

    const curData = useMemo(() => {
        const firstPageIndex = (page - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return data.slice(firstPageIndex, lastPageIndex);
    }, [page,data]);

    const ListItems = ({data,path,pay}) =>{
        return data.map((item,i)=>(
            path==='/projs' ? <ProjItem item={item} like={like} key={i}/>
            : path==='/firms' ? <FirmItem item={item} like={like} key={i}/>
            : path==='/freelances' ? <FreeItem item={item} like={like} key={i} block={block}/> 
            : ''
        ));
    }

    return (
        <><ListItems path={path} data={curData} block={block}/></>
    )

}
export {List}