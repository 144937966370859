import InputMask from 'react-input-mask';

import { useMemo, useState } from 'react';
import {Controller, useForm} from 'react-hook-form';

import { Section } from "../../../components/Section";
import { MySelect } from "../../../components/MySelect";
import { MyCreatable } from "../../../components/MyCreatable";
import { Errors } from "../../../components/Errors";

import { Study } from "./Study";
import { StudyAdd } from "./StudyAdd";
import { Work } from "./Work";
import { WorkAdd } from "./WorkAdd";
import { Lang } from "./Lang";
import { LangAdd } from "./LangAdd";
import { DocAdd } from "./DocAdd";
import { Mes } from './Mes';
import { useLogin } from '../../../hook/useLogin';
import { PayMes } from '../../../components/PayMes/PayMes';

const Form = ({data,setData,type,axios}) =>{

    const {user} = useLogin();

    const err={
        req : 'bitte ausfüllen'
    }

    // useForm
    const {
        register, 
        handleSubmit,
        control,
        formState: {errors},
        watch,
        //getValues,
        reset
    } = useForm({
        defaultValues:useMemo(() => data, [data]),
        shouldUnregister: true
    });

    const form={
        watch:watch,
        control:control,
        register:register,
        errors:errors
    }

    // save info
    const onSubmit = data => {
        axios.post('/free/info',data)
        .then(()=>{
            setShow(true);
            reset({...data});
        });
    }

    const [show,setShow] = useState();
    const [pay,setPay] = useState();

    const setCat = (value,onChange) => {  
        if(user.payd && user.tarif===2){
            onChange(value.map(item=>item.value));
        }else{
            if(value.length>1){
                setPay(true);
            }else{
                onChange(value.map(item=>item.value));
            }
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {show?<Mes setShow={setShow}/>:<></>}
            <PayMes setShow={setPay} show={pay}/>
        
            <div className="fr-fr">
                <div className="fr-fr-content">

                    <div className="fr-fr-form">

                        <div className="inputs-block">
                            <div className="font-h6b-16">Qualifikationen</div>
                        </div>

                        <div>
                            <div className="inputs-block _label _span _select">
                                <label>Kategorieauswahl</label>
                                <Controller 
                                    isMulti={true}
                                    name="cat" 
                                    control={control}
                                    rules={{required:err.req}}
                                    render={({field:{onChange,value}})=>{
                                        return <MySelect 
                                            isMulti={true}
                                            isSearchable={false}
                                            value={type.cat.filter(item=>value.includes(item.value))}
                                            onChange={value=>setCat(value,onChange)}
                                            placeholder="All categories"
                                            options={type.cat}
                                        />}
                                    }
                                />
                            </div>
                            <Errors error={errors?.cat?.message}/>
                        </div>

                        <div className='_span'>
                            <div className="inputs-block _label _span _select">
                                <label>Skils</label>
                                <Controller 
                                    name="skills" 
                                    control={control}
                                    rules={{required:err.req}}
                                    render={({field:{onChange,value}})=>
                                        <MyCreatable
                                            value={value}
                                            onChange={e=>onChange(e)}
                                            placeholder="Schlagwörter für bessere Suchoptionen eingeben über Koma"
                                            options={type.skills}
                                        />
                                    }
                                />
                            </div>
                            <Errors error={errors?.skills?.message}/>
                        </div>

                        <div className="fr-fr-section">

                            <Section name="Aus-Und Weiterbildung" def={data.study.length?true:false}>
                                <Study 
                                    data={data}
                                    setData={setData}
                                    form={form}
                                    type={type.study}/>
                                <StudyAdd 
                                    data={data}
                                    setData={setData}
                                    type={type.study}
                                />
                            </Section>

                            <Section name="Werdegang"  def={data.work.length?true:false}>
                                <Work
                                    data={data}
                                    form={form}
                                    setData={setData}/>
                                <WorkAdd
                                    data={data}
                                    setData={setData}/>
                            </Section>

                            <Section name="Sprachkenntnisse" def={data.lang.length?true:false}>
                                <Lang
                                    data={data}
                                    form={form}
                                    setData={setData}
                                    type={type.level}/>
                                <LangAdd
                                    data={data}
                                    setData={setData}
                                    type={type.level}/>
                            </Section>

                            <Section name="Dokumenten" def={data.doc.length?true:false}>
                                <DocAdd
                                    form={form}
                                    data={data}
                                    setData={setData}/>
                            </Section>

                        </div>
                    </div>

                </div>
                <div className="fr-fr-save">
                    <button 
                        type="submit" 
                        className="button button-primary">Speichern</button>
                </div>
            </div>
        </form>
    )
}

export {Form}