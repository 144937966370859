export const Rules = {
    title:'Rechnung',
    html:<div>
        <p>Beim Bestellung der Kostenpflichtigen Pakete ist Ihre Zahlung sofort f&auml;llig.<br />
        Schauen Sie Ihre In-Mail Postfach an. Dort wird einen Rechnung f&uuml;r die ausgew&auml;hlte<br />
        Leistungen hinterlegt.<br />
        Bitte Zahlen Sie den Rechnungsbetrag gem&auml;&szlig; vereinbarten Zahlungsfristen.<br />
        Die Rechnung wird f&uuml;r die Jahresabo ausgestellt.</p>

        <div class="simple-paypal"><img src="/img/logo/paypal.png"/></div>
        
        {/* <p><b>Bitte &uuml;berweisen an:</b><br />
        Intema Consulting<br />
        SOLARIS BANK<br />
        <b>IBAN:</b> DE79 XXXX XXXX XXXX XXXX 64<br />
        <b>BIC:</b> SOBKDEBBXXX<br />
        <b>Verwendungszweck:</b> Rechnungsnummer</p> */}
    </div>
    
}