import "./Prof.scss";

import {useEffect,useState} from 'react';
import { Loading } from "../../components/Loading";

import useAxios from '../../hook/useAxios';
import {useLogin} from '../../hook/useLogin';

import { Form } from "./Prof/Form";

const Prof = () => {

    //focus = () => this.refs.editor.focus();

    const setDate = (date) =>(
        date
        ?new Date(date)
        :new Date()
    )

    const {user,setUser}=useLogin();
    const axios = useAxios(user);

    // fotm data
    const [data,setData] = useState();

    // get types
    // type default
    var [type,setType] = useState({
        gender:[],
        lands:[]
    });

    useEffect(()=>{
        // get data
        axios.get('/firm/prof')
        .catch(e=>{
            console.log(e);
        })
        .then(res=>res.data)
        .then(data=>{
            if(data.res){
                data.res.dataStart=setDate(data.res.dataStart);
                setData(data.res);

                console.log(data.res);

                // get types
                axios.get('/site/type/',{params:{type:['lands','gender']}})
                .then(res=>res.data)
                .then(data => setType(data))
                .catch((err) => console.log(err))
            }
        });

    },[]);

    //! old publication
    // const submitPublic = () =>{
    //     axios.post('/firm/public')
    //     .then(()=>console.log('public'))
    //     .catch((err) => console.log(err))
    // }

    //? for div as textarea
    // const getSelection = ()=>{
    //     var selObj = window.getSelection();
    //     console.log(selObj);
    // }

    return (
        data
        ?<Form 
            data={data} 
            type={type}
            axios={axios}
            setUser={setUser} 
            user={user}/>
        :<Loading/>
    )
}

export {Prof}