import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";

// styles
import "react-datepicker/dist/react-datepicker.css";
import "../css/datepicker.scss";

// lang
import de from 'date-fns/locale/de';
registerLocale('de', de)
setDefaultLocale('de');

export default DatePicker