export const Data = {
    title:'Datenschutz',
    html:
    <div>
        <ul>
            <li><b>Allgemeine Hinweise</b></li>
            <li>Diese Datenschutzerkl&auml;rung enth&auml;lt ausf&uuml;hrliche Informationen dar&uuml;ber, was mit Ihren pers&ouml;nlichen Daten passiert, wenn Sie unsere Website www.freelancerweb.de besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie sich pers&ouml;nlich identifizieren k&ouml;nnen. Wir halten uns bei der Verarbeitung Ihrer Daten streng an die gesetzlichen Bestimmungen, insbesondere die Datenschutzgrundverordnung (&bdquo;DSGVO&ldquo;), und legen gro&szlig;en Wert darauf, dass Ihr Besuch auf unserer Website absolut sicher ist.</li>
            <li><b>Verantwortliche Stelle</b></li>
            <li>Datenschutzrechtlich verantwortlich f&uuml;r die Erhebung und Verarbeitung von personenbezogenen Daten auf dieser Website ist:
            <p><b>Name:</b> Intema Consulting<br />
            <b>Vertreten durch:</b> Igor Zukrow, CEO<br />
            <b>Stra&szlig;e, Hausnummer:</b> Maximinstr. 24<br />
            <b>Postleitzahl, Ort:</b> 54340 Longuich<br />
            <b>Land:</b> Deutschland<br />
            <b>E-Mail:</b> info@intema-consulting.com<br />
            <b>Tel.:</b> +4965024040836</p>
            </li>
            <li><b>Zugriffsdaten (Server-Logfiles)</b></li>
            <li>Beim Aufruf unserer Website erheben wir und speichern automatisch in sogenannten Server-Logfiles Zugriffsdaten, die Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:
            <ul>
                <li>Browsertyp und Browserversion Ihres PC</li>
                <li>von Ihrem PC verwendetes Betriebssystem</li>
                <li>Referrer URL (Quelle/Verweis, von welchem Sie auf unsere Website gelangten)</li>
                <li>Hostname des zugreifenden Rechners</li>
                <li>Datum und Uhrzeit der Serveranfrage</li>
                <li>die aktuell von Ihrem PC verwendete IP-Adresse (ggf. in anonymisierter Form)</li>
            </ul>
            </li>
            <li>Ein Personenbezug ist uns im Regelfall nicht m&ouml;glich und auch nicht beabsichtigt. Die Verarbeitung solcher Daten erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unseres berechtigten Interesses an der Verbesserung der Stabilit&auml;t und Funktionalit&auml;t unserer Website.</li>
            <li><b>Cookies</b></li>
            <li>Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu erm&ouml;glichen, verwenden wir sogenannte Cookies. Hierbei handelt es sich um kleine Textdateien, die auf Ihrem Endger&auml;t abgelegt werden. Cookies k&ouml;nnen keine Programme ausf&uuml;hren oder Viren auf Ihr Computersystem &uuml;bertragen.</li>
            <li>Cookies, die zur Durchf&uuml;hrung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erw&uuml;nschter Funktionen erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Wir haben ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung unserer Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerkl&auml;rung gesondert behandelt.</li>
            <li>Die meisten der von uns verwendeten Cookies sind so genannte &bdquo;Session-Cookies&rdquo;. Sie werden nach Ende Ihres Besuchs automatisch gel&ouml;scht. Andere Cookies bleiben auf Ihrem Endger&auml;t gespeichert bis Sie diese l&ouml;schen. Diese Cookies erm&ouml;glichen es uns, Ihren Browser beim n&auml;chsten Besuch wiederzuerkennen.</li>
            <li>Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell ausschlie&szlig;en sowie das automatische L&ouml;schen der Cookies beim Schlie&szlig;en des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.</li>
            <li><b>Webanalyse Tools und Werbung</b>
            <ul>
                <li><b>Google Analytics</b>
                <p>Unsere Website benutzt den Webanalysedienst Google Analytics. Anbieter ist die Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland (&bdquo;Google&ldquo;).</p>

                <p>Google Analytics verwendet sogenannte &bdquo;Cookies&ldquo;. Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung unserer Website durch Sie erm&ouml;glichen. Die durch Cookies erzeugten Informationen &uuml;ber Ihre Benutzung unserer Website werden in der Regel an einen Server von Google in den USA &uuml;bertragen und dort gespeichert.</p>

                <p>Rechtsgrundlage f&uuml;r die Verarbeitung Ihrer Daten ist die von Ihnen &uuml;ber das Cookie-Consent-Tool erteilte Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 Satz 1 lit. a) DSGVO.</p>
                </li>
                <li><b>IP-Anonymisierung</b>
                <p>Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europ&auml;ischen Union oder in anderen Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum vor der &Uuml;bermittlung in die USA gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von Google in den USA &uuml;bertragen und dort gek&uuml;rzt. In unserem Auftrag wird Google diese Informationen benutzen, um Ihre Nutzung unserer Website auszuwerten, um Reports &uuml;ber die Websiteaktivit&auml;ten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegen&uuml;ber uns zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser &uuml;bermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengef&uuml;hrt.</p>
                </li>
                <li><b>Demografische Merkmale bei Google Analytics</b>
                <p>Unsere Website benutzt die Funktion &bdquo;demografische Merkmale&rdquo; von Google Analytics. Dadurch k&ouml;nnen Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten k&ouml;nnen keiner bestimmten Person zugeordnet werden. Sie k&ouml;nnen diese Funktion jederzeit &uuml;ber die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics - wie im Gliederungspunkt &bdquo;Widerspruch gegen Datenerfassung&rdquo; dargestellt - generell untersagen.</p>
                </li>
                <li><b>Auftragsverarbeitung</b>
                <p>Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen Datenschutzbeh&ouml;rden bei der Nutzung von Google Analytics vollst&auml;ndig um.</p>
                </li>
                <li><b>Speicherdauer</b>
                <p>Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die mit Cookies, Nutzerkennungen (z.B. User ID) oder Werbe-IDs (z.B. DoubleClick-Cookies, Android-Werbe-ID) verkn&uuml;pft sind, werden nach 14 Monaten anonymisiert bzw. gel&ouml;scht. Details hierzu ersehen Sie unter folgendem Link: https://support.google.com/analytics/answer/7667196?hl=de</p>
                </li>
                <li><b>Widerspruch gegen Datenerfassung</b>
                <p>Sie k&ouml;nnen die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich werden nutzen k&ouml;nnen.</p>

                <p>Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch die Cookies erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verf&uuml;gbare Browser-Add-on zur Deaktivierung von Google Analytics herunterladen und installieren: https://tools.google.com/dlpage/gaoptout?hl=de. Wenn Sie die Cookies auf Ihrem Rechner l&ouml;schen, m&uuml;ssen Sie den Opt-Out-Cookie erneut setzen.</p>

                <p>Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerkl&auml;rung von Google: https://support.google.com/analytics/answer/6004245?hl=de.</p>

                <p>Weitere Informationen zum Datenschutz k&ouml;nnen Sie der Datenschutzerkl&auml;rung von Google entnehmen: https://policies.google.com/privacy?hl=de&amp;gl=de</p>
                </li>
            </ul>
            </li>
            <li><b>Google Tag Manager</b>
            <p>Unsere Website verwendet den Google Tag Manager des Anbieters Google. Google Tag Manager ist eine L&ouml;sung, mit der Vermarkter Website-Tags &uuml;ber eine Oberfl&auml;che verwalten k&ouml;nnen. Das Tool, das die Tags implementiert, ist eine Cookie-lose Domain und speichert keine personenbezogenen Daten. Das Tool sorgt f&uuml;r die Ausl&ouml;sung anderer Tags, die ihrerseits unter Umst&auml;nden Daten erfassen. Google Tag Manager greift nicht auf diese Daten zu. Wenn auf Domain- oder Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese f&uuml;r alle Tracking-Tags bestehen, die mit Google Tag Manager implementiert werden.</p>
            </li>
            <li><b>Google Ads und Google Conversion-Tracking</b>
            <p>Unsere Website verwendet Google Ads (vormals Google AdWords). Google Ads ist ein Online-Werbeprogramm des Anbieters Google.</p>

            <p>Google Ads erm&ouml;glicht uns, mit Hilfe von Werbemitteln auf externen Webseiten auf unsere Angebote aufmerksam zu machen und zu ermitteln, wie erfolgreich einzelne Werbema&szlig;nahmen sind. Dies hilft uns, Ihnen Werbung anzuzeigen, die f&uuml;r Sie von Interesse ist, unsere Website f&uuml;r Sie interessanter zu gestalten und eine faire Berechnung von Werbekosten zu erreichen.</p>

            <p>Im Rahmen von Google Ads nutzen wir das sogenannte Conversion-Tracking. Die Werbemittel werden durch Google &uuml;ber sogenannte &bdquo;AdServer&ldquo; ausgeliefert. Hierf&uuml;r verwenden wir sogenannte AdServer-Cookies, durch die bestimmte Parameter zur Erfolgsmessung, wie Einblendung der Anzeigen oder Klicks durch die Nutzer, gemessen werden k&ouml;nnen. Wenn Sie auf eine von Google geschaltete Anzeige klicken, wird ein Cookie f&uuml;r das Conversion-Tracking gesetzt. Bei Cookies handelt es sich um kleine Textdateien, die der Internet-Browser auf dem Computer des Nutzers ablegt. Diese Cookies verlieren nach 30 Tagen ihre G&uuml;ltigkeit und dienen nicht der pers&ouml;nlichen Identifizierung der Nutzer. Diese Cookies erm&ouml;glichen Google eine Wiedererkennung Ihres Webbrowsers. Besuchen Sie bestimmte Seiten unseres Webauftritts, wenn das Cookie noch nicht abgelaufen ist, k&ouml;nnen Google und wir erkennen, dass Sie auf die konkrete Anzeige geklickt haben und zu dieser Seite weitergeleitet wurden.</p>

            <p>Jeder Google Ads-Kunde erh&auml;lt ein anderes Cookie. Die Cookies k&ouml;nnen somit nicht &uuml;ber die Websites von Ads-Kunden nachverfolgt werden. Zum Cookie werden in der Regel als Analysewerte folgende Informationen gespeichert: Unique-Cookie-ID, Anzahl Ad Impressions pro Platzierung (Frequency), letzte Impression (relevant f&uuml;r Post-View-Conversions), Opt-out-Informationen (Markierung, dass der Nutzer nicht mehr angesprochen werden m&ouml;chte). Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken f&uuml;r Ads-Kunden zu erstellen, die sich f&uuml;r Conversion-Tracking entschieden haben. Die Ads-Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer pers&ouml;nlich identifizieren lassen. Wenn Sie nicht am Tracking teilnehmen m&ouml;chten, k&ouml;nnen Sie dieser Nutzung widersprechen, indem Sie das Cookie des Google Conversion-Trackings &uuml;ber ihren Internet-Browser unter Nutzereinstellungen leicht deaktivieren. Sie werden sodann nicht in die Conversion-Tracking Statistiken aufgenommen.</p>

            <p>Die Zusammenfassung der erfassten Daten in Ihrem Google-Konto erfolgt ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung, die Sie bei Google abgeben oder widerrufen k&ouml;nnen (Art. 6 Abs. 1 lit. a DSGVO). Bei Datenerfassungsvorg&auml;ngen, die nicht in Ihrem Google-Konto zusammengef&uuml;hrt werden (z.B., weil Sie kein Google-Konto haben oder der Zusammenf&uuml;hrung widersprochen haben), beruht die Erfassung der Daten auf Art. 6 Abs. 1 lit. f DSGVO. Das berechtigte Interesse ergibt sich daraus, dass wir ein Interesse an der anonymisierten Analyse der Besucher unserer Website zu Werbezwecken haben, um sowohl unser Webangebot als auch unsere Werbung zu optimieren.</p>

            <p>Weitergehende Informationen und die Datenschutzbestimmungen finden Sie in der Datenschutzerkl&auml;rung von Google unter: https://policies.google.com/technologies/ads?hl=de.</p>
            </li>
            <li><b>Google Remarketing</b>
            <p>Unsere Website nutzt die Funktionen von Google Remarketing in Verbindung mit den ger&auml;te&uuml;bergreifenden Funktionen von Google Ads und Google DoubleClick des Anbieters Google.</p>

            <p>Google Remarketing analysiert Ihr Nutzerverhalten auf unserer Website, um Sie in bestimmte Werbe-Zielgruppen einzuordnen und Ihnen anschlie&szlig;end beim Besuch von anderen Onlineangeboten passende Werbebotschaften auszuspielen (Remarketing bzw. Retargeting).</p>

            <p>Die mit Google Remarketing erstellten Werbe-Zielgruppen k&ouml;nnen mit den ger&auml;te&uuml;bergreifenden Funktionen von Google verkn&uuml;pft werden, damit Ihnen interessenbezogene, personalisierte Werbebotschaften, die in Abh&auml;ngigkeit Ihres fr&uuml;heren Nutzungs- und Surfverhaltens auf einem Ger&auml;t an Sie angepasst wurden, auch auf einem anderen Ihrer Ger&auml;te angezeigt werden. Haben Sie eine entsprechende Einwilligung erteilt, verkn&uuml;pft Google zu diesem Zweck Ihren Web- und App-Browserverlauf mit Ihrem Google-Konto. Auf diese Weise k&ouml;nnen auf jedem Ger&auml;t, auf dem Sie sich mit Ihrem Google-Konto anmelden, dieselben personalisierten Werbebotschaften geschaltet werden.</p>

            <p>Zur Unterst&uuml;tzung dieser Funktion erfasst Google Analytics Google-authentifizierte IDs der Nutzer, die vor&uuml;bergehend mit unseren Google-Analytics-Daten verkn&uuml;pft werden, um Zielgruppen f&uuml;r die ger&auml;te&uuml;bergreifende Anzeigenwerbung zu definieren und zu erstellen.</p>

            <p>Sie k&ouml;nnen dem ger&auml;te&uuml;bergreifenden Remarketing/Targeting dauerhaft widersprechen, indem Sie personalisierte Werbung in Ihrem Google-Konto deaktivieren; folgen Sie hierzu diesem Link: https://www.google.com/settings/ads/onweb/.</p>

            <p>Die Zusammenfassung der erfassten Daten in Ihrem Google-Konto erfolgt ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung, die Sie bei Google abgeben oder widerrufen k&ouml;nnen (Art. 6 Abs. 1 lit. a DSGVO). Bei Datenerfassungsvorg&auml;ngen, die nicht in Ihrem Google-Konto zusammengef&uuml;hrt werden (z.B., weil Sie kein Google-Konto haben oder der Zusammenf&uuml;hrung widersprochen haben), beruht die Erfassung der Daten auf Art. 6 Abs. 1 lit. f DSGVO. Das berechtigte Interesse ergibt sich daraus, dass wir ein Interesse an der anonymisierten Analyse der Besucher unserer Website zu Werbezwecken haben.</p>

            <p>Weitergehende Informationen und die Datenschutzbestimmungen finden Sie in der Datenschutzerkl&auml;rung von Google unter: https://policies.google.com/technologies/ads?hl=de.</p>
            </li>
            <li><b>Google AdSense</b>
            <p>Unsere Website benutzt Google AdSense, einen Dienst zum Einbinden von Werbeanzeigen des Anbieters Google.</p>

            <p>Google AdSense verwendet sogenannte &bdquo;Cookies&ldquo;, d.h. Textdateien, die auf Ihrem Computer gespeichert werden und die dazu dienen, auf unserer Website Werbeanzeigen einzublenden, die zu unseren Inhalten und Ihren Interessen passen. Google AdSense verwendet auch so genannte Web Beacons (unsichtbare Grafiken). Durch diese Web Beacons k&ouml;nnen Informationen &uuml;ber den Besucherverkehr auf unseren Seiten f&uuml;r das Online-Marketing statistisch ausgewertet werden.</p>

            <p>Die durch Cookies und Web Beacons erzeugten Informationen &uuml;ber die Benutzung unserer Website (einschlie&szlig;lich Ihrer IP-Adresse) und Auslieferung von Werbeformaten werden an einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Diese Informationen k&ouml;nnen von Google an Dritte weitergegeben werden. Google wird Ihre IP-Adresse jedoch nicht mit anderen Daten, die Google m&ouml;glicherweise von Ihnen gespeichert hat, zusammenf&uuml;hren.</p>

            <p>Soweit Sie eine Einwilligung erteilt haben, erfolgt die Speicherung und Verarbeitung von personenbezogenen Daten auf Grundlage dieser Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO. Wir haben zudem ein berechtigtes Interesse gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO an der Analyse des Nutzerverhaltens, um sowohl unser Webangebot als auch unsere Werbung zu optimieren.</p>

            <p>Die Zusammenfassung der erfassten Daten in Ihrem Google-Konto erfolgt ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung, die Sie bei Google abgeben oder widerrufen k&ouml;nnen (Art. 6 Abs. 1 lit. a DSGVO).</p>

            <p>Sie k&ouml;nnen die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website voll umf&auml;nglich nutzen k&ouml;nnen. Durch die Nutzung dieser Website erkl&auml;ren Sie sich mit der Bearbeitung der &uuml;ber Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.</p>
            </li>
            <li><b>Google Fonts</b>
            <p>Wir verwenden auf unserer Website &bdquo;Google Fonts&ldquo; (vormals &bdquo;Google Web Fonts&ldquo;), einen Dienst des Anbieters Google.</p>

            <p>Google Fonts erm&ouml;glicht uns die Verwendung externer Schriftarten, sog. Google Fonts. Dazu wird beim Abrufen unserer Website die ben&ouml;tigte Google Font von Ihrem Webbrowser in den Browsercache geladen. Dies ist notwendig, damit Ihr Browser eine optisch verbesserte Darstellung unserer Texte anzeigen kann. Wenn Ihr Browser diese Funktion nicht unterst&uuml;tzt, wird eine Standardschrift von Ihrem Computer zur Anzeige genutzt.</p>

            <p>Die Einbindung der Google Fonts erfolgt durch einen Serveraufruf, in der Regel bei einem Server von Google in den USA. Hierdurch wird an den Server &uuml;bermittelt, welche unserer Internetseiten Sie besucht haben. Auch wird die IP-Adresse des Browsers Ihres Endger&auml;tes von Google gespeichert. Wir haben keinen Einfluss auf den Umfang und die weitere Verwendung der Daten, die durch den Einsatz von Google Fonts durch Google erhoben und verarbeitet werden.</p>

            <p>Wir verwenden Google Fonts zu Optimierungszwecken, insbesondere um die Nutzung unserer Website f&uuml;r Sie zu verbessern und um deren Ausgestaltung nutzerfreundlicher zu machen. Hierin liegt unser berechtigtes Interesse an der Verarbeitung der vorstehenden Daten durch den Drittanbieter. Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.</p>

            <p>Weitergehende Informationen zu Google Fonts finden Sie unter https://fonts.google.com/, https://developers.google.com/fonts/faq?hl=de-DE&amp;csw=1.</p>
            </li>
            <li><b>WordPress Stats</b>
            <p>Unsere Website benutzt das Tool WordPress Stats, um Besucherzugriffe statistisch auszuwerten. WordPress Stats ist eine Unterfunktion des Plugin Jetpack. Anbieter ist die Automattic Inc., 60 29th Street #343, San Francisco, CA 94110-4929, USA.</p>

            <p>WordPress Stats verwendet Cookies, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung unserer Website erlauben. Die durch das Cookie erzeugten Informationen &uuml;ber Ihre Benutzung unseres Onlineangebotes werden auf einem Server in den USA gespeichert. Dabei k&ouml;nnen aus den verarbeiteten Daten Nutzungsprofile der Nutzer erstellt werden, die nur zu Analyse- und nicht zu Werbezwecken eingesetzt werden. Ihre IP-Adresse wird nach der Verarbeitung und vor der Speicherung anonymisiert.</p>

            <p>&bdquo;WordPress-Stats&ldquo;-Cookies verbleiben auf Ihrem Endger&auml;t, bis Sie sie l&ouml;schen. Weitere Informationen erhalten Sie in den Datenschutzerkl&auml;rungen von Automattic: https://automattic.com/privacy/ und Hinweisen zu Jetpack-Cookies: https://jetpack.com/support/cookies/.</p>

            <p>Die Speicherung von &bdquo;WordPress Stats&rdquo;-Cookies und die Nutzung dieses Analyse-Tools erfolgen auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an der anonymisierten Analyse des Nutzerverhaltens, um sowohl unser Webangebot als auch unsere Werbung zu optimieren.</p>
            </li>
            <li><b>Soziale Medien</b>
            <ul>
                <li><b>Facebook-Plugins (Like &amp; Share-Button)</b>
                <p>Auf unserer Website sind Plugins des sozialen Netzwerks Facebook, Anbieter Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA, integriert (&bdquo;Facebook&ldquo;). Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem &bdquo;Like-Button&ldquo; (&bdquo;Gef&auml;llt mir&ldquo;) auf unserer Website. Eine &Uuml;bersicht &uuml;ber die Facebook Plugins finden Sie hier: https://developers.facebook.com/docs/plugins/?locale=de_DE.</p>

                <p>Um den Schutz Ihrer Daten beim Besuch unserer Website zu erh&ouml;hen, sind die Facebook-Plugins nicht uneingeschr&auml;nkt, sondern lediglich unter Verwendung eines HTML-Links (sogenannte &bdquo;Shariff&ldquo;-L&ouml;sung von c&lsquo;t) in die Seite eingebunden. Diese Einbindung gew&auml;hrleistet, dass beim Aufruf einer Seite unseres Webauftritts, die solche Plugins enth&auml;lt, noch keine Verbindung mit den Servern von Facebook hergestellt wird. Erst wenn Sie auf den Facebook Button klicken, &ouml;ffnet sich ein neues Fenster Ihres Browsers und ruft die Seite von Facebook auf, auf der Sie den Like- oder Share-Button bet&auml;tigen k&ouml;nnen.</p>

                <p>Informationen &uuml;ber den Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre diesbez&uuml;glichen Rechte und Einstellungsm&ouml;glichkeiten zum Schutz Ihrer Privatsph&auml;re finden Sie in der Datenschutzerkl&auml;rung von Facebook unter: https://de-de.facebook.com/privacy/explanation.</p>
                &zwnj;</li>
                <li><b>Google+ Plugin</b>
                <p>Unsere Website benutzt Social Plugins von Google+ des Anbieters Google. Die Plugins sind z.B. an Buttons mit dem Zeichen &bdquo;+1&ldquo; auf wei&szlig;em oder farbigem Hintergrund erkennbar. Eine &Uuml;bersicht &uuml;ber die Google Plugins und deren Aussehen finden Sie hier: https://developers.google.com/+/plugins</p>

                <p>Um den Schutz Ihrer Daten beim Besuch unserer Website zu erh&ouml;hen, sind die Google+-Plugins nicht uneingeschr&auml;nkt, sondern lediglich unter Verwendung eines HTML-Links (sogenannte &bdquo;Shariff&ldquo;-L&ouml;sung von c&lsquo;t) in die Seite eingebunden. Diese Einbindung gew&auml;hrleistet, dass beim Aufruf einer Seite unseres Webauftritts, die solche Plugins enth&auml;lt, noch keine Verbindung mit den Servern von Google hergestellt wird. Erst wenn Sie auf den Google+ Button klicken, &ouml;ffnet sich ein neues Fenster Ihres Browsers und ruft die Seite von Google auf.</p>

                <p>Informationen &uuml;ber den Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Google sowie Ihre diesbez&uuml;glichen Rechte und Einstellungsm&ouml;glichkeiten zum Schutz Ihrer Privatsph&auml;re finden Sie in der Datenschutzerkl&auml;rung von Google: https://policies.google.com/privacy?hl=de.</p>
                </li>
                <li><b>LinkedIn Plugin</b>
                <p>Unsere Website benutzt Funktionen des Netzwerks LinkedIn. Anbieter ist die LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA (&bdquo;LinkedIn&ldquo;).</p>

                <p>Um den Schutz Ihrer Daten beim Besuch unserer Website zu erh&ouml;hen, sind die LinkedIn-Plugins nicht uneingeschr&auml;nkt, sondern lediglich unter Verwendung eines HTML-Links (sogenannte &bdquo;Shariff&ldquo;-L&ouml;sung von c&lsquo;t) in die Seite eingebunden. Diese Einbindung gew&auml;hrleistet, dass beim Aufruf einer Seite unseres Webauftritts, die solche Plugins enth&auml;lt, noch keine Verbindung mit den Servern von LinkedIn hergestellt wird. Erst wenn Sie auf den LinkedIn Button klicken, &ouml;ffnet sich ein neues Fenster Ihres Browsers und ruft die Seite von LinkedIn auf.</p>

                <p>Informationen &uuml;ber den Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch LinkedIn sowie Ihre diesbez&uuml;glichen Rechte und Einstellungsm&ouml;glichkeiten zum Schutz Ihrer Privatsph&auml;re finden Sie in der Datenschutzerkl&auml;rung von LinkedIn unter: https://www.linkedin.com/legal/privacy-policy.</p>
                &zwnj;</li>
                <li><b>XING Plugin</b>
                <p>Unsere Website benutzt Funktionen des Netzwerks XING. Anbieter ist die XING AG, Dammtorstra&szlig;e 29-32, 20354 Hamburg, Deutschland (&bdquo;XING&ldquo;).</p>

                <p>Um den Schutz Ihrer Daten beim Besuch unserer Website zu erh&ouml;hen, sind die XING-Plugins nicht uneingeschr&auml;nkt, sondern lediglich unter Verwendung eines HTML-Links (sogenannte &bdquo;Shariff&ldquo;-L&ouml;sung von c&lsquo;t) in die Seite eingebunden. Diese Einbindung gew&auml;hrleistet, dass beim Aufruf einer Seite unseres Webauftritts, die solche Plugins enth&auml;lt, noch keine Verbindung mit den Servern von XING hergestellt wird. Erst wenn Sie auf den XING Button klicken, &ouml;ffnet sich ein neues Fenster Ihres Browsers und ruft die Seite von XING auf, auf der Sie den Share-Button bet&auml;tigen k&ouml;nnen.</p>

                <p>Weitere Information zum Datenschutz und dem XING Share-Button finden Sie in der Datenschutzerkl&auml;rung von XING unter: https://www.xing.com/app/share?op=data_protection</p>
                </li>
                <li><b>YouTube Plugin</b>
                <p>F&uuml;r Integration und Darstellung von Videoinhalten benutzt unsere Website Plugins von YouTube. Anbieter des Videoportals ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA (&bdquo;YouTube&ldquo;).</p>

                <p>Um den Schutz Ihrer Daten beim Besuch unserer Website zu erh&ouml;hen, sind die YouTube-Plugins nicht uneingeschr&auml;nkt, sondern lediglich unter Verwendung eines HTML-Links (sogenannte &bdquo;Shariff&ldquo;-L&ouml;sung von c&lsquo;t) in die Seite eingebunden. Diese Einbindung gew&auml;hrleistet, dass beim Aufruf einer Seite unseres Webauftritts, die solche Plugins enth&auml;lt, noch keine Verbindung mit den Servern von YouTube hergestellt wird. Erst wenn Sie auf den YouTube Button klicken, &ouml;ffnet sich ein neues Fenster Ihres Browsers und ruft die Seite von YouTube auf, auf der Sie den Like-Button bet&auml;tigen k&ouml;nnen.</p>

                <p>Informationen &uuml;ber den Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch YouTube sowie Ihre diesbez&uuml;glichen Rechte und Einstellungsm&ouml;glichkeiten zum Schutz Ihrer Privatsph&auml;re finden Sie in der Datenschutzerkl&auml;rung von YouTube unter: https://www.google.de/intl/de/policies/privacy.</p>
                </li>
            </ul>
            </li>
            <li><b>Google Maps</b></li>
            <li>Unsere Website nutzt &uuml;ber eine API (Application Programming Interface) den Kartendienst Google Maps des Anbieters Google.</li>
            <li>Um den Datenschutz auf unserer Website zu gew&auml;hrleisten, ist Google Maps deaktiviert, wenn Sie unsere Website das erste Mal betreten. Eine direkte Verbindung zu den Servern von Google wird erst hergestellt, wenn Sie Google Maps selbstst&auml;ndig aktivieren (Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO). Auf diese Weise wird verhindert, dass Ihre Daten schon beim ersten Betreten unserer Website an Google &uuml;bertragen werden.</li>
            <li>Nach der Aktivierung wird Google Maps Ihre IP-Adresse speichern. Diese wird anschlie&szlig;end in der Regel an einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Der Anbieter dieser Seite hat nach der Aktivierung von Google Maps keinen Einfluss auf diese Daten&uuml;bertragung.</li>
            <li>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerkl&auml;rung von Google: https://www.google.de/intl/de/policies/privacy/.</li>
            <li><b>Newsletter</b></li>
            <li>Sofern Sie ausdr&uuml;cklich eingewilligt haben, senden wir an Ihre E-Mail-Adresse regelm&auml;&szlig;ig unseren Newsletter. Zum Erhalt unseres Newsletters m&uuml;ssen Sie uns Ihre E-Mail-Adresse mitteilen und sie anschlie&szlig;end verifizieren. Erg&auml;nzende Daten werden nicht erhoben oder sind freiwillig. Die Verwendung der Daten erfolgt ausschlie&szlig;lich f&uuml;r den Versand des Newsletters.</li>
            <li>Die bei der Newsletteranmeldung gemachten Daten werden ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO verarbeitet. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit m&ouml;glich. F&uuml;r den Widerruf gen&uuml;gt eine formlose Mitteilung per E-Mail oder Sie melden sich &uuml;ber den &bdquo;Austragen&ldquo;-Link im Newsletter ab. Die Rechtm&auml;&szlig;igkeit der bereits erfolgten Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.</li>
            <li>Zur Einrichtung des Abonnements eingegebene Daten werden im Falle der Abmeldung gel&ouml;scht. Sollten diese Daten f&uuml;r andere Zwecke und an anderer Stelle an uns &uuml;bermittelt worden sein, verbleiben diese weiterhin bei uns.</li>
            <li><b>Newsleterversand mit CleverReach</b></li>
            <li>F&uuml;r den Versand von Newslettern nutzen wir CleverReach. Anbieter ist die CleverReach GmbH &amp; Co. KG, M&uuml;hlenstr. 43, 26180 Rastede. Mit diesem Dienst k&ouml;nnen wir den Newsletterversand organisieren und analysieren. Ihre f&uuml;r den Bezug des Newsletters eingegebenen Daten, wie beispielsweise Ihre E-Mail-Adresse, werden auf den Servern von CleverReach gespeichert. Serverstandorte sind Deutschland bzw. Irland.</li>
            <li>Der Newsletterversand mit CleverReach gestattet es uns, das Verhalten des Newsletterempf&auml;ngers zu analysieren. Die Analyse legt u.a. dar, wie viele Empf&auml;nger unseren Newsletter ge&ouml;ffnet haben und mit welcher H&auml;ufigkeit Links im Newsletter geklickt wurden. CleverReach unterst&uuml;tzt Conversion-Tracking, um damit zu analysieren, ob nach Klick auf einen Link eine zuvor definierte Aktion, wie beispielsweise ein Produktkauf, erfolgt ist. Einzelheiten zur Datenanalyse durch CleverReach finden Sie unter: https://www.cleverreach.com/de/funktionen/reporting-und-tracking/.</li>
            <li>Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit m&ouml;glich. W&uuml;nschen Sie keine Analyse durch CleverReach, m&uuml;ssen Sie den Newsletter abbestellen. F&uuml;r die Abbestellung gen&uuml;gt eine formlose Mitteilung per E-Mail an uns oder Sie melden sich &uuml;ber den &bdquo;Austragen&ldquo;-Link im Newsletter ab. Die Rechtm&auml;&szlig;igkeit der bereits erfolgten Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.</li>
            <li>Zur Einrichtung des Abonnements eingegebene Daten werden im Falle der Abmeldung von unseren Servern und den Servern von CleverReach gel&ouml;scht. Sollten diese Daten f&uuml;r andere Zwecke und an anderer Stelle an uns &uuml;bermittelt worden sein, verbleiben diese weiterhin bei uns.</li>
            <li>Einzelheiten zu den Datenschutzbestimmungen von CleverReach finden Sie unter: https://www.cleverreach.com/de/datenschutz/.</li>
            <li>Zur vollst&auml;ndigen Erf&uuml;llung der gesetzlichen Datenschutzvorgaben haben wir mit CleverReach einen Vertrag &uuml;ber die Auftragsverarbeitung abgeschlossen.</li>
            <li><b>Kontaktformular</b></li>
            <li>Sofern Sie mit uns Kontakt per E-Mail oder &uuml;ber ein Kontaktformular aufnehmen, werden &uuml;bermittelte Daten einschlie&szlig;lich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu k&ouml;nnen oder um f&uuml;r Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.</li>
            <li>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit m&ouml;glich. F&uuml;r den Widerruf gen&uuml;gt eine formlose Mitteilung per E-Mail. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.</li>
            <li>&Uuml;ber das Kontaktformular &uuml;bermittelte Daten verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unber&uuml;hrt.</li>
            <li><b>Speicherdauer von Beitr&auml;gen und Kommentaren</b></li>
            <li>Beitr&auml;ge und Kommentare sowie damit in Verbindung stehende Daten, wie beispielsweise IP-Adressen, werden gespeichert. Der Inhalt verbleibt auf unserer Website, bis er vollst&auml;ndig gel&ouml;scht wurde oder aus rechtlichen Gr&uuml;nden gel&ouml;scht werden musste.</li>
            <li>Die Speicherung der Beitr&auml;ge und Kommentare erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit m&ouml;glich. F&uuml;r den Widerruf gen&uuml;gt eine formlose Mitteilung per E-Mail. Die Rechtm&auml;&szlig;igkeit bereits erfolgter Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.</li>
            <li><b>Abonnieren von Kommentaren</b></li>
            <li>Sie k&ouml;nnen als Nutzer unserer Website nach erfolgter Anmeldung Kommentare abonnieren. Mit einer Best&auml;tigungs-E-Mail pr&uuml;fen wir, ob Sie der Inhaber der angegebenen E-Mail-Adresse sind. Sie k&ouml;nnen die Abo-Funktion f&uuml;r Kommentare jederzeit &uuml;ber einen Link, der sich in einer Abo-Mail befindet, abbestellen. Zur Einrichtung des Abonnements eingegebene Daten werden im Falle der Abmeldung gel&ouml;scht. Sollten diese Daten f&uuml;r andere Zwecke und an anderer Stelle an uns &uuml;bermittelt worden sein, verbleiben diese weiterhin bei uns.</li>
            <li>Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit m&ouml;glich. F&uuml;r den Widerruf gen&uuml;gt eine formlose Mitteilung per E-Mail. Die Rechtm&auml;&szlig;igkeit bereits erfolgter Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.</li>
            <li><b>Nutzerkonto</b></li>
            <li>Wenn Sie ein Nutzerkonto er&ouml;ffnen, willigen Sie damit ein, dass Ihre Bestandsdaten wie Name, Adresse und E-Mail-Adresse sowie Ihre Nutzungsdaten (Benutzername, Passwort) gespeichert werden. Dadurch haben Sie die M&ouml;glichkeit, sich mit Ihrer E-Mail-Adresse sowie Ihrem pers&ouml;nlichen Passwort bei uns einzuloggen.</li>
            <li><b>Datenverwendung und -weitergabe</b></li>
            <li>Die personenbezogenen Daten, die Sie uns z.B. per E-Mail mitteilen (z.B. Ihr Name und Ihre Adresse oder Ihre E-Mail-Adresse), werden wir weder an Dritte verkaufen noch anderweitig vermarkten. Ihre personenbezogenen Daten werden nur zur Korrespondenz mit Ihnen und nur f&uuml;r den Zweck verarbeitet, zu dem Sie uns die Daten zur Verf&uuml;gung gestellt haben. Zur Abwicklung von Zahlungen geben wir Ihre Zahlungsdaten an das mit der Zahlung beauftragte Kreditinstitut weiter.</li>
            <li>Die Verwendung von Daten, die bei Ihrem Besuch unserer Website automatisch erhoben werden, erfolgt nur zu den vorstehend genannten Zwecken. Eine anderweitige Verwendung der Daten findet nicht statt.</li>
            <li>Wir versichern, dass wir Ihre personenbezogenen Daten im &Uuml;brigen nicht an Dritte weitergeben, es sei denn, dass wir dazu gesetzlich verpflichtet w&auml;ren oder Sie uns vorher Ihre Zustimmung gegeben haben.</li>
            <li><b>SSL- bzw. TLS-Verschl&uuml;sselung</b></li>
            <li>Unsere Website benutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von &bdquo;http://&rdquo; auf &bdquo;https://&rdquo; wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</li>
            <li>Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.</li>
            <li><b>Speicherdauer</b></li>
            <li>Personenbezogene Daten, die uns &uuml;ber unsere Website mitgeteilt worden sind, werden nur so lange gespeichert, bis der Zweck erf&uuml;llt ist, zu dem sie uns anvertraut wurden. Soweit handels- und steuerrechtliche Aufbewahrungsfristen zu beachten sind, kann die Speicherdauer zu bestimmten Daten bis zu 10 Jahre betragen.</li>
            <li><b>Betroffenenrechte</b></li>
            <li>Hinsichtlich der Sie betreffenden personenbezogenen Daten haben als Betroffener der Datenverarbeitung nach Ma&szlig;gabe der gesetzlichen Bestimmungen folgende Rechte gegen&uuml;ber dem Verantwortlichen:
            <p>Dieses Recht besteht jedoch nicht, soweit die Verarbeitung erforderlich ist:</p>

            <ul>
                <li><b>Widerrufsrecht</b></li>
                <li>Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sofern die Verarbeitung Ihrer Daten auf Ihrer Einwilligung beruht, haben Sie das Recht, eine einmal erteilte Einwilligung in die Verarbeitung von Daten gem&auml;&szlig; Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung f&uuml;r die Zukunft zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht ber&uuml;hrt. Speicherung der Daten f&uuml;r Abrechnungs- und buchhalterische Zwecke bleibt von einem Widerruf nicht ber&uuml;hrt.</li>
                <li><b>Auskunftsrecht</b></li>
                <li>Sie haben das Recht, gem&auml;&szlig; Art. 15 DSGVO von uns eine Best&auml;tigung dar&uuml;ber zu verlangen, ob wir personenbezogene Daten verarbeiten, die Sie betreffen. Liegt eine solche Verarbeitung vor, haben Sie das Recht auf Auskunft &uuml;ber Ihre von uns verarbeiteten personenbezogenen Daten, die Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empf&auml;nger oder Kategorien von Empf&auml;ngern, gegen&uuml;ber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer bzw. die Kriterien f&uuml;r die Festlegung der Speicherdauer, das Bestehen eines Rechts auf Berichtigung, L&ouml;schung, Einschr&auml;nkung der Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbeh&ouml;rde, die Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer automatisierten Entscheidungsfindung einschlie&szlig;lich Profiling und ggf. aussagekr&auml;ftige Informationen &uuml;ber die involvierte Logik und die Sie betreffende Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien gem&auml;&szlig; Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittl&auml;nder bestehen.</li>
                <li><b>Recht auf Berichtigung</b></li>
                <li>Sie haben das Recht, gem&auml;&szlig; Art. 16 DSGVO jederzeit unverz&uuml;gliche Berichtigung Sie betreffender unrichtiger personenbezogener Daten und/oder die Vervollst&auml;ndigung Ihrer unvollst&auml;ndigen Daten zu verlangen.</li>
                <li><b>Recht auf L&ouml;schung</b>
                <p>Sie haben das Recht, gem&auml;&szlig; Art. 17 DSGVO die L&ouml;schung Ihrer personenbezogenen Daten zu verlangen, sofern einer der folgenden Gr&uuml;nde zutrifft:</p>

                <ul>
                    <li>Ihre personenbezogenen Daten sind f&uuml;r die Zwecke, f&uuml;r die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig;</li>
                    <li>Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem&auml;&szlig; Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO st&uuml;tzte, und es fehlt an einer anderweitigen Rechtsgrundlage f&uuml;r die Verarbeitung;</li>
                    <li>Sie legen gem&auml;&szlig; Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gr&uuml;nde f&uuml;r die Verarbeitung vor, oder Sie legen gem&auml;&szlig; Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein;</li>
                    <li>Die personenbezogenen Daten wurden unrechtm&auml;&szlig;ig verarbeitet;</li>
                    <li>Die L&ouml;schung der personenbezogenen Daten ist zur Erf&uuml;llung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht des Mitgliedstaates erforderlich, dem wir unterliegen;</li>
                    <li>Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gem&auml;&szlig; Art. 8 Abs. 1 DSGVO erhoben;</li>
                </ul>

                <ul>
                    <li>zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und Information;</li>
                    <li>zur Erf&uuml;llung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder des Mitgliedstaates, dem wir unterliegen, erfordert, oder zur Wahrnehmung einer Aufgabe, die im &ouml;ffentlichen Interesse liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, die uns &uuml;bertragen wurde;</li>
                    <li>aus Gr&uuml;nden des &ouml;ffentlichen Interesses im Bereich der &ouml;ffentlichen Gesundheit gem&auml;&szlig; Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;</li>
                    <li>f&uuml;r im &ouml;ffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder f&uuml;r statistische Zwecke gem&auml;&szlig; Art. 89 Abs. 1 DSGVO, soweit das Betroffenenrecht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unm&ouml;glich macht oder ernsthaft beeintr&auml;chtigt, oder</li>
                    <li>zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</li>
                </ul>
                </li>
            </ul>

            <p>Haben wir Ihre personenbezogenen Daten &ouml;ffentlich gemacht und sind wir nach Vorstehendem zu deren L&ouml;schung verpflichtet, so treffen wir unter Ber&uuml;cksichtigung der verf&uuml;gbaren Technologie und der Implementierungskosten angemessene Ma&szlig;nahmen, auch technischer Art, um f&uuml;r die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, dar&uuml;ber zu informieren, dass Sie als betroffene Person von ihnen die L&ouml;schung aller Links zu Ihren personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.</p>
            </li>
            <li><b>Recht auf Einschr&auml;nkung der Verarbeitung</b>
            <p>Sie haben das Recht, gem&auml;&szlig; Art. 18 DSGVO die Einschr&auml;nkung der Verarbeitung (Sperrung) Ihrer personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden F&auml;llen:</p>

            <ul>
                <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah / geschieht, k&ouml;nnen Sie statt der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.</li>
                <li>Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
            </ul>

            <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.</p>
            </li>
            <li><b>Recht auf Unterrichtung</b>
            <p>Haben Sie das Recht auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung der Verarbeitung gegen&uuml;ber uns geltend gemacht, sind wir verpflichtet, allen Empf&auml;ngern, denen Ihre personenbezogenen Daten offengelegt wurden, diese Berichtigung oder L&ouml;schung der Daten oder Einschr&auml;nkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unm&ouml;glich oder ist mit einem unverh&auml;ltnism&auml;&szlig;igen Aufwand verbunden. Ihnen steht gem&auml;&szlig; Art. 19 DSGVO das Recht zu, nach Verlangen &uuml;ber diese Empf&auml;nger unterrichtet zu werden.</p>
            </li>
            <li><b>Recht, nicht einer ausschlie&szlig;lich auf einer automatisierten Verarbeitung &ndash; einschlie&szlig;lich Profiling &ndash; beruhenden Entscheidung unterworfen zu werden</b>
            <p>Sie haben das Recht, gem&auml;&szlig; Art. 22 DSGVO nicht einer ausschlie&szlig;lich auf einer automatisierten Verarbeitung &ndash; einschlie&szlig;lich Profiling &ndash; beruhenden Entscheidung unterworfen zu werden, die Ihnen gegen&uuml;ber rechtliche Wirkung entfaltet oder Sie in &auml;hnlicher Weise erheblich beeintr&auml;chtigt.</p>

            <p>Dies gilt nicht, wenn die Entscheidung</p>

            <ul>
                <li>f&uuml;r den Abschluss oder die Erf&uuml;llung eines Vertrags zwischen Ihnen und uns erforderlich ist,</li>
                <li>aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zul&auml;ssig ist und diese Rechtsvorschriften angemessene Ma&szlig;nahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder</li>
                <li>mit Ihrer ausdr&uuml;cklichen Einwilligung erfolgt.</li>
            </ul>

            <p>Allerdings d&uuml;rfen die Entscheidungen in den in (a) bis (c) genannten F&auml;llen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder lit. g gilt und angemessene Ma&szlig;nahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.</p>

            <p>In den in (a) und (c) genannten F&auml;llen treffen wir angemessene Ma&szlig;nahmen, um Ihre Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung geh&ouml;rt.</p>
            </li>
            <li><b>Recht auf Daten&uuml;bertragbarkeit</b>
            <p>Sofern die Verarbeitung auf Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO beruht und mithilfe automatisierter Verfahren erfolgt, haben Sie das Recht, gem&auml;&szlig; Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, g&auml;ngigen und maschinenlesebaren Format zu erhalten und einem anderen Verantwortlichen zu &uuml;bermitteln oder die &Uuml;bermittlung an einen anderen Verantwortlichen zu verlangen, soweit dies technisch machbar ist.</p>
            </li>
            <li><b>Widerspruchsrecht</b>
            <p>Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabw&auml;gung nach Art. 6 Abs. 1 lit. f DSGVO st&uuml;tzen, haben Sie jederzeit das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen; dies gilt auch f&uuml;r ein auf diese Bestimmung gest&uuml;tztes Profiling. Die jeweilige Rechtsgrundlage, auf der eine Verarbeitung beruht, entnehmen Sie dieser Datenschutzerkl&auml;rung. Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen oder die Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen (Widerspruch nach Art. 21 Abs. 1 DSGVO).</p>

            <p>Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch f&uuml;r das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten anschlie&szlig;end nicht mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).</p>

            <p>Sie haben die M&ouml;glichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft &ndash; ungeachtet der Richtlinie 2002/58/EG - Ihr Widerspruchsrecht mittels automatisierter Verfahren auszu&uuml;ben, bei denen technische Spezifikationen verwendet werden.</p>
            </li>
            <li><b>Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde gem&auml;&szlig; Art. 77 DSGVO</b>
            <p>Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>

            <p>Die f&uuml;r uns zust&auml;ndige Aufsichtsbeh&ouml;rde ist:</p>

            <p>Der Landesbeauftragte f&uuml;r den Datenschutz und die Informationsfreiheit Rheinland-Pfalz</p>

            <p>Postfach 30 40<br />
            55020 Mainz</p>

            <p>Hintere Bleiche 34<br />
            55116 Mainz</p>

            <p>Telefon: 061 31/8920-0<br />
            E-Mail: poststelle@datenschutz.rlp.de<br />
            Internet: https://www.datenschutz.rlp.de</p>
            </li>
            <li><b>G&uuml;ltigkeit und &Auml;nderung dieser Datenschutzerkl&auml;rung</b></li>
            <li>Diese Datenschutzerkl&auml;rung gilt ab dem 8. August 2022. Wir behalten uns das Recht vor, diese Datenschutzerkl&auml;rung jederzeit unter Beachtung der geltenden Datenschutzvorschriften zu &auml;ndern. Dies kann z.B. zur Einhaltung neuer Gesetzesbestimmungen oder zur Ber&uuml;cksichtigung der &Auml;nderungen unserer Website bzw. neuer Dienstleistungen auf unserer Website erforderlich sein. Es gilt die zum Zeitpunkt Ihres Besuchs abrufbare Fassung.</li>
            <li>Sollte diese Datenschutzerkl&auml;rung ge&auml;ndert werden, beabsichtigen wir, &Auml;nderungen an unserer Datenschutzerkl&auml;rung auf dieser Seite bekannt zu geben, sodass Sie umfassend dar&uuml;ber informiert sind, welche personenbezogene Daten wir sammeln, wie wir sie verarbeiten und unter welchen Umst&auml;nden sie weitergegeben werden k&ouml;nnen.</li>
</ul>
</div>

    
}