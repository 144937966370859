import { useState } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";

import { Like } from "../../../components/Like";
import { Share } from "../../../components/Share";
import { Navi } from "../../../components/Navi";
import { Contacts } from "../Contacts";
import { Rat } from "../../../components/Rat";
import { RatItem } from "../../../components/RatItem";

const Top = ({data,user,axios,setModal}) => {

    // navigation
    const navi=[
        {name:'Home',url:'/'},
        {name:'Freelancer',url:'/freelances'},
        {name:data.name,url:'/'}
    ];

    const navigate = useNavigate();

    // back
    // var navigate=useNavigate();
    // const goBack = () => navigate(-1);
    var location=useLocation();

    const from=location.state?.from || 0;
    const back=[
        'Zurück',
        '',
        'Zurück zur Bearbeitung'
    ];

    // http patern 
    var pattern = /^((http|https|ftp):\/\/)/;

    // email show
    const [emailShow,setEmail] = useState(false);

    // show modal
    const [show,setShow]=useState(false);

    // download PDF
    const downloadPDF = () => {
        user
        ?axios.get('/doc/pdf/',{params:{id:data.id,type:1},responseType: 'blob'})
        .then(res=>{
            var url = window.URL.createObjectURL(res.data),
            link = document.createElement("a");
            link.href = url;
            link.setAttribute('download', `${data.name}.pdf`);
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        })
        :setModal({open:true,st:'mn'})
    }

    // rat
    const [points,setRat]=useState(data.rat);

    return (
        <div className="pr">

                <Contacts
                id={data.id}
                setShow={show=>setShow(show)}
                show={show}/>
                
                <div className="page-block _small">
                    
                    <div className="pr-top">
                        <div>
                            <div className="navi _gray">
                                <Navi list={navi}/>
                            </div>
                        </div>
                        <div 
                            onClick={()=>navigate(-1)}
                            className="back font-button1-16">
                            {back[from]}
                        </div>
                    </div>

                    <div className="pr-info">
                        <div>
                            <div className="pr-grid">
                                <div>
                                    <div className="foto _2d">
                                        {data.foto
                                        ?<img src={data.foto} alt="Foto"/>
                                        :<div className="icons _user _black _big _opacity"></div>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div className="pr-title">{data.name}</div>
                                    {points?<RatItem points={points}/>:''}
                                    <div className="pr-sub">
                                        <div className="pr-number">{data.cat}</div>
                                    </div>
                                    <div className="pr-free">
                                        <div>
                                            <div className="pr-feee-top">
                                                <div>
                                                    <div className={`label ${data.status.style}`}>
                                                        {data.status.text}
                                                    </div>
                                                </div>
                                            </div>
                                            {data.status.val!==21?<div>{data.status.data}: {data.status.from}</div>:<></>}
                                            {/* <div>Veröffentlichungsdatum: {data.create}</div> */}
                                        </div>
                                        <div>
                                            <div className="pr-icons _ver">
                                                {data.timeRateJson
                                                ?<div className="pr-item">
                                                    <div className="icons _money _small"></div>
                                                    <div className="pr-item-text">{data.timeRateJson}</div>
                                                </div>
                                                :<></>}
                                                {data.dayRateJson
                                                ?<div className="pr-item">
                                                    <div className="icons _money _small"></div>
                                                    <div className="pr-item-text">{data.dayRateJson}</div>
                                                </div>
                                                :<></>}
                                                {data.email?<div className="pr-item"><div className="icons _email _small"></div>
                                                    {
                                                        emailShow
                                                        ?<a href={`mailto:${data.email}`} target="_blank" rel="nofollow" className="href pr-item-text">{data.email}</a>
                                                        :<div onClick={()=>setEmail(true)} className="href pr-item-text">E-Mail Adresse anzeigen</div>
                                                    }
                                                </div>:''}
                                                {data.web?<div className="pr-item"><div className="icons _web _small"></div><div className="pr-item-text"><a href={pattern.test(data.web)?data.web:`https://${data.web}`} target="_blank">{data.web}</a></div></div>:''}
                                                {data.phone?<div className="pr-item"><div className="icons _phone _small"></div><div className="pr-item-text">{data.phone}</div></div>:''}
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pr-right">
                            <div className="pr-right-icons">
                                <Rat id={data.id} type={0} setRat={setRat}/>
                                <Share link={`/freelance/${data.id}`}/>
                                <Like id={data.id} type="2" like={data.l}/>
                            </div>
                            <div className="pr-right-buttons">
                                {user?.type!==0?<div 
                                    className="button button-primary"
                                    onClick={()=>user?setShow(true):setModal({open:true,st:'mn'})}>
                                    Kontaktieren
                                </div>:<></>}
                                
                                <div
                                    className="button button-small _download"
                                    onClick={()=>downloadPDF()}>
                                    Herunterladen als PDF
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

        </div>
    )
}

export {Top}