import { useEffect, useRef } from "react";

const MesItem = ({data}) =>{

    const area=useRef();

    // scroll bottom
    const scrollToBottom = () =>{
        area.current.scrollTop = area.current.scrollHeight;
    }

    // after message change
    useEffect(() => {
        scrollToBottom()
    }, [data]);

    function createMarkup(html){
        return {__html: html};
    }

    return (
        <div className="mail-mail overflow-none" ref={area}>
            {data?data.map((item,i)=>
            <div key={i} className="mail-mes-item">
                <div className="mail-mes">
                    <div className="mail-foto"><img src={item.img} alt="Foto"/></div>
                    <div className="mail-in font-h7b-14">{item.name}</div>
                    <div className="mail-mes-data font-text5-14">
                        {item.data.data} <span>{item.data.time}</span>
                    </div>
                </div>
                <div className="mail-mes-text">
                    <div className="mail-mes-block">
                        <div dangerouslySetInnerHTML={createMarkup(item.mes)}/>
                    </div>
                    {item.files.length?<div className="mail-mes-append">
                        {item.files.map((file,i)=>
                        <div className="mail-mes-doc" key={i}>
                            <div className="icons _file _small"></div>
                            <a href={`/download/mail/${file.cod}`}>{file.file}</a>
                        </div>)}
                    </div>:''}
                </div>
            </div>):''}
        </div>
    )
}

export {MesItem}