const CheckboxItem = ({children,...item}) => {
    return (
        <div className="checkbox">
            <input 
                id={`check${item.value}`}
                type="checkbox" 
                checked={item.active?'checked':''} 
                readOnly/>
            <label 
                htmlFor={`check${item.value}`}
                onClick={()=>{item.toggle(item.value);}}>
                {children}
            </label>
        </div>
    )
}

//onClick={()=>toggle}
export {CheckboxItem}