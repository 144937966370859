import {Link} from 'react-router-dom';

const Navi = ({list,white}) => {
    return (
        <div className={`navi ${white?'_white':''}`}>
            {list.map((item,i)=>
                <div key={i} className="navi-block">
                    <div className="navi-line">|</div>
                    <Link 
                        to={item.url} 
                        className="navi-item">
                        {item.name}
                    </Link>
                </div>
            )}
        </div>
    )
}

export {Navi}