import { useEffect, useState } from "react";
import { useParams} from "react-router-dom";

import useAxios from '../../hook/useAxios';
import { useLogin } from "../../hook/useLogin";

import { Loading } from "../../components/Loading";

import { Work } from "./FreeDet/Work";
import { Lang } from "./FreeDet/Lang";
//import { Tags } from "./FreeDet/Tags";
import { Docs } from "./FreeDet/Docs";
import { Study } from "./FreeDet/Study";
import { Top } from "./FreeDet/Top";
import { Soc } from "./Soc";
import { TagsList } from "../../components/TagsList";

const FreeDet = () => {
    const {id} = useParams();
    const [data,setData]=useState(null);

    const {user,setModal} = useLogin();
    const axios = useAxios(user);

    useEffect(()=>{
        
        // get detail
        axios.get('/site/freeDet/',{params:{id:id}})
            .then(res=>res.data)
            .then(data =>{
                data.res
                ?setData(data.res)
                :window.location.href="/freelance/"
            });
    },[]);

    return (
        data ?
        <div className="other-det">
            
            <Top data={data} user={user} axios={axios} setModal={setModal}/>

            <div className="other-det-content page-block _small">
                
                {data.descrText!==''?
                <><div className="title">Über mich</div>
                <div className="other-det-text">
                    {data.descrText}
                </div></>:''}

                {data.skills.length
                ?<>
                    <div className="title">Qualifikationen</div>
                    <TagsList title="Skils" tags={data.skills}/>
                </>
                :<></>}

                {data.study?<Study list={data.study}/>:<></>}

                {data.work?<Work list={data.work}/>:<></>}
                
                {data.lang?<Lang list={data.lang}/>:<></>}

                {data.docs?<Docs list={data.docs}/>:<></>}

                {data?.soc?.length?<Soc list={data.soc}/>:<></>}

                {/* <div className="other-det-button _left">
                    <div className="button button-primary">herunterladen</div>
                </div> */}

                {/* <div className="other-det-bottom">
                    <div className="title">Kontakt {`&`} Standort</div>
                    <div className="other-det-contacts">
                        <div className="other-det-form">
                            <Contacts/>
                        </div>
                        <div className="other-det-map">
                            <img src="/img/temp/map.jpg" alt="Map"/>
                        </div>
                    </div>
                </div> */}

            </div>
        </div>:<Loading/>
    )
}
export {FreeDet};