import "./Service.scss";
import {Link} from 'react-router-dom';
import { Top } from "./ServiceFree/Top";
import { useState } from "react";
import { Mes } from "./Mes";
import { useLogin } from "../../hook/useLogin";

const ServiceFree = () => {

    const [mes,setMes] = useState(false);
    const [type,setType] = useState(0);
    const {setModal,user}=useLogin();

    const clickHandler = type =>{

        setType(type);
    
        // if not login open modal
        user
        ?setMes(true)
        :setModal({open:true,st:'mn'});
    }

    const year = '01.01.2026';
    const sale = <div className="services-buy-sale">Kennenlernen Aktion! Free Premium Mitgliedschaft bis {year}</div>;

    return(
        <>
            <Top/>
            {mes?<Mes setShow={setMes} type={type}/>:''}

            <div className="services-buy">
                <div className="page-block">

                    <div className="services-buy-pack">
                        <div className="services-buy-item">
                            <div className="services-buy-free">kostenlos</div>
                            <div className="services-buy-title">
                                <div>
                                    <div className="services-buy-name">Basis</div>
                                    <div className="services-buy-price font-h2b-32">0,00 €*</div>
                                </div>
                                <div className="services-buy-monce">
                                    <div>12 Monate Vertragslaufzeit</div>
                                    <div>0,00 €/Jahr*</div>
                                </div>
                            </div>
                            <div className="services-buy-content">
                                
                                {sale}

                                <div>Registrierung und Support</div>
                                <div>6 Bewerbungen am Tag</div>
                                <div>alle Projekte ansehen</div>
                                <div>2 Dokumenten hochladen</div>
                                <div>Anbieter ausschließlich über Portal auf InMail anschreiben.</div>
                                <div>Suchagenten 1</div>
                                <div>Online support</div>
                                <div>Registrierung nur in 1 Rubrik des Portals</div>
                                <div className="_no">Feedback schreiben</div>
                                <div className="_no">Projekte nach Aktualität nach Anbieter sortieren</div>
                                <div className="_no">Name und Kontakte des Projektanbieters sehen</div>
                                <div className="_no">Kann nicht bewerten werden</div>
                                <div className="_no">Anbieter bewerten Kommentare,  und Beiträge schreiben</div>
                                <div className="_no">Statistik</div>
                                <div className="_no">Profilbesucher anzeigen</div>
                            </div>
                            {/* <div className="services-button">
                                <div 
                                    onClick={()=>clickHandler(1)}
                                    className={`button button-primary ${user?.type!==0?'disabled':''}`}>
                                    Jetzt Buchen
                                </div>
                            </div> */}
                        </div>
                        <div className="services-buy-item">
                            <div className="services-buy-free">kostenlos</div>
                            <div className="services-buy-title">
                                <div>
                                    <div className="services-buy-name _premium">Premium</div>
                                    <div className="services-buy-price font-h2b-32"><s>5,00€*</s></div>
                                </div>
                                <div className="services-buy-monce">
                                    <div>12 Monate Vertragslaufzeit</div>
                                    <div><s>60,00 €/Jahr*</s></div>
                                </div>  
                            </div>
                            <div className="services-buy-content">
                                
                                {sale}

                                <div>Registrierung und Support;</div>
                                <div>alle Projekte ansehen;</div>
                                <div>Bewerben ohne Limits</div>
                                <div>bis zu 10 Dokumenten hochladen;</div>
                                <div>Anbieter über In-Mail und E-Mail kontaktieren;</div>
                                <div>Bis zu 20 Suchagenten;</div>
                                <div>Online Support</div>
                                <div>Registrierung in 10 Rubriken möglich;</div>
                                <div>Feedback schreiben;</div>
                                <div>Projekte nach Aktualität nach Anbieter sortieren</div>
                                <div>Name und Kontakte des Projektanbieters sehen</div>
                                <div>Kann nicht bewerten werden</div>
                                <div>Anbieter bewerten Kommentare,  und Beiträge schreiben</div>
                                <div>Statistik</div>
                                <div>Profilbesucher anzeigen</div>
                            </div>
                            {/* <div className="services-button">
                                <div 
                                    onClick={()=>clickHandler(2)}
                                    className={`button button-primary ${user?.type!==0?'disabled':''}`}>
                                    Jetzt Buchen
                                </div>
                            </div> */}
                        </div>
                    </div>
                    
                    <div className="services-text font-text5-14">
                    *12 Monate Vertragslaufzeit Keine weiteren Kosten, keine Vermittlungsgebühr.
                    Die Preise sind Nettopreise und verstehen sich zzgl. gesetzlichen Mehrwertsteuer.
                    Die Verträge laufen auf unbegrenzte Zeit und werden automatisch um 1 Jahr verlängert.
                    Kündigungsfrist beträgt 14 Tage.</div>

                </div>
            </div>

            <div className="page-content  _gray">
                <div className="services-block">
                    <div className="page-block">
                        <div className="font-h1b-50">Haben Sie noch Fragen?</div>
                        <div className="font-text1-32">Wir sind immer für Sie da</div>
                        <Link to="/contacts" className="button button-primary" style={{marginTop: "40px"}}>kontaktiere uns</Link>
                    </div>
                </div>
            </div>

        </>
    )
}

export {ServiceFree}