import { useEffect, useState } from "react"
import { TagsList } from "./Tags/TagsList";
import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";

const Tabs = () =>{
    // data state
    const [data, setData] = useState([]);

    const {user}=useLogin();
    const axios=useAxios(user);

    useEffect(()=>{
        axios.get('/admin/tags')
        .then(res=>res.data)
        .then(data=>setData(data.res));
    },[]);

    return (
        <div className="fr-tags">
            <div className="font-h6b-16">Active tags</div> 
            <div>
                <TagsList tags="active" data={data || []} setData={setData}/>
            </div>
            {/* <div className="font-h6b-16">Search tags</div> 
            <div>
                <TagsList tags="search" data={data || []} setData={setData}/>
            </div> */}
        </div>
    )
}

export {Tabs}