import "./Home.scss";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../css/swiper.scss';

import useAxios from "../../hook/useAxios";

import { Circles } from "./Home/Circles";
import { Contacts } from "./Home/Contacts";
import { Feedback } from "./Home/Feedback";
import { TopFirm } from "./Home/TopFirm_old";
import { FreeList } from "./Home/FreeList";
import { FirmList } from "./Home/FirmList";
import { Icons } from "./Home/Iconst";
import { Searching } from "./Home/Searching";

import { useEffect, useState } from "react";
import { TopProj } from "./Home/TopProj";
import { News } from "./Home/News";

const Home = () => {

    const axios=useAxios()

    const [list,setList] = useState({});

    useEffect(()=>{
        axios.get('/site/home')
        .then(res=>res.data)
        .then(data=>
            data?setList(data.res):''
        )
    },[])

    return(
        <div className="home">

            <Searching/>

            <Icons/>

            <Circles/>

            <div className="page-content">

            <FirmList  list={'firm' in list?list.firm:null}/>            

            <FreeList list={'free' in list?list.free:null}/>
            
            </div>

            <TopFirm  list={list.top}/>

            <Contacts/>

            <br/><br/>

            <TopProj list={'proj' in list?list.proj:null}/>

            <News/>

            <Feedback list={'feed' in list?list.feed:null}/>

        </div>
    )
}

export {Home}