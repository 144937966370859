import axios from 'axios';

const useAxios = (user) => {
    //process.env.REACT_APP_TEST_TOKEN

    const config={
        baseURL:`${process.env.REACT_APP_DOMAIN}/api/v1`,
        headers:
            {
                'Authorization':`Bearer ${user?.accessToken?user.accessToken:''}`,
                'Content-Type' : 'multipart/form-data'
            }
    };

    const instance = axios.create(config);

    // // Add a request interceptor
    // instance.interceptors.request.use(function (response) {
    //     // Do something before request is sent
    //     console.log(response,'res');
    //     //return false

    //     //return response;
    // }, function (error) {
    //     // Do something with request error
    //     console.log(error,'error');
    //     //return Promise.reject(error);
    // });

    return instance;

}

export default useAxios;


// axios.defaults.baseURL = process.env.REACT_APP_API;
// 1?axios.defaults.headers.common['Authorization'] =  `Bearer ${process.env.REACT_APP_TEST_TOKEN}`:'';
