import "./Contacts.scss";

import {Modal} from '../../components/Modal';

import { Errors } from "../../components/Errors";
import {useForm } from 'react-hook-form';

import useAxios from "../../hook/useAxios";
import {useLogin} from "../../hook/useLogin";

const Contacts = ({id,setShow,show}) =>{

    const {user}=useLogin();
    const axios=useAxios(user);

    // parse
    const err = {
        req: 'bitte ausfüllen'
    }

    const {
        register, 
        handleSubmit,
        reset, 
        formState: {errors},
    } = useForm();

    // send form
    const onSubmit = data =>{
        // message for you
        axios.post('/mail/',{mes:data.mes,id:id,topic:'Eine Nachricht für Sie'})
        setShow(false);
        reset()
    }

    return (
        show?<Modal setShow={setShow}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="contacts-mes">
                    <div>
                        <div className="font-h2b-32 _center">Kontaktieren</div>
                    </div>
                    <div>
                        <div className="inputs-block _label">
                            <label>Nachricht</label>
                            <textarea
                                className="textarea" 
                                {...register("mes",{required:err.req})}
                                placeholder="Die Nachricht darf 100 Zeichen nicht überschreiten"/>
                        </div>
                        <Errors error={errors?.mes?.message}/>
                    </div>
                    <div>
                        <div className="contacts-mes-text font-text5-14 _center">Senden Sie Ihren Bewerbungstext mit Premium-Plan</div>
                        <button type="submit" className="button button-primary _width">senden</button>
                    </div>
                </div>
            </form>
        </Modal>:''
    )
}
export {Contacts}