import { useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { MesDel } from "../../../components/MesDel/MesDel";
import {Share} from '../../../components/Share';

// import { Top } from "./Top"

const ProjItem = ({item,del}) =>{

    const [show,setShow] = useState(false);

    return (
        <tr className="table-row">
            <td>
                <div className="table-image">
                    <Link 
                        to={`/proj/${item.proj}`}
                        target="_blank"
                        className="table-image-ico">
                        {item.img
                            ?<img src={item.img} alt="Foto"/>
                            :<div></div>
                        }
                    </Link>
                    <div className="font-h7b-14">{item.projName}</div>
                </div>
            </td>
            <td>{item.data}</td>
            <td>
                <ReactTooltip />
                <MesDel setShow={setShow} show={show} del={()=>{setShow(false);del()}}>
                    <p>Das Projekt wird aus der Liste entfernt.<br/>
                    Das Projekt aus der Liste entfernen?</p>
                </MesDel>
                <div className="table-icons">
                    <div 
                        className="icons _del" 
                        data-help="Delete" data-tip="Löschen" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>setShow(true)}>
                    </div>
                    <Share link={`/proj/${item.id}`}/>
                </div>
            </td>
            <td>
                {item.cancel
                ?<div className="label _no _line">Geschlossen</div>
                :<div className="label _free _line">Aktiv</div>}
            </td>
        </tr>
    )
}
export {ProjItem}