import './About.scss';

import {ScrollToTop} from "../../components/ScrollToTop";
import { Contacts } from './About/Contacts';
import { Link } from 'react-router-dom';

const About = () => {
    return (
    <>  
        <ScrollToTop/>
        <div className="about-banner">
            <div className="about-banner-block page-block _small">
                <div className="about-banner-top font-h1b-50 _white">
                Eine Plattform, mit der sie wachsen
                </div>
                <div className="about-banner-line"></div>
                <div className="about-banner-bottom _font-white">
                    FreelancerWeb ist eine Online-Plattform für beste Kooperation zwischen Projektanbietern, Freelancern, Freiberuflern und Interim Managern aus Deutschland, der Schweiz und Österreich.
                    <br/>
                    <br/>
                    Unsere Internet-Ressource bietet Experten die Möglichkeit, nach Projekten aus bestimmten Fachgebieten, Fachbereichen und Städten zusuchen, und für Personalvermittler werden alle Funktionen für eine einfache Interim Manager Suche zusammengestellt.
                </div>
            </div>
        </div>

        <div className="about-content">
            <div className="page-block _small">
                <div className="about-title font-h1b-50">Erfolgsgeschichte</div>
                <div className="about-text">
                    Fast seit 20 Jahren ist unser Geschäftsleiter im Einkauf und rozessoptimierung  tätig und hat für das Projekt die Geschäftsführung und die Entwicklungsidee übernommen.  
                    <br/><br/>Er hat BWL Studium mit Erfolg absolviert, und viele Jahre  rbeitserfahrungen, Kenntnisse und Fähigkeiten sowie in Festanstellung als auch in Freelance im Einkauf, Supplay Chain Management, Vertragsmanagement gesammelt hat.
                </div>
                <div className="about-cart about-text">
                    Für mich war wirklich eine Herausforderung einen IT Projekt zu gründen und die Plattform FreelancerWeb.de so zusammenzustellen, dass alle meine Kollegen – Freelancer, und die Dienstleister vollkommen zufrieden bleiben.
                    <br/><br/>Ich habe persönlich alle Funktionen des Portals unter die Lupe genommen, beschrieben, getestet und für das Projekt freigegeben.
                </div>
            </div>
        </div>

        <div className="about-flag">
            <img src="./img/about/background_mountain.jpeg" width="100%" alt=""/>
            <div className="page-block _small about-flag-text">
                <div className="page-title about-title font-h1b-50">Entwicklung</div>
                <div className="about-text">
                    Wir haben lange an diesem Projekt gearbeitet. Nur die Verarbeitung der gesammelten Informationen dauerte ca. 8 Monate. Mit umfassender Erfahrung im Marketing und in der IT Bereich, analysierte unser Team die Bedürfnisse von Freiberuflern und Projektanbietern. 
                    <br/><br/>
                    Wir haben die langjährige Erfahrung des Experten im Bereich Interim Management genutzt, um einen Algorithmus zur Suche und Platzierung der erforderlichen Informationen in unserer Internetressource zu entwickeln.
                </div>
                <div className="page-title about-title font-h1b-50">Modernste Technologien als Vorteil </div>
                <div className="about-text">
                    Und schließlich erschien das lang erwartete Internetprojekt - FreelancerWeb.de  Die FreelancerWeb-Website enthält alle erforderlichen Mechanismen, um Informationen über die Experten, das Unternehmen und die Projekte zu veröffentlichen.
                    <br/><br/>
                    Außerdem haben wir ein praktisches Bedienpanel erstellt - das Dashboard, mit dem die Benutzer die erforderlichen Informationen platzieren und so einfach wie möglich gestalten können.
                    <br/><br/>
                    Mit unserem Algorithmus können sich Projektanbieter und Experten problemlos sich finden ohne die Zeit zu verschwenden.
                </div>
            </div>
        </div>`

        <div className="about-nout">
            <div className="page-block">
                <img src="./img/about/nout.jpg" width="100%" alt=""/>
            </div>
        </div>

        <div className="about-sequrity">
            <div className="page-block _small">
                <div className="about-sequrity-block">
                    <div className="about-sequrity-icon">
                        <img src="./img/about/sequrity.png" alt=""/>
                    </div>
                    <div>
                        <div className="about-sequrity-title font-h1b-50">Sicherheit</div>
                        <div className="font-text1-32">Auf unserer Website sind alle Anforderungen zum Schutz personenbezogener Daten erfüllt.</div>
                    </div>
                </div>
            </div>
        </div>

        <div className="about-web">
            <div className='page-block _small'>
                <div className='about-web-text'>
                    Die kostenlose Registrierung auf dem Portal bietet Anfängern eine Möglichkeit für einen schnellen Start ins Freiberufliches Leben.
                    <br/><br/>
                    Unser Projekt bringt an einem Ort hochqualifizierte Experten aus verschiedenen Bereichen und Branchen sowie die Unternehmen die sie benötigen zusammen.
                </div>
            </div>

            <div className="about-feedback">
                <div className="page-block _small">
                    <div className="about-feedback-block">
                        <div className="about-feedback-foto">
                            <img src="./img/about/foto.jpeg" alt=""/>
                        </div>
                        <div className="about-feedback-text">
                            Über den Support können sie uns rund um die Uhr kontaktieren. Unsere Spezialisten helfen Ihnen bei der Lösung ihrer Angelegenheiten.
                        </div>
                    </div>
                </div>
            </div>

            {/* <Contacts/> */}
                
            {/* <div className="about-bottom-flag">
                <div className='page-block _small'>
                    <div className="about-bottom-text">
                        <div className="page-title font-h2b-32 _center">Mit der Ukraine stehen</div>
                        <div className='font-text3-18'>Niemand hätte sich vorstellen können, dass im Jahr 2022 im Herzen Europas ein ausgewachsener Krieg ausbrechen würde. Russland griff die Ukraine an und verursachte Tausende von Opfern.
                            Städte werden zerstört, Mens`chen sterben, Familien sind gezwungen, all ihr Hab und Gut zurückzulassen und zu fliehen.
                            Am schlimmsten ist, dass die Kinder leiden. 
                            <br/><br/>
                            Krankenhäuser, Entbindungsheime und Wohngebiete wurden bombardiert. 
                            <br/><br/>
                            In diesem Zusammenhang unterstützt FreelancerWeb den Sofia Charity Organisation. 
                            10 % aller Ihrer Zahlungen gehen an diesen Fonds, um Flüchtlingen aus der Ukraine, vom Krieg betroffenen Kindern und Kindern, die eine sofortige medizinische Behandlung benötigen, zu helfen. 
                            Auf diese Weise werden Sie auch Teil unseres Freiwilligen-Netzwerks. 
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='_center'><Link to="/contacts" className="button button-secondary">Kontakt FreelancerWeb</Link></div>
        </div>
    </>
    )
}

export {About}