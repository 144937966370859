import { useState,useEffect} from "react";
import { Link } from "react-router-dom";

import useAxios from "../../hook/useAxios";
import {useLogin} from "../../hook/useLogin";

import { NewsItem } from "./News/NewsItem";

const News = () => {
    const {user,setUser}=useLogin();
    const axios=useAxios(user);

    const [data,setData]=useState(null);

    useEffect(()=>{
        axios.get('/admin/news')
        .then(res=>res.data)
        .then(data=>setData(data.res));
    },[])

    const delNews = news =>{
        // filter
        setData(data.filter(item=>item.id!==news.id));
        // request
        axios.delete('/admin/news/',{params:{id:news.id}});
    }
    
    return (
        <div>
            <div className="fr-flex">
                <div className="font-h6b-16">Nachrichten</div>
                <div><Link to="./add" className="button button-small _plus">Neuigkeiten hinzufügen</Link></div>
            </div>
            <div className="fr-ps">
                <table className="table">
                    <thead>
                        <tr className="table-title">
                            <td>Überschrift der Nachrichten</td>
                            <td>Datum</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {data
                        ?data.map(
                            (item,i)=>
                                <NewsItem 
                                key={i} 
                                item={item} 
                                del={()=>delNews(item)}/>
                            )
                        :<tr></tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export {News}