const Docs = ({list}) =>{

    return (
        list.length?
        <div>
            <div className="title">Dokumenten</div>
            <div className="other-det-doc">
                {list.map((item,i)=>
                    <a href={`${process.env.REACT_APP_DOMAIN}/download/doc/${item.cod}`} className="other-det-dcitem" key={i}>
                        <div className="other-det-dctext">{item.name}</div>
                    </a>
                )}
            </div>
        </div>:''
    )
}

export {Docs}