import './Rat.scss';

import { useState } from "react";
import ReactTooltip from "react-tooltip";

import { RatMes } from "./RatMes";

import {useLogin} from "../hook/useLogin";

const Rat = ({id,type,setRat}) =>{
    
    const {user,setModal}=useLogin();

    // show modal
    const [show,setShow]=useState(false);

    //  rat
    const ratClick = e => {
        e.stopPropagation();
        e.preventDefault();

        user
        ?setShow(true)
        :setModal({open:true,st:'mn'});
    }

    return (
        <>
            {show
                ?<RatMes id={id} type={type} setShow={setShow} setRat={setRat}/>
                :<></>
            }
            <ReactTooltip />
            <div 
                className={`icons _star`} 
                data-help="like" data-tip="bewertung" data-place="top" data-effect="solid" data-class="c-tooltips"
                onClick={ratClick}>
            </div>
        </>
    )
    
}

export {Rat}