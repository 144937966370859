

const Soc = ({list}) =>{
    return (
        <div>
        <div className="title">Kontakt & Standort</div>
            <div className="other-det-soc">
            {list.map((item,i)=>
                <div key={i}><a href={`https://${item.url}`} className={`icons _${item.name}`} target="_blank"></a></div>
            )}
            </div>
        </div>
    )
}

export {Soc}