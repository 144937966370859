import { useState } from "react"
// import { MySelect } from "../../../components/MySelect";
// import DatePicker from "../../../hook/useDatepicker";

const StudyAdd = ({setData,data,type,title}) =>{

    // default input
    const def={
        id:null,
        idStudy:31, // default institute
        studyData:{
            start:null,
            end:null
        },
        studyName:'',
        studyPlace:''
    }

    // study
    const addStudy = () =>{
        let newItem=data.study.concat([def]);
        setData({...data,study:newItem});
    }

    return (<div className="section-block">
        <div className="button button-small _plus" onClick={()=>{addStudy();}}>Position Einfügen</div>
    </div>)
}

export {StudyAdd}