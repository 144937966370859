import "./Service.scss";
import {Link} from 'react-router-dom';
import { Top } from "./ServiceFirm/Top";
import { useState } from "react";
import { Mes } from "./Mes";
import { useLogin } from "../../hook/useLogin";

const ServiceFirm = () => {

    const [mes,setMes] = useState(false);
    const [type,setType] = useState(0);
    const {setModal,user}=useLogin();

    const clickHandler = type =>{

        setType(type);
    
        // if not login open modal
        user
        ?setMes(true)
        :setModal({open:true,st:'mn'});
    }

    const year = '01.01.2026';
    const sale = <div className="services-buy-sale">Kennenlernen Aktion! Free Premium Mitgliedschaft bis {year}</div>;
    
    return(
        <>
            <Top/>
            {mes?<Mes setShow={setMes} type={type}/>:''}

            <div className="services-buy">
                <div className="page-block">

                    <div className="services-buy-pack">
                        <div className="services-buy-item">
                            <div className="services-buy-free">kostenlos</div>
                            <div className="services-buy-title">
                                <div>
                                    <div className="services-buy-name">Basis</div>
                                    <div className="services-buy-price font-h2b-32"><s>40,00 €*</s></div>
                                </div>
                                <div className="services-buy-monce">
                                    <div>12 Monate Vertragslaufzeit</div>
                                    <div><s>480,00 €/Jahr*</s></div>
                                </div>
                            </div>
                            <div className="services-buy-content">
                                
                                {sale}

                                <div>Registrierung und Support</div>
                                <div>Darstellung inkl. Logo</div>
                                <div>Nur 2 Lizenznutzer</div>
                                <div>10 Projektausschreibungen / Monat</div>
                                <div>Alle Freelancer ansehen</div>
                                <div>Registrierung in allen Rubriken des Portals</div>
                                <div>Bewerbungen per E-Mail und im Account erhalten</div>
                                <div>100 Profile im Monat ansehen</div>
                                <div>Online support</div>
                                <div>Eigeschränkte Experteninformation</div>
                                <div>Zahl der Suchagenten 5</div>
                                <div>Experten Lebenslauf und die andere Dateidownload Möglichkeit </div>
                                <div className="_no">Hervorgehobene Sichtbarkeit</div>
                                <div className="_no">Freelancer bewerten, Kommentare und Beiträge schreiben</div>
                                <div>Nur Auflistung in Firmendatenbank, keine Annonce auf Hauptseite</div>
                                <div>Fremde Projektangebote auf ihre Projektseite</div>
                                <div className="_no">Statistik</div>
                            </div>
                            {/* <div className="services-button">
                                <div 
                                    onClick={()=>clickHandler(1)}
                                    className={`button button-primary ${user?.type!==1?'disabled':''}`}>
                                    Jetzt Buchen
                                </div>
                            </div> */}
                        </div>
                        <div className="services-buy-item">
                            <div className="services-buy-free">kostenlos</div>
                            <div className="services-buy-title">
                                <div>
                                    <div className="services-buy-name _premium">Premium</div>
                                    <div className="services-buy-price font-h2b-32"><s>60,00 €*</s></div>
                                </div>
                                <div className="services-buy-monce">
                                    <div>12 Monate Vertragslaufzeit</div>
                                    <div><s>720,00 €/Jahr*</s></div>
                                </div>
                            </div>
                            <div className="services-buy-content">

                                {sale}

                                <div>Registrierung und Support</div>
                                <div>Darstellung inkl. Logo</div>
                                <div>Unbegrenzte Lizenznutzer</div>
                                <div>Unlimitiert Projektausschreibungen</div>
                                <div>Alle Freelancer ansehen</div>
                                <div>Registrierung in allen Rubriken des Portals</div>
                                <div>Bewerbungen per E-Mail und im Account erhalten</div>
                                <div>Uneingeschränkt Profile ansehen</div>
                                <div>Online support</div>
                                <div>Vollständige Experteninformation</div>
                                <div>Unbegrenzte Zahl der Suchagenten</div>
                                <div>Experten Lebenslauf und die andere Dateidownload Möglichkeit </div>
                                <div>Hervorgehobene Sichtbarkeit</div>
                                <div>Freelancer bewerten, Kommentare und Beiträge schreiben</div>
                                <div>Experten  Daten weiterleiten</div>
                                <div>Auflistung in Firmendatenbank und Annonce auf Hauptseite des Portals.</div>
                                <div>Keine fremde Projektangebote auf Projektseite</div>
                                <div>Platzierung in TOP Anbieter</div>
                                <div>Plazierung in TOP Projekte</div>
                                <div>Statistik</div>
                            </div>
                            {/* <div className="services-button">
                                <div 
                                    onClick={()=>clickHandler(2)}
                                    className={`button button-primary ${user?.type!==1?'disabled':''}`}>
                                    Jetzt Buchen
                                </div>
                            </div> */}
                        </div>
                    </div>
                    
                    <div className="services-text font-text5-14">
                    *12 Monate Vertragslaufzeit Keine weiteren Kosten, keine Vermittlungsgebühr.
                    Die Preise sind Nettopreise und verstehen sich zzgl. gesetzlichen Mehrwertsteuer.
                    Die Verträge laufen auf unbegrenzte Zeit und werden automatisch um 1 Jahr verlängert.
                    Kündigungsfrist beträgt 14 Tage.</div>

                </div>
            </div>

            <div className="page-content _gray">
                <div className="services-block">
                    <div className="page-block">
                        <div className="font-h1b-50">Haben Sie noch Fragen?</div>
                        <div className="font-text1-32">Wir sind immer für Sie da</div>
                        <Link to="/contacts" className="button button-primary">kontaktiere uns</Link>
                    </div>
                </div>
            </div>

        </>
    )
}

export {ServiceFirm}