import './Menu.scss';

import { useEffect, useState } from 'react';
import useAxios from '../../hook/useAxios';
import { MenuItem } from './MenuItem';
import { Link } from 'react-router-dom';

const Menu = ({show}) =>{

    const axios=useAxios();

    const [cat,setCat]=useState(false);

    useEffect(()=>{
        axios.get('/site/type',{params:{type:['cat']}})
        .then(res=>res.data)
        .then(data=>{
            // top menu item
            data.cat.unshift({label:'Alle',value:null})
            // set items
            setCat(data.cat)
        
        });
    },[]);

    // menu list
    const menu=[
        {name:'Projekte',url:'/projs'},
        {name:'Freelancer',url:'/freelances'},
        {name:'Firmen',url:'/firms'}
    ]

    return (
        cat
        ?<div className="page-menu-center">
            <div className={`page-menu-list `+(show?'active':'')}>
                {menu.map((item,i)=>
                    <MenuItem cat={cat} item={item} key={i}/>)
                }
                <div className="page-menu-link">
                    <Link to="/news">News</Link>
                </div>
            </div>
        </div>:''
    )
}

export {Menu}