import "./Config.scss";

import {useRef, useState} from 'react';
import {Controller,useForm} from 'react-hook-form';

import { CheckboxItem } from '../../components/CheckboxItem';
import {Errors} from '../../components/Errors';

import useAxios from "../../hook/useAxios";

import { PasInput } from './Config/PasInput';
import { Mes } from './Config/Mes';

import { useLogin } from "../../hook/useLogin";
import { useEffect } from "react";

const Config = () => {

    const {user} = useLogin();
    const axios = useAxios(user);

    const [show,setShow]= useState(false);

    // err message
    const err={
        require : 'bitte ausfüllen',
        email: "E-Mail nicht erlaubt",
        pas: "Das Passwort muss aus mehr als 8 Buchstaben und Zahlen bestehen",
        repeat: "Die Kennwörter stimmen nicht überein"
    }

    // useForm
    const {
        register, 
        handleSubmit,
        setError,
        formState: {errors},
        watch,
        control
    } = useForm({
        defaultValues:{
            login:'',
            oldpas:'',
            pas:'',
            repas:'',
            spam:user?.spam || false,
            info:user?.info || false
        }
    });

    // pas
    const pas = useRef("");
    pas.current=watch("pas")

    // submit
    const onSubmit = data =>{
        axios.post('/user/pas',data)
        .then(res=>res.data)
        .then(data=>
            data.error
            ?setError(data.error)
            :''
        )
    }

    return (
    <form onSubmit={handleSubmit(onSubmit)}>
        {show?<Mes setShow={setShow}/>:<></>}
        <div className="fr-cf">
            <div className="fr-cf-content">

                <div className="fr-cf-form">
        
                    <div className="inputs-block">
                        <div className="font-h6b-16">Passwort ändern</div>
                    </div>

                    <div className='_span'>
                        <div className="inputs-block _label">
                            <label>Email</label>
                            <input 
                                {...register("login",{
                                    pattern:{
                                        value:/^[^\s]+@[^\s@]+(\.[^\s@]+){1,}$/,
                                        message:err.email
                                    }
                                })}
                                className="inputs off" 
                                autoComplete="off"/>
                        </div>
                        <Errors error={errors?.login?.message}/>
                    </div>
        
                    <div className='_span'> 
                        <Controller 
                            name="oldpas" 
                            control={control}
                            rules={{
                                validate: value =>
                                    value==='' && pas.current!='' || err.req
                            }}
                            render={({field:{onChange,value}})=>
                                <PasInput 
                                    label="Altes Kennwort" 
                                    onChange={onChange}
                                    value={value}/>
                            }
                        />
                        <Errors error={errors?.oldpas?.message}/>
                    </div>
        
                    <div className=' _span'>
                        <Controller 
                            name="pas" 
                            control={control}
                            rules={{
                                pattern:{
                                    value:/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                                    message:err.pas
                                }
                            }}
                            render={({field:{onChange,value}})=>
                                <PasInput 
                                    label="Neues Passwort" 
                                    onChange={onChange}
                                    value={value}/>
                            }
                        />
                        <Errors error={errors?.pas?.message}/>
                    </div>
        
                    <div className='_span'>
                        <Controller 
                            name="repas" 
                            control={control}
                            rules={{
                                validate: value =>
                                    value === pas.current || err.repeat
                            }}
                            render={({field:{onChange,value}})=>
                                <PasInput 
                                    label="Passwort bestätigen" 
                                    onChange={onChange}
                                    value={value}/>
                            }
                        />
                        <Errors error={errors?.repas?.message}/>
                    </div> 
                    <div className='_span'>
                        <Controller 
                            name="spam"
                            control={control}
                            render={({field:{onChange,value}})=>
                                <CheckboxItem 
                                    active={value}
                                    value="spam"
                                    toggle={()=>onChange(!value)}>
                                    Ich möchte den FreelanceHunter Newsletter abonnieren.
                                </CheckboxItem>
                            }
                        />
                    </div>
                    <div className='_span'>
                        <Controller 
                            name="info"
                            control={control}
                            render={({field:{onChange,value}})=>
                                <CheckboxItem 
                                    active={value}
                                    value="info"
                                    toggle={()=>onChange(!value)}>
                                    Ich bestätige die Richtigkeit die Information.
                                </CheckboxItem>
                            }
                        />
                    </div>
                    <div>
                        <div className="button button-primary" onClick={()=>setShow(true)}>Konto löschen</div>
                    </div>
                </div>
            </div>
        
            <div className="fr-cf-save">
                <button type="submit" className="button button-primary">Speichern</button>
            </div>
        </div>
    </form>
    )
}

export {Config}