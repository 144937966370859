import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Top } from "./ProjDet/Top";

import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";

import { Loading } from "../../components/Loading";

import { Company } from "./ProjDet/Company";
import { Info } from "./ProjDet/Info";

const ProjDet = () => {
    const {id} = useParams();
    const [data,setData]=useState(null);

    const {user,setModal} = useLogin();
    const axios = useAxios(user);

    var navigate=useNavigate();
    const goBack = () => navigate(-1);

    // if id correct, firm is active
    useEffect(()=>{
        if(isNaN(parseInt(id))){
            //<Navigate to="/proj/" replace/>
        }else{
            axios.get('/site/projDet/',{params:{id:id}})
                .then(res=>res.data)
                .then(data => {
                    data.res
                    ?setData(data.res)
                    :window.location.href="/proj/";
                });
        }
    },[]);

    return (
        data?
        <div className="other-det">
            <Top data={data} user={user} axios={axios} setModal={setModal}/>

            <div className="other-det-content page-block _small">
                <Info data={data}/>
                <Company data={data}/>
            </div>
        </div>:<Loading/>
    )
}
export {ProjDet};