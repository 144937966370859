import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Navi } from "../../../components/Navi";
import { Like } from "../../../components/Like";
import { Share } from "../../../components/Share";
import { Contacts } from "../Contacts";
import { Rat } from "../../../components/Rat";
import { RatItem } from "../../../components/RatItem";

const Top = ({ data, user, axios, setModal }) => {
  // navigation
  const navi = [
    { name: "Home", url: "/" },
    { name: "Firmen", url: "/firms" },
    { name: data.companyName, url: "/" },
  ];

  const navigate = useNavigate();

  // back
  // var navigate=useNavigate();
  // const goBack = () => navigate(-1);
  var location = useLocation();
  const from = location.state?.from || 0;
  const back = ["Zurück", "Zurück zum Projekt", "Zurück zur Bearbeitung"];

  // http patern
  var pattern = /^((http|https|ftp):\/\/)/;

  // show modal
  const [show, setShow] = useState(false);

  // email show
  const [emailShow, setEmail] = useState(false);

  // to={location.state?.back || '/firm'}
  // state={{from:1,back:`/firm/${data.id}`}}

  // download page as
  const downloadPDF = () => {
    user
      ? axios
          .get("/doc/pdf/", {
            params: { id: data.id, type: 2 },
            responseType: "blob",
          })
          .then((res) => {
            var url = window.URL.createObjectURL(res.data),
              link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${data.companyName}.pdf`);
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
          })
      : setModal({ open: true, st: "mn" });
  };

  // rat
  const [points, setRat] = useState(data.rat);

  return (
    <div className="pr">
      <Contacts id={data.id} setShow={(show) => setShow(show)} show={show} />

      <div className="page-block _small">
        <div className="pr-top">
          <div>
            <Navi list={navi} />
          </div>
          <div className="back font-button1-16" onClick={() => navigate(-1)}>
            {back[from]}
          </div>
        </div>

        <div className="pr-info">
          <div>
            <div className="pr-grid">
              <div>
                <div className="logo _2d">
                  {data.logo !== "" ? (
                    <img src={data.logo} alt="Logo" />
                  ) : (
                    <div className="icons _user _black _big _opacity"></div>
                  )}
                </div>
              </div>
              <div>
                <div className="pr-title">{data.companyName}</div>
                {points ? <RatItem points={points} /> : ""}
                <div className="pr-sub">
                  <div className="pr-number">{data.titleText}</div>
                </div>
                <div className="pr-icons">
                  {data.map ? (
                    <div className="pr-item">
                      <div className="icons _map _small"></div>
                      <div className="pr-item-text">{data.map}</div>
                    </div>
                  ) : (
                    ""
                  )}
                  {data.email ? (
                    <div className="pr-item">
                      <div className="icons _email _small"></div>
                      {emailShow ? (
                        <a
                          href={`mailto:${data.email}`}
                          target="_blank"
                          rel="noreferrer"
                          className="href pr-item-text"
                        >
                          {data.email}
                        </a>
                      ) : (
                        <div
                          onClick={() => setEmail(true)}
                          className="href pr-item-text"
                        >
                          E-Mail Adresse anzeigen
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {data.web ? (
                    <div className="pr-item">
                      <div className="icons _web _small"></div>
                      <a
                        href={
                          pattern.test(data.web)
                            ? data.web
                            : `https://${data.web}`
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="pr-item-text"
                      >
                        {data.web}
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  {data.phone ? (
                    <div className="pr-item">
                      <div className="icons _phone _small"></div>
                      <div className="pr-item-text">{data.phone}</div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="pr-right">
            <div className="pr-right-icons">
              <Rat id={data.id} type={1} setRat={setRat} />
              <Share link={`/firm/${data.id}`} />
              <Like id={data.id} type="2" like={data.l} />
            </div>
            <div className="pr-right-buttons">
              {user?.type !== 1 ? (
                <div
                  className="button button-primary"
                  onClick={() =>
                    user ? setShow(true) : setModal({ open: true, st: "mn" })
                  }
                >
                  Kontaktieren
                </div>
              ) : (
                <></>
              )}

              <div
                className="button button-small _download"
                onClick={() => downloadPDF()}
              >
                Herunterladen als PDF
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { Top };
