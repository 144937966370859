import { useEffect, useRef, useState } from 'react'
import { CirclesItem } from './CirclesItem'
import {useScroll} from '../../../hook/useScroll';
import { useLogin } from '../../../hook/useLogin';
import useAxios from '../../../hook/useAxios';
const Circles = () =>{

    const el = useRef();
    const [animate,setAnimate]=useState(false);
    const [offset, setOffset] = useState(0);

    const {user} = useLogin();
    const axios = useAxios(user);

    const scroll=useScroll();
    
    const [graf,setGraf] = useState()

    useEffect(()=>{
        axios.get('/site/graf/')
        .then(res=>res.data)
        .then(data=>setGraf(data.res));
    },[])

    useEffect(()=>{
        setOffset(el.current.offsetTop);
        if(scroll.middle>offset){
            setAnimate(true);
        }
    },[scroll])
    
    return (
        <div className="page-content _dark">
            <div className="home-graf" ref={el}>
                <div className="page-block">
                    
                    <div className="home-graf-content">
                        
                        {graf?graf.map((item,i)=>
                            <CirclesItem 
                                {...item} 
                                animate={animate} 
                                key={i}/>
                        ):<></>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export {Circles}