import { useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { MesDel } from "../../../components/MesDel/MesDel";
import { MesMail } from "../../../components/MesMail/MesMail";
import { Mes } from "./Mes";

const PayItem = ({item,del,pay}) =>{
    
    const [show,setShow] = useState(false);
    const [remove,setRemove] = useState(false);
    const [mail,setMail] = useState(false);

    const status=[
        {'style':'_wait','text':'Warten'},
        {'style':'_free','text':'Verarbeitet'},
    ]

    return (
        <tr className="table-row">
            <td>
                <Link 
                    to={item.type?`/firm/${item.idUser}`:`/freelance/${item.id}`}
                    target="_blank"
                    className="table-image">
                    <div className="table-image-ico">
                        {item.ava
                            ?<img src={item.ava} alt="Foto"/>
                            :<div></div>
                        }
                    </div>
                    <div>
                        <div className="table-bold">{item.name}</div>
                        <div>{item.addInfo}</div>
                    </div>
                </Link>
            </td>
            <td>
                <div className={`label font-button2-13 ${status[item.pay].style}`}>{status[item.pay].text}</div>
                <div className="table-data">
                    <span>Gesendet</span>
                    <div>{item.dataSend}</div>
                </div>
            </td>
            <td>
                <div>
                    <div className="table-bold">{item.tarif}</div>
                    <div className="table-data">
                        {item.sum} €/Jahr
                    </div>
                </div>
            </td>
            <td>
                {show?<Mes setShow={setShow} pay={pay} id={item.id}/>:<></>}
                {mail?<MesMail setShow={setMail} id={item.id}  topic="Requisiten"/>:<></>}  
                <ReactTooltip />
                <MesDel setShow={setRemove} show={remove} del={()=>{setRemove(false);del()}}>
                    <p>Anwendung löschen?</p>
                </MesDel>                      
                <div className="table-icons"></div>
                    <div 
                        className="icons _send"
                        data-help="Send" data-tip="Senden" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>setMail(true)}>
                    </div>
                    <div 
                        className="icons _period"
                        data-help="Plonge" data-tip="Fortsetzen" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>setShow(true)}>
                    </div>
                    <div 
                        className="icons _del" 
                        data-help="Delete" data-tip="Löschen" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>setRemove(true)}>
                    </div>
            </td>
        </tr>
    )
}

export {PayItem}