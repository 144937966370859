const Work = ({list}) =>{

    function createMarkup(html) {
        return {__html: html};
    }

    return (
        list.length?<div>
            <div className="title">Werdegang</div>
            <div className="other-det-ls">
                {list.map((item,i)=>
                    <div className="other-det-lsitem"  key={i}>
                        <div className="other-det-lstitle">
                            {item.startData}{item.endData?' - '+item.endData:''} <span>{item.companyName}</span>
                        </div>
                        <div className="other-det-lssub">{item.companyCat}</div>
                        <div className="other-det-lssub">{item.position}</div>
                        <div className="other-det-lstext" dangerouslySetInnerHTML={createMarkup(item.descrText)} />
                    </div>
                )}
            </div>
        </div>:''
    )
}

export {Work}