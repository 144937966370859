import { useState,useEffect} from "react";

import useAxios from "../../hook/useAxios";
import {useLogin} from "../../hook/useLogin";

import { MailItem } from "./Mail/MailItem";

const PageSize=5;

const Mail = () => {

    const {user,setUser}=useLogin();
    const axios=useAxios(user);

    const [data,setData]=useState(null);

    useEffect(()=>{
        axios.get('/mail')
        .then(res=>res.data)
        .then(data=>setData(data.res));
        //setTimeout(()=>setUser({...user,mail:0}),1000);
        setUser({...user,mail:0});
    },[])

    const delMes = mail =>{
        setData(data.filter(item=>item.id!==mail.id));
        axios.delete('/mail/',{params:{id:mail.id}});
    }

    return (
    <>
    <div className="fr-ps">
        <table className="table">
            <thead>
                <tr className="table-title">
                    <td>Absender</td>
                    <td>Betreff</td>
                    <td>Datum</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {data
                ?data.map(
                    (item,i)=>
                        <MailItem 
                        key={i} 
                        item={item} 
                        del={()=>delMes(item)}/>
                    )
                :<tr></tr>}
            </tbody>
        </table>
        </div>

        {/* <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={data.length}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
        /> */}</>
    )
}

export {Mail}