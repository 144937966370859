import "./PayMes.scss";
import {Modal} from '../Modal';
import { Link } from "react-router-dom";
import { useLogin } from "../../hook/useLogin";

const PayMes = ({setShow,show}) =>{

    const {user} = useLogin();
    const link=user?.type==0?'free':'firm';

    return (
        show?<Modal setShow={setShow}>
            <div className="proj-mes">
                <div>
                    <div className="font-h5b-18">Damit Sie alle Funktionen FreelanceHunter nutzen können, müssen Sie Premium Mitgliedschaft haben.</div>
                </div>
                <div>
                    <Link to={`/services/${link}`} className="button button-primary _green _width">Account Upgraden</Link>
                </div>
            </div>
        </Modal>:''
    )
}
export {PayMes}