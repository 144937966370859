import {useLogin} from "../../../hook/useLogin";

import {Swiper,SwiperSlide} from "swiper/react"
import { useState } from "react";
import { Link } from "react-router-dom";
import { FreeListItem } from "./FreeListItem";

const FreeList = ({list}) =>{

    const {user,setModal}=useLogin();
    const [active,setActive]=useState(null);

    return (
        list?
            <div className="page-block">
                <div className="page-content-text">
                    <div className="font-h1b-50">Neue Freelancer</div>
                    <div className="font-text1-32 _half">
                        Jeden Tag finden viele Experten hier ihr neues Projekt
                    </div>
                </div>

                <div className="swiper-block">
                    <Swiper breakpoints={{
                            400: {
                                slidesPerView: 3,
                                slidesPerGroup:3
                            },
                            800: {
                                slidesPerView: 5,
                                slidesPerGroup:5
                            },
                            1000: {
                                slidesPerView: 6,
                                slidesPerGroup:6
                            }
                        }}
                        pagination={{ clickable: true }}
                        centeredSlides={false}
                        centerInsufficientSlides={true}
                        lazy={true}>
                        {list.map((item,i)=>
                            <SwiperSlide key={item.id}>
                                <FreeListItem link={`/freelance/${item.id}`}>
                                    <div className={`home-freelancer-item ${active===i?'active':''}`} 
                                        onMouseOver={()=>setActive(i)}  
                                        onMouseOut={()=>setActive(null)}>
                                        <div className="home-freelancer-foto">
                                            <img src={item.img} alt="foto"/>
                                            <div className="home-freelancer-status">
                                                <div className={`label font-button2-13 ${item.status.style}`}>
                                                    {item.status.text}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="home-freelancer-name font-text">{item.name}</div>
                                        <div className="home-freelancer-job">{item.titleText}</div>
                                    </div>
                                </FreeListItem>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>

                {user && 1?'':<div onClick={()=>setModal({open:true,st:'fr'})} className="button button-secondary">Registrieren</div>}

            </div>

        :''
    )
}

export {FreeList}