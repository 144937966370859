import { useState } from "react";

const RatStars = ({ p }) => {
  const [hover, setHover] = useState(0);
  const [points, setRating] = useState(0);

  // return to parent points
  const setPoints = (index) => {
    // inside status
    setRating(index);
    // outside
    p(index);
  };

  return (
    <div className="rat-mes-stars">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <div key={index}>
            <div
              className={`icons _star ${
                index <= (hover || points) ? "active" : ""
              }`}
              onClick={() => setPoints(index)}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(points)}
            ></div>
          </div>
        );
      })}
    </div>
  );
};

export { RatStars };
