import { useState } from 'react';
import {Controller, useForm} from 'react-hook-form';

import useAxios from '../../../hook/useAxios';

import { Errors } from '../../../components/Errors';
import { CheckboxItem } from '../../../components/CheckboxItem';

const Form = () =>{

    const axios = useAxios();

    const [res,setRes] = useState(false);

    const err={
        req:"bitte ausfüllen"
    }

    const onSubmit = data => {
        axios.post('/site/email',data)
        .then(()=>setRes(true));
    }

    const { 
        register,
        handleSubmit,
        formState: {errors},
        control,
        reset
    } = useForm();
    
    return (
        <div className="contacts-part">

            {res
            ?<div className='contacts-res'>
                <div className="contacts-title font-h2b-32">
                    <div>Ihre Nachricht wurde gesendet</div>
                    <div 
                        className='button button-primary'
                        onClick={()=>{setRes(false);reset()}}>
                        schicke mehr
                    </div>
                </div>
            </div>
            :<form onSubmit={handleSubmit(onSubmit)}>
                <div className="contacts-form">
                    <div className="contacts-title font-h2b-32">Kontakt</div>
                        <div className="contacts-form-input">
                            <div className="inputs-block _label">
                                <label className="font-text5-14">Name</label>
                                <input 
                                    {...register("name",{
                                        required:err.req
                                    })}
                                    className="inputs off"/>
                            </div>
                            <Errors error={errors?.name?.message}/>
                        </div>
                        <div className="contacts-form-input">
                            <div className='inputs-block _label'>
                                <label htmlFor="email" className="font-text5-14">Email</label>
                                <input 
                                    {...register("email",{
                                        required:err.req
                                    })}
                                    className="inputs off"
                                    />
                            </div>
                            <Errors error={errors?.email?.message}/>
                        </div>
                        <div className="contacts-form-input">
                            <div className="inputs-block _label">
                                <label htmlFor="email" className="font-text5-14">Telefonnummer</label>
                                <input 
                                    {...register("phone",{
                                        required:err.req
                                    })}
                                    className="inputs off"
                                    />
                            </div>
                            <Errors error={errors?.phone?.message}/>
                        </div>
                        <div className='contacts-form-input'>
                            <div className="inputs-block _label">
                                <label htmlFor="botscraft" className="font-text5-14">Nachricht</label>
                                <textarea 
                                    {...register("text",{
                                        required:err.req
                                    })}
                                    className="inputs off"                        
                                    placeholder="Die Nachricht darf 100 Zeichen nicht überschreiten"
                                    />
                            </div>
                            <Errors error={errors?.text?.message}/>
                        </div>
                        <div className="contacts-form-input">
                        <Controller 
                                name="agree"
                                control={control}
                                rules={{required:err.req}}
                                render={({field:{onChange,value}})=>
                                <CheckboxItem 
                                    active={value}
                                    value="agree"
                                    toggle={()=>onChange(!value)}>
                                    Hiermit akzeptieren Sie unsere <a href="/page/agb/" target="_blank" rel="nofollow">AGB</a> und <a href="/page/date" target="_blank" rel="nofollow">Datenschultzbestimmungen</a>
                                </CheckboxItem>
                                }
                            />
                            <Errors error={errors?.agree?.message}/>
                        </div>
                        <div className="contacts-form-input">
                            <div className="contacts-form-bottom">
                                <div>
                                    <button type="submit" className="button button-primary">Senden</button>
                                </div>                
                                <div className="contacts-form-small">
                                    Information dazu nach Artikel 13 DSGVO
                                </div>
                            </div>
                        </div>
                </div>
            </form>}

        </div>
    )
}

export {Form}