const CharItem = ({active,disabled,letter,setLetter}) => {
    return (
        <div 
            className={`char-item font-button1-16 _font-white 
            ${disabled ? 'disabled' : ""} 
            ${active===letter ? 'active' : ''}`}
            onClick={()=>{setLetter(letter)}}>
            {letter}
        </div>
    )

}

export {CharItem}