import { Link } from "react-router-dom"
import "swiper/css/grid";
import { Swiper, SwiperSlide } from "swiper/react"
import { useLogin } from "../../../hook/useLogin";

const TopFirm = ({list}) =>{

    //const chunk = (a,n)=>[...Array(Math.ceil(a.length/n))].map((_,i)=>a.slice(n*i,n+n*i));

    const {user,setModal} = useLogin();

    // speed = {1000}
    // autoplay={{ delay: 7000 }}

    const slideCount = state =>{
        const res = Math.ceil(list.length/state) || 1;
        console.log(res,list.length,state);
        return res;
    }

    return (
        list?
        <div className="page-content _white">
            <div className="page-block">
            
                <div className="page-content-text">
                    <div className="font-h1b-50">Top Firmen</div>
                    <div className="font-text1-32">Unsere Top Firmen für Sie:</div>
                </div>

                <Swiper 
                    className="home-company"
                    pagination={{clickable: true }}

                    centeredSlides={false}
                    centerInsufficientSlides={true}
                    centeredSlidesBounds={false}
                    loop={true}

                    breakpoints={{
                        300:{
                            slidesPerView:3,
                            slidesPerGroup:9,
                            grid:{rows:3,fill:'row'}
                          },
                        400:{
                            slidesPerView:4,
                            slidesPerGroup:12,
                            grid:{rows:4,fill:'row'}
                        },
                        800:{
                            slidesPerView:6,
                            slidesPerGroup:18,
                            grid:{rows:6,fill:'row'}
                        },
                        1000: {
                            slidesPerView:8,
                            slidesPerGroup:24,
                            grid:{rows:8,fill:'row'}
                        }
                        }}>
                    {list.map((item,i)=>
                        <SwiperSlide key={i}>
                            {user?<Link 
                                to={`/firm/${item.id}`} 
                                className="home-company-item" key={item.id}>
                                <img src={item.img} alt="logo"/>
                            </Link>:<div 
                                onClick={()=>setModal({open:true,st:'mn'})}
                                className="home-company-item" key={item.id}>
                                <img src={item.img} alt="logo"/>
                            </div>}
                        </SwiperSlide>
                    )}</Swiper>
            
            </div>

        </div>:''
    )
}

export {TopFirm}