import { useState } from "react"
import { Link } from "react-router-dom";
import {SwiperSlide } from "swiper/react"
import { useLogin } from "../../../hook/useLogin";

const FreeListItem = ({children,link}) =>{

    const [active,setActive]=useState();
    const {user,setModal}=useLogin();

    return (
        user
        ?<Link 
            to={link} 
            className="home-freelancer-container">{children}</Link>
        :<div 
            className="home-freelancer-container" 
            onClick={()=>setModal({open:true,st:'mn'})}>{children}</div>
    )
}

export {FreeListItem}