import { useState } from "react";
import { Like } from "../../components/Like";
import { Rat } from "../../components/Rat";
import { RatItem } from "../../components/RatItem";
import { Share } from "../../components/Share";
import { TagsList } from "../../components/TagsList";
import { Item } from "./Item";

const FreeItem = ({item,block}) =>{

    const [points,setRat]=useState(item.rat);

    return (
        <Item link={`/freelance/${item.id}`} block={block}>
            <div className="item-logo _foto">
                {item.foto
                ?<img src={item.foto} alt="foto"/>
                :<div className="icons _user _black _big _opacity"></div>}
            </div>
            <div className="item-line"></div>
            <div className="item-info">
                <div className="item-info-top">
                    <div>
                        <div className="item-freelancer-title">
                            <div className="item-title font-h4b-20">{item.name}</div>
                            <div className="item-freelancer-status">
                                <div className={`label font-button2-13 ${item.status.style}`}>
                                    {item.status.text}
                                </div>
                                {item.status.val===22
                                    ?<div className="item-freelancer-data font-text4-16">{item.status.from}</div>
                                    :''
                                }
                            </div>
                        </div>
                        {points?<RatItem points={points}/>:''}
                    </div>
                    <div>
                        <div className="item-icons">
                            <Rat id={item.id} type={0} setRat={setRat}/>
                            <Share link={`/freelance/${item.id}`}/>
                            <Like id={item.id} type="0" like={item.l}/>
                        </div>
                    </div>
                </div>

                <div className="item-freelancer-work font-text4-16">
                    {item.cat.length
                        ?item.cat.map((item,i)=>
                            <div key={i}>{item.name}</div>
                            //<Link to="/freelances" state={{cat:item.id}} key={i}>{item.name}</Link>
                        )
                        :''}
                </div>
                {/* <div className="item-freelancer-skils font-text6-13">
                    {item.list.map((litem,i)=>
                        <div key={i}>{litem.name}</div>
                    )}
                </div> */}

                {item.list.length
                ?<TagsList title="Skils" tags={item.list}/>
                :<></>}

                <div className="item-freelancer-live font-text4-16">
                    {item.map && <div className="item-freelancer-info">
                        <div className="icons _map _small _opacity"></div>
                        <div>{item.map}</div>
                    </div>}
                    <div className="item-freelancer-info">
                        <div className="icons _clock _small _opacity"></div>
                        <div>{item.last}</div>
                    </div>
                </div>
            </div>
        </Item>
    )
}

export {FreeItem}