import { useState } from "react";

const PasInput = ({label,onChange,value}) =>{

    const [hidePas,setShowPas] = useState(true);

    return (
        <div className="inputs-block _label">
            <label>{label}</label>
            <input
                value={value}
                onChange={onChange}
                type={hidePas?'password':'text'}
                className="inputs off _icon" 
                autoComplete="off"/>
            <div className="icons _eye _small inputs-ico"
                onClick={()=>{setShowPas(!hidePas)}}>
            </div>
        </div>
    )
}

export {PasInput}