import { useEffect, useState } from "react"
import { MySelect } from "../../components/MySelect";
import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";
import { PayItem } from "./Pay/PayItem"

const Pay = () =>{

    const type = [
        {value:0,label:'Warten'},
        {value:1,label:'Verarbeitet'},
        {value:2,label:'Alle Arten'}
    ]

    // data state
    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [search,setSearch] = useState({type:0});

    const {user,setModal}=useLogin();
    const axios=useAxios(user);

    useEffect(()=>{
        axios.get('/admin/pay',{params:search})
        .then(res=>res.data)
        .then(data=>{
            setData(data.res);
            setRefresh(false);
        });
    },[refresh]);

    const payDel = id =>{
        setData(data.filter(item=>item.id!==id));
        axios.delete('/admin/pay',{params:{id:id}});
    }

    const paySet = id =>{
        data.map(item=>item.id==id?item.pay=1:0);
        setData([...data]);
    }

    return (
        <>
            <div className="table-search">
                <div>
                    <MySelect 
                        value={type.filter(item=>item.value===search.type)}
                        onChange={res=>setSearch({...search,type:res.value})} 
                        options={type} 
                        placeholder="Alle Arten"
                        isSearchable={false}/>
                </div>
                <div>
                    <div className="button button-primary _form" onClick={()=>setRefresh(true)}>Suche eingrenzen</div>
                </div>
            </div>

            <table className="table">
                <thead>
                    <tr className="table-title">
                        <td>Absender</td>
                        <td>Status</td>
                        <td>Tarif</td>
                        <td>Datum</td>
                    </tr>
                </thead>
                <tbody>
                    {data
                    ?data.map((item,i)=>
                    <PayItem 
                        key={i} 
                        item={item} 
                        del={()=>payDel(item.id)}
                        pay={()=>paySet(item.id)}/>):<tr></tr>}
                </tbody>
            </table>
        </>
    )
}

export {Pay}