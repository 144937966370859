import { useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { MesDel } from "../../../components/MesDel/MesDel";

const  FreeItem = ({del,item}) =>{

    const [show,setShow] = useState(false);

    return (
        <tr className="table-row" key={item.id}>
            <td>
                <ReactTooltip />
                <MesDel setShow={setShow} show={show} del={()=>{setShow(false);del()}}>
                    <p>Folgen Sie der Firma nicht mehr?</p>
                </MesDel>
                <div className="table-image">
                    <Link 
                        to={`/freelance/${item.id}`} 
                        target="_blank" 
                        className="table-image-ico">
                        {item.img
                            ?<img src={item.img} alt="Foto"/>
                            :<div></div>
                        }
                    </Link>
                    <div>
                        <div className="table-bold">{item.name}</div>
                        <div className="font-text5-14">{item.catName}</div>
                    </div>
                </div>
            </td>
            <td>
                <div
                    className={`label font-button2-13 ${item.status.style}`}>
                    {item.status.text}
                </div>
                <div className="font-text5-14">
                    {item.status.val===22?<div>{item.status.from}</div>:''}
                </div>
            </td>
            <td>
                <div 
                    className="button button-line" 
                    onClick={()=>setShow(true)}>
                    nicht mehr folgen
                </div>
            </td>
        </tr>
    )
}
export {FreeItem};