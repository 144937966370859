import { useState } from "react";
import {Swiper,SwiperSlide } from "swiper/react"

import { FeedMes } from "../../../components/Feedback/FeedMes";
import { useLogin } from "../../../hook/useLogin";

const Feedback = ({list}) => {

    const {user} = useLogin();
    const [show,setShow] = useState(false);
    
    return (
        list?
        <div className="page-content">
            
            <div className="page-content-text">
                <div className="font-h1b-50">Kundenstimmen und Referenzen </div>
                <div className="font-text1-32">Meinungen unserer Nutzer :</div>
            </div>

            <div className="swiper-block">
                <Swiper 
                    speed = {1000}
                    autoplay={{ delay: 7000 }}
                    pagination={{ clickable: true }}
                    spaceBetween={0}
                    slidesPerView={1}>
                    {list.map((item,i)=>
                        <SwiperSlide key={i}>
                            <div className="home-cart-item">
                                <div className="home-freelancer-item">
                                    <div className="home-freelancer-foto">
                                        <img src={item.img} alt="Foto"/>
                                    </div>
                                    <div className="home-freelancer-name font-text">{item.name}</div>
                                    <div className="home-freelancer-job">{item.info}</div>
                                </div>
                                <div className="home-cart-text font-text3-18">
                                    {item.text}
                                </div>
                            </div>
                        </SwiperSlide>)}
                </Swiper>
            </div>
            
            {show?<FeedMes setShow={setShow}/>:''}
            {user?<div onClick={()=>setShow(true)} className="button button-secondary">Rezension schreiben</div>:''}

        </div>:''
    )
}

export {Feedback}