import ReactDOM from "react-dom";
import {useLocation} from "react-router-dom";
import './Login.scss';

import {Main} from './Main';
import {Firm} from './Firm';
import {Free} from './Free';
import {Choise} from './Choise';

// take provider data
import {useLogin} from "../../hook/useLogin";
import { Recovery } from './Recovery';

const Login = () => {

    // user hook
    const {modal,setModal} = useLogin();

    //location > state > from > pathname
    const location = useLocation();
    const fromPage=location.pathname || '/account/';

    // close modal fn
    const closeLogin = () =>{
        setModal({open:false,st:''});
    }

    return ReactDOM.createPortal(
        <div className="overflow login-overflow" onClick={closeLogin}>
            <div className="login">
                <div className="login-block" onClick={(e)=>e.stopPropagation()}>
                    {modal.st==='mn' && <Main fromPage={fromPage}/>}
                    {modal.st==='ch' && <Choise/>}
                    {modal.st==='fi' && <Firm fromPage={fromPage}/>}
                    {modal.st==='fr' && <Free fromPage={fromPage}/>}
                    {modal.st==='re' && <Recovery fromPage={fromPage}/>}
                    {/* {modal.st==='pf' && <Proof fromPage={fromPage}/>} */}
                </div>
            </div>
        </div>,
        document.getElementById('modal')
    );

    // <div style={style}>
    //         <h1>Login</h1>
    //         <form onSubmit={handleSubmit}>
    //             <label>Name:</label>
    //             <input name="username" type="text" classNameName="inputs _line"/>
    //             <button type="submit" classNameName="button button-primary">Login</button>
    //         </form>
    //         {/* {fromPage} */}
    //     </div>
}

export {Login}