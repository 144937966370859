import { useState } from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { MesDel } from "../../../components/MesDel/MesDel";
import { MesMail } from "../../../components/MesMail/MesMail";
import { Mes } from "./Mes";

import useAxios from '../../../hook/useAxios';
import {useLogin} from "../../../hook/useLogin";

const UsersItem = ({item,del,set,tar}) =>{

    // user enter
    const {signIn} = useLogin();
    const axios=useAxios();

    // enter user
    const ChangeUser = data => {

        axios.get('/site/enter',{params:data})
        .then(res=>res.data)
        .then(data => {
            // no user, user problem
            if(data.error){
                console.log(data.error);
            }else{
                if(data.res){
                    signIn(data.res,'/account/main');
                }
            }
        })
    }

    // modal
    const [show,setShow] = useState(false);
    const [remove,setRemove] = useState();
    const [active,setActive] = useState();
    const [mail,setMail] = useState(false);

    const [pas,setPas] = useState(false);

    const tarif=['','BS','PR'];
    const style=['','_wait','_free'];
    
    // stop event
    const pasHandle = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    // show hide pas
    const showHandle = () => {
        setPas(!pas);
    }
    
    return (
        <tr className="table-row" key={item.id}>
            <td>
                <div className="table-image">
                    <Link 
                        to={item.type?`/firm/${item.id}`:`/freelance/${item.id}`}
                        className="table-image-ico"
                        target="_blank">
                        {item.ava
                            ?<img src={item.ava} alt="Icons"/>
                            :<div className="table-image-user"></div>
                        }
                    </Link>
                    <div className="table-data">
                        <div className="table-bold">{item.name}</div>
                        <div>{item.addInfo}</div>
                        <div>{item.login}</div>
                        <div className="table-flex" onClick={pasHandle}>
                            <div>{pas?item.pas:item.pas.replaceAll(/./g,'*')}</div>
                            <div className="icons _eye _vsmall" onClick={showHandle}></div>
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <div>
                    {item.payd?<div className={`label font-button2-13 ${style[item.tarif]}`}>{tarif[item.tarif]} {item.payTill}</div>:''}
                    <div className="table-data">
                        <span>Datum der Änderung: </span>
                        <div>{item.editData}</div>
                    </div>
                </div>
            </td>
            <td>
                <ReactTooltip/>
                {show?<Mes setShow={setShow} id={item.id} tar={tar}/>:<></>}
                {mail?<MesMail setShow={setMail} id={item.id}  topic="Überprüfe das Ergebnis"/>:<></>}
                <MesDel setShow={setRemove} show={remove} del={()=>{setRemove(false);del()}}>
                    <p>Anfrage zur Projektaktivierung löschen?</p>
                </MesDel>
                <MesDel setShow={setActive} show={active} del={()=>{setActive(false);set()}} title="Bestätigen">
                    <p>Aktivieren Sie das Projekt und veröffentlichen Sie es auf der Website?</p>
                </MesDel>
                <div className="table-icons">
                    {item.proof?<div 
                        className="icons _check"
                        data-help="Confirm" data-tip="Bestätigen" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>setActive(true)}>
                    </div>:<div></div>}
                    <div 
                        className="icons _enter"
                        data-help="Enter" data-tip="Ausfahrt" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>ChangeUser({login:item.login,pas:item.pas})}>
                    </div>
                    <div 
                        className="icons _send"
                        data-help="Send" data-tip="Senden" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>setMail(true)}>
                    </div>
                    <div 
                        className="icons _period"
                        data-help="Plonge" data-tip="Fortsetzen" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>setShow(true)}>
                    </div>
                    <div className="icons _del"
                        data-help="Delete" data-tip="Löschen" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>setRemove(true)}>
                    </div>
                </div>
            </td>
        </tr>
    )
}

export {UsersItem}