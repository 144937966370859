import { useState } from "react";
import DatePicker from "../../../hook/useDatepicker";

const WorkAdd = ({setData,data}) => {

    const def={
        id:null,
        workData:{
            start:null,
            end:null
        },
        companyName:'',
        position:'',
        descrText:''
    };

    // add
    const addWork = () =>{
        let newItem=data.work.concat([def]);
        setData({...data,work:newItem});
    }


    return (<div className="section-block">
        {/* <div className="fr-fr-s">
            <div className="inputs-block _label">
                <label>Zeitraum</label>
                <DatePicker 
                    onChange={
                        ([start,end]) => 
                        setItem({...item,workData:{start:start,end:end}})
                    } 
                    onChangeRaw={(e) => e.preventDefault()}
                    selected={item.workData.start}
                    startDate={item.workData.start}
                    endDate={item.workData.end}
                    maxDate={Date.now()}

                    className="myDatepicker"
                    dateFormat="dd LLLL yyyy"
                    selectsRange
                />
                <div className="icons _cal _small inputs-ico"></div>
            </div>
            <div className="inputs-block _label">
                <label>Firma</label>
                <input 
                    value={item.companyName}
                    onChange={e=>
                        setItem({...item,companyName:e.target.value})
                    }
                    placeholder="Apple" 
                    type="text" 
                    className="inputs off" 
                    autoComplete="off"/>
            </div>
            <div className="inputs-block _label">   
                <label>Position / oder Projekt</label>
                <input 
                    value={item.position}
                    onChange={e=>
                        setItem({...item,position:e.target.value})
                    }
                    placeholder="Position"
                    type="text"
                    className="inputs off" 
                    autoComplete="off"/>
            </div>
            <div className="inputs-block _label">
                <label>Kurzbeschreibung</label>
                <textarea 
                    value={item.descrText}
                    onChange={e=>
                        setItem({...item,descrText:e.target.value})
                    }
                    className="inputs off" />
            </div>
        </div> */}
        <div className="button button-small _plus" onClick={()=>{addWork()}}>Position Einfügen</div>
    </div>)
}

export {WorkAdd}