import {Controller} from 'react-hook-form';
import { MySelect } from "../../../components/MySelect";
import { Errors } from "../../../components/Errors";

const Lang = ({setData,data,type,form}) => {

    const err={
        req : 'bitte ausfüllen'
    }

    const delLang = index=>{
        // delete by index
        let newItem=data.lang.filter((item,key)=>key!==index);
        setData({...data,lang:newItem});
    }

    return (
        data.lang.map((item,key)=>{
        const langName=`lang.${key}`;
        return <div className="section-block" key={key}>
            <input type="hidden" {...form.register(`${langName}.id`)} />
            <div className="fr-fr-s _two">
                <div>
                    <div className="inputs-block _label">   
                        <label>Sprache</label>
                        <input
                            {...form.register(`${langName}.lang`,{required:err.req})}
                            placeholder="English"
                            className="inputs off" 
                            autoComplete="off"/>
                    </div>
                    <Errors error={form.errors?.lang?.[key]?.lang?.message}/>
                </div>
                <div>
                    <div className="inputs-block _label _select">
                        <label>Niveau</label>
                        <Controller 
                            name={`${langName}.idLevel`}
                            control={form.control}
                            rules={{required:err.req}}
                            render={({field:{onChange,value}})=>{
                                //value=value || 33;
                                return <MySelect 
                                    isSearchable={false}
                                    value={type.filter(item=>item.value===value)}
                                    onChange={e=>onChange(e.value)}
                                    options={type}/>
                                }
                            }
                        />
                    </div>
                    <Errors error={form.errors?.lang?.[key]?.idLevel?.message}/>
                </div>
            </div>
            <div className="button button-small _minus" onClick={()=>{delLang(key)}}>Position löschen</div>

        </div>})
    )
}

export {Lang}