import {useLogin} from "../../hook/useLogin";

const Choise = ({}) => {

    // user hook
    const {setModal} = useLogin();
    
    return (
        <div className="login-content">
            <div className="login-left">
                <div>
                    <div className="login-logo">
                        <img src="/img/logo/logo_big.png" alt="Logo"/>
                    </div>
                    <div className="login-text">Willkommen bei Freelancerweb</div>
                </div>
                <div className="login-left-buttom">
                    <div className="login-small-text font-text4">Haben Sie schon einen Account</div>
                    <div 
                        className="href font-button2-13" 
                        onClick={()=>setModal({open:true,st:'mn'})}
                    >Ins Konto einloggen</div>
                </div>
            </div>
            <div>
                
                <div className="login-form">
                    <div className="login-form-input">
                        <div className="login-title _center">Bitte auswählen</div>
                    </div>
                    <div className="login-form-input">
                        <div className="button button-menu" 
                            onClick={()=>setModal({open:true,st:'fr'})}>
                            <div className="icons _menu _user"></div> 
                            Registrieren Sie sich als Freiberufler
                        </div>
                    </div>
                    <div className="login-form-input">
                        <div className="button button-menu" 
                            onClick={()=>setModal({open:true,st:'fi'})}>
                            <div className="icons _menu _company"></div> 
                            Registrieren Sie sich als Firma
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export {Choise}