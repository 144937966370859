import { CharItem } from "./CharItem";

const Chars = ({setLetter,letter,letters}) => {
    
    return (
        letters.map((item,key) =>
            <CharItem 
            key={key}
            active={letter}
            letter={item.letter} 
            disabled={!item.active}  
            setLetter={setLetter}/>
        )
    )
}

export {Chars}