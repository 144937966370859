import { useState } from 'react';
import InputMask from 'react-input-mask';
import {Controller, useForm} from 'react-hook-form';

import DatePicker from '../../../hook/useDatepicker';

import { MySelect } from "../../../components/MySelect";
import { Errors } from '../../../components/Errors';

import { Img } from "../../user/Img";
import { Mes } from './Mes';

const Form = ({data,setUser,user,type,axios}) =>{

    const [mes,setMes] = useState(false);

    const err={
        req : 'bitte ausfüllen',
        max:'Kurzvorstellung / max. 80'
    }

    // contant data
    const showStatus=[
        {label:'Verstecken',value:0},
        {label:'Anzeigen',value:1}
    ]

    // useForm
    const {
        register, 
        handleSubmit,
        control, 
        watch,
        formState: {errors}
    } = useForm({
        defaultValues:data
    });

    //console.log(errors);

    const statusWatcher=watch("status");

    // save
    const onSubmit = data =>{
        axios.post('/free/edit',data)
        .then(setMes(true));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {mes?<Mes setShow={setMes}/>:''}
            <div className="fr-pf">
                <div className="fr-pf-content">

                <Img/>
                    <div className="form _tree">
                        
                        <div className='_span'>
                            <div className="inputs-block _label">
                                <label>Kurzvorstellung</label>
                                <input 
                                    {...register("titleText",{
                                        required:err.req,
                                        maxLength:{
                                            value:80,
                                            message:err.max
                                        }
                                    })}
                                    className="inputs off" 
                                    autoComplete="off"/>
                            </div>
                            <Errors error={errors?.titleText?.message}/>
                        </div>
                    </div>
                    <div className="form _tree">
                        
                        <div className='_span'>
                            <div className="inputs-block _label">
                                <label>Über mich</label>
                                <textarea 
                                    {...register("descrText",{
                                        required:err.req
                                    })}
                                    className="textarea off" 
                                    autoComplete="off"/>
                            </div>
                            <Errors error={errors?.descrText?.message}/>
                        </div>
                    </div>
                        
                    <div className="fr-pf-title">
                        <div className="font-h6b-16">Persönliche Daten</div>
                    </div>

                    <div className="form _tree">
                        
                        <div>
                            <div className="inputs-block _label _select">
                                <label>Anrede</label>
                                <Controller 
                                    name="gender" 
                                    control={control}
                                    rules={{required:err.req}}
                                    render={({field:{onChange,value}})=>
                                        <MySelect 
                                            isSearchable={false}
                                            value={type.gender.filter(item=>item.value===value)}
                                            onChange={e=>onChange(e.value)}
                                            options={type.gender}/>
                                    }
                                />
                            </div>
                            <Errors error={errors?.gender?.message}/>
                        </div>
                        <div>
                            <div className="inputs-block _label">
                                <label>Vorname</label>
                                <input 
                                    {...register("name",{
                                        required:err.req
                                    })}
                                    placeholder='Alex'
                                    onChange={e=>
                                        setUser({...user,name:e.target.value})
                                    }
                                    className="inputs off" 
                                    autoComplete="off"/>
                            </div>
                            <Errors error={errors?.name?.message}/>
                        </div>
                        <div>
                            <div className="inputs-block _label">
                                <label>Nachname</label>
                                <input
                                    {...register("lastName",{
                                        required:err.req
                                    })} 
                                    placeholder='Fuller'
                                    onChange={(e)=>
                                        setUser({...user,lastName:e.target.value})
                                    }
                                    className="inputs off" 
                                    autoComplete="off"/>
                            </div>
                            <Errors error={errors?.lastName?.message}/>
                        </div>
                    </div>

                    <div className="fr-pf-title">
                        <div className="font-h6b-16">Kontakt Information</div>
                    </div>

                    <div className="form">

                        <div>
                            <div className="inputs-block _label _select">
                                <label>Land</label>
                                <Controller 
                                    name="idLand" 
                                    control={control}
                                    rules={{required:err.req}}
                                    render={({field:{onChange,value}})=>
                                        <MySelect 
                                            isSearchable={false}
                                            value={type.lands.filter(item=>item.value===value)}
                                            onChange={e=>onChange(e.value)}
                                            options={type.lands}/>
                                    }
                                />
                            </div>
                            <Errors error={errors?.idLand?.message}/>
                        </div>

                        {/* <div>
                            <div className="inputs-block _label">
                                <label>Nationalität</label>
                                <input 
                                    {...register("nation",{
                                        required:err.req
                                    })}
                                    className="inputs off" 
                                    autoComplete="off"/>
                            </div>
                            <Errors error={errors?.nation?.message}/>
                        </div> */}
                        
                        <div>
                            <div className="inputs-block _label">
                                <label>Stadt</label>
                                <input 
                                    {...register("locationJson.city",{
                                        required:err.req
                                    })}
                                    className="inputs off" 
                                    autoComplete="off"/>
                            </div>
                            <Errors error={errors?.locationJson?.city?.message}/>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <label>PLZ</label>
                                <input 
                                    {...register("locationJson.code",{
                                        required:err.req
                                    })}
                                    className="inputs off" 
                                    autoComplete="off"/>
                            </div>
                            <Errors error={errors?.locationJson?.code?.message}/>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <label>Straße</label>
                                <input 
                                    {...register("locationJson.street",{
                                        required:err.req
                                    })}
                                    className="inputs off" 
                                    autoComplete="off"/>
                            </div>
                            <Errors error={errors?.locationJson?.street?.message}/>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <label>Haus №</label>
                                <input 
                                    {...register("locationJson.build",{
                                        required:err.req
                                    })}
                                    className="inputs off" 
                                    autoComplete="off"/>
                            </div>
                            <Errors error={errors?.locationJson?.build?.message}/>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <label>Telefonnummer</label>
                                <input 
                                    {...register("phone",{
                                        required:err.req
                                    })}
                                    placeholder="+49 456 78 78"
                                    className="inputs off" 
                                    autoComplete="off"/>
                            </div>
                            <Errors error={errors?.phone?.message}/>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <label>Webseite</label>
                                <input 
                                    {...register("web")}
                                    className="inputs off" 
                                    autoComplete="off"/>
                            </div>
                            <Errors error={errors?.web?.message}/>
                        </div>
                    </div>

                    <div className="fr-pf-title">
                        <div className="font-h6b-16">Stunden-/ Tagessatz</div>
                    </div>

                    <div className="form">

                        <div className="inputs-block _span">
                            <div className="fr-pf-time">
                                <div>Tagessatz</div>
                                
                                <div>
                                    <div className="inputs-block _label">
                                        <label>Von</label>
                                        <input 
                                            {...register("dayRateJson.from",{
                                                required:err.req
                                            })}
                                            className="inputs off" 
                                            autoComplete="off"/>
                                        <div className="icons _euro _small inputs-ico"></div>
                                    </div>
                                    <Errors error={errors?.dayRateJson?.from?.message}/>
                                </div>

                                <div>
                                    <div className="inputs-block _label">
                                        <label>Bis</label>
                                        <input 
                                            {...register("dayRateJson.to",{
                                                required:err.req
                                            })}
                                            className="inputs off" 
                                            autoComplete="off"/>
                                        <div className="icons _euro _small inputs-ico"></div>
                                    </div>
                                    <Errors error={errors?.dayRateJson?.to?.message}/>
                                </div>

                                <div>
                                    <div className="inputs-block _label _select">
                                        <Controller 
                                            name="dayRateJson.show" 
                                            control={control}
                                            render={({field:{onChange,value}})=>
                                                <MySelect 
                                                    isSearchable={false}
                                                    value={showStatus.filter(item=>item.value===value)}
                                                    onChange={e=>onChange(e.value)} 
                                                    options={showStatus}/>
                                            }
                                        />  
                                    </div>
                                    <Errors error={errors?.dayRateJson?.show?.message}/>
                                </div>

                                <div>Stundensatz</div>
                                <div>
                                    <div className="inputs-block _label">
                                        <label>Von</label>
                                        <input 
                                            {...register("timeRateJson.from",{
                                                required:err.req
                                            })}
                                            className="inputs off" 
                                            autoComplete="off"/>
                                        <div className="icons _euro _small inputs-ico"></div>
                                    </div>
                                    <Errors error={errors?.timeRateJson?.from?.message}/>
                                </div>

                                <div>
                                    <div className="inputs-block _label">
                                        <label>Bis</label>
                                        <input 
                                            {...register("timeRateJson.to",{
                                                required:err.req
                                            })}
                                            className="inputs off" 
                                            autoComplete="off"/>
                                        <div className="icons _euro _small inputs-ico"></div>
                                    </div>
                                    <Errors error={errors?.timeRateJson?.to?.message}/>
                                </div>

                                <div>
                                    <div className="inputs-block _label _select">
                                        <Controller 
                                            name="timeRateJson.show" 
                                            control={control}
                                            render={({field:{onChange,value}})=>
                                                <MySelect 
                                                    isSearchable={false}
                                                    value={showStatus.filter(item=>item.value===value)}
                                                    onChange={e=>onChange(e.value)}
                                                    options={showStatus}/>
                                            }
                                        />
                                    </div>
                                    <Errors error={errors?.timeRateJson?.show?.message}/>
                                </div>

                            </div>

                        </div>
                    
                    </div>

                    <div className="fr-pf-title">
                        <div className="font-h6b-16">Verfügbarkeit</div>
                    </div>

                    <div className="form">

                        <div>
                            <div className="inputs-block _label _select">
                                <Controller 
                                    name="status" 
                                    control={control}
                                    render={({field:{onChange,value}})=>
                                        <MySelect 
                                            isSearchable={false}
                                            value={type.free.filter(item=>item.value===value)}
                                            onChange={e=>onChange(e.value)}
                                            options={type.free}/>
                                    }
                                />
                            </div>
                            <Errors error={errors?.status?.message}/>
                        </div>
                    
                    </div>

                    {statusWatcher!==21?

                    <>
                        <div className="fr-pf-title">
                            <div className="font-h6b-16">Verfügbar ab:</div>
                        </div>

                        <div className="form">

                            <div className="inputs-block _label">
                                <Controller 
                                    name="statusFrom" 
                                    control={control}
                                    render={({field:{onChange,value}})=>
                                        <DatePicker 
                                            selected={value}
                                            closeOnScroll={true}
                                            alwaysShowMask={false}
                                            onChange={onChange}
                                            className="myDatepicker"
                                            dateFormat="dd.MM.yyyy"
                                            placeholder="Datum auswählen"
                                            customInput={
                                                <InputMask mask="99.99.9999" maskChar={null}/>
                                            }
                                        />
                                    }
                                />
                                <div className="icons _cal _small inputs-ico"></div>
                            </div>
                        
                        </div>
                    </>:''}

                    <div className="fr-pf-title">
                        <div className="font-h6b-16">Soziale Netzwerke</div>
                    </div>
                    
                    <div className="form">

                        <div>
                            <div className="inputs-block _label">
                                <label>Facebook</label>
                                <input 
                                    {...register("socJson.fb")}
                                    className="inputs _icon off"/>
                                <div className="icons _fb _small inputs-ico"></div>
                            </div>
                            <Errors error={errors?.socJson?.fb?.message}/>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <label>Google</label>
                                <input 
                                    {...register("socJson.gl")}
                                    className="inputs _icon off" />
                                <div className="icons _gl _small inputs-ico"></div>
                            </div>
                            <Errors error={errors?.socJson?.gl?.message}/>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <label>Linkedin</label>
                                <input 
                                    {...register("socJson.li")}
                                    className="inputs _icon off" />
                                <div className="icons _li _small inputs-ico"></div>
                            </div>
                            <Errors error={errors?.socJson?.li?.message}/>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <label>Xing</label>
                                <input 
                                    {...register("socJson.xi")}
                                    className="inputs _icon off"/>
                                <div className="icons _xi _small inputs-ico"></div>
                            </div>
                            <Errors error={errors?.socJson?.xi?.message}/>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <label>YouTube</label>
                                <input 
                                    {...register("socJson.yt")}
                                    className="inputs _icon off"/>
                                <div className="icons _yt _small inputs-ico"></div>
                            </div>
                            <Errors error={errors?.socJson?.yt?.message}/>
                        </div>

                        <div>
                            <div className="inputs-block _label">
                                <label>Twitter</label>
                                <input 
                                    {...register("socJson.tw")}
                                    className="inputs _icon off"/>
                                <div className="icons _tw _small inputs-ico"></div>
                            </div>
                            <Errors error={errors?.socJson?.tw?.message}/>
                        </div>

                    </div>
                    {/* <div class="form _button">
                        <div><Link to={`/frilance/${user.id}}`} target="_blank" rel="noopener noreferrer" className="button button-secondary">ANZEIGEN</Link></div>
                        <div><div className="button button-primary" onClick={()=>submit()}>Veröffentlichen </div></div>
                    </div> */}

                </div>
                <div className="fr-pf-save">
                    <button type="submit" className="button button-primary">Speichern</button>
                </div>
            </div>
        </form>
    )
}

export {Form}