import { useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { MesDel } from "../../../components/MesDel/MesDel";

const MailItem = ({item,del}) =>{

    const navigate=useNavigate();
    const [show,setShow] = useState(false);

    return (
        <tr key={item.id} className="table-row">
                <td>
                    <div className="card-item-title">
                        <Link className="card-item-foto" to={`../mail/${item.id}`}>
                            {item.img
                                ?<img src={item.img} alt="Foto"/>
                                :<div className="icons _user _black _opacity"></div>
                            }
                        </Link>
                        <div className="table-bold">{item.name}</div>
                    </div>
                </td>
                <td>
                    <div className="table-bold">{item.topic}</div>
                </td>
                <td>{item.data}</td>
                <td>
                    <ReactTooltip />
                    <MesDel setShow={setShow} show={show} del={()=>{del();setShow(false)}}>
                        <p>Das Projekt wird aus der Liste entfernt, aber Ihre Bewerbung bleibt für das Projekt aktiv.
                        <br/>Ein Projekt aus der Liste entfernen?</p>
                    </MesDel>
                    <div className="table-icons">
                        <div 
                            className="icons _del"
                            onClick={e=>{e.stopPropagation();setShow(true)}}
                            data-help="Delete" data-tip="Löschen" data-place="top" data-effect="solid" data-class="c-tooltips"
                            ></div>
                        <div 
                            className="icons _reply" 
                            onClick={()=>navigate(`../mail/${item.id}`)}
                            data-help="Replay" data-tip="Anzeigen" data-place="top" data-effect="solid" data-class="c-tooltips"
                            ></div>
                    </div>
                </td>
            </tr>
    )
}

export {MailItem}