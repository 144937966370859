import {useLocation, Navigate} from 'react-router-dom';

import useAxios from '../hook/useAxios';
import {useLogin} from '../hook/useLogin';

const RequireAuth = ({children}) => {
    const location= useLocation();
    const {user,setUser} = useLogin();
    const axios = useAxios(user);

    // if not auth return relink component
    if(!user){
        // go to main if not enter yet
        return <Navigate to='/' state={{from:location}}/>
    }else{
        // update user
        //?
        // update user account
        // axios.get('/user/')
        // .then(res=>res.data)
        // .then(data=>{
        //     // user.payd=data.res.payd;
        //     // user.tarif=data.res.tarif;
        //     if(user.payd!==data.res.payd || user.tarif!==data.res.tarif){
        //         user.payd=data.res.payd;
        //         user.tarif=data.res.tarif;
        //         setUser({...user});
        //     }
        // });
    }

    // else return private page
    return children;
} 

export {RequireAuth}
