import './Modal.scss';

import ReactDOM from "react-dom";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Modal = ({children,setShow,navi}) => {

    // animate 
    const [active,setActive]= useState(false);
    useEffect(()=>{
        setActive(true);
    },[])
    

    const navigate=useNavigate();

    // if navi sate navigate / can be another page
    const relocate = () =>{
        if(navi){navigate(navi);}
    }

    return ReactDOM.createPortal(
        <div className={`modal ${active?'active':''}`} onClick={e=>{e.stopPropagation();setShow(false)}}>
            <div className='modal-block'>
                <div className="modal-content overflow" onClick={e=>e.stopPropagation()}>
                    <div className="modal-close" onClick={()=>{setShow(false);relocate()}}></div>
                    {children}
                </div>
            </div>
        </div>,
        document.getElementById('modal')
    )
}

export {Modal}