export const Agb = {
    title:'Allgemeine Geschäftsbedingungen zur Nutzung der Plattform freelancerweb.de',
    html:<div>
        <b><p>Inhaltsverzeichnis</p></b>
        <p>
            (Hinweis: Um den Lesefluss nicht zu beeinträchtigen wird hier und im folgenden
            Vertragstext zwar nur die männliche Form genannt, stets aber die weibliche und
            andere Formen gleichermaßen mitgemeint.)
        </p>
        <b><p>1. Anwendungsbereich</p></b>
        <p>
            1.1. Diese Nutzungsbedingungen regeln das Rechtsverhältnis zwischen der
            freelancerweb.de (nachfolgend: "freelancerweb.de") als Betreiberin der
            Online-Plattform freelancerweb.de und den registrierten Nutzern (nachfolgend:
            "Nutzer") dieser B2B-Online-Plattform. Diese Nutzungsbedingungen regeln die
            Nutzung der Online-Plattform freelancerweb.de, einschließlich der Nutzung des
            freelancerweb.de Blogs, ausschließlich und unabhängig davon über welches
            Endgerät oder Betriebssystem die Nutzung erfolgt, einschließlich der Nutzung
            der Dienste über die entsprechenden Apps.
        </p>
        <p>
            1.2. Abweichende Geschäftsbedingungen des Nutzers finden nur dann und
            insoweit Anwendung, als sie von freelancerweb.de schriftlich anerkannt
            werden. 
        </p>
       <b><p> 2. Vertragsgegenstand</p></b>
        <p>
            2.1. freelancerweb.de stellt mit freelancerweb.de eine Informations-, Service-
            und Kommunikationsplattform zur Verfügung, die dem Nutzer die Möglichkeit
            zur Personal- und Projektakquise sowie zur Selbstdarstellung gibt. Darüber
            hinaus ermöglichen die Dienste von freelancerweb.de auch den Austausch
            elektronischer Nachrichten zwischen den Nutzergruppen untereinander und
            zwischen den Nutzern und freelancerweb.de. Zudem stellt freelancerweb.de
            den Nutzern einen Blog zum Austausch und zur sachlichen Kommentierung der
            Beiträge (freelancerweb.de Blog) zur Verfügung. 
        </p>
        <p>
            2.2. Die Leistungen von freelancerweb.de gliedern sich in kostenfreie
            Mitgliedschaften und kostenpflichtige Mitgliedschaften. 
        </p>
        <p>
            2.3. Die jeweils aktuelle Übersicht der Leistungen der verschiedenen
            Mitgliedschaftsarten und Accounts ist über freelancerweb.de abrufbar.
            freelancerweb.de behält sich das Recht vor, zur fortlaufenden
            Weiterentwicklung von freelancerweb.de sein Leistungsangebot zu erweitern,
            zu verändern, zu verringern oder teilweise einzustellen, soweit damit die
            Interessen des jeweiligen betroffenen Nutzers nicht unzumutbar beeinträchtigt
            werden. Ansprüche des Nutzers auf Minderung, Erstattung oder Schadensersatz
            ergeben sich daraus nicht. Der Nutzer ist jedoch berechtigt, seine Mitgliedschaft
            gemäß Ziffer 9.3. aus wichtigem Grund außerordentlich, das heißt ohne
            Einhaltung der in Ziffer 9.2. vorgesehenen Frist, zu kündigen, wenn während
            einer vereinbarten Mindestlaufzeit oder bis zum Ablauf der Frist für eine
            ordentliche Kündigung das von ihm ausgewählte und bestellte
            Leistungsangebot in wesentlichem Umfang verringert oder eingestellt wird.
        </p>
        <p>
            2.4. freelancerweb.de gewährt dem Nutzer eine im Rahmen der technischen
            Möglichkeiten bestmögliche Verfügbarkeit der Inhalte und Leistungen.
            freelancerweb.de ist berechtigt, für notwendige Wartungs-, Sicherheits- oder
            Aktualisierungsarbeiten die Zugriffsmöglichkeit auf freelancerweb.de kurzzeitig
            zu unterbrechen. Für Störungen der Verfügbarkeit, die außerhalb des
            Einflussbereiches von freelancerweb.de liegen, kann keine Verantwortung
            übernommen werden. 
        </p>
        <p>
            2.5. Partnerangebote sind, wenn nicht ausdrücklich anders beschrieben, nur für
            von freelancerweb.de bestimmte Gruppen von Nutzern erhältlich, die
            freelancerweb.de nach eigenem Ermessen festlegt. An den unmittelbar
            zwischen den Nutzern und den Partnerunternehmen abgeschlossenen
            Verträgen ist freelancerweb.de weder als Vertragspartner noch als Vertreter
            beteiligt. Für diese Partnerangebote gelten die AGB und
            Datenschutzerklärungen der jeweiligen Partnerunternehmen.
        </p>
        <p>
            2.6. freelancerweb.de kann jeden Nutzer über neue eigene Angebote und
            Angebote von Partnerunternehmen informieren. Um Anbieter und
            Nachfragende zusammenzubringen, so dass Projekte möglichst schnell und
            effizient besetzt sowie erfolgreich aus Sicht der jeweiligen Vertragsparteien
            realisiert werden, kann freelancerweb.de den registrierten Nutzern aktiv
            Informationen zukommen lassen. Hierzu können die am Markt verfügbaren und
            eingesetzten Kommunikationswege (wie z.B. E-Mail, interne Nachrichten, SMS,
            Messenger-Dienste, Social Feeds) Verwendung finden.
        </p>
        <b><p>3. Registrierung und Nutzerkonto</p></b>
        <p>
            3.1. Die Nutzung der auf freelancerweb.de verfügbaren Dienste setzt eine
            Registrierung und Anmeldung als Nutzer voraus. Ein Anspruch auf die Teilnahme
            als Nutzer besteht nicht. freelancerweb.de ist berechtigt, Nutzeranträge ohne
            Angabe von Gründen abzulehnen.
        </p>
        <p>
            3.2. Die Registrierung und Anmeldung ist dem Nutzer nur erlaubt, wenn er
            volljährig und unbeschränkt geschäftsfähig ist. Minderjährigen Personen ist eine
            Registrierung und Anmeldung untersagt. Bei einer juristischen Person müssen
            die Registrierung und Anmeldung durch eine unbeschränkt geschäftsfähige und
            vertretungsberechtigte natürliche Person erfolgen.
        </p>
        <p>
            3.3. Mit dem Absenden des Registrierungsformulars gibt der Nutzer ein Angebot
            zum Abschluss einer Nutzungsvereinbarung mit freelancerweb.de ab.
            Akzeptiert freelancerweb.de die Registrierung, erhält der Nutzer eine
            Bestätigungs-E-Mail mit einem personalisierten Aktivierungs-Link. Mit Betätigen
            dieses Aktivierungs-Links kommt zwischen freelancerweb.de und dem Nutzer
            ein Vertrag über die Nutzung der Dienste vom freelancerweb.de (im Folgenden
            „Nutzungsvertrag“) zustande. Um die Registrierung abzuschließen, muss der
            Nutzer den mit der Bestätigungs-E-Mail mitgeteilten Link aufrufen. Der
            Nutzungsvertrag kommt vor Betätigung des Aktivierungs-Links zustande,
            insofern zuvor bereits eine kostenpflichtige Mitgliedschaft durch den Nutzer
            abgeschlossen wurde.
        </p>
        <p>
            3.4. Mit erfolgreicher Registrierung wird für den Nutzer ein Nutzerkonto angelegt,
            auf das er mit Hilfe seiner E-Mail-Adresse und seines Passwortes zugreifen kann.
            Das Passwort kann vom Nutzer jederzeit über den passwortgeschützten
            Nutzerbereich geändert werden. Es ist vom Nutzer geheim zu halten und vor
            dem Zugriff durch unbefugte Dritte geschützt aufzubewahren. Stellt der Nutzer
            fest oder hegt er den Verdacht, dass seine Zugangsdaten von einem Dritten
            unbefugt genutzt werden, hat er dies freelancerweb.de unverzüglich
            mitzuteilen und sein Passwort unverzüglich zu ändern.
        </p>
        <p>
            3.5. Jeder Nutzer darf sich für die jeweilige gewerbliche bzw. freiberufliche
            Nutzung der Dienste von freelancerweb.de jeweils nur einmal registrieren.
            Nutzerkonten sind nicht übertragbar. Bei Zuwiderhandlungen können von
            freelancerweb.de einzelne oder alle Nutzerkonten gesperrt oder gelöscht
            werden; freelancerweb.de behält sich in einem solchen Fall auch die
            außerordentliche Kündigung einzelner oder sämtlicher Konten des betroffenen
            Nutzers vor. Eine Erstattung bereits geleisteter Mitgliedsbeiträge erfolgt insoweit
            nicht; entsprechende Mitgliedsbeiträge werden als pauschalierter
            Schadensersatz einbehalten, wobei dem Mitglied der Nachweis gestattet bleibt,
            dass ein Schaden nicht bzw. nicht in der entsprechenden Höhe entstanden ist.
            Gelingt dieser Nachweis, wird freelancerweb.de entsprechende
            Mitgliedsbeiträge anteilig erstatten soweit diese den entstandenen Schaden
            übersteigen. Soweit ein tatsächlich bei freelancerweb.de eingetretener
            Schaden den Betrag der einbehaltenen oder einzubehaltenden Mitgliedsbeiträge
            übersteigt, bleibt eine weitergehende Geltendmachung von Schadensersatz
            ausdrücklich vorbehalten. Auf weitergehende Schadenersatzansprüche werden
            die einbehaltenen Beiträge angerechnet.
        </p> 
        <b><p>4. Pflichten der Nutzer</p></b>
        <p>4.1. Der Nutzer ist verpflichtet,</p>
        <p>
            4.1.1. auf freelancerweb.de und in der Kommunikation mit anderen Nutzern
            ausschließlich wahre und nicht irreführende Angaben zu machen und keine
            Pseudonyme zu verwenden, insbesondere ausgeschriebene Angebote (Profile,
            Projekte) müssen real existent sein, im Zweifel und auf Verlangen von
            freelancerweb.de muss der Nutzer den Nachweis erbringen, dass die
            ausgeschriebenen Projekte real existent sind; das Veröffentlichen gefälschter
            oder nicht mehr existenter Angebote (Profile, Projekte) ist untersagt und kann
            eine vorübergehende oder vollständige Sperrung des Accounts, eine
            außerordentliche Kündigung durch freelancerweb.de und/oder die
            Geltendmachung von Schadensersatzansprüchen nach sich ziehen und/oder an
            andere Nutzer kommuniziert bzw. für sie sichtbar gemacht werden.
            freelancerweb.de behält sich vor, ein Bonus/Malus System einzusetzen, dass
            gepflegte und stets aktualisierte Profile und Projekte im Vergleich zu anderen
            Profilen und Projekten in der Sichtbar- und Wahrnehmbarkeit bevorteilt; 
        </p>
        <p>
            4.1.2. in Datenfeldern nur Angaben zu machen, für die das jeweilige Datenfeld
            nach seiner Beschreibung vorgesehen ist, und Angaben, insbesondere zu
            Kontaktdaten, nicht an anderen als den vorgesehenen Stellen zu machen; 
        </p>
        <p>
            4.1.3. seine Angaben auf freelancerweb.de aktuell und vollständig zu halten. Der
            Projektanbieter ist verpflichtet seine Projekt-Veröffentlichung umgehend offline
            zu stellen, sobald ein Angebot nicht mehr aktuell ist. Der Freelancer ist
            verpflichtet seine Verfügbarkeitsangabe aktualisiert zu halten. Bei
            Zuwiderhandlungen kann freelancerweb.de geeignete Maßnahmen ergreifen,
            die vor allem das Löschen offenbar veralteter oder nicht mehr existenter
            Angebote (Profile, Projekte) umfassen; zudem behält sich freelancerweb.de das
            Recht vor, veraltete oder seit einem Zeitraum von 2 Jahren nicht mehr
            aktualisierte Profile oder Projekte von Nutzern gemäß Ziffer 3.5. auf offline zu
            stellen;
        </p>
        <p>
            4.1.4. auf freelancerweb.de ausschließlich solche Inhalte zu veröffentlichen
            (insbesondere bei der Erstellung eines eigenen Profils oder eigener
            Projektausschreibungen) und an andere Nutzer ausschließlich solche
            Nachrichten zu versenden, die im Rahmen der Zweckbestimmung von
            freelancerweb.de zur Anbahnung eines Dienst-, Werk-, Arbeits- oder sonstigen
            Beschäftigungsverhältnisses unmittelbar zwischen den Nutzern (oder im Falle
            der Vermittlung zwischen einem Nutzer und einem Kunden des Vermittlers) oder
            zur sachlichen Kommentierung der Beiträge im freelancerweb.de Blog dienen.
            Sachfremde Veröffentlichungen und interne Nachrichten (z.B. Werbung) sind
            unzulässig, insbesondere interne Nachrichten, die nicht personalisiert sind (z.B.
            Massen- oder Kettenmails) oder keinen klaren Bezug zum Profil des Empfängers
            aufweisen; 
        </p>
        <p>
            4.1.5. seine Zugangsdaten (Benutzername, Passwort) geheim zu halten und nicht
            an Dritte weiterzugeben; 
        </p>
        <p>
            4.1.6. alle Informationen und Daten, die er von anderen Nutzern im Rahmen der
            Nutzung von freelancerweb.de erhält, vertraulich zu behandeln und Dritten
            nicht weiterzugeben oder zugänglich zu machen, soweit nicht eindeutig
            erkennbar ist, dass der Nutzer als Vermittler tätig wird; in diesem Fall darf der
            Vermittler die erhaltenen Informationen und Daten nur in dem durch den Zweck
            der Vermittlung erforderlichen Umfang nur an seine Kunden weitergeben oder
            seinen Kunden zugänglich machen. Diese Verpflichtung gilt über die
            Beendigung des Vertrages hinaus; 
        </p>
        <p>
            4.1.7. seinen Account ausschließlich selbst bzw. durch einen bevollmächtigten
            Vertreter zu nutzen und Dritten die erworbenen Leistungen ohne Zustimmung
            durch freelancerweb.de weder anzubieten oder noch zu überlassen,
            insbesondere nicht anderen Nutzern Leistungen aus seinem Account zur
            Nutzung zu überlassen; es liegt weiter in der Verantwortung des Nutzers sicher
            zu stellen, dass sein Zugang zu freelancerweb.de und die Nutzung der auf
            freelancerweb.de zur Verfügung stehenden Dienste ausschließlich durch ihn
            bzw. durch die von ihm bevollmächtigte Person erfolgt. Steht zu befürchten, dass
            unbefugte Dritte von seinen Zugangsdaten Kenntnis erlangt haben oder
            erlangen werden, ist freelancerweb.de unverzüglich zu informieren; Der Nutzer
            haftet für jedwede Nutzung und/oder sonstige Aktivität, die unter seinen
            Zugangsdaten ausgeführt wird, nach den gesetzlichen Bestimmungen;
        </p>
        <p>
            4.1.8.im Fall des Bestehens mehrerer Accounts (z.B. für eigene Mitarbeiter) jeden
            Account nur einer Person zur Verfügung zu stellen und den Account nur durch
            diese Person zu nutzen. freelancerweb.de behält sich vor, die Einhaltung dieser
            Nutzungsbeschränkung auch mit technischen Mitteln zu überprüfen. Bei
            Zuwiderhandlungen kann freelancerweb.de eine Vertragsstrafe je unerlaubter
            Nutzung erheben, die freelancerweb.de nach billigem Ermessen nach Maßgabe
            der jeweiligen Umstände des Einzelfalls einseitig festlegen kann. Nutzungsrechte,
            insbesondere für beim Nutzer angestellte Mitarbeiter, können erworben werden;
        </p>
        <p>
            4.1.9.jede Handlung zu unterlassen, die die Funktionalität der technischen
            Infrastruktur von freelancerweb.de und den sicheren Betrieb der Dienste von
            freelancerweb.de beschädigen oder beeinträchtigen könnte, die Verfügbarkeit
            der von freelancerweb.de gespeicherten Daten beeinträchtigt oder andere
            Nutzer belästigen könnte; 
        </p>
        <p>
            4.1.10.auf freelancerweb.de keine rechtswidrigen Inhalte zu veröffentlichen,
            keine Logos oder Bilder von Dritten ohne deren vorherige Zustimmung zu
            veröffentlichen sowie keine Rechte Dritter zu verletzen; insbesondere keine
            beleidigenden, verleumderischen, pornografischen, jugendgefährdenden oder
            sonst rechtsverletzenden Inhalte zu veröffentlichen oder auf solche zu verweisen.
            Dies gilt auch bei der Kommentierung von Beiträgen des freelancerweb.de
            Blogs. 
        </p>
        <p>
            4.2. Der Nutzer sichert zu, hinsichtlich der von ihm bereit gestellten Inhalte
            Inhaber aller zu einer Veröffentlichung erforderlichen Rechte zu sein und wird es
            unterlassen, Inhalte einzustellen, die gegen urheber-, marken- und
            wettbewerbsrechtliche Vorschriften verstoßen. 
        </p>
        <p>
            4.3. Unzulässig ist, die über freelancerweb.de erlangten Daten kommerziell
            weiterzuverarbeiten, mit diesen Daten zu handeln, oder sich an die Nutzer mit
            Werbung zu wenden, wenn die beworbenen / angebotenen Produkte oder
            Dienstleistungen in Konkurrenz zu freelancerweb.de stehen. Bei einer
            Verletzung dieser Bestimmungen wird eine von freelancerweb.de nach
            billigem Ermessen festzusetzende Vertragsstrafe ausgelöst. Diese kann pro
            Verstoß bis zu EUR 10.000 betragen. Darüberhinausgehende Ansprüche von
            freelancerweb.de bleiben unberührt. Auf weitergehende
            Schadenersatzansprüche wird die Vertragsstrafe angerechnet.
        </p>
        <b><p>5. Löschung von Inhalten, Sperrung von Nutzern, Haftung des Nutzers</p></b>
        <p>
            5.1. freelancerweb.de behält sich das Recht vor, von den Nutzern veröffentlichte
            Inhalte zu löschen oder die Veröffentlichung bestimmter Inhalte nicht
            vorzunehmen, wenn diese rechtswidrig sind, gegen die guten Sitten oder Rechte
            Dritter verstoßen, mit Zweck und Form von freelancerweb.de unvereinbar sind,
            insbesondere wenn sie falsch sind, wenn sie diesen Nutzungsbedingungen
            widersprechen, oder wenn die Veröffentlichung aus sonstigen Gründen
            unzumutbar ist. Das vorstehende Recht, von den Nutzern veröffentlichte Inhalte
            zu löschen oder die Veröffentlichung bestimmter Inhalte nicht vorzunehmen,
            besteht auch in Fällen, in denen die Richtigkeit eines veröffentlichten oder zu
            veröffentlichenden Inhalts zwischen zwei oder mehreren Nutzern streitig ist, bis
            zur abschließenden Klärung des Streites. Zudem behält sich freelancerweb.de
            das Recht vor, von den Nutzern veröffentlichte Inhalte insoweit zu korrigieren, als
            diese Rechtschreibfehler, offensichtliche Fehler oder Schreibfehler (z.B.
            „Vertipper“ oder „Zahlendreher“) enthalten.
        </p>
        <p>
            5.2. Verstößt der Nutzer gegen Verpflichtungen, die ihn nach den Bestimmungen
            dieses Vertrages treffen, ist freelancerweb.de berechtigt, das Nutzerkonto
            vorübergehend zu sperren oder den Vertrag mit dem Nutzer gemäß Ziffer 9.3.
            aus wichtigem Grund außerordentlich zu kündigen. Eine Erstattung bereits
            geleisteter Mitgliedsbeiträge erfolgt insoweit nicht; entsprechende
            Mitgliedsbeiträge werden als pauschalierter Schadensersatz einbehalten wobei
            dem Mitglied der Nachweis gestattet bleibt, dass ein Schaden nicht bzw. nicht in
            der entsprechenden Höhe entstanden ist. Gelingt der Nachweis wird
            freelancerweb.de entsprechende Mitgliedsbeiträge anteilig erstatten soweit
            diese den entstandenen Schaden übersteigen. Weitergehende Ansprüche
            bleiben unberührt wobei der pauschalierte Schadenersatz auf weitergehende
            Schadenersatzansprüche angerechnet wird. 
        </p>
        <p>
            5.3. Soweit ein Nutzer durch freelancerweb.de nach Absatz 5.2. vorrübergehend
            oder dauerhaft von der Nutzung Dienste ausgeschlossen wurde oder der
            bestehende Vertrag mit dem Nutzer beendet wurde, ist es dem Nutzer untersagt,
            die von freelancerweb.de angebotenen Dienste mit anderen Nutzerkonten,
            einschließlich solcher Nutzerkonten, die für Dritte angelegt wurden, zu nutzen
            oder sich erneut für eine Registrierung auf freelancerweb.de anzumelden. Im
            Falle der vorübergehenden bzw. zeitlich unbegrenzten Sperrung sperrt
            freelancerweb.de den Nutzerzugang und benachrichtigt den Nutzer hierüber
            per E-Mail. Im Falle einer vorübergehenden Sperrung reaktiviert
            freelancerweb.de nach Ablauf der Sperrzeit die Zugangsberechtigung und
            benachrichtigt den Nutzer hierüber per E-Mail. 
        </p>
        <p>
            5.4. Im Falle einer Verletzung von Rechten Dritter durch Veröffentlichungen des
            Nutzers auf freelancerweb.de stellt der Nutzer freelancerweb.de von allen
            Ansprüchen Dritter frei. Die Freistellung umfasst auch die erforderlichen
            Rechtsverfolgungskosten, einschließlich sämtlicher Gerichts- und Anwaltskosten
            in Höhe der gesetzlich festgelegten Sätze. Der Nutzer ist weiterhin verpflichtet,
            freelancerweb.de im Falle einer Inanspruchnahme durch Dritte auf
            entsprechende Anforderung unverzüglich wahrheitsgemäß und vollständig alle
            Informationen und Auskünfte zu erteilen, die für die Prüfung der Ansprüche und
            eine ggf. notwendige Verteidigung gegen die Ansprüche erforderlich sind.
        </p>
       <p>
            5.5. freelancerweb.de behält sich vor, bis zu einer verbindlichen Klärung,
            vorübergehend Daten zu sperren, wenn deren Richtigkeit zweifelhaft ist, oder
            Dritte (insbesondere andere Nutzer) in diesem Zusammenhang eigene Rechte
            oder gar eine Rechtsverletzung nachvollziehbar behauptet haben.
       </p>
        <b><p>6. Preise und Zahlungsbedingungen</p></b>
        <p>
            6.1. Für die kostenpflichtigen Angebote von freelancerweb.de gelten die
            zum Zeitpunkt der jeweiligen Leistungserbringung geltenden und
            auf www.freelancerweb.de einsehbaren Preise. 
        </p>
        <p>
            6.2. Die Entgelte für den Empfang der Leistungen von freelancerweb.de
            sind wie folgt zur Zahlung fällig:
        </p>
        <p>
            <ul>
                <li>
                    Die Gebühren und Entgelte für von freelancerweb.de erbrachte
                    Leistungen sind sofort nach Erhalt der Rechnung oder entsprechend
                    den auf der Rechnung angegebenen Zahlungszielen zahlbar und
                    fällig. Nach Fälligkeit tritt unmittelbar Verzug ein. 
                </li>
                <li>
                    Der Nutzer erklärt sich damit einverstanden, dass Rechnungen und
                    Zahlungserinnerungen per E-Mail übermittelt werden können.
                    Rechnungen sind grundsätzlich im Account des Nutzers abrufbar,
                    bis dieser gelöscht wird.
                </li>
            </ul>
        </p>
        <p>
            6.3. freelancerweb.de behält sich das Recht zur Anpassung der jeweils
            geltenden Preise vor. Preisanpassungen sowie Anpassungen des
            Leistungsumfangs gelten unmittelbar für alle neu abgeschlossenen
            Mitgliedschaften. Bei Bestandskunden gelten die Preisanpassungen nicht
            rückwirkend für bereits begonnene vertragliche Laufzeiten der
            Mitgliedschaft, sondern erst mit einer Verlängerung oder einem
            Neuabschluss der Laufzeit der Mitgliedschaft.
        </p>
        <p>
            6.4. Soweit dem Nutzer von Seiten von freelancerweb.de
            Sonderkonditionen bzw. im Vergleich zu den jeweils aktuellen Preisen
            reduzierte Entgelte angeboten oder gewährt werden, beziehen sich diese
            Sonderkonditionen bzw. reduzierten Entgelte ausschließlich auf die
            einzelne, im Zusammenhang mit dem Sonderangebot begründete
            Vertragslaufzeit. Im Fall einer nachfolgenden Verlängerung oder eines
            Neuabschlusses der Laufzeit der Mitgliedschaft richten sich die zu
            zahlenden Entgelte - vorbehaltlich einer ausdrücklichen abweichenden
            Individualregelung - nach den allgemeinen zum Zeitpunkt der jeweiligen
            Leistungserbringung geltenden und den
            auf www.freelancerweb.deeinsehbaren Preisen. 
        </p>
        <p>
            6.5. Die Zurückhaltung von Zahlungen oder die Aufrechnung mit
            Gegenansprüchen durch den Nutzer ist nur zulässig, wenn diese
            Gegenansprüche von freelancerweb.de verbindlich anerkannt oder
            rechtskräftig festgestellt sind. 
        </p>
        <p>
            6.6. Die Bezahlung per Überweisung erfolgt nach Erhalt der Rechnung
            und auf in Rechnung hinterlegte Konto der freelancerweb.de.
        </p>
        <p>
            6.7. Die Bezahlung per Lastschrift, Kreditkarte und Paypal erfolgt über
            externe Vertragspartner, die auf www.freelancerweb.de unter einem Link
            (siehe Ziffer 2.5. Datenübermittlung in Drittstaaten / Dritte, „Externe
            Zahlungsdienstleister“) benannt sind. Im Falle eines unberechtigten
            Stornos werden Forderungen der freelancerweb.de an denjenigen der
            vorgenannten Vertragspartner abgetreten, der den entsprechenden
            Zahlungsvorgang ausgeführt hat. Die weitere Abwicklung findet dann
            ausschließlich im Verhältnis zwischen dem Nutzer und demjenigen der
            vorgenannten Vertragspartner statt, der den entsprechenden
            Zahlungsvorgang ausgeführt hat . 
        </p>
        <b><p>7. Haftung</p></b>
        <p>
            7.1. freelancerweb.de haftet nicht für die Richtigkeit, Vollständigkeit oder
            Rechtmäßigkeit von Angaben, die von den Nutzern veröffentlicht werden. Die auf
            freelancerweb.de, den Teilnehmer-Seiten von freelancerweb.de in sozialen
            Netzwerken (z.B. Facebook, Xing, LinkedIn) sowie auf dem freelancerweb.de
            Blog veröffentlichten Inhalte von Nutzern geben nicht die Meinung von
            freelancerweb.de wieder und werden nicht auf Richtigkeit, Vollständigkeit oder
            Rechtmäßigkeit geprüft.
        </p>
        <p>
            7.2. freelancerweb.de haftet, gleich aus welchem Rechtsgrund, für Schäden nur,
            wenn freelancerweb.de, deren gesetzliche Vertreter oder Erfüllungsgehilfen
            den Schaden grob fahrlässig oder vorsätzlich verursacht haben oder der Schaden
            auf die Verletzung einer wesentlichen Vertragspflicht zurückzuführen ist.
        </p> 
        <p>
            7.3. Bei der Haftung für die Verletzung einer vertragswesentlichen Pflicht, ohne
            dass freelancerweb.de grobe Fahrlässigkeit oder Vorsatz zur Last fallen, sowie
            bei der Haftung für grobe Fahrlässigkeit oder Vorsatz von Mitarbeitern, die nicht
            Geschäftsführer oder leitende Angestellte von freelancerweb.de sind, ist die
            Haftung von freelancerweb.de auf den typischerweise vorhersehbaren
            Schaden, der in der Regel seiner Höhe nach ein jährliches Nutzungsentgelt nicht
            überschreitet, beschränkt.
        </p> 
        <p>
            7.4. Die Haftung bei einer Verletzung von Leben, Körper oder Gesundheit sowie
            die Haftung für eine übernommene Garantie bleiben von den vorstehenden
            Bestimmungen unberührt.
        </p>
        <p>
            7.4. freelancerweb.de distanziert sich hiermit ausdrücklich von allen Inhalten
            aller durch Nutzer gelinkten und verknüpften Seiten, die von der Website
            freelancerweb.de erreichbar sind. Dies gilt für alle innerhalb des eigenen
            Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in
            Gästebüchern, Diskussionsforen und Mailinglisten. Für illegale, fehlerhafte oder
            unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder
            Nichtnutzung solcherart dargebotener Informationen entstehen, ist allein der
            jeweilige Anbieter der verlinkten Seite verantwortlich und nicht
            freelancerweb.de als diejenige, die über Links auf die jeweilige Veröffentlichung
            lediglich verweist. 
        </p>
        <b><p>8. Datenschutz</p></b>
        <p>
            8.1. freelancerweb.de erhebt, verarbeitet und nutzt die Daten der Nutzer im
            Rahmen der geltenden Datenschutzgesetze. 
        </p>
        <p>8.2. Auf die Datenschutzerklärung (hier klicken) wird hingewiesen.</p>
        <b><p>9. Beendigung des Vertrages</p></b>
        <p>
            9.1. Der Vertrag über eine kostenlose Mitgliedschaft kann jederzeit ohne Angabe
            von Gründen von den Vertragsparteien gekündigt werden. 
        </p>
        <p>
            9.2. Der Vertrag über eine kostenpflichtige Mitgliedschaft läuft zunächst über den
            vom Nutzer gebuchten Mindestnutzungszeitraum, höchstens jedoch über den
            Zeitraum von einem Jahr. Erfolgt keine rechtzeitige Kündigung durch den Nutzer
            oder freelancerweb.de verlängert sich die kostenpflichtige Mitgliedschaft
            jeweils um Verlängerungszeiträume der gleichen Dauer. Der Vertrag über eine
            kostenpflichtige Mitgliedschaft kann mit einer Frist von 7 Tagen vor Ende der
            Mindestvertragslaufzeit gekündigt werden. 
        </p>
        <p>
            9.3. Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt
            unberührt. Ein wichtiger Grund liegt insbesondere vor, wenn der Nutzer
            gesetzliche Vorschriften verletzt, gegen wesentliche Verpflichtungen nach
            diesem Vertrag verstößt oder den guten Ruf von freelancerweb.de erheblich
            gefährdet. 
        </p>
        <p>
            9.4. Nach der Vertragsbeendigung ist freelancerweb.de berechtigt, alle von
            dem Nutzer auf freelancerweb.de veröffentlichten Inhalte zu löschen. 
        </p>
        <b><p>10. Schlussbestimmungen</p></b>
        <p>
            10.1. Änderungen oder Ergänzungen dieses Vertrages bedürfen der Schriftform.
            Dies gilt auch für die Schriftformklausel. Mündliche Nebenabreden sind nicht
            getroffen.
        </p> 
        <p>
            10.2. Die Abtretung von Forderungen aller Art gegen freelancerweb.de an Dritte
            ist ausgeschlossen. § 354 a HGB bleibt unberührt. 
        </p>
        <p>
            10.3. freelancerweb.de behält sich das Recht vor, diese allgemeinen
            Nutzungsbedingungen zu ändern. freelancerweb.de wird den Nutzer über die
            neuen Nutzungsbedingungen rechtzeitig vor dem geplanten Inkrafttreten der
            Änderungen informieren. Der Nutzer hat in diesem Fall das Recht, das
            Vertragsverhältnis innerhalb von 4 Wochen nach Erhalt dieser Information zu
            kündigen. Nach Ablauf dieser Frist gilt die Änderung von dem Nutzer als
            genehmigt. freelancerweb.de wird den Nutzer bei der Benachrichtigung über
            die neuen Nutzungsbedingungen darauf hinweisen, ab wann die neuen
            Nutzungsbedingungen in Kraft treten. 
        </p>
        <p>
            10.4. Anwendbar ist das Recht der Bundesrepublik Deutschland unter Ausschluss
            des UN-Kaufrechts (Convention of Contracts for the International Sales of Goods,
            CISG). Soweit der Nutzer ein Kaufmann, eine juristische Person des öffentlichen
            Rechts oder ein öffentlich-rechtliches Sondervermögen ist oder keinen
            allgemeinen Gerichtsstand in der Bundesrepublik Deutschland hat, ist das
            Gericht am Sitz von freelancerweb.de als ausschließlicher Gerichtsstand
            vereinbart. freelancerweb.de ist jedoch auch berechtigt, am allgemeinen
            Gerichtsstand des Nutzers zu klagen. 
        </p>
        <p>
            10.5. Sollte eine Vertragsbestimmung ganz oder teilweise unwirksam sein,
            berührt dies die Wirksamkeit der übrigen Klauseln und der übrigen Teile solcher
            Klauseln nicht. Die Vertragschließenden sind verpflichtet, unwirksame Teile einer
            Klausel im Wege der ergänzenden Vereinbarung unverzüglich durch eine solche
            Abrede zu ersetzen, die dem wirtschaftlichen Ergebnis der unwirksamen
            Bestimmung am nächsten kommt. Im Zweifel gilt die unwirksame Bestimmung
            durch eine solche Abrede als ersetzt.
        </p>
    </div>
}