import "./Mes.scss";
import {Modal} from '../../../components/Modal';

const MesOk = ({setShow}) =>{
    return (
        <Modal setShow={setShow} navi='/account/proj/'>
            <div className="mes-simple">
                <div className="message-icon">
                    <div className='icons _alert _alert-ok'></div>
                </div>
                <div className="font-h5b-18">Ihr Projekt wurde erfolgreich auf freelancehunter.de plaziert. </div>
                <div>Ab jetzt Sie können alle Ihre veröffentliche Projekte unter Darshboard -  Aktive Projekte ansehen und bearbeiten.</div>
                <div>Wir wünschen Ihnen viel Erfog!</div>
            </div>
        </Modal>
    )
}

export {MesOk}