import useAxios from "../hook/useAxios";
import {createContext,useEffect,useState} from "react";
import {useNavigate} from 'react-router-dom';

export const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {

    const navigate = useNavigate();

    const [config,setConfig]=useState();

    const [user,setUser] = useState(
        localStorage.getItem('user')
        ?JSON.parse(localStorage.getItem('user'))
        :null
    );

    // likes
    const [likes,setLikes] = useState(
        localStorage.getItem('likes')
        ?JSON.parse(localStorage.getItem('likes'))
        :[]
    );
    
    const [modal,setModal] = useState({open:false,st:'mn'});
    const [pay,setPay] = useState(false);

    const axios = useAxios(user);

    useEffect(()=>{

        //? PROMISE
        // const promise = new Promise((res,err)=>{
        //     axios.get('/site/config',{validateStatus: false})
        //     .then(r=>res(r.data))
        //     .catch((e)=>err(e));
        // });
        // promise.then(res=>{return res},err=>{return err});

        //setConfig({...data.data,title:'Freelancer'})
        
        // let apiRes = null;
        // try {
        //     apiRes = axios.get('/site/config',{validateStatus: false})
        //     .then(data=>setConfig({...data.data,title:'Freelancer'}))
        //? CATCH
        //     .catch((e) =>console.log(e));
        // } catch(err){
        //     console.error(err);
        //? FINALLY
        // } finally{
        //     console.log(apiRes);
        // }

        axios.get('/site/config')
        .then(data=>{
            setConfig({...data.data,title:'Freelancer'})
        });

    },[]);

    const signIn = (user,url) => {
        // if admin
        if(user.type === 2){localStorage.setItem('admin',user.accessToken);}

        // set user
        localStorage.setItem('user',JSON.stringify(user));
        setUser(user);
        navigate(url,{replace:true}) // return to locate page

        // start mail check
        //setMail(startMail(user));
    }

    const signOut = (url) => {
        // clear user
        localStorage.removeItem('admin');
        localStorage.removeItem('user');
        setUser(null);
        navigate(url,{replace:true}) // go to home page
    }

    // reсheck login and pas
    // if(!user){
    //         useEffect(()=>{
    //             signOut('/');
    //         },[]);
    // }

    const value = {
        modal,
        setModal,

        pay,
        setPay,

        user,
        setUser,
        signIn,
        signOut,
        
        setConfig,
        config,

        likes,
        setLikes
    }

    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
}