import { Errors } from "../../../components/Errors"

const Doc = ({register,axios,fields,remove,errors}) => {

    const requireMes = 'bitte ausfüllen';

    // del doc
    const delDoc = (index) => {
        // delete by index
        let id=fields[index].id;
        axios.delete('/doc/',{params:{id:id}});
        remove(index);
    };

    return (
        fields.length
        ?<div className="fr-fr-d">
            {fields.map((item,index)=>
                <div key={item.id}>
                    <div>
                        <div className="inputs-block _label">
                            <label>Datei Name</label>
                            <input 
                                {...register(`doc[${index}].name`,{
                                    required:requireMes
                                })}
                                placeholder="Weitermachen"
                                className="inputs off"
                                autoComplete="off"/>
                        </div>
                        <Errors error={errors?.doc?.[index]?.name?.message}/>
                    </div>
                    <div>{item.file}</div>
                    <div><div className="icons _del" onClick={()=>{delDoc(index)}}></div></div>
                </div>
            )}
        </div>
        :<></>
    )
}

export {Doc}