import { Link } from "react-router-dom"

const Contacts = () =>{
    return (
        <div className="page-content _dark">
            <div className="home-contacts">
                <div className="page-block">x
                    <div className="page-content-text">
                        <div className="font-h1b-50">Haben Sie noch Fragen?</div>
                        <div className="font-text1-32 _hide">Wir sind jederzeit für Sie erreichbar</div>
                    </div>

                    <div>
                        <Link to="/contacts" className="button button-secondary _noline">kontaktiere uns</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {Contacts}