import { useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { MesDel } from "../../../components/MesDel/MesDel";

const NewsItem = ({item,del}) =>{

    const navigate=useNavigate();

    // delete message
    const [show,setShow] = useState(false);

    return (
        <tr key={item.id} className="table-row">
                <td>
                    <div className="table-bold">{item.name}</div>
                </td>
                <td>{item.data}</td>
                <td>
                    <ReactTooltip />
                    <MesDel setShow={setShow} show={show} del={()=>{del();setShow(false)}}>
                        <p>News löschen, Löschen der News bestätigen</p>
                    </MesDel>
                    <div className="table-icons">
                        <div 
                            className="icons _edit" 
                            onClick={()=>navigate(`../news/${item.id}`)}
                            data-help="Replay" data-tip="Veränderung" data-place="top" data-effect="solid" data-class="c-tooltips">
                        </div>
                        <div 
                            className="icons _del"
                            onClick={e=>{e.stopPropagation();setShow(true)}}
                            data-help="Delete" data-tip="Löschen" data-place="top" data-effect="solid" data-class="c-tooltips">
                        </div>
                        <Link to={`/news/${item.id}`} target="_blank"
                            className="icons _reply"
                            data-help="Open" data-tip="Offen" data-place="top" data-effect="solid" data-class="c-tooltips">
                        </Link>
                    </div>
                </td>
            </tr>
    )
}

export {NewsItem}