const Icons = () =>{
    return (
        <div className="page-content _white">
            <div className="page-block">
            
                <div className="page-content-text">
                    <div className="font-h1b-50">Warum Wir ?</div>
                    <div className="font-text1-32 _half" >Jeden Tag entscheiden sich viele Unternehmen und Experten für uns, weil:</div>
                </div>

                <div className="home-icons">
                    <div className="home-icons-item">
                        <div className="home-icons-img _ico1"></div>
                        <div>Keine versteckten Kosten, keine Vermittlungsgebühren</div>
                    </div>
                    <div className="home-icons-item">
                        <div className="home-icons-img _ico2"></div>
                        <div>Weil wir speziell auf Freelancer, Freiberufler und Unternehmer orientiert sind.</div>
                    </div>
                    <div className="home-icons-item">
                        <div className="home-icons-img _ico3"></div>
                        <div>Dank SSL - bzw. TLS-Verschlüsselung sind ihre Daten bei uns gut aufgehoben</div>
                    </div>
                    <div className="home-icons-item">
                        <div className="home-icons-img _ico4"></div>
                        <div>Wir sorgen dafür, das Auftraggeber, Interim Manager und Freelancer ohne Probleme aufeinander treffen</div>
                    </div>
                    <div className="home-icons-item">
                        <div className="home-icons-img _ico5"></div>
                        <div>Aus eigener Erfahrung wurden nur die notwendigsten Optionen ausgewählt und angepasst</div>
                    </div>
                    <div className="home-icons-item">
                        <div className="home-icons-img _ico6"></div>
                        <div> Doppelter Kommentar</div>
                    </div>
                </div>
            
            </div>
        </div>
    )
}

export {Icons}