import "./RatMes.scss";

import React, { useState } from "react";

import { useLogin } from "../hook/useLogin";
import useAxios from "../hook/useAxios";
import { Modal } from "./Modal";
import { RatStars } from "./RatStars";
import { useEffect } from "react";

const RatMes = ({ id, type, setShow, setRat }) => {
  const [disabled, setDisable] = useState(true);

  const { user } = useLogin();
  const axios = useAxios(user);

  const [allPoints, setPoints] = useState(null);

  // send form
  const onSubmit = (e) => {
    e.preventDefault();
    // request
    const req = { id: id, points: allPoints };
    axios
      .post("/user/rat", req)
      .then((res) => res.data)
      .then((data) => (data.res ? setRat(data.res) : ""));
    setShow(false);
  };

  // rat questions
  // 0 - freelancer
  // 1 - company
  const rat =
    type === 0
      ? [
          "Fachkompetenz",
          "Flexibilität",
          "Kommunikation",
          "Erfahrungen",
          "Verantwortung",
          "Teamarbeit",
          "Kann mehrere Anforderungen und Aufgaben. erfüllen",
          "Mobilität ( Projektstandort, Auslandseinsatz. Wenn nur Remoteprojekte – Bewertung mit *)",
          "Wahrscheinlichkeit Wiederbeauftragung",
        ]
      : [
          "Projektbeschreibung",
          "Projektangebote (kommen immer neue Angebote)",
          "Kommunikation",
          "Fachkompetenz des Ansprechpartner s",
          "Feedback zum Prozess",
          "Prozessdauer",
          "Begleitung während des Projektlaufzeit",
          "Zahlungsbedingungen",
          "Vertragsabschlussprozess  (kompliziert / unkompliziert)",
          "Projektverlängerung (wenn am Anfang darüber gesprochen wurde, wird dann auch seitens Provider eine Verlängerung beim Kunde diskutiert?)",
        ];

  // set empty array
  useEffect(() => {
    setPoints(Array(rat.length));
  }, []);

  // set points
  const setPoint = (i, bal) => {
    // set one point
    allPoints[i] = bal;
    // not render set same object
    setPoints(allPoints);

    // filter disable
    const dis = () =>
      allPoints.filter((item) => item).length === rat.length
        ? setDisable(false)
        : !disabled
        ? setDisable(true)
        : "";

    dis();
  };

  return (
    <Modal setShow={setShow}>
      <form onSubmit={onSubmit}>
        <div className="rat-mes">
          <div>
            <div className="font-h2b-32 _center">Bewertung</div>
          </div>
          <div className="res-mes-grid overlow">
            {rat.map((item, i) => (
              <React.Fragment key={i}>
                <div>{item}</div>
                <RatStars p={(index) => setPoint(i, index)} />
              </React.Fragment>
            ))}
          </div>
          <div>
            <button
              type="submit"
              className={`button button-primary ${
                disabled ? "disabled" : ""
              } _width`}
            >
              senden
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export { RatMes };
