
import { useEffect, useState } from "react";
import { ProjItem } from "./Proj/ProjItem";

import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";

const PageSize=5;

const Proj = () => {

    const {user}=useLogin();
    const axios=useAxios(user);

    // data state
    const [data, setData] = useState(null);

    useEffect(()=>{
        axios.get('/firm/list/')
        .then(res=>res.data)
        .then(data=>{
            setData(data.res);
        });
    },[])

    const deleteProj = proj => {
        setData(data.filter(item=>item.id!==proj.id));
        axios.delete('/firm/proj/',{params:{id:proj.id}});
    }

    const stopProj = proj => {  
        proj.pause=!proj.pause;
        setData([...data]);
        axios.post('/firm/stop',{id:proj.id});
    }

    return (
        <>
            <div className="fr-pr">

                <table className="table">
                    <thead>
                        <tr className="table-title">
                            <td>Projekt Tittel</td>
                            <td>Status</td>
                            <td>Aktionen</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data
                        ?data.map((item,i)=>
                            <ProjItem key={i} item={item} 
                                del={()=>deleteProj(item)} 
                                pause={()=>stopProj(item)}/>
                            )
                        :<tr></tr>}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export {Proj}