import "./Mes.scss";
import {Modal} from '../../../components/Modal';

const Mes = ({setShow}) =>{
    return (
        <Modal setShow={setShow}>
            <div className="mes-simple">
                <div className="font-h5b-18">Sie haben den Newsletter abonniert</div>
            </div>
        </Modal>
    )
}

export {Mes}