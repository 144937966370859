import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom";
import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";

const MailChecker = () =>{

    const {user} = useLogin();
    const axios = useAxios(user);

    const mail = useRef();
    const [mailCount,setMailCount]=useState(0);

    useEffect(()=>{
        if(user){
            const getMail = () => {
                axios.get('/mail/count')
                .then(res=>res.data)
                .then(data=>setMailCount(data.res))
            }
            mail.current = setInterval(()=>getMail(),5000);
            getMail();
        }
        return ()=>clearInterval(mail.current);
    },[]);

    return <div className="menu-mail">
        {mailCount?<Link to="/account/mail/" onClick={()=>setMailCount(0)}><span>{mailCount}</span></Link>:''}
    </div>
}

export {MailChecker}