import "./Info.scss";

import { useEffect, useState } from "react";

import { useLogin } from "../../hook/useLogin";
import useAxios from "../../hook/useAxios";

import { Loading } from "../../components/Loading";

import { Form } from "./Info/Form";
 
const Info = () => {

    const {user}=useLogin();
    const axios=useAxios(user);

    // type default
    var [type,setType] = useState({
        cat:[],
        skills:[],
        study:[],
        level:[]
    });
    
    var [data,setData] = useState(null);

    useEffect(()=>{
        // get info
        axios.get('/free/info')
        .catch(e=>console.log(e))
        .then(res=>res.data)
        .then(data=>{
            if(data.res){
                setData(data.res);
            }
        });

        // get types
        const req={
            params:{'type':[
                'cat',
                'skills',
                'study',
                'level'
            ]}
        };
        axios.get('/site/type/',req)
        .then(res=>res.data)
        .then(data =>setType(data));
    },[]);

    return (
        data?
        <Form
        data={data}
        setData={setData}
        type={type}
        axios={axios}/>
        :<Loading/>
    )
}

export {Info}