import { useState } from "react"
import { MySelect } from "../../../components/MySelect"

const LangAdd = ({setData,data,type}) => {

    const def={
        id:null,
        lang:'',
        idLevel:33 // intermedia
    }

    // add
    const addLang = () =>{
        let newItem=data.lang.concat([def]);
        setData({...data,lang:newItem});
    }

    return (
        <div className="section-block">
            <div className="button button-small _plus" onClick={()=>{addLang();}}>Position Einfügen</div>

        </div>
    )
}

export {LangAdd}