import { useEffect, useState, useMemo} from "react";

import { MesMail } from "../../components/MesMail/MesMail";
import { MySelect } from "../../components/MySelect";

import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";

import { UsersItem } from "./Users/UsersItem";

import Pagination from "../../components/Pagination";

const pageSize=10;

const Users = () =>{

    const dateToString = d =>{
        return `${d.getDate().toString().padStart(2, "0")}.${(d.getMonth()+1).toString().padStart(2, "0")}.${d.getFullYear()}`;
    }

    const type = [
        {value:0,label:'Bestätigung'},
        {value:1,label:'Freelancer'},
        {value:2,label:'Firm'},
        {value:3,label:'Alle Nutzer'},
    ]

    // data state
    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const {user}=useLogin();
    const axios=useAxios(user);

    const [search,setSearch] = useState({
        type:0,
        name:''
    });

    const [mail,setMail] = useState(false);
 
    // get user
    useEffect(()=>{
        axios.get('/admin/user',{params:search})
        .then(res=>res.data)
        .then(data=>{
            setData(data.res || []);
            setRefresh(false);
        });
    },[refresh]);

    // delete user
    const userDel = id =>{
        setData(data.filter(item=>item.id!==id));
        axios.delete('/admin/user',{params:{id:id}});
    }

    // set changes active
    const userSet = id =>{
        setData(data.filter(item=>item.id!==id));
        axios.post('/admin/user',{id:id});
    }

    // set tarif
    const userTar = (id,tarif,payTill) =>{
        ///console.log(id,tarif,payTill);
        setData(data.map(item => item.id === id 
            ? {...item,payd:1,tarif:tarif,payTill:dateToString(payTill)} 
            : item
        ));
    }

    // pagination state
    const [page,setPage] = useState(1);

    // curData
    const curData = useMemo(() => {
        const firstPageIndex = (page - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return data.slice(firstPageIndex, lastPageIndex);
    }, [page,data]);

    return (
        <>
            {mail?<MesMail setShow={setMail} id={data.map(item=>item.id)} topic="Eine Nachricht schicken"/>:<></>}
            <div className="table-search">
                <div>
                    <div className="inputs-block _label _span">
                        <input 
                            className="inputs" 
                            placeholder="Suche mit Name"
                            value={search.name} 
                            onChange={e=>setSearch({...search,name:e.target.value})}/>
                    </div>
                </div>
                <div>
                    <MySelect 
                        value={type.filter(item=>item.value===search.type)}
                        onChange={res=>setSearch({...search,type:res.value})} 
                        options={type} 
                        placeholder="Alle Arten"
                        isSearchable={false}/>
                </div>
                <div>
                    <div className="button button-primary _form" onClick={()=>setRefresh(true)}>Suche eingrenzen</div>
                </div>
                <div>
                    <div
                        className="icons _send"
                        data-help="Send" data-tip="Senden" data-place="top" data-effect="solid" data-class="c-tooltips"
                        onClick={()=>setMail(true)}>
                    </div>
                </div>
            </div>
            <table className="table _margin">
                <thead>
                    <tr className="table-title">
                        <td>Benutzer</td>
                        <td>Datum</td>
                        <td>Aktionen</td>
                    </tr>
                </thead>
                <tbody>
                    {curData
                    ?curData.map((item,i)=>
                    <UsersItem 
                        key={i}
                        item={item}
                        tar={(tarif,payTill)=>{userTar(item.id,tarif,payTill);}}
                        del={()=>userDel(item.id)} 
                        set={()=>userSet(item.id)}/>)
                        :<tr></tr>
                    }
                </tbody>
            </table>
            <Pagination
                className="pagination-bar"
                currentPage={page}
                totalCount={data.length}
                pageSize={pageSize}
                onPageChange={page => setPage(page)}
            />
            <div className="_center _margin">Benutzer: {data?.length || 0}</div>
        </>
    )
}

export {Users}