import "./MesMail.scss";
import { useRef, useState } from "react";
import {Modal} from '../Modal';

import { useLogin } from "../../hook/useLogin";
import useAxios from "../../hook/useAxios";

const MesMail = ({setShow,id,topic}) =>{

    const {user}=useLogin();
    const axios=useAxios(user);

    // state
    const def={
        mes:'Nachricht',
        topic:topic,
        id:id,
        files:[]
    }

    const [req,setReq] = useState(def);

    // choise file
    const choiseImage = useRef();

    const addFile = file => {
        if(file){
            if(file.size<=2000000){
                req.files.push(file);
                setReq({...req});
            }
            choiseImage.current.value='';
        }
    };

    const delFile = index =>{
        setReq({...req,files:req.files.filter((item,i)=>i!==index)});
    }

    // mail send form
    const submit = ()=>{
        var data = new FormData();
        data.append('mes',req.mes);
        data.append('topic',req.topic);
        data.append('id',req.id);
        req.files.map(item=>data.append("file[]", item));
        axios.post('/mail/',data)
        .then(req=>console.log(req.data));
        setReq(def);
        setShow(false);
    }
    
    return (
        <Modal setShow={setShow}>
            <div className="mes-mail">
                <div>
                    <div className="font-h2b-32 _center">Senden einer Nachricht</div>
                </div>
                <div>
                    <div className="inputs-block _label">
                        <label>Topic</label>
                        <input
                            className="inpust" 
                            value={req.topic} 
                            onChange={(e)=>setReq({...req,topic:e.target.value})}/>
                    </div>
                </div>
                <div>
                    {req.files.length?<div className="mail-atach">{req.files.map((item,i)=>
                        <div className="mail-atach-item" key={i}>
                            {item.name}
                            <div className="icons _close _vsmall" onClick={()=>delFile(i)}></div>
                        </div>
                    )}</div>:''}
                    <div className="inputs-block _label">
                        <label>Nachricht</label>
                        <textarea
                            className="textarea" 
                            value={req.mes} 
                            onChange={(e)=>setReq({...req,mes:e.target.value})}/>
                        <div className="mes-mail-atach">
                            <div 
                                className="icons _attach _small"
                                onClick={e => {choiseImage.current.click();}}>    
                            </div>
                            <input 
                                type="file" 
                                onChange={e => addFile(e.target.files[0])} 
                                ref={choiseImage}/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="button button-primary _width" onClick={()=>submit()}>senden</div>
                </div>
            </div>
        </Modal>
    )
}

export {MesMail}