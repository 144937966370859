import './Footer.scss';

import {Link} from 'react-router-dom';
import { Spam } from './Spam';
import { useLogin } from "../hook/useLogin"

const Footer = () =>{

    const {config} = useLogin();

    return (
        <div className="page-bottom">
            <div className="page-block">

                <div className="page-bottom-content">
                    <div className="page-bottom-left">
                        <Link to="/" className="page-bottom-logo">
                        
                        </Link>
                        <div className="page-bottom-contacts">
                            <Link to="/contacts" className="page-bottom-link">Kontakt</Link>
                            <Link to="/about" className="page-bottom-link">Unternehmen</Link>
                            <Link to="/news" className="page-bottom-link">News</Link>
                        </div>
                        {config?<div className="page-soc">
                            {Object.keys(config.socJson).map((item,i) =>
                                {
                                    return config.socJson[item]!==''
                                    ?<a key={i} href={config.socJson[item]} target="_blank" rel="noreferrer" className="page-soc-item">
                                        <div className={`soc soc-${item}`}></div>
                                    </a>
                                    :''
                                }
                            )}
                        </div>:<></>}
                        <div className="page-feedback">
                            <div className="page-feedback-title font-h7b-14">Newsletter</div>
                            <Spam/>
                        </div>
                    </div>
                    <div className="page-bottom-menu">

                        <div className="page-bottom-item">
                            <div className="page-bottom-title font-h3b-24">Service</div>
                            <div className="page-bottom-list">
                                <Link to="/services/free/" className="page-bottom-link">Leistungen & Preise für Freelance</Link>
                                <Link to="/services/firm/" className="page-bottom-link">Leistungen & Preise für Firmen</Link>
                                <Link to="/services/free/info/" className="page-bottom-link">Info für Freelancer</Link>
                                <Link to="/services/firm/info/" className="page-bottom-link">Info für Firmen</Link>
                            </div>
                        </div>

                        <div className="page-bottom-item">
                            <div className="page-bottom-title font-h3b-24">Menü</div>
                            <div className="page-bottom-list">
                                <Link to="/projs" className="page-bottom-link" rel="norefferer">Projekte</Link>
                                <Link to="/freelances" className="page-bottom-link" rel="norefferer">Freelancer</Link>
                                <Link to="/firms" className="page-bottom-link" rel="norefferer">Firmen</Link>
                            </div>
                        </div>

                        <div className="page-bottom-item">
                            <div className="page-bottom-title font-h3b-24">Rechtliches</div>
                            <div className="page-bottom-list">
                                <Link to="/page/imp/" className="page-bottom-link" rel="norefferer">Impressum</Link>
                                <Link to="/page/agb/" className="page-bottom-link" rel="norefferer">AGB</Link>
                                <Link to="/page/date/" className="page-bottom-link" rel="norefferer">Datenschutz</Link>
                            </div>
                        </div>

                        <div className="page-bottom-item">
                            <div className="page-bottom-title font-h3b-24">Zahlungen</div>
                            <div className="page-bottom-list">
                                <Link to="/page/rules/" className="page-bottom-link"  rel="norefferer">Rechnung</Link>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className="page-copyright font-text6-13">
                <div className="page-block">
                    © freelancerweb.de, {(new Date().getFullYear())}
                </div>
            </div>

        </div>
    )
}

export {Footer}