import {useState } from "react";
import ReactTooltip from "react-tooltip";
import { useNavigate} from "react-router-dom";

import useAxios from "../../../hook/useAxios";
import {useLogin} from "../../../hook/useLogin";

import { MesDel } from "../../../components/MesDel/MesDel";

const Top = ({data}) =>{

    const {user}=useLogin();
    const axios=useAxios(user);

    var navigate=useNavigate();
    const goBack = () => navigate(-1);

    // delete mes
    const mesDel = (id) =>{
        axios.delete('/mail/',{params:{id:id}});
        goBack();
    }

    const [show,setShow] = useState(false);

    return (
        <div className="mail-top">
            <div className="icons _back" onClick={goBack}></div>
            <div>
                <div className="mail-user">
                    <div className="mail-foto">
                        <img src={data.mail.img} alt="Foto"/>
                    </div>
                    <div>
                        <div className="mail-text">{data.mail.name}</div>
                        <div className="mail-name font-h7b-14">{data.mail.topic}</div>
                    </div>
                </div>
            </div>
            <div className="mail-data">
                <div>Bewerbung vom {data.mail.data}</div>
                <div>Letzte Aktivität {data.mail.last}</div>
            </div>
            <ReactTooltip />
            <MesDel setShow={setShow} show={show} del={()=>{setShow(false);mesDel(data.mail.id)}}>
                <p>Löschen Sie die Korrespondenz wirklich?</p>
            </MesDel>
            <div 
                className="icons _del" 
                data-help="Delete" data-tip="Löschen" data-place="top" data-effect="solid" data-class="c-tooltips"
                onClick={()=>setShow(true)}
                ></div>
        </div>
    )
}

export {Top}