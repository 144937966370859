import { useEffect, useState } from "react"

import useAxios from "../../../hook/useAxios";
import { useLogin } from "../../../hook/useLogin";

const TagsList = ({tags,data,setData}) => {

    const {user}=useLogin();
    const axios=useAxios(user);

    //const list=data.filter(item=>item[tags]===1);
    const list=data;

    const delTags = id =>{
        console.log(id);
        axios.delete('/admin/tags',{params:{id:id}})
        .then(()=>setData(list.filter(item=>item.id!==id)));
    }

    return (
        <div className="taglist">
            {list.map((item,i)=>
                <div className="taglist-item" key={i}>
                    {item.name} 
                    <div className="icons _close taglist-item-del" onClick={()=>delTags(item.id)}></div>
                </div>)
            }
        </div>
    )

}

export {TagsList}