import { Navi } from "../../../components/Navi";

const Top = () =>{

    const list=[
        {url:"/",name:"Home"},
        {url:"services/firm/",name:"Leistungen & Preise fur Firmen"}
    ]

    return (
        <div className="services-banner">
            <div className="services-banner-block">
                <div className="page-block">
                    <Navi list={list} white/>
                </div>

                <div className="page-block">

                    <div className="services-banner-title font-h3b-24">Sie sind nur 3 Schritte von Ihren neuen Projekt entfernt</div>

                    <div className="services-banner-icons">
                        <div className="services-banner-item">
                            <div className="services-banner-graf _user"></div>
                            <div className="services-banner-name">1. Registrieren</div>
                        </div>
                        <div className="services-banner-item">
                            <div className="services-banner-graf _stat"></div>
                            <div className="services-banner-name">2. Daten eingeben</div>
                        </div>
                        <div className="services-banner-item">
                            <div className="services-banner-graf _true"></div>
                            <div className="services-banner-name">3. Projektausschreiben</div>
                        </div>
                        <div className="services-banner-item">
                            <div className="services-banner-graf _play"></div>
                            <div className="services-banner-name">4. Loslegen</div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export {Top}