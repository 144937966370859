import { useMemo } from "react";
import { UserItem } from "./UserItem";

const UserList = ({data,page,pageSize}) =>{

    // slide
    const curData = useMemo(() => {
        const firstPageIndex = (page - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return data.slice(firstPageIndex, lastPageIndex);
    }, [page,data]);

    return (
        data.length?<>
            <div className="font-h6b-16 fr-st-title">Profilbesucher</div>
            <div className="fr-st-cards">
                {curData.map((item,i)=><UserItem item={item} key={i}/>)}
            </div>
        </>:''
    )
}

export {UserList}
