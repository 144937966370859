import { useEffect, useState } from "react";
import { ArhiveItem } from "./Arh/ArhiveItem";

import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";

const Arhive = () => {

    const {user}=useLogin();
    const axios=useAxios(user);
    
    const [data, setData] = useState(null);

    useEffect(()=>{
        axios.get('/firm/arh/')
        .then(res=>res.data)
        .then(data=>setData(data.res));
    },[]);

    const deleteProj = proj => {
        setData(data.filter(item=>item.id!==proj.id));
        axios.delete('/firm/proj/',{params:{id:proj.id}});
    } 

    return (
        <div className="fr-pr">

            <table className="table">
                <thead>
                    <tr className="table-title">
                        <td>Projekt Tittel</td>
                        <td>Status</td>
                        <td>Aktionen</td>
                    </tr>
                </thead>
                <tbody>
                    {data
                    ?data.map(
                        (item,i)=>
                            <ArhiveItem 
                            key={i} 
                            item={item} 
                            del={()=>deleteProj(item)}/>
                        )
                    :<tr></tr>}
                </tbody>
            </table>
        </div>
    )
}

export {Arhive}