import { useState } from 'react';
import './Loading.scss';

const Loading = () => {

    const [error,setError] = useState(false);
    const [start,setStart] = useState(false);

    setTimeout(
        ()=>{setError(true)},10000
    )

    setTimeout(
        ()=>{setStart(true)},300
    )

    return (
        !error
        ?start
        ?<div className="loadingio-spinner-dual-ring-x63m4hm89l">
            <div className="ldio-bs873xp74oe">
                <div></div><div><div></div></div>
            </div>
        </div>
        :''
        :<div>Load error</div>
    )
}

export {Loading};