import './Content.scss';

// page
import {Proj} from './Proj';
import {Prof} from './Prof';
import {Info} from './Info';
import {Spam} from './Spam';
import {Company} from './Company';

// user for all
import {Main} from '../user/Main.jsx';
import {Mail} from '../user/Mail';
import {MailDet} from '../user/MailDet';
import {Stat} from '../user/Stat';
import {Config} from '../user/Config';

// menu
import json from './menu.json';
import {Menu} from '../user/Menu.jsx';

// user
import { useLogin } from '../../hook/useLogin';
import {Routes, Route,Navigate} from 'react-router-dom';

import { Premium } from '../user/Premium';

const FreeContent = () => {

    const {user}=useLogin();

    const pr=<div className="fr-top">
        <div className="page-block">
            <div className="fr-top-flex">
                <div>Nutzen Sie alle Optionen des Portals</div>
                <div className="button button-primary _green ">Account Upgraden</div>
            </div>
        </div>
    </div>

    return (
        <>
            <Premium/>

            <div className="page-block _small">
                <div className="fr">
                    <Menu json={json}/>
                
                    <div className="fr-content">
                        <Routes>
                            <Route path="/main" element={<Main json={json} status={0}/>}/>
                            <Route path="/proj" element={<Proj/>}/>
                            <Route path="/prof" element={<Prof/>}/>
                            <Route path="/info" element={<Info/>}/>
                            <Route path="/stat" element={<Stat/>}/>
                            <Route path="/company" element={<Company/>}/>
                            <Route path="/mail" element={<Mail/>}/>
                            <Route path="/mail/:id" element={<MailDet/>}/>
                            <Route path="/news" element={<Spam/>}/>
                            <Route path="/config/" element={<Config/>}/>
                            <Route path="*" element={<Navigate to={'./main'}/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    )
}

export {FreeContent}