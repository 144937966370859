const MainInfo = ({data}) =>{
    return (
        <div className="fr-pr-top">
            <div className="fr-pr-item">
                <div className="fr-pr-number">{data.main.all}</div>
                <div className="fr-pr-text">Bewerbungen<br/>gesendet</div>
            </div>

            <div className="fr-pr-item">
                <div className="fr-pr-number">{data.main.active}</div>
                <div className="fr-pr-text">Aktive<br/>Bewerbungen </div>
            </div>

            <div className="fr-pr-item">
                <div className="fr-pr-number">{data.main.cancel}</div>
                <div className="fr-pr-text">Geschlossene<br/>Bewerbungen</div>
            </div>
        </div>
    )
}

export {MainInfo}