import './Contacts.scss';

import {useLogin} from '../../hook/useLogin';

import { Info } from './Contacts/Info';
import { Form } from './Contacts/Form';

const Contacts = () => {

    const {config} = useLogin();

    return (
        config?
        <div className="contacts-content">
            <div className="page-block _small">
                <div className="contacts-block">
                    <Info config={config}/>
                    <Form/>
                </div>
            </div>
        </div>:''
    )
}

export {Contacts}