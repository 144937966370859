const Lang = ({list}) =>{
    return (
        list.length?
        <div>
            <div className="title">Sprachkenntnisse</div>
            <div className="other-det-gr">
                {list.map((item,i)=>
                    <div className="other-det-gritem"  key={i}>
                        <div className="other-det-grtitle">{item.name}:</div>
                        <div>{item.label}</div>
                    </div>
                )}
            </div>
        </div>
        :''
    )
}

export {Lang}