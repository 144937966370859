import { useSpring, animated } from 'react-spring'

const CirclesItem = (item) =>{

    const {number} = useSpring({ 
        number:item.animate?item.number:0,
        from: { number: 0},
        config:{
            duration: 1500
        }
      });

    let svg = (<div className="home-graf-img">
        <svg width="140" height="140" viewBox="0 0 140 140" className="donut" style={{color:'var(--color-red-lable)'}}>
            <circle cx="70" cy="70" r="60" fill="transparent" stroke="#313A4E" strokeWidth="4" strokeOpacity="0.70"></circle>
            <circle className="circle" cx="70" cy="70" r="60" fill="transparent" stroke="#0F8BFF" strokeWidth="6" strokeLinecap="round" strokeDasharray="0 540" strokeDashoffset="135"></circle>
        </svg>
    </div>);

    return (
        <div className={`home-graf-item ${item.animate?'animated':''}`}>
            <div className="home-graf-circle">
                {svg}
                <div className="home-graf-val">
                    <animated.div>
                        {/* {number.to(n=>n.toFixed(0))} */}
                    </animated.div>
                </div>
            </div>
            <div className="home-graf-title">{item.name}</div>
        </div>
    )
}

export {CirclesItem}