import './Proj.scss';

import {useEffect, useState } from "react";

import useAxios from '../../hook/useAxios';
import {useLogin} from '../../hook/useLogin';

import { MainInfo } from "./Proj/MainInfo";
import { ProjItem } from './Proj/ProjItem';

const Proj = () => {

    const {user}=useLogin();
    const axios=useAxios(user);

    const [data,setData] = useState({
        main:{
            all:0,
            cancel:0,
            active:0
        }
        // proj from axios
    });

    // server request
    useEffect(()=>{
        axios.get('/free/proj')
        .then(res=>res.data)
        .then(data=>{
            setData(data.res)
        });
    },[])

    const projDel = proj =>{
        data.proj=data.proj.filter(item=>item.id!==proj.id)
        setData({...data});
        axios.delete('/free/proj',{params:{id:proj.id}})
        .then(res=>res.data)
        .then(data=>{
            data.main=data.res;
            setData({...data});
        });
    }

    return (
        <div className="fr-pr">
                <MainInfo data={data}/>

                <table className="table">
                    <thead>
                        <tr className="table-title">
                            <td>Projekt Tittel</td>
                            <td>Datum</td>
                            <td></td>
                            <td>Status</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.proj?data.proj.map((item,i)=><ProjItem
                            key={i}
                            item={item} 
                            del={()=>projDel(item)} 
                        />):<tr></tr>}
                    </tbody>
                </table>
            </div>
    )
}

export {Proj}