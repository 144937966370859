import {Controller} from 'react-hook-form';
import InputMask from 'react-input-mask';

import { Errors } from "../../../components/Errors";
import { NewEditor } from "../../../components/NewEditor/NewEditor"
import DatePicker from "../../../hook/useDatepicker";

const Work = ({setData,data,form}) => {

    console.log(data);

    const err={
        req : 'bitte ausfüllen',
        df:'falsches Datum',
    }

    //const dataRegx=/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

    // data fn
    const setDate = (date) =>(
        date
        ?new Date(date)
        :null
    )

    const delWork = (index)=>{
        // delete by index
        let newItem=data.work.filter((item,key)=>key!==index);
        setData({...data,work:newItem});
    }

    //! useFieldArray in DocAdd

    return (
        data.work.map((item,key)=>{
        
        const inputName=`work.${key}`;
        const error=form.errors?.work?.[key];

        return <div className="section-block" key={key}>
            <input type="hidden" {...form.register(`${inputName}.id`)} />
            <div className="fr-fr-s">
                <div className='fr-fr-data'>
                    <div>
                        <div className="inputs-block _label">
                            <label>Start</label>
                            <Controller 
                                    name={`${inputName}.startData`}
                                    control={form.control}
                                    rules={{required:err.req}}
                                    render={({field:{onChange,value}})=>{
                                        return <DatePicker 
                                            selected={setDate(value)}
                                            closeOnScroll={true}
                                            alwaysShowMask={false}
                                            onChange={onChange}
                                            maxDate={Date.now()}
                                            className="myDatepicker"
                                            dateFormat="dd.MM.yyyy"
                                            placeholder="Datum auswählen"
                                            customInput={
                                                <InputMask mask="99.99.9999" maskChar={null}/>
                                            }
                                        />
                                    }}
                                />
                            {/* <Controller 
                                name={`${inputName}.startData`}
                                control={form.control}
                                rules={{
                                    required:err.req,
                                    pattern: {
                                        value: dataRegx,
                                        message: err.df,
                                    }
                                }}
                                render={({field:{onChange,value}})=>
                                    <InputMask 
                                        mask='99.99.9999'
                                        maskChar=''
                                        value={value}
                                        onChange={onChange}
                                        placeholder="Datum auswählen"
                                    />
                                }
                            /> */}
                            <div className="icons _cal _small inputs-ico"></div>
                        </div>
                        <Errors error={error?.startData?.message}/>
                    </div>
                    <div>
                        <div className="inputs-block _label">
                            <label>Ende</label>
                            <Controller 
                                name={`${inputName}.endData`}
                                control={form.control}
                                render={({field:{onChange,value}})=>
                                    <DatePicker
                                        selected={setDate(value)}
                                        closeOnScroll={true}
                                        alwaysShowMask={true}
                                        onChange={onChange}
                                        maxDate={Date.now()}
                                        className="myDatepicker"
                                        dateFormat="dd.MM.yyyy"
                                        placeholder="Datum auswählen"
                                        customInput={
                                            <InputMask mask="99.99.9999" maskChar={null}/>
                                        }
                                    />
                                }
                            />
                            {/* <Controller 
                                name={`${inputName}.endData`}
                                control={form.control}
                                rules={{
                                    pattern: {
                                        value: dataRegx,
                                        message: err.df,
                                    }
                                }}
                                render={({field:{onChange,value}})=>
                                    <InputMask 
                                        mask='99/99/9999'
                                        maskChar=''
                                        value={value}
                                        onChange={onChange}
                                        placeholder="Datum auswählen"
                                    />
                                }
                            /> */}
                            <div className="icons _cal _small inputs-ico"></div>
                        </div>
                        <Errors error={error?.endData?.message}/>
                    </div>
                </div>
                <div>
                    <div className="inputs-block _label">
                        <label>Firma</label>
                        <input 
                            {...form.register(`${inputName}.companyName`,{required:err.req})}
                            placeholder="Apple" 
                            className="inputs off" 
                            autoComplete="off"/>
                    </div>
                    <Errors error={error?.companyName?.message}/>
                </div>
                <div>
                    <div className="inputs-block _label">
                        <label>Branche</label>
                        <input 
                            {...form.register(`${inputName}.companyCat`)}
                            placeholder="" 
                            className="inputs off" 
                            autoComplete="off"/>
                    </div>
                </div>
                <div>
                    <div className="inputs-block _label">   
                        <label>Position / oder Projekt</label>
                        <input 
                            {...form.register(`${inputName}.position`,{required:err.req})}
                            placeholder="Position"
                            className="inputs off" 
                            autoComplete="off"/>
                    </div>
                    <Errors error={error?.position?.message}/>
                </div>
                <div>
                    <div className="inputs-block _label">
                        <label>Kurzbeschreibung</label>
                        <Controller 
                            name={`${inputName}.descrText`}
                            rules={{required: err.req}}
                            control={form.control}
                            render={({field:{onChange,value}})=>
                                <NewEditor 
                                    html={value || ''}
                                    onChange={html=>onChange(html)}
                                />
                            }
                        />
                    </div>
                    <Errors error={error?.descrText?.message}/>
                </div>
                
            </div>
            <div className="button button-small _minus" onClick={()=>{delWork(key)}}>Position löschen</div>
        </div>})
    )
}

export {Work}