const Study = ({list}) =>{
    return (
        list.length?<div>
            <div className="title">Aus-Und Weiterbildung</div>
            <div className="other-det-ls">
                {list.map((item,i)=>
                    <div className="other-det-lsitem" key={i}>
                        <div className="other-det-lstitle">
                            {item.startData}{item.endData?' - '+item.endData:''}  <span>{item.studyName}</span>
                        </div>
                        <div className="other-det-lstext">{item.studyPlace}</div>
                    </div>
                )}
            </div>
        </div>:''
    )
}

export {Study}