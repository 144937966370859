import { useState } from "react";
import { Link } from "react-router-dom"

import { PayMes } from "../../../components/PayMes/PayMes";
import { useLogin } from "../../../hook/useLogin";

const Company = ({data}) =>{

    const {user} = useLogin();

    const [pay,setPay] = useState(false);

    // email show
    const [emailShow,setEmail] = useState(false);

    return (
        <>
            {pay?<PayMes setShow={setPay} show={pay}/>:''}
            <div className="title">Über das Unternehmen</div>
            <div className="other-det-company">
                {user && user.payd?
                // show company
                <>
                    <Link 
                        to={`/firm/${data.company.id}`} 
                        className="logo" 
                        state={{from:1,back:`/proj/${data.id}`}}>
                            {data.company.logo!==''
                            ?<img src={data.company.logo} alt="Logo"/>
                            :<div className="icons _user _black _big _opacity"></div>
                            }
                    </Link>
                    <div className="other-det-info">
                        <div className="font-h2b-32">{data.company.name}</div>
                        <div className="other-det-icons">
                            {data.company.map?<div className="other-det-item"><div className="icons _map"></div><div className="font-text4-16">{data.company.map}</div></div>:''}
                            {data.company.email?<div className="other-det-item"><div className="icons _email"></div><div className="font-text4-16">
                                {
                                    emailShow
                                    ?<a href={`mailto:${data.company.email}`} className="href" target="_blank" rel="nofollow">{data.company.email}</a>
                                    :<div onClick={()=>setEmail(true)} className="href pr-item-text">E-Mail Adresse anzeigen</div>
                                }
                            </div></div>:''}
                            {data.company.web?<div className="other-det-item"><div className="icons _web"></div><div><a href={`https://${data.company.web}`} target="_blank" rel="nofollow" className="font-text4-16">{data.company.web}</a></div></div>:''}
                            {data.company.phone?<div className="other-det-item"><div className="icons _phone"></div><div className="font-text4-16">{data.company.phone}</div></div>:''}
                        </div>
                    </div>
                </>:
                // open pay modal
                <div 
                    className="logo" 
                    onClick={()=>setPay(true)}>
                    <div className="icons _user _black _big _opacity"></div>
                </div>}
            </div>
        </>
    )
}

export {Company}