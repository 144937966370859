import { useRef, useState } from "react";

import useAxios from "../../../hook/useAxios";
import {useLogin} from "../../../hook/useLogin";
//import { MailFiles } from "./MailFiles";

const MesForm = ({id,setData,append}) =>{

    const {user}=useLogin();
    const axios=useAxios(user);

    const choiseImage = useRef();

    const def={
        id:id,
        mes:'',
        files:[]
    }

    const [req,setReq] = useState(def);

    // send
    const submit = () =>{

        var data = new FormData();
        data.append('mes',req.mes);
        data.append('id',req.id);
        req.files.map(item=>data.append("file[]", item));

        // send mes
        axios.post('/mail/mes/',data)
        .then(res=>res.data)
        .then(data=>{
            if(data.res){
                append(data.res);
                setReq(def);
            }
        })
    };

    const addFile = file => {
        if(file){
            if(file.size<=2000000){
                req.files.push(file);
                setReq({...req});
            }
            choiseImage.current.value='';
        }
    };

    const delFile = index =>{
        setReq({...req,files:req.files.filter((item,i)=>i!==index)});
    }

    return (
        <div>
            {/* file upload list */}
            {req.files.length?<div className="mail-atach">{req.files.map((item,i)=>
                <div className="mail-atach-item" key={i}>
                    {item.name}
                    <div className="icons _close _vsmall" onClick={()=>delFile(i)}></div>
                </div>
            )}</div>:''}
        
            <div className="mail-form">
                <div>
                    <div className="inputs-block _label _span">
                        <label>Sofort antworten</label>
                        <textarea
                            className="textarea" 
                            value={req.mes}
                            onChange={e=>setReq({...req,mes:e.target.value})}/>
                        <div className="mes-mail-atach">
                            <div 
                                className="icons _attach _small"
                                onClick={e => {choiseImage.current.click();}}>    
                            </div>
                            <input 
                                type="file" 
                                onChange={e => addFile(e.target.files[0])} 
                                ref={choiseImage}/>
                        </div>
                        {/* <div className="mail-attach">
                            <div>Nur für Premium Mitglieder möglich</div>
                            <div className="icons _attach active"></div>
                        </div> */}
                    </div>
                </div>
                <div><div className="icons _send" onClick={()=>submit()}></div></div>
            </div>
        </div>
    )
}

export {MesForm}