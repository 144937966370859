import './Mes.scss';
import {Modal} from '../../components/Modal';

import useAxios from '../../hook/useAxios';
import { useLogin } from '../../hook/useLogin';
import { useState } from 'react';

const Mes = ({setShow,type}) =>{

    // axios user
    const {user}=useLogin();
    const axios = useAxios(user);

    const [send,setSend] = useState(true);

    const tarif=[];
    tarif[0]=[null,0,60];
    tarif[1]=[null,480,720];

    const text=[null,'Basis','Premium'];

    // send request
    const sendRequest = () =>{
        const data={
            tarif:type
        }
        axios.post('/user/premium',data);
        setSend(false);
    }

    const pay=tarif[user.type][type];
    const taxes=pay*0.19;
    const sum=pay+taxes;

    return (
        <Modal setShow={setShow}>
            <div className="mes-premium">
                {send?<div className='mes-premium-content'>
                    <div className="mes-premium-title">Sie haben den {text[type]}-Tarif gewählt.</div>
                    {pay?<div className='mes-premium-price'>
                        <div><div>Jährlich:</div><div>{pay.toFixed(2)}€</div></div>
                        <div><div>MwSt:</div><div>{taxes.toFixed(2)}€</div></div>
                        <div className="mes-premium-sum"><div>Summe:</div><div>{sum.toFixed(2)}€</div></div>
                    </div>:<></>}
                    <div>Nachdem Sie die Auswahl bestätigt haben, erhalten Sie Details zur Zahlung</div>
                    <div
                        onClick={()=>sendRequest()} 
                        className="button button-primary">
                        Auswahl bestätigen
                    </div>
                </div>
                :<div className='mes-premium-content'>
                    <div className="font-h5b-18">Vielen Dank! Ihre Anmeldung wurde verschickt, warten Sie auf eine Bestätigung</div>
                </div>}
            </div>
        </Modal>
    )
}

export {Mes}