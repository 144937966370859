import { Link } from "react-router-dom"

const Proj = ({list,title,company}) =>{
    
    return (
        <div>
            <div className="title">{title}</div>
            <div className="other-det-pr">
            {list.map((item,i)=>
                <Link key={i} className="other-det-pritem" to={`/proj/${item.id}`} state={{from:1,back:`/firm/${company}`}}>
                    <div className="other-det-prtitle">{item.projName}</div>
                    <div className="other-det-pricons">
                        <div><div className="icons _home"></div><div>{item.typeName}</div></div>
                        <div><div className="icons _clock"></div><div>Anfang: {item.startData}</div></div>
                        <div><div className="icons _bag"></div><div>{item.workType}</div></div>
                        {item.endData ? <div><div className="icons _clock"></div><div>Ende: {item.endData}</div></div>: ""}
                    </div>
                </Link>)}
            </div>

            {/* <div className="other-det-button">
                <div className="button button-primary">alle anzeigen</div>
            </div> */}
        </div>
    )
}

export {Proj}