import {useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { MesDel } from "../../../components/MesDel/MesDel";

const CompanyItem = ({del,item}) =>{

    const [show,setShow] = useState(false);

    return (
    <tr className="table-row" key={item.id}>
        <td>
            <ReactTooltip />
            <MesDel setShow={setShow} show={show} del={()=>{setShow(false);del()}}>
                <p>Folgen Sie dem Freiberufler nicht mehr?</p>
            </MesDel>
            <div className="table-image">
                <Link 
                    to={`/firm/${item.id}`}
                    className="table-image-ico">
                    {item.img
                        ?<img src={item.img} alt="Foto"/>
                        :<div className="icons _user _black _opacity"></div>
                    }
                </Link>
                <div>
                    <div className="table-bold">{item.companyName}</div>
                </div>
            </div>
        </td>
        <td>
            <div className="card-map">
                <div className="icons _map _small"></div>
                <div>{item.city}, {item.country}</div>
            </div>
        </td>
        <td>
            <div className="button button-line" 
                onClick={()=>setShow(true)}>
                nicht mehr folgen</div>
        </td>
    </tr>)
}

export {CompanyItem}