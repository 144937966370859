import { useEffect, useState } from 'react';

export function useScroll () {

    const [offset, setOffset] = useState({});

    useEffect(() => {
        const onScroll = () => setOffset({
            top:window.pageYOffset,
            bottom:window.pageYOffset+window.innerHeight,
            middle:window.pageYOffset+window.innerHeight/2
        });
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return offset
};