import { Link } from "react-router-dom"
import {Swiper,SwiperSlide } from "swiper/react"
import { useLogin } from "../../../hook/useLogin"

const TopFirm = ({list}) =>{

    const {user,setModal} = useLogin();

    //const chunk = (a,n)=>[...Array(Math.ceil(a.length/n))].map((_,i)=>a.slice(n*i,n+n*i));

    return (
        list?
        <div className="page-content _white">
            <div className="page-block">
            
                <div className="page-content-text">
                    <div className="font-h1b-50">Top Dienstleister</div>
                </div>

                <div className="swiper-block">
                    <Swiper 
                        breakpoints={{
                            400: {
                                slidesPerView: 3,
                                slidesPerGroup:3
                            },
                            800: {
                                slidesPerView: 5,
                                slidesPerGroup:5
                            },
                            1000: {
                                slidesPerView: 6,
                                slidesPerGroup:6
                            }
                        }}
                        pagination={{ clickable: true }}
                        spaceBetween={10}
                        centeredSlides={false}
                        centerInsufficientSlides={true}
                        centeredSlidesBounds={false}>
                        {list.map((item,i)=>
                            <SwiperSlide key={i}>
                                {user?
                                <Link to={`/firm/${item.id}`} className="home-company-item">
                                    <img src={item.img} alt="logo"/>
                                </Link>:<div 
                                    onClick={()=>setModal({open:true,st:'mn'})}
                                    className="home-company-item" key={item.id}>
                                    <img src={item.img} alt="logo"/>
                                </div>}
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            
            </div>

        </div>:''
    )
}

export {TopFirm}