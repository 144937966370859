import "./FeedMes.scss";
import { useState } from "react";
import {Modal} from '../Modal';

import { useLogin } from "../../hook/useLogin";
import useAxios from "../../hook/useAxios";

const FeedMes = ({setShow,id}) =>{

    const def={
        mes:'',
        id:id
    }

    const [req,setReq] = useState(def);

    const {user}=useLogin();
    const axios=useAxios(user);

    // send form
    const submit = ()=>{
        axios.post('/user/feedback',req)
        setReq(def);
        setShow(false);
    }
    
    return (
        <Modal setShow={setShow}>
            <div className="mes-mail">
                <div>
                    <div className="font-h2b-32 _center">Hinterlassen Sie eine Bewertung</div>
                </div>
                <div>
                    <div className="inputs-block _label">
                        <label>Nachricht</label>
                        <textarea
                            className="textarea" 
                            value={req.mes} 
                            onChange={(e)=>setReq({...req,mes:e.target.value})}/>
                    </div>
                </div>
                <div>
                    <div className="button button-primary _width" onClick={()=>submit()}>senden</div>
                </div>
            </div>
        </Modal>
    )
}

export {FeedMes}