import { useParams,useNavigate} from "react-router-dom"
import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";
import {Loading} from "../../components/Loading";
import { useEffect } from "react";

const Proof = () =>{

    const {signIn} = useLogin();
    const navigate=useNavigate();
    
    const {cod} = useParams();
    const axios = useAxios();

    useEffect(()=>{
        axios.post('/site/proof',{cod})
        .then(res=>res.data)
        .then(data=>{
            if(data.res){
                // pas 
                data.res.pas=data.res.pas.split("|")[0];
                axios.get('/site/enter',{params:data.res})
                .then(res=>res.data)
                .then(data => 
                    data.res
                    ?signIn(data.res,'/account/main')
                    :navigate('/')
                )
            }else{
                navigate('/')
            }
        })
    },[])

    return (
        <Loading/>
    )
}

export {Proof}