import "./Mes.scss";
import {Modal} from '../../../components/Modal';

const MesOk = ({setShow}) =>{
    return (
        <Modal setShow={setShow} navi='/account/news/'>
            <div className="mes-simple">
                <div className="message-icon">
                    <div className='icons _alert _alert-ok'></div>
                </div>
                <div className="font-h5b-18">Die Daten wurden gespeichert</div>
                <div>Jetzt können Sie zur Nachrichtenliste gehen</div>
            </div>
        </Modal>
    )
}

export {MesOk}