import "./Mes.scss";

import { useState } from 'react';
import {Modal} from '../../../components/Modal';
import { Errors } from "../../../components/Errors";

import useAxios from "../../../hook/useAxios";
import {useLogin} from "../../../hook/useLogin";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Mes = ({data,setShow,show,alertMes}) =>{

    const {user}=useLogin();
    const axios=useAxios(user);

    // temp
    //const temp='Доброго дня! Мені какавий ваш проект, розкажіть щось про нього';

    const def = `Sehr geehrte Damen und Herren, ich interessiere mich für Ihr Projekt.
Bitte kontaktieren Sie mich diesbezüglich`;

    const [mes,setMes]=useState(def);
    const [error,setError]=useState(null);
    const [pay,setPay]=useState(false);

    useEffect(()=>{
        setMes(def);
        setError(null);
        setPay(false);
    },[]);

    console.log(mes);

    // send form
    const submit = ()=>{
        // send req
        axios.post('/free/proj',{id:data.id})
        .then(res=>res.data)
        .then(res=>{            
            // if no some errors
            if(res.error){
                setError(res.error);
            // if no pay account
            }else if(res.pay){
                setPay(true);
            }else{

                // alert and send id info

                //mes.replace(/(?:\r\n|\r|\n)/g, '<br />')

                const messsage=`${mes}
                
Alle meine Bewerbungsunterlagen sind für Sie auf meine Seite bei der FreelanceHunter <a href="/freelance/${user.id}">hinterlegt.</a>
                
Mit freundlichen Grüßen
${user.name} ${user.lastName}`;

                // send mail request
                const req={
                    id:data.idUser,
                    topic:'Bewerbung eingereicht',
                    mes:messsage,
                }

                axios.post('/mail/',req);
                
                // clear all inputs and close
                setMes('');
                setShow(false);
                alertMes(true);
            }
        }
        );

    }

    return (
        <Modal setShow={setShow}>
            <div className="proj-mes">
                {error?<div>
                    <div>{error}</div>
                </div>
                :!pay?
                <>
                    <div>
                        <div className="font-h2b-32 _center">Bewerben</div>
                    </div>
                    <div>
                        <div className="font-text5-14">Projekte</div>
                        <div className="font-h5b-18">{data.name}</div>
                    </div>
                    <div>
                        <div className="inputs-block _label">
                            <label>Nachricht</label>
                            <textarea
                                className="textarea" 
                                placeholder="Anwendungsbenachrichtigung (für Basiskonto)"
                                value={mes} 
                                onChange={(e)=>setMes(e.target.value)}/>
                        </div>
                    </div>
                    <div>
                        {/* <div className="proj-mes-text font-text5-14 _center">Senden Sie Ihren Bewerbungstext mit Premium-Plan</div> */}
                        <div className="button button-primary _width" onClick={()=>submit()}>senden</div>
                    </div>
                </>:
                <>
                    <div>
                        <div className="font-h5b-18">Sie haben Ihres Tageslimit verbraucht. Um weiter sich bewerben zu können, müssen Sie Premium Mitgliedschaft haben.</div>
                    </div>
                    <div>
                        <Link to="/services/free/" className="button button-primary _green _width">Account Upgraden</Link>
                    </div>
                </>}
            </div>
        </Modal>
    )
}
export {Mes}