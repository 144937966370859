import { useFieldArray } from "react-hook-form"
import { useRef} from "react";
import { Doc } from "./Doc";

import useAxios from '../../../hook/useAxios';
import {useLogin} from '../../../hook/useLogin';

const DocAdd = ({register,control,errors}) => {

    const {user} = useLogin();
    const axios = useAxios(user);

    // new append array filelds
    const {fields,append,remove} = useFieldArray({
        name:"doc",
        control
    });

    // image change
    const choiseFile = useRef(null);

    const addDoc = (file)=>{
        if(file){  
            if(file.size<=2000000){
                const formData = new FormData();
                formData.append("file", file);
                axios
                .post('/doc/', formData,{
                    headers:{'content-type': 'multipart/form-data'}
                })
                .then(res=>res.data)
                .then(data =>{
                    if(data.res){
                        append(data.res);
                    }
                })
                .catch(() => console.log("File Upload Error"));
            }
            choiseFile.current.value='';
        }
    };

    return (
    <div className="fi-pf-doc">
        <Doc 
            fields={fields}
            remove={remove}
            errors={errors}
            register={register}
            axios={axios}/>
        <div 
            className="button button-small _plus" 
            onClick={(e) => choiseFile.current.click()}>
            Datei hochladen
        </div>
        <input 
            type="file"
            onChange={e => addDoc(e.target.files[0])} 
            ref={choiseFile}/>
    </div>)
}

export {DocAdd}