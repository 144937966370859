import './index.scss';
import 'animate.css';

//import MetaTags from 'react-meta-tags';
import {Routes, Route} from 'react-router-dom';

// main layout
import {Main} from './pages/Main';

// main page
import {Home} from './pages/home/Home';
import {About} from './pages/home/About';
import {Contacts} from './pages/home/Contacts';
import {Notfound} from './pages/home/Notfound';
import {Simple} from './pages/home/Simple';

// other
import {Firm} from './pages/other/Firm';
import {Free} from './pages/other/Free';
import {Proj} from './pages/other/Proj';

import {FirmDet} from './pages/other/FirmDet';
import {ProjDet} from './pages/other/ProjDet';
import {FreeDet} from './pages/other/FreeDet';

import {Proof} from './pages/other/Proof';

//! temp
import { Game } from "./pages/Game";

import { News } from './pages/other/News';
import { NewsDet } from './pages/other/NewsDet';

// othet pages
import {ServiceFirm} from './pages/services/ServiceFirm';
import {ServiceFree} from './pages/services/ServiceFree.jsx';
import {InfoFirm} from './pages/services/InfoFirm';
import {InfoFree} from './pages/services/InfoFree';

// firm account
import {FreeContent} from './pages/free/Content'
import {FirmContent} from './pages/firm/Content'
import {AdminContent} from './pages/admin/Content'

import {Login} from './components/Login/Login.jsx';
import {RequireAuth} from './auth/RequireAuth';

import {useLogin} from "./hook/useLogin";

import { Cookie } from "./components/Cookie";

import SwiperCore,{Navigation,Pagination,Lazy,Autoplay,Grid} from 'swiper';

SwiperCore.use([Navigation,Pagination,Lazy,Autoplay,Grid]);

function App() {

  const {user,modal}=useLogin();

  //console.log(user);

  // <MetaTags>
  //         {config?
  //           <>
  //             <title>{config.title}</title>
  //             <meta id="meta-description" name="description" content={config.descr} />
  //             <meta id="og-title" property="og:title" content={config.title} />
  //           </>:<></>
  //         }
  //         {/* <meta id="og-image" property="og:image" content="/soc.jpg" /> */}
  //     </MetaTags>

  return (
    <>
      <Cookie/>
      <Routes>
        <Route path="/" element ={<Main/>}>
          <Route index element={<Home/>}/>
          <Route path="about" element={<About/>}/>
          <Route path="contacts" element={<Contacts/>}/>

          <Route path="game" element={<Game/>}/>

          <Route path="page/:page" element={<Simple/>}/>

          <Route path="news/" element={<News/>}/>
          <Route path="news/:id" element={<NewsDet/>}/>

          <Route path="projs" element={<Proj/>}/>
          <Route path="projs/:page" element={<Proj/>}/>
          <Route path="proj/:id" element={<ProjDet/>}/>

          <Route path="proof/:cod" element={<Proof/>}/>
          
          <Route path="firms" element={<Firm/>}/>
          <Route path="firms/:page" element={<Firm/>}/>
          <Route path="firm/:id" element={<FirmDet/>}/>

          <Route path="freelances" element={<Free/>}/>
          <Route path="freelances/:page" element={<Free/>}/>
          <Route path="freelance/:id" element={<FreeDet/>}/>

          <Route path="services/firm" element={<ServiceFirm/>}/>
          <Route path="services/free" element={<ServiceFree/>}/>
          <Route path="services/firm/info" element={<InfoFirm/>}/>
          <Route path="services/free/info" element={<InfoFree/>}/>

          <Route path="account/*" element={
            <RequireAuth>
              {
                user?
                    user.type===0?<FreeContent/>:
                    user.type===1?<FirmContent/>:
                    user.type===2?<AdminContent/>:<></>
                :<></> 
              }
            </RequireAuth>
          }/>

          <Route path="*" element={<Notfound/>}/>
        </Route>
      </Routes>
      
      {/* login modal set in context */}
      {modal.open && <Login/>}
    </>
  );
}

export default App;
