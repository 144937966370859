import "./MesDel.scss";

import {Modal} from '../Modal';

const MesDel = ({title,del,show,setShow,children}) =>{

    return (
        show?<Modal setShow={setShow}>
            <div className="mes-del">
                <div>
                    <div className="font-h2b-32 _center">{title?title:'Löschen'}</div>
                </div>
                <div>
                    <div className="_center">
                        {children}
                    </div>
                </div>
                <div>
                    <div className="mes-del-button">
                        <div className="button button-primary" onClick={()=>setShow(false)}>Nein</div>
                        <div className="button button-secondary" onClick={()=>del()}>Ja</div>
                    </div>
                </div>
            </div>
        </Modal>:<></>
    )
}
export {MesDel}