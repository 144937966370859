import { useState } from "react"

const Section = ({children,name,def}) => {

    const [active,setActive] = useState(def || false);

    return (
        <div className={`fr-fr-item section-content ${active?'active':''}`}>
            <div className="section" onClick={()=>setActive(!active)}>
            <div>{name}</div>
                <div className="icons _arrow _small inputs-ico"></div>
            </div>
            {children}
        </div>
    )
}

export {Section}